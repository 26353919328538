
export enum IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST {
    REQUEST = "getPartsCashOrderLookup/GET_PARTS_CASH_ORDER_LOOKUP_REQUEST",
    SUCCESS = "getPartsCashOrderLookup/GET_PARTS_CASH_ORDER_LOOKUP_SUCCESS",
    FAILED = "getPartsCashOrderLookup/GET_PARTS_CASH_ORDER_LOOKUP_FAILED"
};

export interface IGetPartsCashOrderLookupRequest {
    token: string;
    keywords: string;
    PageSize: number;
    PageNumber: number;
};

export interface IGetPartsCashOrderLookup {
    customers: ICashOrderCustomer[];
    parts: ICashOrderPart[];
};

export interface ICashOrderCustomer {
    id: number;
    last_Name: string;
    first_Name: string;
};

export interface ICashOrderPart {
    id: number;
    part_No: string;
    name: string;
};