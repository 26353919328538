import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR } from "../../shared/theme";
import LAPagination from "../../shared/pagination";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { PartsResponse } from "../../shared/publicInterfaces";
import { PartList } from "./dismantledList";
import queryString from "query-string";
import { ROUTE } from "../../routes";
import SearchBox from "../../shared/searchBox";
import { DismantledPartDetailPopup } from "./dismantledDetailPopUp";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import { login } from "../../../redux/login/loginAccessor";
import { ICart } from "../../../redux/cart/cartConstants";
import { getCart } from "../../../redux/cart/cartAccessor";
import { AdminCheck, callRouteWithQueryString, getIP, ONE, OrderdeskCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import { cartLoadAction } from "../../../redux/cart/cartActions";
import { IDismantledFilter } from "../../../redux/parts/facets/partsFacetConstants";
import { IPartDismantledEquipment, IPartDismantledEquipmentRequest } from "../../../redux/getPartsDismantledEquipments/getPartsDismantledEquipmentConstants";
import { getPartsDismantledEquipments } from "../../../redux/getPartsDismantledEquipments/getPartsDismantledEquipmentAccessor";
import { getPartsDismantledEquipmentsLoadAction } from "../../../redux/getPartsDismantledEquipments/getPartsDismantledEquipmentActions";
import PageSpacing from "../../shared/pageSpacing";
import LAAutoComplete from "../../shared/autoComplete";
import { IDismantledLookups, IDismantledLookupsRequest } from "../../../redux/parts/getDismantledLookups/getDismantledLookupsConstants";
import { getDismantledLookups } from "../../../redux/parts/getDismantledLookups/getDismantledLookupsAccessor";
import { getDismantledLookupsLoadAction } from "../../../redux/parts/getDismantledLookups/getDismantledLookupsActions";
import { IGetSEOKeywordsRequest } from "../../../redux/getSEOKeywords/getSEOKeywordsConstants";
import { getSEOKeywordsLoadAction } from "../../../redux/getSEOKeywords/getSEOKeywordsActions";
import { getSEOKeywordsStatus } from "../../../redux/getSEOKeywords/getSEOKeywordsAccessor";
import { LASecondaryButton } from "../../shared/buttons";
import { ListIcon } from "../../shared/icons";


interface IDismantledPartsComponentStoreProps {
    cart: Server<ICart[]>;
    loginStatus: Server<ILoginResponse>;
    seoKeywords: Server<PartsResponse<string>>;
    getDropDownData: Server<PartsResponse<IDismantledLookups>>;
    PartDismantledEquipmentList: Server<PartsResponse<IPartDismantledEquipment[]>>;
};

interface IDismantledPartsComponentDispatchProps {
    cartRequest: () => unknown;
    getSEOKeywordsRequest: (data: IGetSEOKeywordsRequest) => unknown;
    getDropDownDataRequest: (request: IDismantledLookupsRequest) => unknown;
    RequestDismantledEquipmentList: (request: IPartDismantledEquipmentRequest) => unknown;
};

interface IDismantledPartsComponentState {
    cart: ICart[];
    data: IPartDismantledEquipment[];
    open: boolean;
    searchText: string;
    selectedMake: string;
    selectedEquipment: string;
    partPopup: {
        part: IPartDismantledEquipment | undefined;
        open: boolean;
    };
    currentPage: number;
    totalRecords: number;
};

const DismantledPartsStyles = styled(LAPaperWithPadding)`

    .deskFacet {
        display: block;
    };

    .mobFacet {
        display: none;
    };

    .manualBtn{
        display: block;
        margin-top: 10px;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .deskFacet {
            display: none;
        };

        .mobFacet {
            display: block;
        };
    };
`;

type IDismantledPartsComponentProps =
    RouteComponentProps
    & IDismantledPartsComponentStoreProps
    & IDismantledPartsComponentDispatchProps;

class DismantledPartsComponent extends PureComponent<IDismantledPartsComponentProps, IDismantledPartsComponentState> {

    public constructor(props: IDismantledPartsComponentProps) {
        super(props);
        this.state = {
            cart: [],
            data: [],
            open: false,
            searchText: "",
            selectedEquipment: "",
            selectedMake: "",
            currentPage: ONE,
            partPopup: {
                open: false,
                part: undefined
            },
            totalRecords: ZEROTH
        };
        this.keyWordSearch = this.debounce(this.keyWordSearch, 500);
    }

    public async componentDidMount(): Promise<void> {
        this.props.getSEOKeywordsRequest({ request: { page: "dismantled" } });
        await this.handleServerCall();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IDismantledPartsComponentProps): void {
        if ((this.props.PartDismantledEquipmentList !== prevProps.PartDismantledEquipmentList) || (this.props.cart !== prevProps.cart)) {
            this.setDataToState();
        };
    }

    public render(): ReactNode {

        const { PartDismantledEquipmentList, loginStatus, getDropDownData, seoKeywords } = this.props;
        const { totalRecords, currentPage, searchText, data, cart, partPopup, selectedMake, selectedEquipment } = this.state;
        const dropDown = hasPayload(getDropDownData) ? getDropDownData.payload.response : { makes: [], equipmentTypes: [] };
        const equipTypes = convertList(dropDown.equipmentTypes ?? []);
        const makes = convertList(dropDown.makes ?? []);

        const onMakeChange = (e: unknown, value: IConvertList) => this.onFacetUpdateClick(value !== null ? { Make: value.name, Equipment: selectedEquipment } : { Make: "", Equipment: selectedEquipment });
        const onEquipmentChange = (e: unknown, value: IConvertList) => this.onFacetUpdateClick(value !== null ? { Make: selectedMake, Equipment: value.name } : { Make: selectedMake, Equipment: "" });

        return (
            <PageSpacing title="Dismantled" description="Dismantled Parts" keywords={hasPayload(seoKeywords) ? seoKeywords.payload.response : undefined}>
                <DismantledPartsStyles>
                    <LAGrid spacing={2}>

                        <LAGridItem xs={12}>

                        <LASecondaryButton
                                label="View Parts"
                                className="pull-left"
                                onClick={this.handleParts}
                                startIcon={<ListIcon color={RED_COLOR} />}
                            />

<LASecondaryButton
                                label="View Available Manuals"
                                className="pull-left manualBtn"
                                onClick={this.handleManualClick}
                                
                            />
                            
                            <h2 className="text-center">DISMANTLED EQUIPMENT</h2>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                            <LAAutoComplete
                                name="make"
                                multiple={false}
                                autoHighlight={true}
                                getOptionLabel="name"
                                dropDownPlaceHolder="Make"
                                onChange={onMakeChange}
                                filterSelectedOptions={true}
                                selectionRemove={undefinedFunction}
                                option={makes}
                                value={hasPayload(getDropDownData) && selectedMake !== "" ? makes.find(s => s.name === selectedMake) : ""}
                                defaultValue={hasPayload(getDropDownData) && selectedMake !== "" ? makes.find(s => s.name === selectedMake) : ""}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                            <LAAutoComplete
                                name="equipmentType"
                                multiple={false}
                                autoHighlight={true}
                                getOptionLabel="name"
                                dropDownPlaceHolder="Equipment"
                                onChange={onEquipmentChange}
                                filterSelectedOptions={true}
                                selectionRemove={undefinedFunction}
                                option={equipTypes}
                                value={hasPayload(getDropDownData) && selectedEquipment !== "" ? equipTypes.find(s => s.name === selectedEquipment) : ""}
                                defaultValue={hasPayload(getDropDownData) && selectedEquipment !== "" ? equipTypes.find(s => s.name === selectedEquipment) : ""}
                            />
                        </LAGridItem>


                        <LAGridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                            <SearchBox
                                fullWidth={true}
                                text={searchText}
                                placeHolder="Search"
                                onSubmit={undefinedFunction}
                                onChange={this.handleSearchTextChange}
                                searchStatus={STATUS_ENUM.SUCCEEDED}
                            />

                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAPagination
                                rowsPerPage={20}
                                className="mt-5"
                                currentPage={currentPage}
                                numberOfItems={totalRecords}
                                onPageChange={this.handlePageChange}
                            />
                        </LAGridItem>

                        <LAGridItem sm={12} md={12} lg={12}>
                            <LAGrid spacing={1}>

                                <LAGridItem xs={12}>
                                    <LAPaperWithPadding>
                                        <PartList
                                            cart={cart}
                                            data={data}
                                            onPartClick={this.onPartClick}
                                            dataStatus={PartDismantledEquipmentList.kind}
                                            isAdmin={(hasPayload(loginStatus) && ((loginStatus.payload.customer.email === AdminCheck)
                                                || (loginStatus.payload.customer.email === OrderdeskCheck))) ? true : false}
                                        />
                                    </LAPaperWithPadding>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAPagination
                                        rowsPerPage={20}
                                        className="mt-5"
                                        currentPage={currentPage}
                                        numberOfItems={totalRecords}
                                        onPageChange={this.handlePageChange}
                                    />
                                </LAGridItem>
                            </LAGrid>
                        </LAGridItem>

                    </LAGrid>

                    {(partPopup.open && (partPopup.part !== undefined)) &&
                        <DismantledPartDetailPopup
                            cart={cart}
                            open={partPopup.open}
                            data={partPopup.part}
                            onClose={this.partPopUp}
                        />}
                </DismantledPartsStyles>
            </PageSpacing>
        );
    }

    private handleParts = (): void => {
        this.props.history.push(ROUTE.PARTS());
    };

    private handleManualClick = (): void => {
        window.open('https://portal.propsense.com/parts/manuals/manual.pdf', '_blank', 'fullscreen=yes');
    };


    private onPartClick = (part: IPartDismantledEquipment): void => {
        this.setState({
            partPopup: {
                part,
                open: true
            }
        });
    };

    private partPopUp = (): void => {
        this.setState({
            partPopup: {
                open: false,
                part: undefined
            }
        });
    };

    private checkCart = (): void => {
        if (hasPayload(this.props.cart))
            this.setState({ cart: this.props.cart.payload });
    };

    private debounce = (fn: any, delay: any): any => {
        let timer: any = null;
        return (...args: any): any => {
            const context = this;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    };

    private onFacetUpdateClick = async (values: IDismantledFilter): Promise<void> => {
        await this.setState({ selectedMake: values.Make, selectedEquipment: values.Equipment });
        callRouteWithQueryString({
            route: this.props, pathName: ROUTE.DISMANTLED(),
            search: { keyword: this.state.searchText, filter: values, pageNumber: this.state.currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private keyWordSearch = async (): Promise<void> => {
        await callRouteWithQueryString({
            route: this.props, pathName: ROUTE.DISMANTLED(),
            search: { keyword: this.state.searchText, filter: { Make: this.state.selectedMake, Equipment: this.state.selectedEquipment }, pageNumber: this.state.currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private handleSearchTextChange = async (searchTextString: string): Promise<void> => {
        this.setState({ searchText: searchTextString });
        this.keyWordSearch();
    };


    private handlePageChange = async (currentPage?: number, rowsPerPage?: number): Promise<void> => {
        this.setState({ currentPage: currentPage ?? 1 });
        await callRouteWithQueryString({
            route: this.props, pathName: ROUTE.DISMANTLED(),
            search: { keyword: this.state.searchText, filter: { Make: this.state.selectedMake, Equipment: this.state.selectedEquipment }, pageNumber: currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private setDataToState = (): void => {
        this.checkCart();

        if (hasPayload(this.props.PartDismantledEquipmentList)) {
            const query: any = queryString.parse(this.props.location.search);
            const val = this.props.PartDismantledEquipmentList.payload;
            this.setState({
                data: val.response,
                totalRecords: val.totalRecords ?? 0,
                searchText: query.keyword ? query.keyword : "",
                currentPage: query.pageNumber ? Number(query.pageNumber) : 1,
                selectedMake: query.filter ? JSON.parse(query.filter).Make : "",
                selectedEquipment: query.filter ? JSON.parse(query.filter).Equipment : ""
            });
        };

        if (isNotLoaded(this.props.PartDismantledEquipmentList))
            this.handleServerCall();

        this.props.getDropDownDataRequest({
        });
    };

    private handleServerCall = async (): Promise<void> => {
        const query: any = queryString.parse(this.props.location.search);

        this.setState({
            searchText: query.keyword ? query.keyword : "",
            currentPage: query.pageNumber ? Number(query.pageNumber) : 1,
            selectedMake: query.filter ? JSON.parse(query.filter).Make : "",
            selectedEquipment: query.filter ? JSON.parse(query.filter).Equipment : ""
        });

        const ip = await getIP();
        
        this.props.RequestDismantledEquipmentList({
            PageSize: 20,
            Keywords: this.state.searchText,
            request: {
                ip,
                Make: this.state.selectedMake,
                Equipment: this.state.selectedEquipment
            },
            PageNumber: query.pageNumber ? Number(query.pageNumber) : 1
        });
    };
}

const mapStateToProps = (state: IStore): IDismantledPartsComponentStoreProps => ({
    cart: getCart(state),
    loginStatus: login(state),
    seoKeywords: getSEOKeywordsStatus(state),
    getDropDownData: getDismantledLookups(state),
    PartDismantledEquipmentList: getPartsDismantledEquipments(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IDismantledPartsComponentDispatchProps => ({
    cartRequest: (): unknown => dispatch(cartLoadAction()),
    getSEOKeywordsRequest: (data: IGetSEOKeywordsRequest): unknown => dispatch(getSEOKeywordsLoadAction(data)),
    getDropDownDataRequest: (request: IDismantledLookupsRequest): unknown => dispatch(getDismantledLookupsLoadAction(request)),
    RequestDismantledEquipmentList: (request: IPartDismantledEquipmentRequest): unknown => dispatch(getPartsDismantledEquipmentsLoadAction(request)),
});


export default connect(mapStateToProps, mapDispatchToProps)(DismantledPartsComponent);


export interface IConvertList {
    id: string;
    name: string;
};

export const convertList = (list: any[]): IConvertList[] => {
    let response = [];
    for (let i = 0; i < list.length; i++) {
        if (list[i] !== null)
            response.push({ id: list[i], name: list[i] });
    };
    return response;
};