
export enum IGET_PARTS_BY_IDS_REQUEST {
    REQUEST = "getPartsByIds/GET_PARTS_BY_IDS_REQUEST",
    SUCCESS = "getPartsByIds/GET_PARTS_BY_IDS_SUCCESS",
    FAILED = "getPartsByIds/GET_PARTS_BY_IDS_FAILED"
};

export interface IGetPartsByIdsRequest {
    request: number[];
    token?: string;
};