
export enum IGET_CUSTOMERS_REQUEST {
    REQUEST = "getCustomers/GET_CUSTOMERS_REQUEST",
    SUCCESS = "getCustomers/GET_CUSTOMERS_SUCCESS",
    FAILED = "getCustomers/GET_CUSTOMERS_FAILED"
};

export interface IGetCustomersRequest {
    token: string;
    keywords: string;
    PageSize: number;
    PageNumber: number;
};

export interface ICustomer {
    id: number;
    first_Name: string;
    last_Name: string;
    address: string;
    city: string;
    province: string;
    country: string;
    postal_Code: string;
    phone: string;
    email: string;
    last_Login: string;
    company: string;
    billing_Country: string;
    billing_Address: string;
    billing_City: string;
    billing_Province: string;
    billing_Postal_Code: string;
};