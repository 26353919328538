import { FLUSH_DATA } from "./flushDataConstants";

export interface IFlushDataRequestAction {
    type: FLUSH_DATA.REQUEST;
}

export const flushDataRequestAction = (): IFlushDataRequestAction => ({
    type: FLUSH_DATA.REQUEST,
});

export interface IFlushDataSuccessAction {
    type: FLUSH_DATA.SUCCESS;
}

export const flushDataSuccessAction = (): IFlushDataSuccessAction => ({
    type: FLUSH_DATA.SUCCESS
});

export interface IFlushDataFailedAction {
    type: FLUSH_DATA.SUCCESS;
}

export const flushDataFailedAction = (): IFlushDataFailedAction => ({
    type: FLUSH_DATA.SUCCESS,
});