import { IGetPartsOrderByCustomer } from "../getPartsOrderByCustomer/getPartsOrderByCustomerConstants";

export enum IGET_ALL_PARTS_ORDER_REQUEST {
    REQUEST = "getAllPartsOrder/GET_ALL_PARTS_ORDER_REQUEST",
    SUCCESS = "getAllPartsOrder/GET_ALL_PARTS_ORDER_SUCCESS",
    FAILED = "getAllPartsOrder/GET_ALL_PARTS_ORDER_FAILED"
};

export interface IGetAllPartsOrderRequest {
    itemId: number;
    token: string;
    keywords: string;
    PageSize: number;
    PageNumber: number;
};

export interface IGetAllPartsOrderResponse extends IGetPartsOrderByCustomer {

};
