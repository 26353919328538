import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { addPartsCashOrderLoadFailedAction, addPartsCashOrderLoadSuccessAction, IAddPartsCashOrderLoadAction, IAddPartsCashOrderLoadFailedAction, IAddPartsCashOrderSuccessAction } from "./addPartsCashOrderActions";
import { IADD_PARTS_CASH_ORDER_REQUEST } from "./addPartsCashOrderConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";
import { IGetAllPartsCashOrders } from "../getAllPartsCashOrders/getAllPartsCashOrdersConstants";

export const addPartsCashOrderEpic: Epic = (
    action$: ActionsObservable<IAddPartsCashOrderLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddPartsCashOrderSuccessAction | IAddPartsCashOrderLoadFailedAction> =>
    action$.ofType(IADD_PARTS_CASH_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IGetAllPartsCashOrders>>(
                    END_POINTS.ADD_PARTS_CASH_ORDER,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<IGetAllPartsCashOrders>): IAddPartsCashOrderSuccessAction => addPartsCashOrderLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(addPartsCashOrderLoadFailedAction("Unable to save order changes")))
                    )
            )
        );