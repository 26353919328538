import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../redux/server";
import { PartsResponse } from "../../shared/publicInterfaces";
import queryString from "query-string";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import { login } from "../../../redux/login/loginAccessor";
import { AdminCheck, OrderdeskCheck, undefinedFunction } from "../../shared/constExports";
import LoginAndRegisterComponent from "../loginAndRegister";
import { ICustomer, IGetCustomersRequest } from "../../../redux/getCustomers/getCustomersConstants";
import { getCustomersStatus } from "../../../redux/getCustomers/getCustomersAccessor";
import { getCustomersLoadAction } from "../../../redux/getCustomers/getCustomersActions";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAIconButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import AddUpdateCustomerComponent from "./addUpdateCustomer";
import PageSpacing from "../../shared/pageSpacing";

interface ICustomersComponentStoreProps {
    loginStatus: Server<ILoginResponse>;
    customers: Server<PartsResponse<ICustomer[]>>;
};

interface ICustomersComponentDispatchProps {
    customersRequest: (data: IGetCustomersRequest) => unknown;
};

interface ICustomersComponentState {
    addUpdate: boolean;
    updateCustomer: ICustomer | undefined;
};

const CustomersStyles = styled(LAPaperWithPadding)`

    .pull-left {
        position: absolute;
        left: 1px;
        top: 1px;
    };
`;

type ICustomersComponentProps =
    RouteComponentProps
    & ICustomersComponentStoreProps
    & ICustomersComponentDispatchProps;

class CustomersComponent extends PureComponent<ICustomersComponentProps, ICustomersComponentState> {

    public constructor(props: ICustomersComponentProps) {
        super(props);
        this.state = {
            addUpdate: false,
            updateCustomer: undefined
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.handleServerCall();
        this.setDataToState();
    };

    public async componentDidUpdate(prevProps: ICustomersComponentProps): Promise<void> {
        if ((this.props.customers !== prevProps.customers)) {
            this.setDataToState();
        };
    }

    public render(): ReactNode {

        const { addUpdate, updateCustomer } = this.state;
        const { loginStatus, customers } = this.props;

        const data = hasPayload(customers) ? customers.payload.response : [];

        return (
            <PageSpacing title="Customers" description="Customers List">
            <CustomersStyles>
                <LAPaperWithPadding>
                    {!addUpdate && <LAGrid spacing={1}>

                        {(isNotLoaded(loginStatus)) && <LAGridItem xs={12}>
                            <LoginAndRegisterComponent {...this.props} />
                        </LAGridItem>}

                        {(hasPayload(loginStatus) &&
                            ((loginStatus.payload.customer.email === AdminCheck) || (loginStatus.payload.customer.email === OrderdeskCheck))
                        ) && <>
                                <LAGridItem xs={12}>
                                    <LAIconButton
                                        className="pull-left"
                                        label="Add New"
                                        icon={<AddIcon />}
                                        onClick={this.onAddClick}
                                    />

                                    <h2 className="text-center">CUSTOMERS</h2>
                                    <hr />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LADevExtremeGrid
                                        export={true}
                                        searchPanel={true}
                                        filterHeader={true}
                                        exportFileName="Customers"
                                        onEdit={this.onCustomerClick}
                                        onClick={undefinedFunction}
                                        data={data}
                                        columns={[
                                            { name: "first_Name", caption: "First Name", type: "string" },
                                            { name: "last_Name", caption: "Last Name", type: "string" },
                                            { name: "phone", caption: "Phone", type: "number" },
                                            { name: "email", caption: "Email", type: "string" },
                                            { name: "company", caption: "Company", type: "string" },
                                            { name: "address", caption: "Address", type: "string" },
                                            { name: "city", caption: "City", type: "string" },
                                            { name: "province", caption: "Province", type: "string" },
                                            { name: "country", caption: "Country", type: "string" },
                                            { name: "last_Login", caption: "Last Login", type: "date" }
                                        ]}
                                    />
                                </LAGridItem>

                            </>}
                    </LAGrid>}

                    {addUpdate && <AddUpdateCustomerComponent
                        {...this.props}
                        token={loginStatus}
                        customer={updateCustomer}
                        onAddUpdateCustomerCancel={this.onAddClick}
                    />}
                </LAPaperWithPadding>
            </CustomersStyles>
            </PageSpacing>
        );
    }

    private onCustomerClick = (e: any): void => {
        if(hasPayload(this.props.customers))
        this.setState({ addUpdate: !this.state.addUpdate, 
            updateCustomer: this.props.customers.payload.response.find((x) => x.id === e.row.data.id) 
        });
    };

    private onAddClick = (): void => {
       this.setState({ addUpdate: !this.state.addUpdate, updateCustomer: undefined });
    };

    private setDataToState = async (): Promise<void> => {
        if (isNotLoaded(this.props.customers))
            this.handleServerCall();
    };

    private handleServerCall = (): void => {
        if (hasPayload(this.props.loginStatus) &&
            ((this.props.loginStatus.payload.customer.email === AdminCheck)
                || (this.props.loginStatus.payload.customer.email === OrderdeskCheck))) {
            const query: any = queryString.parse(this.props.location.search);
            this.props.customersRequest({
                PageSize: 20,
                token: this.props.loginStatus.payload.token,
                keywords: query.keyword ? query.keyword : "",
                PageNumber: query.pageNumber ? Number(query.pageNumber) : 1
            });
        };
    };

}

const mapStateToProps = (state: IStore): ICustomersComponentStoreProps => ({
    loginStatus: login(state),
    customers: getCustomersStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ICustomersComponentDispatchProps => ({
    customersRequest: (data: IGetCustomersRequest): unknown => dispatch(getCustomersLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(CustomersComponent);