import { IAddPartsOrderItem } from "../addPartsOrder/addPartsOrderConstants";

export enum IUPDATE_PARTS_CASH_ORDER_REQUEST {
    REQUEST = "updatePartsCashOrder/UPDATE_PARTS_CASH_ORDER_REQUEST",
    SUCCESS = "updatePartsCashOrder/UPDATE_PARTS_CASH_ORDER_SUCCESS",
    FAILED = "updatePartsCashOrder/UPDATE_PARTS_CASH_ORDER_FAILED"
};

export interface IUpdatePartsCashOrderRequest {
    token?: string;
    request: IUpdatePartsCashOrder;
};

export interface IUpdatePartsCashOrder {
    ID: number;
    Customer_ID: number;
    Shipping_Address: string;
    Shipping_Province: string;
    Shipping_City: string;
    Shipping_Country: string;
    Shipping_Postal_Code: string;
    Modified: string;
    Modified_By: string;
    Notes: string;
    Status: string;
    Total_Items_Cost: number;
    Shipping_Cost: number;
    Tax: number;
    Total_Cost: number;
    USD_Rate: number;
    Items: IUpdatePartsCashOrderItem[];
};

export interface IUpdatePartsCashOrderItem extends IAddPartsOrderItem {
    ID: number;
}


export interface IUpdatePartsCashOrderResponse {

};
