import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";
import { getSEOKeywordsLoadFailedAction, getSEOKeywordsLoadSuccessAction, IGetSEOKeywordsLoadAction, IGetSEOKeywordsLoadFailedAction, IGetSEOKeywordsSuccessAction } from "./getSEOKeywordsActions";
import { IGET_SEO_KEYWORDS_REQUEST } from "./getSEOKeywordsConstants";

export const getSEOKeywordsEpic: Epic = (
    action$: ActionsObservable<IGetSEOKeywordsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSEOKeywordsSuccessAction | IGetSEOKeywordsLoadFailedAction> =>
    action$.ofType(IGET_SEO_KEYWORDS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.SEO_KEYWORDS,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<string>): IGetSEOKeywordsSuccessAction => getSEOKeywordsLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(getSEOKeywordsLoadFailedAction("Unable to get SEO Keywords")))
                    )
            )
        );