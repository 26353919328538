import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IAddPartsOrderLoadAction, IAddPartsOrderLoadFailedAction, IAddPartsOrderSuccessAction } from "./addPartsOrderActions";
import { IAddPartsOrderResponse, IADD_PARTS_ORDER_REQUEST } from "./addPartsOrderConstants";


type Actions =
    IAddPartsOrderLoadAction
    | IAddPartsOrderLoadFailedAction
    | IAddPartsOrderSuccessAction
    | IFlushDataSuccessAction;

export const AddPartsOrderReducer = (state: Server<PartsResponse<IAddPartsOrderResponse>> = notLoaded, action: Actions): Server<PartsResponse<IAddPartsOrderResponse>> => {
    switch (action.type) {
        case IADD_PARTS_ORDER_REQUEST.REQUEST:
            return loading;

        case IADD_PARTS_ORDER_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IADD_PARTS_ORDER_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};