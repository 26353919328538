
export enum IGET_PARTS_REQUEST {
    REQUEST = "getParts/GET_PARTS_REQUEST",
    SUCCESS = "getParts/GET_PARTS_SUCCESS",
    FAILED = "getParts/GET_PARTS_FAILED"
};

export interface IGetPartsRequest {
    request: {
        manufacturers: number[];
        models: string[];
        keyword: string;
        ip: string;
    };
    PageNumber: number;
    PageSize: number;
    token?: string;
};

export interface IPart {
    id: number;
    name: string;
    part_No: string;
    alternate_Part_No: string;
    model: string;
    make: string;
    condition: string;
    location: string;
    type: string;
    description: string;
    notes: string;
    year: string;
    weight: number;
    available_Quantity: number;
    price: string;
    photos_1?: string;
    photos_2?: string;
    photos_3?: string;
};
