import { ReactNode, PureComponent } from "react";
import styled from "styled-components";
import { IPartsFacetResponse } from "../../../redux/parts/facets/partsFacetConstants";
import { LAButton } from "../buttons";
import LAChip from "../chip";
import { ZEROTH } from "../constExports";
import LAGrid from "../grid";
import LAGridItem from "../gridList";
import { HasClass } from "../publicInterfaces";
import { MEDIA_QUERY_PHONE } from "../theme";
import LAFacetFiltersList from "./facetFiltersList";

interface ILAFacetProps extends HasClass {
    preSelected: IPartsFacetResponse;
    numberOfItems: number;
    facets: IPartsFacetResponse;
    onFacetUpdateClick: (values: IPartsFacetResponse) => void;
};
interface ILAFacetState {
    fixFilter: boolean;
    viewMore: any;
    selectedValues: IPartsFacetResponse;
    facetValues: IPartsFacetResponse | undefined;
};

const FacetStyles = styled.div`
 
    padding: 1.75rem 20px !important;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        padding-top: 0.75rem !important;
        
        .filterWrapper {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 5;
        };
    };
`;

class LAFacet extends PureComponent<ILAFacetProps, ILAFacetState> {

    public constructor(props: ILAFacetProps) {
        super(props);

        this.state = {
            facetValues: undefined,
            selectedValues: { 
                models: [],
                manufacturers: []
            },
            fixFilter: false,
            viewMore: {
                id: "",
                count: 3
            }
        };
    }

    public componentDidMount(): void {
        this.setState({ selectedValues: this.props.preSelected });
    };

    public componentDidUpdate(prevProps: ILAFacetProps): void {
        if(prevProps.preSelected !== this.props.preSelected)
            this.setState({ selectedValues: this.props.preSelected });
    };


    public render(): ReactNode {

        const { selectedValues, fixFilter, viewMore } = this.state;
        const { numberOfItems, facets } = this.props;

        return (
            <FacetStyles>

                <LAGrid direction="column">
                    {(Object.values(selectedValues).length > ZEROTH || (numberOfItems === ZEROTH)) &&
                        <LAGridItem xs={12} className="mb-2">
                            <LAGrid direction="column">
                                <LAButton
                                    label="CLEAR ALL"
                                    className="mb-2"
                                    onClick={this.handleClearAllFacets}
                                />

                                {selectedValues.manufacturers.length > 0 &&
                                    <>
                                        <strong>Selected Makes</strong>
                                        {selectedValues.manufacturers
                                            .map((x, idx): JSX.Element => (
                                                <LAGridItem xs={12} md={12} key={idx}>
                                                    <LAChip
                                                        className="mt-2 mb-2"
                                                        label={x.manufacturer}
                                                        onClick={(): void => this.handleFacetRemove(x.manufacturerID, "manufacture", idx)}
                                                        onDelete={(): void => this.handleFacetRemove(x.manufacturerID, "manufacture", idx)}
                                                    />
                                                </LAGridItem>
                                            ))}
                                    </>}

                                    {selectedValues.manufacturers.length > 0 && selectedValues.models.length > 0 &&
                                    <>
                                        <strong>Selected Model</strong>
                                        {selectedValues.models
                                            .map((x, idx): JSX.Element => (
                                                <LAGridItem xs={12} md={12} key={idx}>
                                                    <LAChip
                                                        className="mt-2 mb-2"
                                                        label={x.model}
                                                        onClick={(): void => this.handleFacetRemove(x.model, "model", idx)}
                                                        onDelete={(): void => this.handleFacetRemove(x.model, "model", idx)}
                                                    />
                                                </LAGridItem>
                                            ))}
                                    </>}
                            </LAGrid>
                        </LAGridItem>
                    }

                    <LAGridItem xs={12}>
                        <div id="filterBox" className={`${fixFilter ? "filterWrapper" : ""}`}>
                            <LAGrid>
                                <LAGridItem xs={12}>
                                    <LAFacetFiltersList
                                        viewMore={viewMore}
                                        facets={facets ? facets : { manufacturers: [], models: [] }}
                                        onViewMoreClick={this.onViewMoreClick}
                                        selectedValues={selectedValues}
                                        onIndividualFacetClick={this.handleFacetClick}
                                    />
                                </LAGridItem>
                            </LAGrid>
                        </div>
                    </LAGridItem>


                </LAGrid>
            </FacetStyles>
        );
    };


    private onViewMoreClick = (type: string): void => {
        this.setState({
            viewMore: {
                id: type,
                count: this.state.viewMore.count + 10
            }
        });
    };

    private handleClearAllFacets = (): void => {
        this.setState({ selectedValues: { manufacturers: [], models: [] } });
        this.callSearch({ manufacturers: [], models: [] });
    };

    private callSearch = (values: IPartsFacetResponse): void => {
        this.props.onFacetUpdateClick(values);
    };

    private handleFacetRemove = (id: number | string, type: string, index: number): void => {
        const currentSelected: IPartsFacetResponse = { ...this.state.selectedValues };

        if (type === "manufacture")
            currentSelected.manufacturers.splice(index, 1);
        
        if (type === "model")
            currentSelected.models.splice(index, 1);

        this.setState({ selectedValues: currentSelected });
        this.callSearch(currentSelected);
    };

    private handleFacetClick = (id: number | string, type: string): void => {
        const currentSelected: IPartsFacetResponse = { ...this.state.selectedValues };

        if(type === "manufacture"){
            const find = this.props.facets.manufacturers.find(x => x.manufacturerID === id);

            let check = false;
            currentSelected.manufacturers.forEach(x => {
                if (x.manufacturerID === id)
                    check = true;
            });
    
            if (check === false && find)
                currentSelected.manufacturers.push(find);
        }

        if(type === "model"){
            const find = this.props.facets.models.find(x => x.model === id);

            let check = false;
            currentSelected.models.forEach(x => {
                if (x.model === id)
                    check = true;
            });
    
            if (check === false && find)
                currentSelected.models.push(find);
        }

        this.setState({ selectedValues: currentSelected });
        this.callSearch(currentSelected);
    };

};


export default LAFacet;
