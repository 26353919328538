import { ReactNode, PureComponent } from "react";
import { IManufactureFacet, IModelFacet, IPartsFacetResponse } from "../../../redux/parts/facets/partsFacetConstants";
import { ZEROTH } from "../constExports";
import LAGrid from "../grid";
import LAGridItem from "../gridList";
import LALinkButton from "../linkButton";
import { HasClass } from "../publicInterfaces";



interface ILAFacetFilterListProps extends HasClass {
    viewMore: any;
    facets: IPartsFacetResponse;
    selectedValues: IPartsFacetResponse;
    onViewMoreClick: (facetGroupKey: string) => void;
    onIndividualFacetClick: (id: number | string, type: string) => void;
};

class LAFacetFiltersList extends PureComponent<ILAFacetFilterListProps> {

    public render(): ReactNode {

        const { viewMore, onIndividualFacetClick, onViewMoreClick, facets } = this.props;

        return (
            <LAGrid direction="column">
                <LAGridItem xs={12}>
                    <div>
                        <strong>Make</strong>
                    </div>
                </LAGridItem>

                {(facets.manufacturers !== null) && <LAGridItem xs={12}>
                    <LAGrid spacing={0}>
                        {this.manufacturerFacets()
                            .slice(ZEROTH, ("manufacture" === viewMore.id) ? viewMore.count : 10)
                            .filter(x => !this.props.selectedValues.manufacturers.includes(x))
                            .map((x: IManufactureFacet, index: number): JSX.Element =>
                                <LAGridItem xs={12} key={x.manufacturerID}>
                                    <LALinkButton
                                        color="inherit"
                                        label={x.manufacturer}
                                        badgeCount={x.count > 0 ? x.count : undefined}
                                        onClick={(): void => onIndividualFacetClick(x.manufacturerID, "manufacture")}
                                    >
                                    </LALinkButton>
                                </LAGridItem>
                            )}
                    </LAGrid>

                    {((viewMore.id === "manufacture") ? (facets.manufacturers.length > viewMore.count) : (facets.manufacturers.length > 10)) && <LALinkButton
                        onClick={(): void => {
                            onViewMoreClick("manufacture");
                        }}
                        className="mt-2"
                        label="View More"
                        fontSize={14}
                    />}

                </LAGridItem>}


                {this.props.selectedValues.manufacturers.length > 0 && <>
                
                <LAGridItem xs={12} className="mt-4">
                    <div>
                        <strong>Models</strong>
                    </div>
                </LAGridItem>

                {(facets.models !== null) && <LAGridItem xs={12}>
                    <LAGrid spacing={0}>
                        {this.modelFacets()
                            .slice(ZEROTH, ("model" === viewMore.id) ? viewMore.count : 10)
                            .filter(x => !this.props.selectedValues.models.includes(x))
                            .map((x: IModelFacet, index: number): JSX.Element =>
                                <LAGridItem xs={12} key={x.model}>
                                    <LALinkButton
                                        color="inherit"
                                        label={x.model}
                                        badgeCount={x.count > 0 ? x.count : undefined}
                                        onClick={(): void => onIndividualFacetClick(x.model, "model")}
                                    >
                                    </LALinkButton>
                                </LAGridItem>
                            )}
                    </LAGrid>

                    {((viewMore.id === "model") ? (facets.models.length > viewMore.count) : (facets.models.length > 10)) && <LALinkButton
                        onClick={(): void => {
                            onViewMoreClick("model");
                        }}
                        className="mt-2"
                        label="View More"
                        fontSize={14}
                    />}

                </LAGridItem>}
                </>}

            </LAGrid>
        );
    }

    private modelFacets = (): IModelFacet[] => {
        const response: IModelFacet[] = [];
        const selected = this.props.selectedValues.models;
        if(selected.length > 0){
            this.props.facets.models.forEach(x => {
                if(!selected.some(q => q.model === x.model)){
                    response.push(x);
                }
            });
            return response;
        } else {
            return this.props.facets.models;
        }
    };

    private manufacturerFacets = (): IManufactureFacet[] => {
        const response: IManufactureFacet[] = [];
        const selected = this.props.selectedValues.manufacturers;
        if(selected.length > 0){
            this.props.facets.manufacturers.forEach(x => {
                if(!selected.some(q => q.manufacturerID === x.manufacturerID)){
                    response.push(x);
                }
            });
            return response;
        } else {
            return this.props.facets.manufacturers;
        }
    };

}

export default LAFacetFiltersList;
