import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { ROUTE } from "../routes";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { BLACK_COLOR, WHITE_COLOR } from "../shared/theme";

const MobileFooterStyles = styled.div`
    .main-footer {
        background-color: ${BLACK_COLOR};
        width:100%;
        left: 0;
        bottom: 0; 
        overflow-x: hidden;
        overflow-y: hidden;
        position: relative;
    };

    .home-page-footer {
        position: fixed;
    };

    strong {
        color: ${WHITE_COLOR};
        font-size: 8px;
    };
`;


type IMobileFooterProps =
    RouteComponentProps;

class MobileFooter extends PureComponent<IMobileFooterProps> {

    public render(): ReactNode {

        return (
            <MobileFooterStyles>
                <div className={window.location.pathname === ROUTE.INDEX() ? "main-footer home-page-footer" : "main-footer"}>
                    <LAGrid spacing={0} className="text-center" justify="space-evenly" alignItems="center">

                        <LAGridItem xs={6} sm={6} md={3}>
                            <strong>Edmonton, AB, Canada, T6P 0C9</strong>
                        </LAGridItem>

                        <LAGridItem xs={6} sm={6} md={3}>
                            <strong>Monday - Friday -- 8AM - 5PM (Mountain Time)</strong>
                        </LAGridItem>

                        <LAGridItem xs={6} sm={6} md={3}>
                            <strong>780-619-3227</strong>
                        </LAGridItem>
                        
                        <LAGridItem xs={6} sm={6} md={3}>
                            <strong>sales.parts@sureway.ca</strong>
                        </LAGridItem>

                        <LAGridItem xs={6} sm={6} md={4}>
                            <strong>&copy; {new Date().getFullYear()} Sureway Construction Group</strong>
                        </LAGridItem>

                    </LAGrid>
                </div>
            </MobileFooterStyles>
        );
    }
}


export default MobileFooter;