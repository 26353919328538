
export enum IGET_ALL_PARTS_CASH_ORDERS_REQUEST {
    REQUEST = "getAllPartsCashOrders/GET_ALL_PARTS_CASH_ORDERS_REQUEST",
    SUCCESS = "getAllPartsCashOrders/GET_ALL_PARTS_CASH_ORDERS_SUCCESS",
    FAILED = "getAllPartsCashOrders/GET_ALL_PARTS_CASH_ORDERS_FAILED"
};

export interface IGetAllPartsCashOrdersRequest {
    token: string;
    keywords: string;
    PageSize: number;
    PageNumber: number;
};

export interface IGetAllPartsCashOrders {
    id: number;
    date: string;
    customer_ID: number;
    invoice_No: string;
    status: string;
    notes: string;
    total_Items_Cost: number;
    tax: number;
    total_Cost: number;
    payment_Details: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    customer: string;
    items: IGetAllPartsCashOrderItem[];
};

export interface IGetAllPartsCashOrderItem {
    id: number;
    order_ID: number;
    part_No: string;
    quantity: number;
    price: number;
    notes: string;
    total_Cost: number;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};