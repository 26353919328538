import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IGetDismantledLookupsLoadAction, IGetDismantledLookupsLoadFailedAction, IGetDismantledLookupsSuccessAction } from "./getDismantledLookupsActions";
import { IGET_LOOKUPS_REQUEST, IDismantledLookups } from "./getDismantledLookupsConstants";
import { IFlushDataSuccessAction } from "../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../flushData.tsx/flushDataConstants";

type Actions =
    | IGetDismantledLookupsLoadAction
    | IGetDismantledLookupsSuccessAction
    | IGetDismantledLookupsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetDismantledLookupsReducer = (state: Server<PartsResponse<IDismantledLookups>> = notLoaded, action: Actions): Server<PartsResponse<IDismantledLookups>> => {
    switch (action.type) {
        case IGET_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};