import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IUPDATE_PARTS_CASH_ORDER_REQUEST } from "./updatePartsCashOrderConstants";
import { IUpdatePartsCashOrderLoadAction, IUpdatePartsCashOrderLoadFailedAction, IUpdatePartsCashOrderSuccessAction } from "./updatePartsCashOrderActions";
import { IGetAllPartsCashOrders } from "../getAllPartsCashOrders/getAllPartsCashOrdersConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";


type Actions =
    IUpdatePartsCashOrderLoadAction
    | IUpdatePartsCashOrderLoadFailedAction
    | IUpdatePartsCashOrderSuccessAction
    | IFlushDataSuccessAction;

export const UpdatePartsCashOrderReducer = (state: Server<PartsResponse<IGetAllPartsCashOrders>> = notLoaded, action: Actions): Server<PartsResponse<IGetAllPartsCashOrders>> => {
    switch (action.type) {
        case IUPDATE_PARTS_CASH_ORDER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_PARTS_CASH_ORDER_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IUPDATE_PARTS_CASH_ORDER_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};