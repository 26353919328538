import { SILENT_LOGIN_FAILED_MESSAGE } from "../../react/shared/constExports";
import LocalStorage from "../../utils/localStorage";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { IRegistrationSuccessAction } from "../registration/registrationActions";
import { IREGISTRATION_REQUEST } from "../registration/registrationConstants";
import { Server, loading, failed, succeeded, notLoaded } from "../server";
import { IUpdateProfileSuccessAction } from "../userProfile/updateProfile/updateProfileActions";
import { IUPDATE_PROFILE_REQUEST } from "../userProfile/updateProfile/updateProfileConstants";
import { ILoginLoadAction, ILoginLoadFailedAction, ILoginSuccessAction, ILogoutAction } from "./loginActions";
import { ILoginResponse, ILOGIN_REQUEST } from "./loginConstants";


type Actions =
    ILoginLoadAction
    | ILoginLoadFailedAction
    | ILoginSuccessAction
    | IUpdateProfileSuccessAction
    | IRegistrationSuccessAction
    | ILogoutAction
    | IFlushDataSuccessAction;

const getInitialData = (): Server<ILoginResponse> => {
    const login = LocalStorage.getILogin();
    if (login) {
        return succeeded(login);
    }

    return notLoaded;
};
const initialData = getInitialData();

export const LoginReducer = (state: Server<ILoginResponse> = initialData, action: Actions): Server<ILoginResponse> => {
    switch (action.type) {
        case ILOGIN_REQUEST.REQUEST:
            return loading;

        case ILOGIN_REQUEST.SUCCESS:
            LocalStorage.setILogin(action.data);
            return succeeded(action.data);

        case IREGISTRATION_REQUEST.SUCCESS:
            if(!action.internalAdd){
            const { First_Name, Last_Name, Email, Phone, Address, Country, Company, City, Province, Billing_Address
                , Billing_City, Billing_Country, Billing_Province, Postal_Code, Billing_Postal_Code, Id } = action.data.request;
            const val: ILoginResponse = {
                token: action.data.token ?? "", customer: {
                    id: Id ?? 0, firstname: First_Name, lastname: Last_Name, email: Email, phone: Phone,
                    address: Address, country: Country, company: Company, city: City, province: Province,
                    billing_address: Billing_Address, billing_country: Billing_Country, billing_city: Billing_City,
                    billing_province: Billing_Province, postal_code: Postal_Code,
                    billing_postal_code: Billing_Postal_Code
                }
            };
            LocalStorage.setILogin(val);
            return succeeded(val);
        } else {
            return state;
        }

        case IUPDATE_PROFILE_REQUEST.SUCCESS:
            if ((action.message === "Success") && (action.internalUpdate === false)) {
                const { id, First_Name, Last_Name, Email, Phone, Address, Country, Company, City, Province, Billing_Address
                    , Billing_City, Billing_Country, Billing_Province,  Postal_Code, Billing_Postal_Code } = action.data.request;
                const val: ILoginResponse = {
                    token: action.data.token, customer: {
                        id, firstname: First_Name, lastname: Last_Name, email: Email, phone: Phone,
                        address: Address, country: Country, company: Company, city: City, province: Province,
                        billing_address: Billing_Address, billing_country: Billing_Country, billing_city: Billing_City,
                        billing_province: Billing_Province, postal_code: Postal_Code,
                        billing_postal_code: Billing_Postal_Code
                    }
                };
                LocalStorage.setILogin(val);
                return succeeded(val);
            };
            return state;

        case ILOGIN_REQUEST.FAILED:
            return failed(action.message);

        case ILOGIN_REQUEST.LOGOUT:
            LocalStorage.logout();
            return failed(SILENT_LOGIN_FAILED_MESSAGE);

        default:
            return state;
    }
};