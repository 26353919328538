import { ReactNode, PureComponent } from "react";
import { LAMenuItem } from "../shared/dropDownMenu";
import { HomeIcon } from "../shared/icons";
import { HasClass } from "../shared/publicInterfaces";
import { WHITE_COLOR } from "../shared/theme";

interface ISurewayConstructionDropDownProps extends HasClass {
};

type ISurewayConstructionDropDown =
    ISurewayConstructionDropDownProps;

class SurewayConstructionDropDown extends PureComponent<ISurewayConstructionDropDown> {

    public render(): ReactNode {
        return (
            <>              
                <LAMenuItem onClick={this.openPopup}>
                <HomeIcon color={WHITE_COLOR} />                
                <span className={`${this.props.className}`}>
                Sureway Construction Home
                </span>
                </LAMenuItem>
            </>
        );
    };

    private openPopup = (): void => {
        window.open('https://sureway.ca');
    };
}

export default SurewayConstructionDropDown;
