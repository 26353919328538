import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_PARTS_REQUEST, IGetPartsRequest, IPart } from "./getPartsConstants";

export interface IGetPartsLoadAction {
    type: IGET_PARTS_REQUEST.REQUEST;
    data: IGetPartsRequest
}
export const getPartsLoadAction = (data: IGetPartsRequest): IGetPartsLoadAction => ({
    type: IGET_PARTS_REQUEST.REQUEST,
    data
});

export interface IGetPartsSuccessAction {
    type: IGET_PARTS_REQUEST.SUCCESS;
    list: PartsResponse<IPart[]>;
}
export const getPartsLoadSuccessAction = (list: PartsResponse<IPart[]>): IGetPartsSuccessAction => ({
    type: IGET_PARTS_REQUEST.SUCCESS,
    list
});
export interface IGetPartsLoadFailedAction {
    type: IGET_PARTS_REQUEST.FAILED;
    message: string;
}
export const getPartsLoadFailedAction = (message: string): IGetPartsLoadFailedAction => ({
    type: IGET_PARTS_REQUEST.FAILED,
    message
});
