import React, { ReactNode, PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import LATextField from "../../shared/textField";
import PasswordTextField from "../../shared/passwordField";
import { LASaveAndCancelButton } from "../../shared/buttons";
import { undefinedFunction, ZEROTH } from "../../shared/constExports";
import { registration } from "../../../redux/registration/registrationAccessor";
import { IRegistrationData, IRegistrationRequest } from "../../../redux/registration/registrationConstants";
import { registrationLoadAction } from "../../../redux/registration/registrationActions";
import { ById } from "../../shared/publicInterfaces";
import { hasPayload, Server, STATUS_ENUM } from "../../../redux/server";
import { FieldValidator, IFieldErrorKeyValue } from "../../shared/fieldValidation";
import LAErrorBox from "../../shared/errorBox";
import { LACheckBox } from "../../shared/checkBox";
import LAAutoComplete from "../../shared/autoComplete";
import { countryList } from "../../shared/countries";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../../routes";


interface IRegisterComponentStoreProps {
    registrationStatus: Server<string>;
};

interface IRegisterComponentDispatchProps {
    registrationRequest: (data: IRegistrationRequest) => unknown;
};

interface IRegisterComponentOwnProps {
    onRegisterCancel: () => void;
}

interface IRegisterComponentState {
    showStatus: boolean;
    data: IRegistrationData;
    confirmPassword: string;
    billingAddressSame: boolean;
    errors: ById<IFieldErrorKeyValue>;
};

const RegisterStyles = styled(LAPaperWithPadding)`
    margin: 20px 20px;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 15px 10px;
    };
`;

type IRegisterComponentProps =
    RouteComponentProps
    & IRegisterComponentStoreProps
    & IRegisterComponentOwnProps
    & IRegisterComponentDispatchProps;

class RegisterComponent extends PureComponent<IRegisterComponentProps, IRegisterComponentState> {

    public constructor(props: IRegisterComponentProps) {
        super(props);
        this.state = {
            data: {
                First_Name: "",
                Last_Name: "",
                Company: "",
                Address: "",
                City: "",
                Province: "",
                Country: "",
                Phone: "",
                Email: "",
                Password: "",
                Billing_City: "",
                Billing_Province: "",
                Billing_Country: "",
                Billing_Address: "",
                Billing_Postal_Code: "",
                Postal_Code: ""
            },
            confirmPassword: "",
            errors: {},
            showStatus: false,
            billingAddressSame: true
        };
    }

    public componentDidMount(): void {
    };

    public componentDidUpdate(prevProps: IRegisterComponentProps): void {
        if (prevProps !== this.props) {
            const { registrationStatus } = this.props;

            if ((this.state.showStatus === false) && (prevProps.registrationStatus !== registrationStatus)){
                if((registrationStatus.kind === STATUS_ENUM.FAILED))
                    this.setState({ showStatus: true });

                if((registrationStatus.kind === STATUS_ENUM.SUCCEEDED))
                    this.props.history.push(ROUTE.PARTS());
            };

        };

        if((this.props.location !== prevProps.location) === true)
            this.props.onRegisterCancel();

    };

    public render(): ReactNode {

        const { data, errors, confirmPassword, showStatus, billingAddressSame } = this.state;

        const onCountry = (event: unknown, v: { name: string, code: string }): void => this.handleInfo("Country", v !== null ? v.name : "");
        const onBillingCountry = (event: unknown, v: { name: string, code: string }): void => this.handleInfo("Billing_Country", v !== null ? v.name : "");

        return (
            <RegisterStyles>
                <LAGrid spacing={3}>

                    <LAGridItem xs={12}>
                        <h2 className="text-center">REGISTER</h2>
                    </LAGridItem>

                    {(showStatus && hasPayload(this.props.registrationStatus)) && <LAGridItem xs={12}>
                        <LAErrorBox text={this.props.registrationStatus.payload} />
                    </LAGridItem>}

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            id="registerName"
                            label="First Name"
                            name="First_Name"
                            value={data.First_Name}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["First_Name"] ? errors["First_Name"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                        id="registerLastName"
                            label="Last Name"
                            name="Last_Name"
                            value={data.Last_Name}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Last_Name"] ? errors["Last_Name"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Company"
                            name="Company"
                            value={data.Company}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Company"] ? errors["Company"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Address"
                            name="Address"
                            value={data.Address}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Address"] ? errors["Address"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="City"
                            name="City"
                            value={data.City}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["City"] ? errors["City"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Province"
                            name="Province"
                            value={data.Province}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Province"] ? errors["Province"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LAAutoComplete
                            name="Country"
                            multiple={false}
                            getOptionLabel="name"
                            autoHighlight={true}
                            option={countryList}
                            onChange={onCountry}
                            filterSelectedOptions={true}
                            selectionRemove={undefinedFunction}
                            dropDownPlaceHolder="Country"
                            errorText={errors["Country"] ? errors["Country"].message : undefined}
                            value={data.Country.length > 0 ? countryList.find(q => q.name === data.Country) : ""}
                            defaultValue={data.Country.length > 0 ? countryList.find(q => q.name === data.Country) : ""}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            name="Postal_Code"
                            label="Postal/Zip Code"
                            value={data.Postal_Code}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Postal_Code"] ? errors["Postal_Code"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Phone"
                            name="Phone"
                            value={data.Phone}
                            fullWidth={true}
                            onChange={this.handlePhone}
                            errorText={errors["Phone"] ? errors["Phone"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LACheckBox
                            value={billingAddressSame}
                            onChange={this.handleCheckBox}
                            label="Billing address same as above"
                        />
                    </LAGridItem>

                    {!billingAddressSame && <>
                        <LAGridItem xs={12} sm={6}>
                            <LATextField
                                label="Billing Address"
                                name="Billing_Address"
                                value={data.Billing_Address}
                                fullWidth={true}
                                onChange={this.handleInfo}
                                errorText={errors["Billing_Address"] ? errors["Billing_Address"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6}>
                            <LATextField
                                label="Billing City"
                                name="Billing_City"
                                value={data.Billing_City}
                                fullWidth={true}
                                onChange={this.handleInfo}
                                errorText={errors["Billing_City"] ? errors["Billing_City"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6}>
                            <LATextField
                                label="Billing Province"
                                name="Billing_Province"
                                value={data.Billing_Province}
                                fullWidth={true}
                                onChange={this.handleInfo}
                                errorText={errors["Billing_Province"] ? errors["Billing_Province"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6}>
                            <LAAutoComplete
                                name="Billing_Country"
                                multiple={false}
                                getOptionLabel="name"
                                autoHighlight={true}
                                option={countryList}
                                onChange={onBillingCountry}
                                filterSelectedOptions={true}
                                selectionRemove={undefinedFunction}
                                dropDownPlaceHolder="Billing Country"
                                errorText={errors["Billing_Country"] ? errors["Billing_Country"].message : undefined}
                                value={data.Country.length > 0 ? countryList.find(q => q.name === data.Billing_Country) : ""}
                                defaultValue={data.Country.length > 0 ? countryList.find(q => q.name === data.Billing_Country) : ""}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6}>
                        <LATextField
                            name="Billing_Postal_Code"
                            label="Billing Postal/Zip Code"
                            value={data.Billing_Postal_Code}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Billing_Postal_Code"] ? errors["Billing_Postal_Code"].message : undefined}
                        />
                    </LAGridItem>
                    </>}

                    <LAGridItem xs={12} sm={12}>
                        <LATextField
                            id="RegisterEmail"
                            label="Email"
                            name="Email"
                            value={data.Email}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Email"] ? errors["Email"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <PasswordTextField
                            id="RegisterPassword"
                            name="Password"
                            label="Password"
                            value={data.Password}
                            required={true}
                            onChange={this.handleInfo}
                            fullWidth={true}
                            onPressEnter={undefinedFunction}
                            className="mb-2"
                            errorText={errors["Password"] ? errors["Password"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <PasswordTextField
                            id="RegisterConfirmPassword"
                            name="confirmPassword"
                            label="Confirm Password"
                            value={confirmPassword}
                            required={true}
                            onChange={this.handleConfirmPassword}
                            fullWidth={true}
                            onPressEnter={this.register}
                            className="mb-2"
                            errorText={errors["confirmPassword"] ? errors["confirmPassword"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LASaveAndCancelButton
                            onSave={this.register}
                            saveButtonText="REGISTER"
                            cancelButtonText="BACK TO LOGIN"
                            onCancel={this.handleCancel}
                        />
                    </LAGridItem>

                </LAGrid>
            </RegisterStyles>
        );
    }

    private handleCheckBox = (): void => {
        this.setState({ billingAddressSame: !this.state.billingAddressSame });
    };

    private handleCancel = (): void => {
        this.setState({
            data: {
                First_Name: "",
                Last_Name: "",
                Company: "",
                Address: "",
                City: "",
                Province: "",
                Country: "",
                Phone: "",
                Email: "",
                Password: "",
                Billing_City: "",
                Billing_Province: "",
                Billing_Country: "",
                Billing_Address: "",
                Billing_Postal_Code: "",
                Postal_Code: ""
            },
            confirmPassword: "",
            errors: {},
            showStatus: false
        });

        this.props.onRegisterCancel();
    };

    private handleInfo = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if ((errors[name] !== undefined) && value.length > ZEROTH) {
            delete errors[name];
        };

        if(name === "Email" && errors[name] === undefined){
            errors = this.errorChecker(name, value, errors, true, true);
        };

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors: errors
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean, email: true | undefined, min?: number, max?: number): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: min, maxLength: max, email, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };
    
    private handlePhone = (name: string, value: string): void => {
        if (!isNaN(Number(value))) {
            this.handleInfo(name, value);
        }
    };

    private handleConfirmPassword = (name: string, value: string): void => {
        const errors = { ...this.state.errors };

        if ((errors[name] !== undefined) && ((value.length > ZEROTH) || (value === this.state.data.Password))) {
            delete errors[name];
        };

        if (value !== this.state.data.Password) {
            errors["confirmPassword"] = { key: "confirmPassword", message: "Password does not match" };
        };

        this.setState({ confirmPassword: value, errors });
    };

    private register = (): void => {
        const iS = this.state.data;
        const errors = { ...this.state.errors };

        if (iS.First_Name.length === ZEROTH) {
            errors["First_Name"] = { key: "First_Name", message: "First Name is Required" };
        };
        if (iS.Last_Name.length === ZEROTH) {
            errors["Last_Name"] = { key: "Last_Name", message: "Last Name is Required" };
        };
        if (iS.Password !== this.state.confirmPassword) {
            errors["confirmPassword"] = { key: "confirmPassword", message: "Password does not match" };
        };
        if ((iS.Password.length === ZEROTH) || (iS.Password.length < 6)) {
            errors["Password"] = { key: "Password", message: "Password is Required and has to be min 6 characters" };
        };
        if (this.state.confirmPassword.length === ZEROTH) {
            errors["confirmPassword"] = { key: "confirmPassword", message: "Confirm Password is Required" };
        };
        if (iS.Email.length === ZEROTH) {
            errors["Email"] = { key: "Email", message: "Email is Required" };
        };
        if (iS.Address.length === ZEROTH) {
            errors["Address"] = { key: "Address", message: "Address is Required" };
        };
        if (iS.City.length === ZEROTH) {
            errors["City"] = { key: "City", message: "City is Required" };
        };
        if (iS.Province.length === ZEROTH) {
            errors["Province"] = { key: "Province", message: "Province is Required" };
        };
        if (iS.Phone.length === ZEROTH) {
            errors["Phone"] = { key: "Phone", message: "Phone is Required" };
        };
        if (iS.Postal_Code.length === ZEROTH) {
            errors["Postal_Code"] = { key: "Postal_Code", message: "Postal_Code is Required" };
        };
        if(!this.state.billingAddressSame){
            if (iS.Billing_Address.length === ZEROTH) {
                errors["Billing_Address"] = { key: "Billing_Address", message: "Billing Address is Required" };
            };

            if (iS.Billing_City.length === ZEROTH) {
                errors["Billing_City"] = { key: "Billing_City", message: "Billing City is Required" };
            };

            if (iS.Billing_Country.length === ZEROTH) {
                errors["Billing_Country"] = { key: "Billing_Country", message: "Billing Country is Required" };
            };
            if (iS.Billing_Postal_Code.length === ZEROTH) {
                errors["Billing_Postal_Code"] = { key: "Billing_Postal_Code", message: "Billing_Postal_Code is Required" };
            };

            if (iS.Billing_Province.length === ZEROTH) {
                errors["Billing_Province"] = { key: "Billing_Province", message: "Billing Province is Required" };
            };
        };


        if (Object.values(errors).length === ZEROTH) {

            if(this.state.billingAddressSame){
                iS.Billing_Address = iS.Address;
                iS.Billing_City = iS.City;
                iS.Billing_Country = iS.Country;
                iS.Billing_Province = iS.Province;
                iS.Billing_Postal_Code = iS.Postal_Code;
            };

            this.props.registrationRequest({ request: iS });
            this.setState({ showStatus: false });
        } else {
            this.setState({ errors });
        }
    };

}

const mapStateToProps = (state: IStore): IRegisterComponentStoreProps => ({
    registrationStatus: registration(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IRegisterComponentDispatchProps => ({
    registrationRequest: (data: IRegistrationRequest): unknown => dispatch(registrationLoadAction(data, false))
});


export default connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);