import { ROUTE } from "./routes";
import HomeComponent from "./home";
import Footer from "./footer/footer";
import Header from "./header/header";
import { connect } from "react-redux";
import Error404Page from "./error404Page";
import Parts from "./component/parts/parts";
import { Component, ReactNode } from "react";
import Report from "./component/reports/report";
import { IStore, IDispatch } from "../redux/reducers";
import OrdersComponent from "./component/orders/orders";
import CheckOutComponent from "./component/cart/checkOut";
import CustomersComponent from "./component/customers/customers";
import { RouteComponentProps, Route, Switch } from "react-router";
import LoginAndRegisterComponent from "./component/loginAndRegister";
import AdminOrdersComponent from "./component/adminOrder/adminOrders";
import DismantledPartsComponent from "./component/dismantled/dismantleds";
import CashOrdersComponent from "./component/cashOrder/cashOrders";
import styled from "styled-components";
import { MEDIA_QUERY_PHONE } from "./shared/theme";
import MobileFooter from "./footer/mobileFooter";
import MobileHeader from "./header/mobileHeader";
import { BottomBanner } from "./footer/bottomBanner";

interface ISecuredRoutesStoreProps {

};

interface ISecuredRoutesDispatchProps {

};

interface ISecuredRoutesState {
    showHeader: boolean;
    loading: boolean;
};

const SecuredRoutesStyles = styled.div`
    
    .mobileView {
        display: none;
    };

    .desktopView {
        display: block;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .mobileView {
            display: block;
        };

        .desktopView {
            display: none;
        };
    };
`;

type ISecuredRoutesProps =
    RouteComponentProps &
    ISecuredRoutesDispatchProps &
    ISecuredRoutesStoreProps;

class SecuredRoutes extends Component<ISecuredRoutesProps, ISecuredRoutesState> {
    public constructor(props: ISecuredRoutesProps) {
        super(props);
        this.state = {
            showHeader: true,
            loading: true
        };
    }

    public componentDidMount(): void {

    }

    public componentDidUpdate(prevProps: ISecuredRoutesProps): void {

    }

    public render(): ReactNode {
        return (
            <SecuredRoutesStyles>
                <div className="desktopView">
                    <Header {...this.props} />
                </div>

                <div className="mobileView">
                    <MobileHeader {...this.props} />
                </div>

                <Switch>
                    <Route exact={true} path={ROUTE.INDEX()} component={HomeComponent} />
                    <Route exact={true} path={ROUTE.PARTS()} component={Parts} />
                    <Route exact={true} path={ROUTE.REPORT()} component={Report} />
                    <Route exact={true} path={ROUTE.CHECKOUT()} component={CheckOutComponent} />
                    <Route exact={true} path={ROUTE.LOGIN()} component={LoginAndRegisterComponent} />
                    <Route exact={true} path={ROUTE.CUSTOMER_ORDER()} component={OrdersComponent} />
                    <Route exact={true} path={ROUTE.ADMIN_ORDER()} component={AdminOrdersComponent} />
                    <Route exact={true} path={ROUTE.CUSTOMERS()} component={CustomersComponent} />
                    <Route exact={true} path={ROUTE.DISMANTLED()} component={DismantledPartsComponent} />
                    <Route exact={true} path={ROUTE.CASH_ORDER()} component={CashOrdersComponent} />
                    <Route render={(): ReactNode => <Error404Page handleShowHeader={this.handleShowHeader} />} />
                </Switch>

                <div className="desktopView">
                    <BottomBanner /> 
                    <Footer {...this.props} />
                </div>

                <div className="mobileView">
                    <BottomBanner  />
                    <MobileFooter {...this.props} />
                </div>
            </SecuredRoutesStyles>
        );
    }

    private handleShowHeader = (showHeader: boolean): void => {
        this.setState({ showHeader });
    };


}

const mapStateToProps = (state: IStore): ISecuredRoutesStoreProps => ({

});

const mapDispatchToProps = (dispatch: IDispatch): ISecuredRoutesDispatchProps => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(SecuredRoutes);