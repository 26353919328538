import { ById, PartsResponse } from "../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IGetPartsOrderReportsLoadAction, IGetPartsOrderReportsLoadFailedAction, IGetPartsOrderReportsSuccessAction } from "./getPartsOrderReportActions";
import { IGET_PARTS_ORDER_REPORTS_REQUEST, IPartsOrderReport } from "./getPartsOrderReportConstants";


type Actions =
    IGetPartsOrderReportsLoadAction
    | IGetPartsOrderReportsLoadFailedAction
    | IGetPartsOrderReportsSuccessAction
    | IFlushDataSuccessAction;

export const GetPartsOrderReportsReducer = (state: Server<PartsResponse<ById<IPartsOrderReport>>> = notLoaded, action: Actions): Server<PartsResponse<ById<IPartsOrderReport>>> => {
    switch (action.type) {
        case IGET_PARTS_ORDER_REPORTS_REQUEST.REQUEST:
            return loading;

        case IGET_PARTS_ORDER_REPORTS_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IGET_PARTS_ORDER_REPORTS_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};