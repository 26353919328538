import { IADD_CART_REQUEST, ICart, ICART_REQUEST, IREMOVE_CART_REQUEST } from "./cartConstants";

//#region GET
export interface ICartLoadAction {
    type: ICART_REQUEST.REQUEST;
};
export const cartLoadAction = (): ICartLoadAction => ({
    type: ICART_REQUEST.REQUEST
});

export interface ICartSuccessAction {
    type: ICART_REQUEST.SUCCESS;
};
export const cartLoadSuccessAction = (): ICartSuccessAction => ({
    type: ICART_REQUEST.SUCCESS
});

export interface ICartLoadFailedAction {
    type: ICART_REQUEST.FAILED;
};
export const cartLoadFailedAction = (): ICartLoadFailedAction => ({
    type: ICART_REQUEST.FAILED
});
//#endregion

//#region Add 
export interface IAddCartLoadAction {
    type: IADD_CART_REQUEST.REQUEST;
    cart: ICart;
};
export const addCartLoadAction = (cart: ICart): IAddCartLoadAction => ({
    type: IADD_CART_REQUEST.REQUEST,
    cart
});

export interface IAddCartSuccessAction {
    type: IADD_CART_REQUEST.SUCCESS;
    cart: ICart;
};
export const addCartSuccessAction = (cart: ICart): IAddCartSuccessAction => ({
    type: IADD_CART_REQUEST.SUCCESS,
    cart
});

export interface IAddCartLoadFailedAction {
    type: IADD_CART_REQUEST.FAILED;
};
export const addCartFailedAction = (): IAddCartLoadFailedAction => ({
    type: IADD_CART_REQUEST.FAILED
});
//#endregion

//#region Remove 
export interface IRemoveCartLoadAction {
    type: IREMOVE_CART_REQUEST.REQUEST;
    id: number;
};
export const removeCartLoadAction = (id: number): IRemoveCartLoadAction => ({
    type: IREMOVE_CART_REQUEST.REQUEST,
    id
});

export interface IRemoveCartSuccessAction {
    type: IREMOVE_CART_REQUEST.SUCCESS;
    id: number;
};
export const removeCartLoadSuccessAction = (id: number): IRemoveCartSuccessAction => ({
    type: IREMOVE_CART_REQUEST.SUCCESS,
    id
});

export interface IRemoveCartLoadFailedAction {
    type: IREMOVE_CART_REQUEST.FAILED;
};
export const removeCartLoadFailedAction = (): IRemoveCartLoadFailedAction => ({
    type: IREMOVE_CART_REQUEST.FAILED
});
//#endregion