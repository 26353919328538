import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_PROFILE_REQUEST } from "./updateProfileConstants";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../../epicUtils";
import { IUpdateProfileLoadAction, IUpdateProfileLoadFailedAction, IUpdateProfileSuccessAction, updateProfileLoadFailedAction, updateProfileLoadSuccessAction } from "./updateProfileActions";

export const updateProfileEpic: Epic = (
    action$: ActionsObservable<IUpdateProfileLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateProfileSuccessAction | IUpdateProfileLoadFailedAction> =>
    action$.ofType(IUPDATE_PROFILE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.UPDATE_PROFILE,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<string>): IUpdateProfileSuccessAction => {
                            return updateProfileLoadSuccessAction(response.message, action.data, action.internalUpdate);
                        }),
                        catchError(() => ActionsObservable.of(updateProfileLoadFailedAction("Unable to update your profile")))
                    )
            )
        );