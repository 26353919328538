import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IGetAllPartsCashOrders } from "../getAllPartsCashOrders/getAllPartsCashOrdersConstants";
import { IAddPartsCashOrderRequest, IADD_PARTS_CASH_ORDER_REQUEST } from "./addPartsCashOrderConstants";

export interface IAddPartsCashOrderLoadAction {
    type: IADD_PARTS_CASH_ORDER_REQUEST.REQUEST;
    data: IAddPartsCashOrderRequest
}
export const addPartsCashOrderLoadAction = (data: IAddPartsCashOrderRequest): IAddPartsCashOrderLoadAction => ({
    type: IADD_PARTS_CASH_ORDER_REQUEST.REQUEST,
    data
});

export interface IAddPartsCashOrderSuccessAction {
    type: IADD_PARTS_CASH_ORDER_REQUEST.SUCCESS;
    response: PartsResponse<IGetAllPartsCashOrders>;
}
export const addPartsCashOrderLoadSuccessAction = (response: PartsResponse<IGetAllPartsCashOrders>): IAddPartsCashOrderSuccessAction => ({
    type: IADD_PARTS_CASH_ORDER_REQUEST.SUCCESS,
    response
});
export interface IAddPartsCashOrderLoadFailedAction {
    type: IADD_PARTS_CASH_ORDER_REQUEST.FAILED;
    message: string;
}
export const addPartsCashOrderLoadFailedAction = (message: string): IAddPartsCashOrderLoadFailedAction => ({
    type: IADD_PARTS_CASH_ORDER_REQUEST.FAILED,
    message
});
