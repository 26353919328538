import React, { PureComponent, ReactNode } from "react";
import Avatar from "@material-ui/core/Avatar";
import styled from "styled-components";
import { HasClass } from "./publicInterfaces";
import { DARK_GREY_COLOR } from "./theme";

interface ILAAvatarWithNoStylingsProps extends HasClass {
    src?: string;
    initials?: string;
    alt?: string;
}

export const LAAvatarWithNoStylings: React.FC<ILAAvatarWithNoStylingsProps> = (props: ILAAvatarWithNoStylingsProps) =>
    props.src
        ? <Avatar
            alt={props.alt}
            src={props.src}
            className={props.className}
        />
        : <Avatar
            alt={props.alt}
            className={props.className}
        >{props.initials}</Avatar>
    ;

interface ILAAvatarProps extends HasClass {
    alt?: string;
    src?: string;
    initials?: string;
    style?: {
        margin: number;
        width: number;
        height: number;
    };
};

const StyledAvatar = styled.div`
    border-radius: 25px;
    border: 3px solid ${DARK_GREY_COLOR};
    box-shadow: 0px 0px 8px 1px ${DARK_GREY_COLOR};
`;

class LAAvatar extends PureComponent<ILAAvatarProps> {
    public render(): ReactNode {

        if (this.props.src !== undefined) {
            return (
                <StyledAvatar>
                    <Avatar
                        style={this.props.style}
                        alt={this.props.alt}
                        src={this.props.src}
                        className={this.props.className}
                    />
                </StyledAvatar>
            );
        }
        return (
            <StyledAvatar>
                <Avatar
                    style={this.props.style}
                    alt={this.props.alt}
                    className={this.props.className}
                >
                    {this.props.initials}
                </Avatar>
            </StyledAvatar>
        );
    }
}

export default LAAvatar;
