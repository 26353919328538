import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";
import { IGetAllPartsOrderResponse, IGET_ALL_PARTS_ORDER_REQUEST } from "./getPartsOrderConstants";
import { getAllPartsOrderLoadFailedAction, getAllPartsOrderLoadSuccessAction, IGetAllPartsOrderLoadAction, IGetAllPartsOrderLoadFailedAction, IGetAllPartsOrderSuccessAction } from "./getPartsOrderActions";

export const getAllPartsOrderEpic: Epic = (
    action$: ActionsObservable<IGetAllPartsOrderLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetAllPartsOrderSuccessAction | IGetAllPartsOrderLoadFailedAction> =>
    action$.ofType(IGET_ALL_PARTS_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IGetAllPartsOrderResponse[]>>(
                    END_POINTS.GET_ALL_PARTS_ORDER,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<IGetAllPartsOrderResponse[]>): IGetAllPartsOrderSuccessAction => getAllPartsOrderLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(getAllPartsOrderLoadFailedAction("Unable to load parts")))
                    )
            )
        );