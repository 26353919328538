import { ReactNode, PureComponent } from "react";
import { LAMenuItem } from "../shared/dropDownMenu";
import { ContactUsIcon } from "../shared/icons";
import { WHITE_COLOR } from "../shared/theme";

interface IContactUsDropDownProps {
    onClick: () => void;
};

interface IContactUsDropDownState {
    open: boolean;
};


class ContactUsDropDown extends PureComponent<IContactUsDropDownProps, IContactUsDropDownState> {



    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.props.onClick}>
                <ContactUsIcon color={WHITE_COLOR} />
                
                <span className="ml-1">
                    Contact Us
                </span>

            </LAMenuItem>
        );
    };
}

export default ContactUsDropDown;
