import { mergeMap, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { ActionsObservable, Epic } from "redux-observable";
import { IAction } from "../reducers";
import { addCartFailedAction, addCartSuccessAction, cartLoadFailedAction, cartLoadSuccessAction, IAddCartLoadAction, ICartLoadAction, IRemoveCartLoadAction, removeCartLoadFailedAction, removeCartLoadSuccessAction } from "./cartActions";
import { IADD_CART_REQUEST, ICART_REQUEST, IREMOVE_CART_REQUEST } from "./cartConstants";

export const cartEpic: Epic = (
    action$: ActionsObservable<ICartLoadAction>
): Observable<IAction> =>
    action$.ofType(ICART_REQUEST.REQUEST)
        .pipe(
            mergeMap(() => {
                return ActionsObservable.of(cartLoadSuccessAction());
            }),
            catchError(() => {
                return ActionsObservable.of(cartLoadFailedAction());
            })
        );

export const addCartEpic: Epic = (
    action$: ActionsObservable<IAddCartLoadAction>
): Observable<IAction> =>
    action$.ofType(IADD_CART_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) => {
                return ActionsObservable.of(addCartSuccessAction(action.cart));
            }),
            catchError(() => {
                return ActionsObservable.of(addCartFailedAction());
            })
        );

export const removeCartEpic: Epic = (
    action$: ActionsObservable<IRemoveCartLoadAction>
): Observable<IAction> =>
    action$.ofType(IREMOVE_CART_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) => {
                return ActionsObservable.of(removeCartLoadSuccessAction(action.id));
            }),
            catchError(() => {
                return ActionsObservable.of(removeCartLoadFailedAction());
            })
        );