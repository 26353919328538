export enum IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST {
    REQUEST = "partsOrderCustomerQuoteResponse/PARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST",
    SUCCESS = "partsOrderCustomerQuoteResponse/PARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_SUCCESS",
    FAILED = "partsOrderCustomerQuoteResponse/PARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_FAILED"
};

export interface IPartsOrderCustomerQuoteResponseRequest {
    token?: string;
    request: IPartsOrderCustomerQuoteResponse;
};

export interface IPartsOrderCustomerQuoteResponse {
    ID: number;
    Comments: string;
    Modified_By: string;
    Quote_Response: string;
};


export interface IPartsOrderCustomerQuoteResponseResponse {

};
