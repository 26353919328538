
export enum IGET_SEO_KEYWORDS_REQUEST {
    REQUEST = "getSEOKeywords/GET_SEO_KEYWORDS_REQUEST",
    SUCCESS = "getSEOKeywords/GET_SEO_KEYWORDS_SUCCESS",
    FAILED = "getSEOKeywords/GET_SEO_KEYWORDS_FAILED"
};

export interface IGetSEOKeywordsRequest {
    request: {
        page: "parts" | "dismantled" | "home";
    }
};