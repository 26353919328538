import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IGetPartsOrderByCustomerRequest, IGetPartsOrderByCustomer, IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST } from "./getPartsOrderByCustomerConstants";

export interface IGetPartsOrderByCustomerLoadAction {
    type: IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.REQUEST;
    data: IGetPartsOrderByCustomerRequest
}
export const getPartsOrderByCustomerLoadAction = (data: IGetPartsOrderByCustomerRequest): IGetPartsOrderByCustomerLoadAction => ({
    type: IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.REQUEST,
    data
});

export interface IGetPartsOrderByCustomerSuccessAction {
    type: IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.SUCCESS;
    response: PartsResponse<IGetPartsOrderByCustomer[]>;
}
export const getPartsOrderByCustomerLoadSuccessAction = (response: PartsResponse<IGetPartsOrderByCustomer[]>): IGetPartsOrderByCustomerSuccessAction => ({
    type: IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.SUCCESS,
    response
});
export interface IGetPartsOrderByCustomerLoadFailedAction {
    type: IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.FAILED;
    message: string;
}
export const getPartsOrderByCustomerLoadFailedAction = (message: string): IGetPartsOrderByCustomerLoadFailedAction => ({
    type: IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.FAILED,
    message
});
