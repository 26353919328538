import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";
import { IRegistrationResponse, IREGISTRATION_REQUEST } from "./registrationConstants";
import { IStore } from "../reducers";
import { EpicDependencies } from "../store";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { END_POINTS } from "../endpoints";
import { IRegistrationLoadAction, IRegistrationLoadFailedAction, IRegistrationSuccessAction, registrationLoadFailedAction, registrationLoadSuccessAction } from "./registrationActions";

export const registrationEpic: Epic = (
    action$: ActionsObservable<IRegistrationLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IRegistrationSuccessAction | IRegistrationLoadFailedAction> =>
    action$.ofType(IREGISTRATION_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IRegistrationResponse>>(
                    END_POINTS.REGISTRATION,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<IRegistrationResponse>): IRegistrationSuccessAction | IRegistrationLoadFailedAction => {
                            if (response.message === "Success") {
                                return registrationLoadSuccessAction({
                                    token: response.response.token,
                                    request: {
                                        ...action.data.request,
                                        Id: response.response.customer_Id
                                    }
                                },
                                action.internalAdd);
                            } else {
                                return registrationLoadFailedAction(response.message);
                            }
                        }),
                        catchError(() => ActionsObservable.of(registrationLoadFailedAction("Unable to register")))
                    )
            )
        );