import { PartsResponse } from "../../react/shared/publicInterfaces";
import { ICustomer, IGetCustomersRequest, IGET_CUSTOMERS_REQUEST } from "./getCustomersConstants";

export interface IGetCustomersLoadAction {
    type: IGET_CUSTOMERS_REQUEST.REQUEST;
    data: IGetCustomersRequest
}
export const getCustomersLoadAction = (data: IGetCustomersRequest): IGetCustomersLoadAction => ({
    type: IGET_CUSTOMERS_REQUEST.REQUEST,
    data
});

export interface IGetCustomersSuccessAction {
    type: IGET_CUSTOMERS_REQUEST.SUCCESS;
    response: PartsResponse<ICustomer[]>;
}
export const getCustomersLoadSuccessAction = (response: PartsResponse<ICustomer[]>): IGetCustomersSuccessAction => ({
    type: IGET_CUSTOMERS_REQUEST.SUCCESS,
    response
});
export interface IGetCustomersLoadFailedAction {
    type: IGET_CUSTOMERS_REQUEST.FAILED;
    message: string;
}
export const getCustomersLoadFailedAction = (message: string): IGetCustomersLoadFailedAction => ({
    type: IGET_CUSTOMERS_REQUEST.FAILED,
    message
});
