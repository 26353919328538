import { ById, PartsResponse } from "../../react/shared/publicInterfaces";
import { IGetPartsOrderReportsRequest, IGET_PARTS_ORDER_REPORTS_REQUEST, IPartsOrderReport } from "./getPartsOrderReportConstants";

export interface IGetPartsOrderReportsLoadAction {
    type: IGET_PARTS_ORDER_REPORTS_REQUEST.REQUEST;
    data: IGetPartsOrderReportsRequest
}
export const getPartsOrderReportsLoadAction = (data: IGetPartsOrderReportsRequest): IGetPartsOrderReportsLoadAction => ({
    type: IGET_PARTS_ORDER_REPORTS_REQUEST.REQUEST,
    data
});

export interface IGetPartsOrderReportsSuccessAction {
    type: IGET_PARTS_ORDER_REPORTS_REQUEST.SUCCESS;
    response: PartsResponse<ById<IPartsOrderReport>>;
}
export const getPartsOrderReportsLoadSuccessAction = (response: PartsResponse<ById<IPartsOrderReport>>): IGetPartsOrderReportsSuccessAction => ({
    type: IGET_PARTS_ORDER_REPORTS_REQUEST.SUCCESS,
    response
});
export interface IGetPartsOrderReportsLoadFailedAction {
    type: IGET_PARTS_ORDER_REPORTS_REQUEST.FAILED;
    message: string;
}
export const getPartsOrderReportsLoadFailedAction = (message: string): IGetPartsOrderReportsLoadFailedAction => ({
    type: IGET_PARTS_ORDER_REPORTS_REQUEST.FAILED,
    message
});
