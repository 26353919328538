import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IGetAllPartsCashOrders } from "../getAllPartsCashOrders/getAllPartsCashOrdersConstants";
import { IUpdatePartsCashOrderRequest, IUPDATE_PARTS_CASH_ORDER_REQUEST } from "./updatePartsCashOrderConstants";

export interface IUpdatePartsCashOrderLoadAction {
    type: IUPDATE_PARTS_CASH_ORDER_REQUEST.REQUEST;
    data: IUpdatePartsCashOrderRequest
}
export const updatePartsCashOrderLoadAction = (data: IUpdatePartsCashOrderRequest): IUpdatePartsCashOrderLoadAction => ({
    type: IUPDATE_PARTS_CASH_ORDER_REQUEST.REQUEST,
    data
});

export interface IUpdatePartsCashOrderSuccessAction {
    type: IUPDATE_PARTS_CASH_ORDER_REQUEST.SUCCESS;
    response: PartsResponse<IGetAllPartsCashOrders>;
}
export const updatePartsCashOrderLoadSuccessAction = (response: PartsResponse<IGetAllPartsCashOrders>): IUpdatePartsCashOrderSuccessAction => ({
    type: IUPDATE_PARTS_CASH_ORDER_REQUEST.SUCCESS,
    response
});
export interface IUpdatePartsCashOrderLoadFailedAction {
    type: IUPDATE_PARTS_CASH_ORDER_REQUEST.FAILED;
    message: string;
}
export const updatePartsCashOrderLoadFailedAction = (message: string): IUpdatePartsCashOrderLoadFailedAction => ({
    type: IUPDATE_PARTS_CASH_ORDER_REQUEST.FAILED,
    message
});
