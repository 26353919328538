
import React, { useState } from "react";
import styled from "styled-components";
import { Drawer } from "@material-ui/core";
import { LAIconButton } from "../shared/buttons";
import { CollapseIcon, ExpandIcon } from "../shared/icons";
import { BLACK_COLOR, WHITE_COLOR } from "../shared/theme";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { LACrossButtonWithInWindow } from "../shared/crossButton";
import bannerImage from "./banner.png";

interface IBottomBannerProps {
}

const BottomBannerStyles = styled.div`
  .banner-button {
    bottom: 0;
    left: 41%;
    z-index: 0;
    width: 20%;
    position: fixed;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background-color: ${BLACK_COLOR};

    strong {
      font-size: 21px;
      color: ${WHITE_COLOR};
    }
  };

    @media only screen and (max-width: 920px) {
      .banner-button {
        width: 100%;
        left: 0%;
        strong {
          font-size: 15px;
        }
      };
    };

`;

const BottomBannerDrawerStyles = styled.div`
    .drawer-title {
      font-size: 25px;
      font-weight: bold;
      color: ${WHITE_COLOR};
      background-color: ${BLACK_COLOR};
    };

    .banner-img {
      width: 95%;
      height: 100%;
    };

    @media only screen and (max-width: 920px) {
      .banner-img {
        width: 100%;
        height: 100%;
      };  
    };
`;


export const BottomBanner: React.FC<IBottomBannerProps> = React.memo((props: IBottomBannerProps) => {

  const [expanded, setExpand] = useState(false);
  const onExpandCollapse = () => setExpand(!expanded);

  return <BottomBannerStyles>
    {!expanded && <div className="banner-button text-center">
      <LAGrid>

        <LAGridItem xs={8} sm={8} md={8} lg={10} className="pt-3">
          <strong>More Parts</strong>
        </LAGridItem>

        <LAGridItem xs={4} sm={4} md={4} lg={2}>
          <LAIconButton
            label="Expand"
            className="mt-2"
            onClick={onExpandCollapse}
            icon={<CollapseIcon color={WHITE_COLOR} />}
          />
        </LAGridItem>

      </LAGrid>
    </div>}

    {expanded && <>
      <Drawer
        open={expanded}
        anchor="bottom"
        onClose={onExpandCollapse}
        PaperProps={{ style: { 
          height: "28%", 
          overflowX: "hidden",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          left: window.innerWidth > 920 ? "27%" : "0%",
          width: window.innerWidth > 920 ? "50%" : "100%"
         } 
        }}
      >
        <BottomBannerDrawerStyles>
        <LAGrid>

          <LAGridItem xs={12} className="drawer-title text-center">
            <span>More Parts</span>
          </LAGridItem>

          <LAGridItem xs={12} className="text-center">
            <img src={bannerImage} className="banner-img" alt="Contact us" title="More Parts" />
          </LAGridItem>

        </LAGrid>

        <LACrossButtonWithInWindow icon={<ExpandIcon color={BLACK_COLOR} />} onClick={onExpandCollapse} />
        </BottomBannerDrawerStyles>
      </Drawer>
    </>}

  </BottomBannerStyles>
});