import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { getCustomersLoadFailedAction, getCustomersLoadSuccessAction, IGetCustomersLoadAction, IGetCustomersLoadFailedAction, IGetCustomersSuccessAction } from "./getCustomersActions";
import { ICustomer, IGET_CUSTOMERS_REQUEST } from "./getCustomersConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";

export const getCustomersEpic: Epic = (
    action$: ActionsObservable<IGetCustomersLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetCustomersSuccessAction | IGetCustomersLoadFailedAction> =>
    action$.ofType(IGET_CUSTOMERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<ICustomer[]>>(
                    END_POINTS.GET_ALL_CUSTOMERS,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<ICustomer[]>): IGetCustomersSuccessAction => getCustomersLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(getCustomersLoadFailedAction("Unable to get customers")))
                    )
            )
        );