import React, { ReactNode, PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import LATextField from "../../shared/textField";
import PasswordTextField from "../../shared/passwordField";
import { LAButton } from "../../shared/buttons";
import { undefinedFunction, ZEROTH } from "../../shared/constExports";
import { ById } from "../../shared/publicInterfaces";
import { hasPayload, Server } from "../../../redux/server";
import { IFieldErrorKeyValue } from "../../shared/fieldValidation";
import LAErrorBox from "../../shared/errorBox";
import { IUpdateProfileData, IUpdateProfileRequest } from "../../../redux/userProfile/updateProfile/updateProfileConstants";
import { updateProfileLoadAction } from "../../../redux/userProfile/updateProfile/updateProfileActions";
import { updateProfile } from "../../../redux/userProfile/updateProfile/updateProfileAccessor";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import LAAutoComplete from "../../shared/autoComplete";
import { countryList } from "../../shared/countries";

interface IUserProfileComponentStoreProps {
    updateProfileStatus: Server<string>;
};

interface IUserProfileComponentDispatchProps {
    updateProfileRequest: (data: IUpdateProfileRequest) => unknown;
};

interface IUserProfileComponentOwnProps {
    data: ILoginResponse;
};
interface IUserProfileComponentState {
    showStatus: boolean;
    data: IUpdateProfileData;
    confirmPassword: string;
    errors: ById<IFieldErrorKeyValue>;
};

const UserProfileStyles = styled(LAPaperWithPadding)`
    margin: 20px 20px;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 15px 10px;
    };
`;

type IUserProfileComponentProps =
    IUserProfileComponentStoreProps
    & IUserProfileComponentOwnProps
    & IUserProfileComponentDispatchProps;

class UserProfileComponent extends PureComponent<IUserProfileComponentProps, IUserProfileComponentState> {

    public constructor(props: IUserProfileComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                First_Name: "",
                Last_Name: "",
                Email: "",
                Password: "",
                Phone: "",
                Address: "",
                Province: "",
                City: "",
                Country: "",
                Company: "",
                Billing_City: "",
                Billing_Province: "",
                Billing_Country: "",
                Billing_Address: "",
                Billing_Postal_Code: "",
                Postal_Code: ""
            },
            confirmPassword: "",
            errors: {},
            showStatus: false
        };
    }

    public componentDidMount(): void {
        const {
            id, firstname, lastname, email, phone, address, province, city, country, company,
            billing_city, billing_province, billing_country,  billing_address,
            billing_postal_code, postal_code
        } = this.props.data.customer;

        this.setState({
            data: {
                id,
                First_Name: firstname,
                Last_Name: lastname,
                Email: email,
                Password: "",
                Phone: phone,
                Address: address,
                Province: province,
                City: city,
                Country: country,
                Company: company,
                Billing_City: billing_city,
                Billing_Province: billing_province,
                Billing_Country: billing_country,
                Billing_Address: billing_address,
                Billing_Postal_Code: billing_postal_code,
                Postal_Code: postal_code
            }
        });
    };

    public componentDidUpdate(prevProps: IUserProfileComponentProps): void {
        if (prevProps !== this.props) {
            if ((this.state.showStatus === false) && (prevProps.updateProfileStatus !== this.props.updateProfileStatus))
                this.setState({ showStatus: true });
        };
    };

    public render(): ReactNode {

        const { data, errors, confirmPassword, showStatus } = this.state;

        const onCountry = (event: unknown, v: { name: string, code: string }): void => this.handleInfo("Country", v !== null ? v.name : "");
        const onBillingCountry = (event: unknown, v: { name: string, code: string }): void => this.handleInfo("Billing_Country", v !== null ? v.name : "");

        return (
            <UserProfileStyles>
                <LAGrid spacing={3}>

                    <LAGridItem xs={12}>
                        <h2 className="text-center">UPDATE PROFILE</h2>
                    </LAGridItem>

                    {(showStatus && hasPayload(this.props.updateProfileStatus)) && <LAGridItem xs={12}>
                        <LAErrorBox text={this.props.updateProfileStatus.payload} />
                    </LAGridItem>}

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="First Name"
                            name="First_Name"
                            value={data.First_Name}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["First_Name"] ? errors["First_Name"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Last Name"
                            name="Last_Name"
                            value={data.Last_Name}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Last_Name"] ? errors["Last_Name"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Company"
                            name="Company"
                            value={data.Company}
                            fullWidth={true}
                            onChange={this.handleInfo}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Address"
                            name="Address"
                            value={data.Address}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Address"] ? errors["Address"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="City"
                            name="City"
                            value={data.City}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["City"] ? errors["City"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Province"
                            name="Province"
                            value={data.Province}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Province"] ? errors["Province"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LAAutoComplete
                            name="Country"
                            multiple={false}
                            getOptionLabel="name"
                            autoHighlight={true}
                            option={countryList}
                            onChange={onCountry}
                            filterSelectedOptions={true}
                            selectionRemove={undefinedFunction}
                            dropDownPlaceHolder="Country"
                            errorText={errors["Country"] ? errors["Country"].message : undefined}
                            value={data.Country.length > 0 ? countryList.find(q => q.name === data.Country) : ""}
                            defaultValue={data.Country.length > 0 ? countryList.find(q => q.name === data.Country) : ""}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Postal/Zip Code"
                            name="Postal_Code"
                            value={data.Postal_Code}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Postal_Code"] ? errors["Postal_Code"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Phone"
                            name="Phone"
                            value={data.Phone}
                            fullWidth={true}
                            onChange={this.handlePhone}
                            errorText={errors["Phone"] ? errors["Phone"].message : undefined}
                        />
                    </LAGridItem>

                        <LAGridItem xs={12} sm={6}>
                            <LATextField
                                label="Billing Address"
                                name="Billing_Address"
                                value={data.Billing_Address}
                                fullWidth={true}
                                onChange={this.handleInfo}
                                errorText={errors["Billing_Address"] ? errors["Billing_Address"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6}>
                            <LATextField
                                label="Billing City"
                                name="Billing_City"
                                value={data.Billing_City}
                                fullWidth={true}
                                onChange={this.handleInfo}
                                errorText={errors["Billing_City"] ? errors["Billing_City"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6}>
                            <LATextField
                                label="Billing Province"
                                name="Billing_Province"
                                value={data.Billing_Province}
                                fullWidth={true}
                                onChange={this.handleInfo}
                                errorText={errors["Billing_Province"] ? errors["Billing_Province"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6}>
                            <LAAutoComplete
                                name="Billing_Country"
                                multiple={false}
                                getOptionLabel="name"
                                autoHighlight={true}
                                option={countryList}
                                onChange={onBillingCountry}
                                filterSelectedOptions={true}
                                selectionRemove={undefinedFunction}
                                dropDownPlaceHolder="Billing Country"
                                errorText={errors["Billing_Country"] ? errors["Billing_Country"].message : undefined}
                                value={data.Country.length > 0 ? countryList.find(q => q.name === data.Billing_Country) : ""}
                                defaultValue={data.Country.length > 0 ? countryList.find(q => q.name === data.Billing_Country) : ""}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6}>
                        <LATextField
                            label="Billing Postal/Zip Code"
                            name="Billing_Postal_Code" 
                            value={data.Billing_Postal_Code}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Billing_Postal_Code"] ? errors["Billing_Postal_Code"].message : undefined}
                        />
                    </LAGridItem>

                       

                    <LAGridItem xs={12} sm={12}>
                        <LATextField
                            label="Email"
                            name="Email"
                            value={data.Email}
                            fullWidth={true}
                            onChange={this.handleInfo}
                            errorText={errors["Email"] ? errors["Email"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <PasswordTextField
                            id="password"
                            name="Password"
                            label="Password"
                            value={data.Password}
                            required={true}
                            onChange={this.handleInfo}
                            fullWidth={true}
                            onPressEnter={undefinedFunction}
                            className="mb-2"
                            errorText={errors["Password"] ? errors["Password"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6}>
                        <PasswordTextField
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm Password"
                            value={confirmPassword}
                            required={true}
                            fullWidth={true}
                            className="mb-2"
                            onPressEnter={this.updateProfile}
                            onChange={this.handleConfirmPassword}
                            errorText={errors["confirmPassword"] ? errors["confirmPassword"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LAButton label="UPDATE PROFILE" fullWidth={true} onClick={this.updateProfile} />
                    </LAGridItem>

                </LAGrid>
            </UserProfileStyles>
        );
    }

    private handleInfo = (name: string, value: string): void => {
        const errors = { ...this.state.errors };

        if ((errors[name] !== undefined) && value.length > ZEROTH) {
            delete errors[name];
        };

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors: errors
        });
    };

    private handlePhone = (name: string, value: string): void => {
        if (!isNaN(Number(value))) {
            this.handleInfo(name, value);
        }
    };

    private handleConfirmPassword = (name: string, value: string): void => {
        const errors = { ...this.state.errors };

        if ((errors[name] !== undefined) && ((value.length > ZEROTH) || (value === this.state.data.Password))) {
            delete errors[name];
        };

        if (value !== this.state.data.Password) {
            errors["confirmPassword"] = { key: "confirmPassword", message: "Password does not match" };
        };

        this.setState({ confirmPassword: value, errors });
    };

    private updateProfile = (): void => {
        const iS = this.state.data;
        const errors = { ...this.state.errors };

        if ((iS.First_Name === null) || (iS.First_Name.length === ZEROTH)) {
            errors["First_Name"] = { key: "First_Name", message: "First Name is Required" };
        };
        if ((iS.Last_Name === null) || (iS.Last_Name.length === ZEROTH)) {
            errors["Last_Name"] = { key: "Last_Name", message: "Last Name is Required" };
        };
        if (iS.Password !== this.state.confirmPassword) {
            errors["confirmPassword"] = { key: "confirmPassword", message: "Password does not match" };
        };
        if ((iS.Password.length === ZEROTH) || (iS.Password.length < 6)) {
            errors["Password"] = { key: "Password", message: "Password is Required and has to be min 6 characters" };
        };
        if (this.state.confirmPassword.length === ZEROTH) {
            errors["confirmPassword"] = { key: "confirmPassword", message: "Confirm Password is Required" };
        };
        if ((iS.Email === null) || (iS.Email.length === ZEROTH)) {
            errors["Email"] = { key: "Email", message: "Email is Required" };
        };
        if ((iS.Province === null) || (iS.Province.length === ZEROTH)) {
            errors["Province"] = { key: "Province", message: "Province is Required" };
        };
        if ((iS.City === null) || (iS.City.length === ZEROTH)) {
            errors["City"] = { key: "City", message: "City is Required" };
        };
        if ((iS.Country === null) || (iS.Country.length === ZEROTH)) {
            errors["Country"] = { key: "Country", message: "Country is Required" };
        };
        if ((iS.Phone === null) || (iS.Phone.length === ZEROTH)) {
            errors["Phone"] = { key: "Phone", message: "Phone is Required" };
        };
        if ((iS.Address === null) || (iS.Address.length === ZEROTH)) {
            errors["Address"] = { key: "Address", message: "Address is Required" };
        };

        if ((iS.Postal_Code === null) || (iS.Postal_Code.length === ZEROTH)) {
            errors["Postal_Code"] = { key: "Postal_Code", message: "Postal_Code is Required" };
        };

        if ((iS.Billing_Address === null) || (iS.Billing_Address.length === ZEROTH)) {
            errors["Billing_Address"] = { key: "Billing_Address", message: "Billing Address is Required" };
        };

        if ((iS.Billing_City === null) || (iS.Billing_City.length === ZEROTH)) {
            errors["Billing_City"] = { key: "Billing_City", message: "Billing City is Required" };
        };

        if ((iS.Billing_Country === null) || (iS.Billing_Country.length === ZEROTH)) {
            errors["Billing_Country"] = { key: "Billing_Country", message: "Billing Country is Required" };
        };

       

        if ((iS.Billing_Province === null) || (iS.Billing_Province.length === ZEROTH)) {
            errors["Billing_Province"] = { key: "Billing_Province", message: "Billing Province is Required" };
        };
        if ((iS.Billing_Postal_Code === null) || (iS.Billing_Postal_Code.length === ZEROTH)) {
            errors["Billing_Postal_Code"] = { key: "Billing_Postal_Code", message: "Billing Postal Code is Required" };
        };


        if ((Object.values(errors).length === ZEROTH)) {
            this.props.updateProfileRequest({ request: iS, token: this.props.data.token });
        } else {
            this.setState({data: iS, errors });
        }
    };

}

const mapStateToProps = (state: IStore): IUserProfileComponentStoreProps => ({
    updateProfileStatus: updateProfile(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IUserProfileComponentDispatchProps => ({
    updateProfileRequest: (data: IUpdateProfileRequest): unknown => dispatch(updateProfileLoadAction(data, false))
});


export default connect(mapStateToProps, mapDispatchToProps)(UserProfileComponent);