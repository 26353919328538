import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, Server, STATUS_ENUM } from "../../../redux/server";
import { PartsResponse } from "../../shared/publicInterfaces";
import { IPart } from "../../../redux/parts/getParts/getPartsConstants";
import { CheckOutItem } from "./checkOutItem";
import { getPartsByIds } from "../../../redux/parts/getPartsByIds/getPartsByIdsAccessor";
import { getPartsByIdsLoadAction } from "../../../redux/parts/getPartsByIds/getPartsByIdsActions";
import { IGetPartsByIdsRequest } from "../../../redux/parts/getPartsByIds/getPartsByIdsConstants";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { AdminCheck, OrderdeskCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import { login } from "../../../redux/login/loginAccessor";
import { ICart } from "../../../redux/cart/cartConstants";
import { addCartLoadAction, cartLoadAction, removeCartLoadAction } from "../../../redux/cart/cartActions";
import { getCart } from "../../../redux/cart/cartAccessor";
import { LASaveAndCancelButton } from "../../shared/buttons";
import { IAddPartsOrderItem, IAddPartsOrderRequest, IAddPartsOrderResponse } from "../../../redux/addPartsOrder/addPartsOrderConstants";
import { addPartsOrder } from "../../../redux/addPartsOrder/addPartsOrderAccessor";
import { addPartsOrderLoadAction } from "../../../redux/addPartsOrder/addPartsOrderActions";
import { ROUTE } from "../../routes";
import LATextArea from "../../shared/textArea";
import RequestStatus from "../../shared/requestStatusSnackbar";
import LALinkButton from "../../shared/linkButton";
import { TermsAndConditionPopupComponent } from "../../shared/termsAndConditionPopup";
import { LACheckBox } from "../../shared/checkBox";
import LAAutoComplete from "../../shared/autoComplete";
import { countryList } from "../../shared/countries";
import PageSpacing from "../../shared/pageSpacing";


interface ICheckOutComponentStoreProps {
    cart: Server<ICart[]>;
    loginStatus: Server<ILoginResponse>;
    parts: Server<PartsResponse<IPart[]>>;
    addPartsOrder: Server<PartsResponse<IAddPartsOrderResponse>>;
};

interface ICheckOutComponentDispatchProps {
    cartRequest: () => unknown;
    addCartRequest: (cart: ICart) => unknown;
    removeCartRequest: (id: number) => unknown;
    partsRequest: (data: IGetPartsByIdsRequest) => unknown;
    addPartsOrderRequest: (data: IAddPartsOrderRequest) => unknown;
};

interface ICheckOutComponentState {
    parts: IPart[];
    itemsInCart: ICart[];
    showMessage: boolean;
    shippingAddress: string;
    shippingProvince: string;
    shippingPostalCode: string;
    shippingCity: string;
    shippingCountry: string;
    termsAndCondition: boolean;
    billingAddressCheck: boolean;
    customerAddressCheck: boolean;
};

const CheckOutStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .bigText {
        font-size: 20px;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;
    };
`;

type ICheckOutComponentProps =
    RouteComponentProps
    & ICheckOutComponentStoreProps
    & ICheckOutComponentDispatchProps;

class CheckOutComponent extends PureComponent<ICheckOutComponentProps, ICheckOutComponentState> {

    public constructor(props: ICheckOutComponentProps) {
        super(props);
        this.state = {
            parts: [],
            itemsInCart: [],
            shippingAddress: "",
            shippingProvince: "",
            shippingCity: "",
            shippingCountry: "",
            shippingPostalCode: "",
            showMessage: false,
            termsAndCondition: false,
            billingAddressCheck: false,
            customerAddressCheck: false
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.props.cartRequest();
        this.loadParts();
    };

    public componentDidUpdate(prevProps: ICheckOutComponentProps): void {
        if (this.props.parts !== prevProps.parts) {
            if (hasPayload(this.props.parts))
                this.setState({ parts: this.props.parts.payload.response });
        };

        if (this.props.addPartsOrder !== prevProps.addPartsOrder) {
            if (hasPayload(this.props.addPartsOrder) && this.props.addPartsOrder.payload.message.toLocaleLowerCase().includes("success")) {
                this.setState({ showMessage: true });
            }
        };
    };

    public render(): ReactNode {

        const { parts, itemsInCart, shippingAddress, showMessage, termsAndCondition, shippingProvince, shippingCity, shippingCountry, billingAddressCheck, customerAddressCheck, shippingPostalCode } = this.state;

        const onShippingCountryChange = (e: unknown, v: { name: string, code: string }): void => this.onInfoChange("shippingCountry", v !== null ? v.name : "");

        return (
            <PageSpacing title="Order Checkout" description="Order Checkout">
            <CheckOutStyles>
                {((hasPayload(this.props.loginStatus) && ((this.props.loginStatus.payload.customer.email !== AdminCheck) && (this.props.loginStatus.payload.customer.email !== OrderdeskCheck)))) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12}>
                            <h2 className="text-center">SHOPPING CART</h2>
                        </LAGridItem>

                        {(STATUS_ENUM.LOADING === this.props.parts.kind) && <LAGridItem xs={12}>
                            <LALoading />
                        </LAGridItem>}

                        {(STATUS_ENUM.FAILED === this.props.parts.kind) && <LAGridItem xs={12}>
                            <LAErrorBox text="Failed to load your cart" />
                        </LAGridItem>}

                        {(STATUS_ENUM.SUCCEEDED === this.props.parts.kind) && <LAGridItem xs={12}>
                            <LAGrid>

                                <LAGridItem xs={12} sm={12} md={12} lg={9}>
                                    {itemsInCart.length > 0 && <CheckOutItem parts={parts} cart={itemsInCart}
                                        disablePrice={true} onCart={this.onCartRemove}
                                        onQtyChange={this.onQtyChange} onNote={this.onNote} />}

                                    {itemsInCart.length === 0 && <LAErrorBox text="Your cart is empty" />}
                                </LAGridItem>

                                <LAGridItem xs={12} sm={12} md={12} lg={3}>
                                    <LAPaperWithPadding>
                                        <LAGrid spacing={2}>

                                            <LAGridItem xs={12}>
                                                <h3>Order Summary</h3>
                                            </LAGridItem>

                                            {/* <LAGridItem xs={12}>
                                                <LATextField
                                                    type="number"
                                                    fullWidth={true}
                                                    name="subTotal"
                                                    disabled={true}
                                                    variant="outlined"
                                                    onChange={undefinedFunction}
                                                    label={`Items sub-total (${itemsInCart.length})`}
                                                    value={Number(itemsInCart.reduce((a, b) => +a + +b.price * b.quantity, 0)).toFixed(2)}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LATextField
                                                    type="number"
                                                    fullWidth={true}
                                                    name="shippingAndHandling"
                                                    disabled={true}
                                                    variant="outlined"
                                                    onChange={undefinedFunction}
                                                    label="Shipping & Handling"
                                                    value=""
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LATextField
                                                    type="number"
                                                    fullWidth={true}
                                                    name="gst"
                                                    disabled={true}
                                                    variant="outlined"
                                                    onChange={undefinedFunction}
                                                    label="GST"
                                                    value=""
                                                />
                                            </LAGridItem> */}

                                            <LAGridItem xs={6}>
                                                <LACheckBox
                                                    label="Use Billing Address"
                                                    value={billingAddressCheck}
                                                    onChange={this.handleBillAddressCheckBox}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={6}>
                                                <LACheckBox
                                                    label="Use Customer Address"
                                                    value={customerAddressCheck}
                                                    onChange={this.handleCustomerAddressCheckBox}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LATextArea
                                                    rowsMin={1}
                                                    rowsMax={2}
                                                    fullWidth={true}
                                                    name="shippingAddress"
                                                    value={shippingAddress}
                                                    label="Shipping Address"
                                                    onChange={this.onInfoChange}
                                                    disabled={itemsInCart.length === 0 ? true : undefined}
                                                    errorText={itemsInCart.length !== 0 ?
                                                        (shippingAddress !== null && shippingAddress.length > ZEROTH) ? undefined : "Required"
                                                        : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LATextArea
                                                    rowsMin={1}
                                                    rowsMax={2}
                                                    fullWidth={true}
                                                    name="shippingCity"
                                                    value={shippingCity}
                                                    label="Shipping City"
                                                    onChange={this.onInfoChange}
                                                    disabled={itemsInCart.length === 0 ? true : undefined}
                                                    errorText={itemsInCart.length !== 0 ?
                                                        (shippingCity !== null && shippingCity.length > ZEROTH) ? undefined : "Required"
                                                        : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LATextArea
                                                    rowsMin={1}
                                                    rowsMax={2}
                                                    fullWidth={true}
                                                    name="shippingProvince"
                                                    value={shippingProvince}
                                                    label="Shipping Province"
                                                    onChange={this.onInfoChange}
                                                    disabled={itemsInCart.length === 0 ? true : undefined}
                                                    errorText={itemsInCart.length !== 0 ?
                                                        (shippingProvince !== null && shippingProvince.length > ZEROTH) ? undefined : "Required"
                                                        : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LAAutoComplete
                                                    name="shippingCountry"
                                                    multiple={false}
                                                    getOptionLabel="name"
                                                    autoHighlight={true}
                                                    option={countryList}
                                                    onChange={onShippingCountryChange}
                                                    filterSelectedOptions={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Shipping Country"
                                                    errorText={itemsInCart.length !== 0 ?
                                                        (shippingCountry !== null && shippingCountry.length > ZEROTH) ? undefined : "Required"
                                                        : undefined}
                                                    value={shippingCountry.length > 0 ? countryList.find(q => q.name === shippingCountry) : ""}
                                                    defaultValue={shippingCountry.length > 0 ? countryList.find(q => q.name === shippingCountry) : ""}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LATextArea
                                                    rowsMin={1}
                                                    rowsMax={2}
                                                    fullWidth={true}
                                                    name="shippingPostalCode"
                                                    value={shippingPostalCode}
                                                    label="Shipping Postal/Zip Code"
                                                    onChange={this.onInfoChange}
                                                    disabled={itemsInCart.length === 0 ? true : undefined}
                                                    errorText={itemsInCart.length !== 0 ?
                                                        (shippingPostalCode !== null && shippingPostalCode.length > ZEROTH) ? undefined : "Required"
                                                        : undefined}
                                                />
                                            </LAGridItem>

                                            {/* <LAGridItem xs={12}>
                                                <LALinkButton label="Terms & Conditions" onClick={this.handleTermsAndCondition} />
                                            </LAGridItem> */}

                                            <LAGridItem xs={12}>
                                                <LASaveAndCancelButton
                                                    onSave={this.getQuote}
                                                    onCancel={this.handleBack}
                                                    saveButtonText="Get Quote"
                                                    cancelButtonText={(showMessage && hasPayload(this.props.addPartsOrder)) ? "Go To Home" : "Continue Shopping"}
                                                    disableSave={((itemsInCart.length === ZEROTH) || (shippingAddress.length === ZEROTH) || (shippingProvince.length === ZEROTH)
                                                        || (shippingCity.length === ZEROTH) || (shippingCountry.length === ZEROTH)
                                                        || (showMessage && hasPayload(this.props.addPartsOrder))) ? true : undefined}
                                                />
                                            </LAGridItem>

                                            {showMessage && hasPayload(this.props.addPartsOrder) && <LAGridItem xs={12}>
                                                <LAErrorBox
                                                    text={this.props.addPartsOrder.payload.message}
                                                />
                                            </LAGridItem>}

                                            {!showMessage && <LAGridItem xs={12}>
                                                <LAErrorBox
                                                    text="You will be contacted by sales repersentative regarding shipping details and payment"
                                                />
                                            </LAGridItem>}

                                        </LAGrid>
                                    </LAPaperWithPadding>
                                </LAGridItem>

                            </LAGrid>
                        </LAGridItem>}

                    </LAGrid>}

                {termsAndCondition && <TermsAndConditionPopupComponent open={termsAndCondition} onClose={this.handleTermsAndCondition} />}

                <RequestStatus requestStatus={this.props.addPartsOrder.kind} />
            </CheckOutStyles>
            </PageSpacing>
        );
    }

    private handleBillAddressCheckBox = (): void => {
        if (hasPayload(this.props.loginStatus)) {
            if (this.state.billingAddressCheck) {
                this.setState({
                    shippingCity: "",
                    shippingAddress: "",
                    shippingCountry: "",
                    shippingProvince: "",
                    billingAddressCheck: false,
                    customerAddressCheck: false,
                });
            } else {
                const { billing_address, billing_city, billing_country, billing_province } = this.props.loginStatus.payload.customer;
                this.setState({
                    billingAddressCheck: true,
                    customerAddressCheck: false,
                    shippingCity: billing_city,
                    shippingCountry: billing_country,
                    shippingAddress: billing_address,
                    shippingProvince: billing_province,
                });
            };
        };
    };

    private handleCustomerAddressCheckBox = (): void => {
        if (hasPayload(this.props.loginStatus)) {
            if (this.state.customerAddressCheck) {
                this.setState({
                    shippingCity: "",
                    shippingAddress: "",
                    shippingCountry: "",
                    shippingProvince: "",
                    billingAddressCheck: false,
                    customerAddressCheck: false,
                });
            } else {
                const { address, city, country, province } = this.props.loginStatus.payload.customer;
                this.setState({
                    shippingCity: city,
                    shippingCountry: country,
                    shippingAddress: address,
                    shippingProvince: province,
                    billingAddressCheck: false,
                    customerAddressCheck: true,
                });
            };
        };
    };

    private handleTermsAndCondition = (): void => {
        this.setState({ termsAndCondition: !this.state.termsAndCondition });
    };

    private onInfoChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            [name]: value
        });
    };

    private onNote = (value: string, index: number): void => {
        const itemsInCart = [...this.state.itemsInCart];
        itemsInCart[index].note = value;
        this.setState({ itemsInCart });
    };

    private getQuote = (): void => {
        if (hasPayload(this.props.loginStatus)) {
            const Items: IAddPartsOrderItem[] = [];

            const { itemsInCart, shippingAddress, shippingProvince, shippingCity, shippingCountry, shippingPostalCode } = this.state;

            itemsInCart.forEach(x => {
                Items.push({ Part_no: x.id.toString(), Quantity: x.quantity, Notes: x.note, Price: Number(x.price), Total_Cost: (Number(x.price) * Number(x.quantity)) });
            });

            this.props.addPartsOrderRequest({
                token: this.props.loginStatus.payload.token,
                request: {
                    Items,
                    Created: new Date().toISOString(),
                    Shipping_Address: shippingAddress,
                    Shipping_City: shippingCity,
                    Shipping_Province: shippingProvince,
                    Shipping_Country: shippingCountry,
                    Shipping_Postal_Code: shippingPostalCode,
                    Customer_ID: this.props.loginStatus.payload.customer.id,
                    Created_By: this.props.loginStatus.payload.customer.email
                }
            });
        }
    };

    private handleBack = (): void => {
        this.props.history.push(ROUTE.PARTS());
    };

    private onQtyChange = (value: number, index: number): void => {
        const itemsInCart = [...this.state.itemsInCart];
        itemsInCart[index].quantity = value;
        this.setState({ itemsInCart });
        this.props.addCartRequest(itemsInCart[index]);
    };

    private onCartRemove = (part: IPart, index: number): void => {
        const iS = [...this.state.parts];
        const itemsInCart = [...this.state.itemsInCart];
        iS.splice(index, 1);
        itemsInCart.splice(index, 1);
        this.setState({ parts: iS, itemsInCart });
        this.props.removeCartRequest(part.id);
    };

    private loadParts = (): void => {
        if (hasPayload(this.props.cart)) {
            const itemsInCart: ICart[] = this.props.cart.payload;
            const data: number[] = itemsInCart.map(x => (x.id));
            this.setState({ itemsInCart });
            this.props.partsRequest({ request: data, token: hasPayload(this.props.loginStatus) ? this.props.loginStatus.payload.token : "" });
        };
    };

}

const mapStateToProps = (state: IStore): ICheckOutComponentStoreProps => ({
    cart: getCart(state),
    loginStatus: login(state),
    parts: getPartsByIds(state),
    addPartsOrder: addPartsOrder(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ICheckOutComponentDispatchProps => ({
    cartRequest: (): unknown => dispatch(cartLoadAction()),
    addCartRequest: (cart: ICart): unknown => dispatch(addCartLoadAction(cart)),
    removeCartRequest: (id: number): unknown => dispatch(removeCartLoadAction(id)),
    partsRequest: (data: IGetPartsByIdsRequest): unknown => dispatch(getPartsByIdsLoadAction(data)),
    addPartsOrderRequest: (data: IAddPartsOrderRequest): unknown => dispatch(addPartsOrderLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(CheckOutComponent);