import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ajaxHeadersFromStore, withJsonContentType } from "../../epicUtils";
import { IPartsFacetLoadAction, IPartsFacetLoadFailedAction, IPartsFacetSuccessAction, partsFacetLoadFailedAction, partsFacetLoadSuccessAction } from "./partsFacetActions";
import { IPARTS_FACET_REQUEST, IPartsFacetResponse } from "./partsFacetConstants";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../endpoints";

export const partsFacetEpic: Epic = (
    action$: ActionsObservable<IPartsFacetLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IPartsFacetSuccessAction | IPartsFacetLoadFailedAction> =>
    action$.ofType(IPARTS_FACET_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IPartsFacetResponse>>(
                    END_POINTS.GET_PARTS_FACETS,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<IPartsFacetResponse>): IPartsFacetSuccessAction => partsFacetLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(partsFacetLoadFailedAction("Unable to load parts facet")))
                    )
            )
        );