import { IADD_CART_REQUEST, ICart, ICART_REQUEST, IREMOVE_CART_REQUEST } from "./cartConstants";
import { Server, loading, failed, succeeded, notLoaded } from "../server";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { IAddCartLoadAction, IAddCartLoadFailedAction, IAddCartSuccessAction, ICartLoadAction, ICartLoadFailedAction, ICartSuccessAction, IRemoveCartLoadAction, IRemoveCartLoadFailedAction, IRemoveCartSuccessAction } from "./cartActions";
import LocalStorage from "../../utils/localStorage";
import { IAddPartsOrderSuccessAction } from "../addPartsOrder/addPartsOrderActions";
import { IADD_PARTS_ORDER_REQUEST } from "../addPartsOrder/addPartsOrderConstants";

type Actions =
    ICartLoadAction
    | ICartLoadFailedAction
    | ICartSuccessAction
    | IAddCartLoadAction
    | IAddCartLoadFailedAction
    | IAddCartSuccessAction
    | IRemoveCartLoadAction
    | IRemoveCartLoadFailedAction
    | IRemoveCartSuccessAction
    | IAddPartsOrderSuccessAction
    | IFlushDataSuccessAction;

//#region GET
export const CartReducer = (state: Server<ICart[]> = notLoaded, action: Actions): Server<ICart[]> => {
    switch (action.type) {
        case ICART_REQUEST.REQUEST:
            return loading;

        case ICART_REQUEST.SUCCESS:
            return succeeded(LocalStorage.getCart());

        case IADD_CART_REQUEST.SUCCESS:
            return notLoaded;

        case IREMOVE_CART_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_PARTS_ORDER_REQUEST.SUCCESS:
            LocalStorage.removeAllFromCart();
            return notLoaded;
        
        case ICART_REQUEST.FAILED:
            return failed("Failed to load cart");

        default:
            return state;
    }
};
//#endregion

//#region ADD
export const AddCartReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_CART_REQUEST.REQUEST:
            return loading;

        case IADD_CART_REQUEST.SUCCESS:
            LocalStorage.addToCart(action.cart);
            return succeeded("Success");

        case IADD_CART_REQUEST.FAILED:
            return failed("Failed to load cart");

        default:
            return state;
    }
};
//#endregion

//#region REMOVE
export const RemoveCartReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IREMOVE_CART_REQUEST.REQUEST:
            return loading;

        case IREMOVE_CART_REQUEST.SUCCESS:
            LocalStorage.removeFromCart(action.id);
            return succeeded("Success");

        case IREMOVE_CART_REQUEST.FAILED:
            return failed("Failed to load cart");

        default:
            return state;
    }
};
//#endregion