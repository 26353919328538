import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { ROUTE } from "../routes";
import { SalesEmail, SalesPhone } from "../shared/constExports";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { EmailIcon, GlobeIcon, HourIcon, PhoneIcon } from "../shared/icons";
import { BLACK_COLOR, WHITE_COLOR } from "../shared/theme";

const FooterStyles = styled.div`
    margin-top: 10px;
    
    .main-footer {
        background-color: ${BLACK_COLOR};
        width:100%;
        left: 0;
        bottom: 0; 
        overflow-x: hidden;
        overflow-y: hidden;
        position: relative;
    };

    .home-page-footer {
        position: fixed;
    };

    strong {
        color: ${WHITE_COLOR};
    };
`;


type IFooterProps =
    RouteComponentProps;

class Footer extends PureComponent<IFooterProps> {

    public render(): ReactNode {

        return (
            <FooterStyles>
                <div className={window.location.pathname === ROUTE.INDEX() ? "main-footer home-page-footer" : "main-footer"}>
                    <LAGrid spacing={0} className="text-center" justify="space-evenly" alignItems="center">

                        <LAGridItem xs={12} sm={12} md={3}>
                            <GlobeIcon color={WHITE_COLOR} />
                            <br />
                            <strong>Edmonton, AB, Canada, T6P 0C9</strong>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={3}>
                            <EmailIcon color={WHITE_COLOR} />
                            <br />
                            <a href={`mailto:${SalesEmail}`}>
                                <strong>{SalesEmail}</strong>
                            </a>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={3}>
                            <PhoneIcon color={WHITE_COLOR} />
                            <br />
                            <a href={`tel:${SalesPhone}`}>
                                <strong>{SalesPhone}</strong>
                            </a>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={3}>
                            <HourIcon color={WHITE_COLOR} />
                            <br />
                            <strong>Monday - Friday -- 8AM - 5PM (Mountain Time)</strong>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={4}>
                            <strong>&copy; {new Date().getFullYear()} Sureway Construction Group</strong>
                        </LAGridItem>

                    </LAGrid>
                </div>
            </FooterStyles>
        );
    }
}


export default Footer;