export const ROUTE = {
    INDEX: (): string => "/",
    PARTS: (): string => "/parts",
    DISMANTLED: (): string => "/dismantled",
    CHECKOUT: (): string => `/checkout`,
    LOGIN: (): string => "/user",
    CUSTOMER_ORDER: (): string => "/my-orders",
    ADMIN_ORDER: (): string => "/admin-orders",
    CASH_ORDER: (): string => "/cash-orders",
    CUSTOMERS: (): string => "/customers",
    REPORT: (): string => "/report"
};
