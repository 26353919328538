export enum IGET_LOOKUPS_REQUEST {
    REQUEST = "getDismantledLookups/GET_LOOKUPS_REQUEST",
    SUCCESS = "getDismantledLookups/GET_LOOKUPS_SUCCESS",
    FAILED = "getDismantledLookups/GET_LOOKUPS_FAILED"
};

export interface IDismantledLookupsRequest {    
    
};


export interface IDismantledLookups {
    equipmentTypes: any[];
    makes: any[];
};

