import { ReactNode, PureComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import HeaderCart from "./headerCart";
import { BLACK_COLOR, WHITE_COLOR } from "../shared/theme";
import { LAButton, LASecondaryButton } from "../shared/buttons";
import { ROUTE } from "../routes";
import HomeDropDown from "./homeDropDown";
import { IDispatch, IStore } from "../../redux/reducers";
import { hasPayload, Server, STATUS_ENUM } from "../../redux/server";
import { ILoginResponse } from "../../redux/login/loginConstants";
import { login } from "../../redux/login/loginAccessor";
import LAHeaderAvatar from "./headerAvatar";
import logo from "../../logo.png";
import { AdminCheck, OrderdeskCheck, SalesEmail } from "../shared/constExports";
import SalesTermDropDown from "./salesTermDropDown";
import PhoneNumberDropDown from "./phoneNumber";
import SurewayConstructionDropdown from "./surewayConstruction";
import ContactUsDropDown from "./contactUsDropDown";
import ContactUsPopup from "./contactUsPopup";
import { EmailIcon } from "../shared/icons";
import { TermsAndConditionPopupComponent } from "../shared/termsAndConditionPopup";

interface IHeaderStoreProps {
    loginStatus: Server<ILoginResponse>;
};

interface IHeaderDispatchProps {

};

interface IHeaderState {
    open: boolean;
    termsAndCondition: boolean;
};

const HeaderStyles = styled.div`

    .home-page {
        background-color: ${BLACK_COLOR};
        width:100%;
        left: 0;
        top: 0; 
        overflow-x: hidden;
        overflow-y: hidden;
        position: fixed;
        align-items: center;
        display: flex;
        height: 50px;
    };

    .other-page {
        background-color: ${BLACK_COLOR};
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
    };

    .main-header {
        .right-side {
            margin-left: auto;
            margin-right: 1rem;
        };
    };

    span {
        color: ${WHITE_COLOR};
    };

    .sub-header {
        display: flex;
        align-items: center;
        background-color: ${BLACK_COLOR};
        width: 100%;
        height: 45px;
        box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);
    };

    .logo-img {
        padding-left: 40px;
        width: 150px;
        height: 35px;
    };
`;


type IHeaderProps =
    RouteComponentProps
    & IHeaderStoreProps
    & IHeaderDispatchProps;

class Header extends PureComponent<IHeaderProps, IHeaderState> {

    public constructor(props: IHeaderProps) {
        super(props);
        this.state = {
            open: false,
            termsAndCondition: false
        };
    }

    public componentDidMount(): void {

    };

    public componentDidUpdate(): void {

    };

    public render(): ReactNode {
        const onLogInClick = () => this.onLogInClick();

        
        return (
            <HeaderStyles>
                <div className={window.location.pathname === ROUTE.INDEX() ? "main-header home-page" : "main-header other-page"}>
                    <img src={logo} onClick={this.onHomeClick} className="logo-img" alt="Sureway Parts" title="logo" />

                    {this.props.location.pathname === ROUTE.INDEX() && <>
                        <PhoneNumberDropDown className="ml-1 mr-2" />
                        <>
                            <EmailIcon color={WHITE_COLOR} />
                            <a href={`mailto:${SalesEmail}`}>
                            <span className="ml-1">
                                {SalesEmail}
                            </span>
                            </a>
                        </>
                        <ContactUsDropDown onClick={this.onContactUsClick} />
                        <SurewayConstructionDropdown className="ml-1 mr-2" />
                    </>}

                    {this.props.location.pathname !== ROUTE.INDEX() && <div>
                        <LAGrid>
                            <LAGridItem xs="auto">
                                <HomeDropDown {...this.props} />
                            </LAGridItem>

                            {/* <LAGridItem xs="auto">
                                <SalesTermDropDown onClick={this.onTermsAndCondition} />
                            </LAGridItem> */}

                            <LAGridItem xs="auto">
                                <ContactUsDropDown onClick={this.onContactUsClick} />
                            </LAGridItem>

                            <LAGridItem xs="auto">
                                <PhoneNumberDropDown />
                            </LAGridItem>

                            <LAGridItem xs="auto">
                                <SurewayConstructionDropdown />
                            </LAGridItem>
                        </LAGrid>
                    </div>}

                    <div className="right-side">
                        <LAGrid justify="flex-end" alignItems="center">

                            <LAGridItem xs="auto">
                                <LAButton label="Dismantled" onClick={this.handleDismantled} />
                            </LAGridItem>

                            {((hasPayload(this.props.loginStatus) && ((this.props.loginStatus.payload.customer.email !== AdminCheck)
                                && (this.props.loginStatus.payload.customer.email !== OrderdeskCheck)))) &&
                                <LAGridItem xs="auto">
                                    <HeaderCart {...this.props} />
                                </LAGridItem>}

                            <LAGridItem xs="auto">
                                {(this.props.loginStatus.kind === STATUS_ENUM.SUCCEEDED) ?
                                    <LAHeaderAvatar {...this.props} data={this.props.loginStatus.payload} />
                                    : <LASecondaryButton label="LogIn" onClick={onLogInClick} />}

                            </LAGridItem>
                        </LAGrid>
                    </div>
                </div>



                {this.state.open && <ContactUsPopup open={this.state.open} onClose={this.onContactUsClick} />}
                {this.state.termsAndCondition && <TermsAndConditionPopupComponent open={this.state.termsAndCondition} tab={0} onClose={this.onTermsAndCondition} />}
            </HeaderStyles>
        );
    }

    private onTermsAndCondition = (): void => {
        this.setState({ termsAndCondition: !this.state.termsAndCondition });
    };

    private handleDismantled = (): void => {
        this.props.history.push(ROUTE.DISMANTLED());
    };

    private onContactUsClick = (): void => {
        this.setState({ open: !this.state.open });
    };

    private onLogInClick = (): void => {
        this.props.history.push(ROUTE.LOGIN());
    };

    private onHomeClick = (): void => {
        this.props.history.push(ROUTE.INDEX());
    };

}

const mapStateToProps = (state: IStore): IHeaderStoreProps => ({
    loginStatus: login(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IHeaderDispatchProps => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(Header);