import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_LOOKUPS_REQUEST, IDismantledLookups, IDismantledLookupsRequest } from "./getDismantledLookupsConstants";


export interface IGetDismantledLookupsLoadAction {
    type: IGET_LOOKUPS_REQUEST.REQUEST;
    data: IDismantledLookupsRequest
}
export const getDismantledLookupsLoadAction = (data: IDismantledLookupsRequest): IGetDismantledLookupsLoadAction => ({
    type: IGET_LOOKUPS_REQUEST.REQUEST,
    data
});
export interface IGetDismantledLookupsSuccessAction {
    type: IGET_LOOKUPS_REQUEST.SUCCESS;
    list: PartsResponse<IDismantledLookups>;
}
export const getDismantledLookupsLoadSuccessAction = (list: PartsResponse<IDismantledLookups>): IGetDismantledLookupsSuccessAction => ({
    type: IGET_LOOKUPS_REQUEST.SUCCESS,
    list
});
export interface IGetDismantledLookupsLoadFailedAction {
    type: IGET_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getDismantledLookupsLoadFailedAction = (message: string): IGetDismantledLookupsLoadFailedAction => ({
    type: IGET_LOOKUPS_REQUEST.FAILED,
    message
});
