import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import LoginComponent from "./login/loginComponent";
import { LAPaperWithPadding } from "../shared/paper";
import { MEDIA_QUERY_PHONE } from "../shared/theme";
import { IDispatch, IStore } from "../../redux/reducers";
import LAGridItem from "../shared/gridList";
import LAGrid from "../shared/grid";
import RegisterComponent from "./register/registerComponent";
import { hasPayload, Server, STATUS_ENUM } from "../../redux/server";
import { ILoginResponse } from "../../redux/login/loginConstants";
import { login } from "../../redux/login/loginAccessor";
import UserProfileComponent from "./userProfile/userProfileComponent";
import PageSpacing from "../shared/pageSpacing";

interface ILoginAndRegisterComponentStoreProps {
    loginStatus: Server<ILoginResponse>;
};

interface ILoginAndRegisterComponentDispatchProps {

};

interface ILoginAndRegisterComponentOwnProps {

};

interface ILoginAndRegisterComponentState {
    register: boolean;
};

const LoginAndRegisterStyles = styled(LAPaperWithPadding)`
    margin: 20px 20px;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 15px 10px;
    };
`;

type ILoginAndRegisterComponentProps =
    RouteComponentProps
    & ILoginAndRegisterComponentOwnProps
    & ILoginAndRegisterComponentStoreProps
    & ILoginAndRegisterComponentDispatchProps;

class LoginAndRegisterComponent extends PureComponent<ILoginAndRegisterComponentProps, ILoginAndRegisterComponentState> {

    public constructor(props: ILoginAndRegisterComponentProps) {
        super(props);
        this.state = {
            register: false,
        };
    }

    public componentDidMount(): void {
    };

    public componentDidUpdate(prevProps: ILoginAndRegisterComponentProps): void {
    };

    public render(): ReactNode {

        const { register } = this.state;
        const { loginStatus } = this.props;
        const loggedIn = loginStatus.kind === STATUS_ENUM.SUCCEEDED;

        return (
            <PageSpacing title="Login/Register" description="Login/Register">
            <LoginAndRegisterStyles>
                <LAGrid alignItems="center" justify="center" >

                    {!register ? <>
                        <LAGridItem xs={12} md={loggedIn ? 5 : 6}>
                            <LoginComponent {...this.props} onRegister={this.onRegister} />
                        </LAGridItem>

                        {loggedIn && hasPayload(loginStatus) && <>
                            <LAGridItem xs={12} md={1}>
                            </LAGridItem>

                            <LAGridItem xs={12} md={6}>
                                <UserProfileComponent data={loginStatus.payload} />
                            </LAGridItem>
                        </>}

                    </> : <LAGridItem xs={12}>
                        <RegisterComponent {...this.props} onRegisterCancel={this.onRegister} />
                    </LAGridItem>}

                </LAGrid>
            </LoginAndRegisterStyles>
            </PageSpacing>
        );
    }

    private onRegister = (): void => {
        this.setState({ register: !this.state.register });
    };

}

const mapStateToProps = (state: IStore): ILoginAndRegisterComponentStoreProps => ({
    loginStatus: login(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ILoginAndRegisterComponentDispatchProps => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(LoginAndRegisterComponent);