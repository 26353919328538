import { IGetPartsDismantledEquipmentsLoadAction, IGetPartsDismantledEquipmentsLoadFailedAction, IGetPartsDismantledEquipmentsSuccessAction } from "./getPartsDismantledEquipmentActions";
import { IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST, IPartDismantledEquipment } from "././getPartsDismantledEquipmentConstants";
import { FLUSH_DATA } from "../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { PartsResponse } from "../../react/shared/publicInterfaces";

type Actions =
    | IGetPartsDismantledEquipmentsLoadAction
    | IGetPartsDismantledEquipmentsSuccessAction
    | IGetPartsDismantledEquipmentsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetPartsDismantledEquipmentsReducer = (state: Server<PartsResponse<IPartDismantledEquipment[]>> = notLoaded, action: Actions): Server<PartsResponse<IPartDismantledEquipment[]>> => {
    switch (action.type) {
        case IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST.REQUEST:
            return loading;

        case IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;
            
        default:
            return state;
    }
};