import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { HomeIcon } from "../shared/icons";
import { WHITE_COLOR } from "../shared/theme";

interface IHomeDropDownProps {
    onClick?: () => void;
};

type IOrderDropDown =
    RouteComponentProps &
    IHomeDropDownProps;

class HomeDropDown extends PureComponent<IOrderDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.gotoHomePage}>
                <HomeIcon color={WHITE_COLOR} />
                
                <span className="">
                    Home
                </span>
            </LAMenuItem>
        );
    };

    private gotoHomePage = (): void => {
        this.props.history.push(ROUTE.INDEX());
        
        if(this.props.onClick)
            this.props.onClick();
    };

}

export default HomeDropDown;
