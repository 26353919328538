import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IUpdatePartsOrderAdminSuccessAction } from "../updatePartsOrderAdmin/updatePartsOrderAdminActions";
import { IUPDATE_PARTS_ORDER_ADMIN_REQUEST } from "../updatePartsOrderAdmin/updatePartsOrderAdminConstants";
import { IUpdatePartsOrderPaymentSuccessAction } from "../updatePartsOrderPayment/updatePartsOrderPaymentActions";
import { IUPDATE_PARTS_ORDER_PAYMENT_REQUEST } from "../updatePartsOrderPayment/updatePartsOrderPaymentConstants";
import { IGetAllPartsOrderLoadAction, IGetAllPartsOrderLoadFailedAction, IGetAllPartsOrderSuccessAction } from "./getPartsOrderActions";
import { IGetAllPartsOrderResponse, IGET_ALL_PARTS_ORDER_REQUEST } from "./getPartsOrderConstants";


type Actions =
    IGetAllPartsOrderLoadAction
    | IGetAllPartsOrderLoadFailedAction
    | IGetAllPartsOrderSuccessAction
    | IUpdatePartsOrderAdminSuccessAction
    | IUpdatePartsOrderPaymentSuccessAction
    | IFlushDataSuccessAction;

export const GetAllPartsOrderReducer = (state: Server<PartsResponse<IGetAllPartsOrderResponse[]>> = notLoaded, action: Actions): Server<PartsResponse<IGetAllPartsOrderResponse[]>> => {
    switch (action.type) {
        case IGET_ALL_PARTS_ORDER_REQUEST.REQUEST:
            return loading;

        case IGET_ALL_PARTS_ORDER_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IGET_ALL_PARTS_ORDER_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_PARTS_ORDER_ADMIN_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};