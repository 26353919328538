import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { getPartsOrderReportsStatus } from "../../../redux/getPartsOrderReport/getPartsOrderReportAccessor";
import { getPartsOrderReportsLoadAction } from "../../../redux/getPartsOrderReport/getPartsOrderReportActions";
import { IGetPartsOrderReportsRequest, IPartsOrderReport } from "../../../redux/getPartsOrderReport/getPartsOrderReportConstants";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import { ById, PartsResponse } from "../../shared/publicInterfaces";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAGrid from "../../shared/grid";
import { LACenteredLoading } from "../../shared/loading";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import LAGridItem from "../../shared/gridList";
import LAErrorBox from "../../shared/errorBox";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { AdminCheck, OrderdeskCheck, undefinedFunction } from "../../shared/constExports";
import { IDispatch, IStore } from "../../../redux/reducers";
import { login } from "../../../redux/login/loginAccessor";
import PageSpacing from "../../shared/pageSpacing";


interface IReportStoreProps {
    loginStatus: Server<ILoginResponse>;
    ReportList: Server<PartsResponse<ById<IPartsOrderReport>>>;
};

interface IReportDispatchProps {
    RequestReportList: (request: IGetPartsOrderReportsRequest) => unknown;
};

interface IReportOwnProps {

};

interface IReportState {
    reports: ById<IPartsOrderReport>;
};

const ReportStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .moveRight {
        position: absolute;
        top: 0px;
        right: 10px;
    };
`;

type IReportProps = RouteComponentProps
    & IReportStoreProps
    & IReportDispatchProps
    & IReportOwnProps;

class Report extends PureComponent<IReportProps, IReportState> {

    public constructor(props: IReportProps) {
        super(props);
        this.state = {
            reports: {}
        };
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IReportProps, prevState: IReportState): Promise<void> {
        if (this.props.ReportList !== prevProps.ReportList) {
            this.callReduxForData();
        }
    };

    public render(): ReactNode {

        const { reports } = this.state;
        const { ReportList, loginStatus } = this.props;

        const isAdmin = hasPayload(loginStatus) ? (( loginStatus.payload.customer.email === AdminCheck) || (loginStatus.payload.customer.email === OrderdeskCheck)) : false;

        return (
            <PageSpacing title="Parts Order Report" description="Parts Order Report">
            <ReportStyles>
                <LAGrid spacing={2}>

                    {isAdmin ?
                        <>
                            {(ReportList.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                                <LACenteredLoading />
                            </LAGridItem>}

                            {(ReportList.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                                <LAErrorBox text="Failed to Load Reports" />
                            </LAGridItem>}

                            {(ReportList.kind === STATUS_ENUM.SUCCEEDED) && <>
                                <LAGridItem xs={12}>
                                    <h2 className="text-center">PARTS ORDERS REPORT</h2>
                                    <hr />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LADevExtremeGrid
                                        export={true}
                                        searchPanel={true}
                                        filterHeader={true}
                                        exportFileName="Report"
                                        onEdit={this.onOrderClick}
                                        onClick={undefinedFunction}
                                        data={Object.values(reports)}
                                        columns={[
                                            { name: "invoice_No", caption: "Invoice Number", type: "string" },
                                            { name: "date", caption: "Date", type: "date" },
                                            { name: "customer_Name", caption: "Customer Name", type: "string" },
                                            { name: "phone_No", caption: "Phone Number", type: "string" },
                                            { name: "email", caption: "Email", type: "string" },
                                            { name: "status", caption: "Status", type: "string" },
                                            { name: "notes", caption: "Notes", type: "number", width: 90 },
                                            { name: "total_Items_Cost", caption: "Total Items Cost", type: "number" },
                                            { name: "shipping_Cost", caption: "Shipping Cost", type: "number" },
                                            { name: "total_Cost", caption: "Total Cost", type: "number" },
                                            { name: "payment_Details", caption: "Payment Details", type: "string" }
                                        ]}
                                    />
                                </LAGridItem>
                            </>}
                        </>
                        : <LAGridItem xs={12}>
                            <LAErrorBox text="Sorry you are not allowed to view this page" />
                        </LAGridItem>}
                </LAGrid>
            </ReportStyles>
            </PageSpacing>
        );
    }

    private onOrderClick = (e: any): void => {
        // e.row.data.id
       // console.log(e.row.data.id);
        //this.props.history.push(ROUTE.ADMIN_ORDER());
        window.location.href = '/admin-orders?itemId='+e.row.data.id;
    };

    private callReduxForData = (): void => {
        if (isNotLoaded(this.props.ReportList)) {
            this.sendRequest();
        };

        if (hasPayload(this.props.ReportList)) {
            this.setState({ reports: this.props.ReportList.payload.response });
        };
    };

    private sendRequest = (): void => {
        if (hasPayload(this.props.loginStatus))
            this.props.RequestReportList({
                token: this.props.loginStatus.payload.token
            });
    };

}

const mapStateToProps = (state: IStore): IReportStoreProps => ({
    loginStatus: login(state),
    ReportList: getPartsOrderReportsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IReportDispatchProps => ({
    RequestReportList: (request: IGetPartsOrderReportsRequest): unknown => dispatch(getPartsOrderReportsLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);