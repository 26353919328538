import { IPaginationRequest } from "../../react/shared/publicInterfaces";

export enum IGET_PARTS_DISMANTLED_EQUIPMENT_REQUEST {
    REQUEST = "getPartsDismantledEquipment/GET_PARTS_DISMANTLED_EQUIPMENT_REQUEST",
    SUCCESS = "getPartsDismantledEquipment/GET_PARTS_DISMANTLED_EQUIPMENT_SUCCESS",
    FAILED = "getPartsDismantledEquipment/GET_PARTS_DISMANTLED_EQUIPMENT_FAILED"
};

export interface IPartDismantledEquipmentRequest extends IPaginationRequest {
    request: {
        ip: string;
        Make: string;
        Equipment: string;
    };
};

export interface IPartDismantledEquipment {
    id: number;
    make: string; 
    model: string;
    hours: string;
    location: string;
    equipment_Type: string;
    notes: string;
    photos_1: string;
    photos_2: string;
    photos_3: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
    serial_No: string;
    unit_No: string;
    available_Components: IPartDismantledComponent[];
};

export interface IPartDismantledComponent {
    id: number;
    name: string;
    equipment_ID: number;
    notes: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};