import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IPart } from "../getParts/getPartsConstants";
import { IGetPartsByIdsRequest, IGET_PARTS_BY_IDS_REQUEST } from "./getPartsByIdsConstants";

export interface IGetPartsByIdsLoadAction {
    type: IGET_PARTS_BY_IDS_REQUEST.REQUEST;
    data: IGetPartsByIdsRequest
}
export const getPartsByIdsLoadAction = (data: IGetPartsByIdsRequest): IGetPartsByIdsLoadAction => ({
    type: IGET_PARTS_BY_IDS_REQUEST.REQUEST,
    data
});

export interface IGetPartsByIdsSuccessAction {
    type: IGET_PARTS_BY_IDS_REQUEST.SUCCESS;
    list: PartsResponse<IPart[]>;
}
export const getPartsByIdsLoadSuccessAction = (list: PartsResponse<IPart[]>): IGetPartsByIdsSuccessAction => ({
    type: IGET_PARTS_BY_IDS_REQUEST.SUCCESS,
    list
});
export interface IGetPartsByIdsLoadFailedAction {
    type: IGET_PARTS_BY_IDS_REQUEST.FAILED;
    message: string;
}
export const getPartsByIdsLoadFailedAction = (message: string): IGetPartsByIdsLoadFailedAction => ({
    type: IGET_PARTS_BY_IDS_REQUEST.FAILED,
    message
});
