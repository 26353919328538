import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST } from "./partsOrderCustomerQuoteResponseConstants";
import { IPartsOrderCustomerQuoteResponseLoadAction, IPartsOrderCustomerQuoteResponseLoadFailedAction, IPartsOrderCustomerQuoteResponseSuccessAction } from "./partsOrderCustomerQuoteResponseActions";


type Actions =
    IPartsOrderCustomerQuoteResponseLoadAction
    | IPartsOrderCustomerQuoteResponseLoadFailedAction
    | IPartsOrderCustomerQuoteResponseSuccessAction
    | IFlushDataSuccessAction;

export const PartsOrderCustomerQuoteResponseReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.REQUEST:
            return loading;

        case IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};