import { ReactNode, PureComponent } from "react";
import { SalesPhone } from "../shared/constExports";
import { LAMenuItem } from "../shared/dropDownMenu";
import { PhoneIcon } from "../shared/icons";
import { HasClass } from "../shared/publicInterfaces";
import { WHITE_COLOR } from "../shared/theme";

interface IPhoneNumberDropDownProps extends HasClass {
};

type IOrderDropDown =
    IPhoneNumberDropDownProps;

class PhoneNumberDropDown extends PureComponent<IOrderDropDown> {

    public render(): ReactNode {
        return (
            <LAMenuItem>

                <PhoneIcon color={WHITE_COLOR} className={this.props.className} />
                <a href={`tel:${SalesPhone}`}>
                    <span className={`${this.props.className}`}>
                        {SalesPhone}
                    </span>
                </a>
            </LAMenuItem>
        );
    };
}

export default PhoneNumberDropDown;
