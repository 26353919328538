import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { MEDIA_QUERY_PHONE } from "./shared/theme";
import { IDispatch, IStore } from "../redux/reducers";
import DesktopHome from "./desktopHome";
import PageSpacing from "./shared/pageSpacing";

interface IHomeComponentStoreProps {

};

interface IHomeComponentDispatchProps {

};

interface IHomeComponentState {

};

const HomeStyles = styled.div`
    
    .mobileView {
        display: none;
    };

    .desktopView {
        display: block;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .mobileView {
            display: block;
        };

        .desktopView {
            display: none;
        };
    };
`;

type IHomeComponentProps =
    RouteComponentProps
    & IHomeComponentStoreProps
    & IHomeComponentDispatchProps;

class HomeComponent extends PureComponent<IHomeComponentProps, IHomeComponentState> {

    public constructor(props: IHomeComponentProps) {
        super(props);
        this.state = {

        };
    }

    public render(): ReactNode {

        return (
            <PageSpacing title="Welcome" description="Welcome">
                <HomeStyles>
                    {/* <div className="desktopView"> */}
                    <DesktopHome {...this.props} />
                  
                
                    {/* </div>

                <div className="mobileView">
                    <MobileHome {...this.props} />
                </div> */}
                </HomeStyles>
            </PageSpacing>
        );
    }

}

const mapStateToProps = (state: IStore): IHomeComponentStoreProps => ({
});

const mapDispatchToProps = (dispatch: IDispatch): IHomeComponentDispatchProps => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);