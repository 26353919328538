import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { IRegistrationSuccessAction } from "../registration/registrationActions";
import { IREGISTRATION_REQUEST } from "../registration/registrationConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IUpdateProfileSuccessAction } from "../userProfile/updateProfile/updateProfileActions";
import { IUPDATE_PROFILE_REQUEST } from "../userProfile/updateProfile/updateProfileConstants";
import { IGetCustomersLoadAction, IGetCustomersLoadFailedAction, IGetCustomersSuccessAction } from "./getCustomersActions";
import { ICustomer, IGET_CUSTOMERS_REQUEST } from "./getCustomersConstants";


type Actions =
    IGetCustomersLoadAction
    | IGetCustomersLoadFailedAction
    | IGetCustomersSuccessAction
    | IRegistrationSuccessAction
    | IUpdateProfileSuccessAction
    | IFlushDataSuccessAction;

export const GetCustomersReducer = (state: Server<PartsResponse<ICustomer[]>> = notLoaded, action: Actions): Server<PartsResponse<ICustomer[]>> => {
    switch (action.type) {
        case IGET_CUSTOMERS_REQUEST.REQUEST:
            return loading;

        case IGET_CUSTOMERS_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IGET_CUSTOMERS_REQUEST.FAILED:
            return failed(action.message);

        case IREGISTRATION_REQUEST.SUCCESS:
            if (action.internalAdd) {
                return notLoaded;
            } else {
                return state;
            }

        case IUPDATE_PROFILE_REQUEST.SUCCESS:
            if (action.internalUpdate) {
                return notLoaded;
            } else {
                return state;
            }

        default:
            return state;
    }
};