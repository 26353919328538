import { IUpdatePartsOrderAdminRequest, IUPDATE_PARTS_ORDER_ADMIN_REQUEST } from "./updatePartsOrderAdminConstants";

export interface IUpdatePartsOrderAdminLoadAction {
    type: IUPDATE_PARTS_ORDER_ADMIN_REQUEST.REQUEST;
    data: IUpdatePartsOrderAdminRequest
}
export const updatePartsOrderAdminLoadAction = (data: IUpdatePartsOrderAdminRequest): IUpdatePartsOrderAdminLoadAction => ({
    type: IUPDATE_PARTS_ORDER_ADMIN_REQUEST.REQUEST,
    data
});

export interface IUpdatePartsOrderAdminSuccessAction {
    type: IUPDATE_PARTS_ORDER_ADMIN_REQUEST.SUCCESS;
    response: string;
}
export const updatePartsOrderAdminLoadSuccessAction = (response: string): IUpdatePartsOrderAdminSuccessAction => ({
    type: IUPDATE_PARTS_ORDER_ADMIN_REQUEST.SUCCESS,
    response
});
export interface IUpdatePartsOrderAdminLoadFailedAction {
    type: IUPDATE_PARTS_ORDER_ADMIN_REQUEST.FAILED;
    message: string;
}
export const updatePartsOrderAdminLoadFailedAction = (message: string): IUpdatePartsOrderAdminLoadFailedAction => ({
    type: IUPDATE_PARTS_ORDER_ADMIN_REQUEST.FAILED,
    message
});
