import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IGetSEOKeywordsLoadAction, IGetSEOKeywordsLoadFailedAction, IGetSEOKeywordsSuccessAction } from "./getSEOKeywordsActions";
import { IGET_SEO_KEYWORDS_REQUEST } from "./getSEOKeywordsConstants";


type Actions =
    IGetSEOKeywordsLoadAction
    | IGetSEOKeywordsLoadFailedAction
    | IGetSEOKeywordsSuccessAction
    | IFlushDataSuccessAction;

export const GetSEOKeywordsReducer = (state: Server<PartsResponse<string>> = notLoaded, action: Actions): Server<PartsResponse<string>> => {
    switch (action.type) {
        case IGET_SEO_KEYWORDS_REQUEST.REQUEST:
            return loading;

        case IGET_SEO_KEYWORDS_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IGET_SEO_KEYWORDS_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};