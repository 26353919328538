import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { getPartsCashOrderLookupLoadFailedAction, getPartsCashOrderLookupLoadSuccessAction, IGetPartsCashOrderLookupLoadAction, IGetPartsCashOrderLookupLoadFailedAction, IGetPartsCashOrderLookupSuccessAction } from "./getPartsCashOrderLookupActions";
import { IGetPartsCashOrderLookup, IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST } from "./getPartsCashOrderLookupConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";

export const getPartsCashOrderLookupEpic: Epic = (
    action$: ActionsObservable<IGetPartsCashOrderLookupLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPartsCashOrderLookupSuccessAction | IGetPartsCashOrderLookupLoadFailedAction> =>
    action$.ofType(IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IGetPartsCashOrderLookup>>(
                    END_POINTS.GET_PARTS_CASH_ORDER_LOOKUP,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<IGetPartsCashOrderLookup>): IGetPartsCashOrderLookupSuccessAction => getPartsCashOrderLookupLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(getPartsCashOrderLookupLoadFailedAction("Unable to get cash order customers")))
                    )
            )
        );