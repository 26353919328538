import { ReactNode, PureComponent } from "react";
import { LAMenuItem } from "../shared/dropDownMenu";
import { EquipmentIcon } from "../shared/icons";
import { WHITE_COLOR } from "../shared/theme";

interface IEquipmentDropDownProps {
    onClick: () => void;
};

interface IEquipmentDropDownState {
    open: boolean;
};


class EquipmentDropDown extends PureComponent<IEquipmentDropDownProps, IEquipmentDropDownState> {



    public render(): ReactNode {
        return (
            <LAMenuItem onClick={this.props.onClick}>
                <EquipmentIcon color={WHITE_COLOR} />                
                <span className="ml-1">
                    Dismantled Equipment
                </span>

            </LAMenuItem>
        );
    };
}

export default EquipmentDropDown;
