import React, { ReactNode, PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR } from "../../shared/theme";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import LATextField from "../../shared/textField";
import { ById, ByIdNumber, PartsResponse } from "../../shared/publicInterfaces";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { RouteComponentProps } from "react-router";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidation";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import { getDate, undefinedFunction, ZEROTH } from "../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import { IGetAllPartsCashOrders } from "../../../redux/getAllPartsCashOrders/getAllPartsCashOrdersConstants";
import { QuantityList } from "../../../redux/cart/cartConstants";
import LAAutoComplete from "../../shared/autoComplete";
import { getPartsCashOrderLookupStatus } from "../../../redux/getPartsCashOrderLookup/getPartsCashOrderLookupAccessor";
import { getPartsCashOrderLookupLoadAction } from "../../../redux/getPartsCashOrderLookup/getPartsCashOrderLookupActions";
import { IGetPartsCashOrderLookup, IGetPartsCashOrderLookupRequest } from "../../../redux/getPartsCashOrderLookup/getPartsCashOrderLookupConstants";
import LATextArea from "../../shared/textArea";
import { IUpdatePartsCashOrderItem, IUpdatePartsCashOrderRequest } from "../../../redux/updatePartsCashOrder/updatePartsCashOrderConstants";
import { updatePartsCashOrderLoadAction } from "../../../redux/updatePartsCashOrder/updatePartsCashOrderActions";
import { IAddPartsCashOrderRequest } from "../../../redux/addPartsCashOrder/addPartsCashOrderConstants";
import { addPartsCashOrderLoadAction } from "../../../redux/addPartsCashOrder/addPartsCashOrderActions";
import { END_POINTS } from "../../../redux/endpoints";
import { DeleteIcon } from "../../shared/icons";
import { addPartsCashOrder } from "../../../redux/addPartsCashOrder/addPartsCashOrderAccessor";
import { updatePartsCashOrderStatus } from "../../../redux/updatePartsCashOrder/updatePartsCashOrderAccessor";


interface IAddUpdateCashOrderComponentStoreProps {
    addCashOrder: Server<PartsResponse<IGetAllPartsCashOrders>>;
    updateCashOrder: Server<PartsResponse<IGetAllPartsCashOrders>>;
    lookupData: Server<PartsResponse<IGetPartsCashOrderLookup>>;
};

interface IAddUpdateCashOrderComponentDispatchProps {
    lookupDataRequest: (data: IGetPartsCashOrderLookupRequest) => unknown;
    updateCashOrderRequest: (data: IUpdatePartsCashOrderRequest) => unknown;
    addCashOrderRequest: (data: IAddPartsCashOrderRequest) => unknown;
};

interface IAddUpdateCashOrderComponentOwnProps {
    token: Server<ILoginResponse>;
    order?: IGetAllPartsCashOrders;
    onAddUpdateCashOrderCancel: () => void;
}

interface IAddUpdateCashOrderComponentState {
    showStatus: boolean;
    data: IGetAllPartsCashOrders;
    errors: ById<IFieldErrorKeyValue>;
    itemsError: ByIdNumber<ById<IFieldErrorKeyValue>>;
};

const AddUpdateCashOrderStyles = styled.div`
    margin: 20px 20px;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 15px 10px;
    };

    .pull-right {
        right: 1px;
        bottom: 1px;
        position: absolute;
    };
`;

type IAddUpdateCashOrderComponentProps =
    RouteComponentProps
    & IAddUpdateCashOrderComponentStoreProps
    & IAddUpdateCashOrderComponentOwnProps
    & IAddUpdateCashOrderComponentDispatchProps;

class AddUpdateCashOrderComponent extends PureComponent<IAddUpdateCashOrderComponentProps, IAddUpdateCashOrderComponentState> {

    public constructor(props: IAddUpdateCashOrderComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                date: new Date().toISOString(),
                customer_ID: 0,
                invoice_No: "",
                status: "",
                notes: "",
                total_Items_Cost: 0,
                tax: 0,
                total_Cost: 0,
                payment_Details: "",
                created_By: "",
                modified_By: "",
                created: "",
                modified: "",
                customer: "",
                items: [{
                    id: 0,
                    order_ID: 0,
                    part_No: "",
                    quantity: 0,
                    price: 0,
                    notes: "",
                    total_Cost: 0,
                    created_By: "",
                    modified_By: "",
                    created: "",
                    modified: ""
                }],
            },
            errors: {
                "customer_ID": { key: "customer_ID", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            itemsError: {
               0: {  "part_No": { key: "part_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED }, }
            },
            showStatus: false
        };
    }

    public componentDidMount(): void {
        this.onInitialLoad();
    };

    public componentDidUpdate(prevProps: IAddUpdateCashOrderComponentProps): void {

        if (this.props.addCashOrder !== prevProps.addCashOrder) {
            if (hasPayload(this.props.addCashOrder) && (this.props.addCashOrder.kind === STATUS_ENUM.SUCCEEDED)) {
                this.setState({ data: this.props.addCashOrder.payload.response });
            };
        };

        if (this.props.updateCashOrder !== prevProps.updateCashOrder) {
            if (hasPayload(this.props.updateCashOrder) && (this.props.updateCashOrder.kind === STATUS_ENUM.SUCCEEDED)) {
                this.setState({ data: this.props.updateCashOrder.payload.response });
            };
        };
        
    };

    public render(): ReactNode {

        const { data, itemsError, errors } = this.state;
        const { order, lookupData } = this.props;

        const dropdownData = hasPayload(lookupData) ? lookupData.payload.response : undefined;
        const onCustomerChange = (event: unknown, value: any) => this.onMasterNumberObjChange("customer_ID", value !== null ? value.id : 0);

        return (
            <AddUpdateCashOrderStyles>
                <LAGrid spacing={1} className="text-center">

                    <LAGridItem xs={12}>
                        <h2 className="text-center">{order ? "UPDATE" : "ADD"} ORDER</h2>
                        <hr />
                    </LAGridItem>

                    <LAGridItem xs={2}>
                        <LAAutoComplete
                            name="customer_ID"
                            multiple={false}
                            onChange={onCustomerChange}
                            autoHighlight={true}
                            getOptionLabel="first_Name"
                            getOptionLabel2="last_Name"
                            dropDownPlaceHolder="Customer"
                            filterSelectedOptions={true}
                            selectionRemove={undefinedFunction}
                            option={dropdownData ? dropdownData.customers : []}
                            errorText={errors["customer_ID"] ? errors["customer_ID"].message : undefined}
                            value={dropdownData && dropdownData.customers.find(q => q.id === data.customer_ID) ? dropdownData.customers.find(q => q.id === data.customer_ID) : ""}
                            defaultValue={dropdownData && dropdownData.customers.find(q => q.id === data.customer_ID) ? dropdownData.customers.find(q => q.id === data.customer_ID) : ""}
                        />
                    </LAGridItem>

                    <LAGridItem xs={2} className="pt-3">
                        <strong>Status: </strong>
                        <span>{data.status}</span>
                    </LAGridItem>

                    <LAGridItem xs={2} className="pt-3">
                        <strong>Date: </strong>
                        <span>{getDate(false, data.date)}</span>
                    </LAGridItem>

                    <LAGridItem xs={12} md={9}>
                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>
                                {data.items.map((x, index) => {
                                    const onDelete = () => this.onItemDelete(index);
                                    const onReqChange = (name: string, value: string) => this.onSubReqItemChange(name, value, index);
                                    const onChange = (name: string, value: string) => this.onSubItemChange(name, value, index);
                                    const qtyChange = (event: unknown, value: { id: number, value: string }) => this.onSubItemChange("quantity", value === null ? "1" : value.id.toString(), index);

                                    return (
                                        <LAGridItem xs={12} key={index}>

                                            <LAPaperWithPadding>
                                                <LAGrid>

                                                    {/* <LAGridItem xs={12} sm={12} md={6}>
                                                         <LAAutoComplete
                                                            name="part_No"
                                                            multiple={false}
                                                            onChange={onPart}
                                                            autoHighlight={true}
                                                            getOptionLabel="name"
                                                            dropDownPlaceHolder="Part"
                                                            filterSelectedOptions={true}
                                                            selectionRemove={undefinedFunction}
                                                            option={dropdownData ? dropdownData.parts : []}
                                                            value={dropdownData && dropdownData.parts.find(q => q.part_No === x.part_No) ? dropdownData.parts.find(q => q.part_No === x.part_No) : ""}
                                                            defaultValue={dropdownData && dropdownData.parts.find(q => q.part_No === x.part_No) ? dropdownData.parts.find(q => q.part_No === x.part_No) : ""}
                                                        /> 
                                                         </LAGridItem> */}

                                                    <LAGridItem xs={12} sm={12} md={6}>
                                                        <LATextField                                                            
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            onChange={onReqChange}
                                                            name="part_No"
                                                            value={x.part_No}
                                                            label="Part # / Description"
                                                            errorText={itemsError[0] && itemsError[0]["part_No"] ? itemsError[0]["part_No"].message : undefined}
                                                        />
                                                    </LAGridItem>

                                                    <LAGridItem xs={12} sm={12} md={6}>
                                                        <LAAutoComplete
                                                            name="quantity"
                                                            multiple={false}
                                                            getOptionLabel="value"
                                                            onChange={qtyChange}
                                                            autoHighlight={true}
                                                            option={QuantityList}
                                                            filterSelectedOptions={true}
                                                            dropDownPlaceHolder="Quantity"
                                                            selectionRemove={undefinedFunction}
                                                            value={QuantityList.find(q => q.id === x.quantity)}
                                                            defaultValue={QuantityList.find(q => q.id === x.quantity)}
                                                        />
                                                    </LAGridItem>

                                                    <LAGridItem xs={6} sm={6} md={6}>
                                                        <LATextField
                                                            type="number"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            onChange={onChange}
                                                            name="price"
                                                            value={x.price}
                                                            label="Price (CAD)"
                                                        />
                                                    </LAGridItem>

                                                    <LAGridItem xs={6} sm={6} md={6}>
                                                        <LATextField
                                                            type="number"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            label="$ Item Cost"
                                                            name="total_Cost"
                                                            disabled={true}
                                                            onChange={undefinedFunction}
                                                            value={Number(x.quantity * x.price).toFixed(2)}
                                                        />
                                                    </LAGridItem>

                                                    <LAGridItem xs={12} sm={12} md={12}>
                                                        <LATextArea
                                                            rowsMin={2}
                                                            rowsMax={4}
                                                            variant="outlined"
                                                            label="Notes"
                                                            onChange={onChange}
                                                            fullWidth={true}
                                                            name="notes"
                                                            value={x.notes}
                                                        />
                                                        <br />
                                                    </LAGridItem>

                                                   <LAIconButton 
                                                        onClick={onDelete}
                                                        label="Remove Item"
                                                        className="pull-right"
                                                        icon={<DeleteIcon color={RED_COLOR} />}
                                                   />

                                                </LAGrid>

                                            </LAPaperWithPadding>
                                        </LAGridItem>
                                    );
                                })}

                                <LAGridItem xs={12}>
                                    <LAButton
                                        label="Add New Item"
                                        onClick={this.addNewItem}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>
                    </LAGridItem>

                    <LAGridItem xs={12} md={3}>
                        <LAPaperWithPadding>

                            <h3>Order Summary</h3>

                            <LAGrid spacing={2}>

                                <LAGridItem xs={12}>
                                    <LATextField
                                        disabled={true}
                                        fullWidth={true}
                                        type="number"
                                        variant="outlined"
                                        name="totalQuantity"
                                        onChange={undefinedFunction}
                                        label={`Items sub-total (${data.items.length})`}
                                        value={Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0)).toFixed(2)}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LATextField
                                        type="number"
                                        variant="outlined"
                                        fullWidth={true}
                                        name="tax"
                                        label="$ GST"
                                        disabled={true}
                                        onChange={undefinedFunction}
                                        value={((5 / 100) * Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0))).toFixed(2)}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LATextField
                                        type="number"
                                        fullWidth={true}
                                        disabled={true}
                                        name="total_Cost"
                                        variant="outlined"
                                        label="Total Cost"
                                        onChange={this.onMasterObjChange}
                                        value={(((5 / 100) * Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0))) + Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0))).toFixed(2)}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LATextArea
                                        rowsMin={2}
                                        rowsMax={4}
                                        variant="outlined"
                                        fullWidth={true}
                                        name="notes"
                                        label="Notes"
                                        value={data.notes}
                                        onChange={this.onMasterObjChange}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        cancelButtonText="Close"
                                        onSave={this.onSave}
                                        onCancel={this.onBack}
                                        disableSave={((Object.values(itemsError).length > 0) || (Object.values(errors).length > 0)) ? true : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                {data.status === "Completed" && <LAGridItem xs={12} className="text-center">
                                <LAButton label="Download Invoice" onClick={this.downloadInvoice} />
                                    </LAGridItem>}
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>
                    </LAGridItem>
                </LAGrid>
            </AddUpdateCashOrderStyles>
        );
    }

    private downloadInvoice = (): void => {
        if (hasPayload(this.props.token))
            fetch(END_POINTS.DOWNLOAD_INVOICE, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: this.props.token.payload.token,
                    request: {
                        OrderId: this.state.data.id,
                        Type: "cash"
                    }
                })
            })
                .then(res => {
                    return res.blob();
                })
                .then(async blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.target = "_blank";
                    a.download = `OrderInvoice-${this.state.data.id}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch((res) => console.log(res));
    };

    private onItemDelete = (index: number): void => {
        const iS = { ...this.state.data };
        const itemsError = this.state.itemsError;

        iS.items.splice(index, 1);

        if(itemsError[index])
            delete itemsError[index];

        this.setState({ data: iS, itemsError });
    };

    private addNewItem = (): void => {
        const iS = { ...this.state.data };
        const itemsError = this.state.itemsError;

        iS.items.push({
            id: 0,
            order_ID: 0,
            part_No: "",
            quantity: 0,
            price: 0,
            notes: "",
            total_Cost: 0,
            created_By: "",
            modified_By: "",
            created: "",
            modified: ""
        });

        const index = iS.items.length - 1;
        itemsError[index] = {};
        itemsError[index] = { "part_No": { key: "part_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED }};

        this.setState({ data: iS, itemsError });
    };

    private onSubItemChange = (name: string, value: string, index: number): void => {
        const iS = { ...this.state.data };
        let found = { ...iS.items[index] };

        if (name === "quantity") {
            found = { ...found, [name]: Number(value) };
        } else {
            found = { ...found, [name]: value };
        };

        iS.items[index] = found;
        this.setState({ data: iS });
    };

    private onSubReqItemChange = (name: string, value: string, index: number): void => {
        const iS = { ...this.state.data };
        let found = { ...iS.items[index] };

        let errors = this.state.itemsError;
        
        if(name === "part_No"){
            if(errors[index]){
                errors[index] = this.errorChecker(name, value.toString(), errors[index], true, undefined, 1);

                if(Object.values(errors[index]).length === 0)
                    delete errors[index];
            } else {
                if(value.length === ZEROTH){
                    errors[index] = {};
                    errors[index] = this.errorChecker(name, value.toString(), errors[index], true, undefined, 1);
                }
            }
        }

        if (name === "quantity") {
            found = { ...found, [name]: Number(value) };
        } else {
            found = { ...found, [name]: value };
        };

        iS.items[index] = found;
        this.setState({ data: iS, itemsError: errors });
    };

    private onBack = (): void => {
        this.props.onAddUpdateCashOrderCancel();
    };

    private onSave = (): void => {
        const { token, order } = this.props;
        const { data } = this.state;

        if(hasPayload(token) && data){
            const items: IUpdatePartsCashOrderItem[] = [];

            data.items.forEach((x) => {
                items.push({
                    ID: x.id,
                    Part_no: x.part_No,
                    Quantity: x.quantity,
                    Price: x.price,
                    Total_Cost: (x.quantity * x.price),
                    Notes: x.notes
                });
            });

            if(order){
                this.props.updateCashOrderRequest({
                    token: token.payload.token,
                    request: {
                        ID: data.id,
                        Customer_ID: data.customer_ID,
                        Shipping_Address: "",
                        Shipping_Province: "",
                        Shipping_City: "",
                        Shipping_Country: "",
                        Modified: new Date().toISOString(),
                        Modified_By: token.payload.customer.email,
                        Notes: data.notes,
                        Status: data.status,
                        Shipping_Cost: 0,
                        Shipping_Postal_Code: "",
                        Total_Items_Cost: data.items.reduce((a, b) => +a + +b.price * b.quantity, 0),
                        Tax: (5 / 100) * Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0)),
                        Total_Cost: ((5 / 100) * Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0))) + Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0)),
                        USD_Rate: 0,
                        Items: items
                    }
                });
            } else {
                this.props.addCashOrderRequest({
                    token: token.payload.token,
                    request: {
                        Customer_ID: data.customer_ID,
                        Created: new Date().toISOString(),
                        Created_By: token.payload.customer.email,
                        Notes: data.notes,
                        Date: data.date,
                        Total_Items_Cost: data.items.reduce((a, b) => +a + +b.price * b.quantity, 0),
                        Tax: (5 / 100) * Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0)),
                        Total_Cost: ((5 / 100) * Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0))) + Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0)),
                        Payment_Details: data.payment_Details,
                        Items: items
                    }
                });
            }
            
        };
    };

    private onMasterObjChange = (name: string, value: string): void => {
  
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            }
        });
    };

    private onMasterNumberObjChange = (name: string, value: number): void => {

        let errors = this.state.errors;
        
        if(name === "customer_ID"){
            errors = this.errorChecker(name, value === 0 ? "" : value.toString(), errors, true, undefined, 1);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean, email: true | undefined, min?: number, max?: number): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: min, maxLength: max, email, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };


    private onInitialLoad = (): void => {
        const { order, token } = this.props;

        if (isNotLoaded(this.props.lookupData) && hasPayload(token)) {
            this.props.lookupDataRequest({
                token: token.payload.token,
                keywords: "",
                PageSize: 20,
                PageNumber: 1
            });
        };

        if (order) {
            this.setState({
                data: order,
                errors: {},
                showStatus: false,
                itemsError: {}
            });
        };
    };

}

const mapStateToProps = (state: IStore): IAddUpdateCashOrderComponentStoreProps => ({
    addCashOrder: addPartsCashOrder(state),
    updateCashOrder: updatePartsCashOrderStatus(state),
    lookupData: getPartsCashOrderLookupStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateCashOrderComponentDispatchProps => ({
    lookupDataRequest: (data: IGetPartsCashOrderLookupRequest): unknown => dispatch(getPartsCashOrderLookupLoadAction(data)),
    updateCashOrderRequest: (data: IUpdatePartsCashOrderRequest): unknown => dispatch(updatePartsCashOrderLoadAction(data)),
    addCashOrderRequest: (data: IAddPartsCashOrderRequest): unknown => dispatch(addPartsCashOrderLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateCashOrderComponent);