import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IGetAllPartsOrderRequest, IGetAllPartsOrderResponse, IGET_ALL_PARTS_ORDER_REQUEST } from "./getPartsOrderConstants";

export interface IGetAllPartsOrderLoadAction {
    type: IGET_ALL_PARTS_ORDER_REQUEST.REQUEST;
    data: IGetAllPartsOrderRequest
}
export const getAllPartsOrderLoadAction = (data: IGetAllPartsOrderRequest): IGetAllPartsOrderLoadAction => ({
    type: IGET_ALL_PARTS_ORDER_REQUEST.REQUEST,
    data
});

export interface IGetAllPartsOrderSuccessAction {
    type: IGET_ALL_PARTS_ORDER_REQUEST.SUCCESS;
    response: PartsResponse<IGetAllPartsOrderResponse[]>;
}
export const getAllPartsOrderLoadSuccessAction = (response: PartsResponse<IGetAllPartsOrderResponse[]>): IGetAllPartsOrderSuccessAction => ({
    type: IGET_ALL_PARTS_ORDER_REQUEST.SUCCESS,
    response
});
export interface IGetAllPartsOrderLoadFailedAction {
    type: IGET_ALL_PARTS_ORDER_REQUEST.FAILED;
    message: string;
}
export const getAllPartsOrderLoadFailedAction = (message: string): IGetAllPartsOrderLoadFailedAction => ({
    type: IGET_ALL_PARTS_ORDER_REQUEST.FAILED,
    message
});
