import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import LAPagination from "../../shared/pagination";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, PartsResponse } from "../../shared/publicInterfaces";
import queryString from "query-string";
import { ROUTE } from "../../routes";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import { login } from "../../../redux/login/loginAccessor";
import { AdminCheck, callRouteWithQueryString, CheckCountryCanada, getUSDRate, ONE, undefinedFunction, ZEROTH } from "../../shared/constExports";
import { OrderList } from "./adminOrderList";
import { IGetAllPartsOrderRequest, IGetAllPartsOrderResponse } from "../../../redux/getAllPartsOrder/getPartsOrderConstants";
import { getAllPartsOrder } from "../../../redux/getAllPartsOrder/getPartsOrderAccessor";
import { getAllPartsOrderLoadAction } from "../../../redux/getAllPartsOrder/getPartsOrderActions";
import LAErrorBox from "../../shared/errorBox";
import { OrderItemPopUpComponent } from "./orderItemPopUp";
import { updatePartsOrderAdminStatus } from "../../../redux/updatePartsOrderAdmin/updatePartsOrderAdminAccessor";
import { IUpdatePartsOrderAdminItem, IUpdatePartsOrderAdminRequest } from "../../../redux/updatePartsOrderAdmin/updatePartsOrderAdminConstants";
import { updatePartsOrderAdminLoadAction } from "../../../redux/updatePartsOrderAdmin/updatePartsOrderAdminActions";
import { IUpdatePartsOrderPaymentRequest } from "../../../redux/updatePartsOrderPayment/updatePartsOrderPaymentConstants";
import { updatePartsOrderPaymentStatus } from "../../../redux/updatePartsOrderPayment/updatePartsOrderPaymentAccessor";
import { updatePartsOrderPaymentLoadAction } from "../../../redux/updatePartsOrderPayment/updatePartsOrderPaymentActions";
import RequestStatus from "../../shared/requestStatusSnackbar";
import SearchBox from "../../shared/searchBox";
import LoginAndRegisterComponent from "../loginAndRegister";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidation";
import { END_POINTS } from "../../../redux/endpoints";
import { CancelConfirmationPopup, ReactivateConfirmationPopup } from "./cancelConfirmation";
import PageSpacing from "../../shared/pageSpacing";



interface IAdminOrdersComponentStoreProps {
    loginStatus: Server<ILoginResponse>;
    updatePartsOrderAdmin: Server<string>;
    updatePartsOrderPayment: Server<string>;
    adminOrders: Server<PartsResponse<IGetAllPartsOrderResponse[]>>;
};

interface IAdminOrdersComponentDispatchProps {
    adminOrdersRequest: (data: IGetAllPartsOrderRequest) => unknown;
    updatePartsOrderAdminRequest: (data: IUpdatePartsOrderAdminRequest) => unknown;
    updatePartsOrderPaymentRequest: (data: IUpdatePartsOrderPaymentRequest) => unknown;
};

interface IAdminOrdersComponentState {
    open: boolean;
    message: string;
    searchText: string;
    currentPage: number;
    cancelPopup: boolean;
    totalRecords: number;
    reactivatePopup: boolean;
    disable: true | undefined;
    redirectedMessage: string;
    redirectFromEmail: boolean;
    usdRate: number | undefined;
    openOrder: number | undefined;
    updatePayment: true | undefined;
    errors: ById<IFieldErrorKeyValue>;
    data: IGetAllPartsOrderResponse[];
};

const AdminOrdersStyles = styled(LAPaperWithPadding)`

`;

type IAdminOrdersComponentProps =
    RouteComponentProps
    & IAdminOrdersComponentStoreProps
    & IAdminOrdersComponentDispatchProps;

class AdminOrdersComponent extends PureComponent<IAdminOrdersComponentProps, IAdminOrdersComponentState> {

    public constructor(props: IAdminOrdersComponentProps) {
        super(props);
        this.state = {
            data: [],
            errors: {},
            message: "",
            open: false,
            searchText: "",
            cancelPopup: false,
            usdRate: undefined,
            currentPage: ONE,
            disable: undefined,
            openOrder: undefined,
            totalRecords: ZEROTH,
            redirectedMessage: "",
            redirectFromEmail: false,
            updatePayment: undefined,
            reactivatePopup: false,
        };
        this.keyWordSearch = this.debounce(this.keyWordSearch, 500);
    }

    public async componentDidMount(): Promise<void> {
        await this.handleServerCall();
        this.setDataToState();
    };

    public async componentDidUpdate(prevProps: IAdminOrdersComponentProps): Promise<void> {

        if (this.props.loginStatus !== prevProps.loginStatus) {
            await this.handleServerCall();
            this.setDataToState();
        };

        if ((hasPayload(this.props.loginStatus) && (this.props.loginStatus.payload.customer.email !== AdminCheck)))
            this.props.history.push(ROUTE.LOGIN());

        if ((this.props.adminOrders !== prevProps.adminOrders))
            this.setDataToState();

        if (this.props.updatePartsOrderAdmin !== prevProps.updatePartsOrderAdmin) {
            if (hasPayload(this.props.updatePartsOrderAdmin)) {
                if (this.props.updatePartsOrderAdmin.payload.toLocaleLowerCase().includes("success")) {
                    if (this.state.redirectFromEmail) {
                        await this.setState({ disable: true, redirectedMessage: this.props.updatePartsOrderAdmin.payload, open: false, openOrder: undefined });
                        callRouteWithQueryString({
                            route: this.props, pathName: ROUTE.ADMIN_ORDER(),
                            search: { pageNumber: 1, pageSize: 20 }
                        });

                        this.handleServerCall();
                    } else {
                        this.setState({ disable: true, redirectedMessage: this.props.updatePartsOrderAdmin.payload, open: false, openOrder: undefined });
                    }
                } else {
                    this.setState({ message: this.props.updatePartsOrderAdmin.payload });
                }
            };
        };

        if (this.props.updatePartsOrderPayment !== prevProps.updatePartsOrderPayment) {
            if (hasPayload(this.props.updatePartsOrderPayment)) {
                if (this.props.updatePartsOrderPayment.payload.toLocaleLowerCase().includes("success")) {
                    if (this.state.redirectFromEmail) {
                        await this.setState({ disable: true, redirectedMessage: this.props.updatePartsOrderPayment.payload, open: false, openOrder: undefined, redirectFromEmail: false });
                        callRouteWithQueryString({
                            route: this.props, pathName: ROUTE.ADMIN_ORDER(),
                            search: { pageNumber: 1, pageSize: 20 }
                        });

                        this.handleServerCall();
                    } else {
                        this.setState({ disable: true, redirectedMessage: this.props.updatePartsOrderPayment.payload, open: false, openOrder: undefined });
                    }
                } else {
                    this.setState({ message: this.props.updatePartsOrderPayment.payload });
                }
            };
        };
    }

    public render(): ReactNode {

        const { adminOrders, loginStatus, updatePartsOrderAdmin, updatePartsOrderPayment } = this.props;
        const { totalRecords, currentPage, data, open, openOrder, disable, message, searchText, reactivatePopup, updatePayment, redirectedMessage, errors, usdRate, cancelPopup } = this.state;

        return (
            <PageSpacing title="Admin Orders" description="Admin Orders">
            <AdminOrdersStyles>
                <LAGrid spacing={1}>

                    {(isNotLoaded(loginStatus)) && <LAGridItem xs={12}>
                        <LoginAndRegisterComponent {...this.props} />
                    </LAGridItem>}

                    {(hasPayload(loginStatus) && loginStatus.payload.customer.email === AdminCheck) && <>
                        <LAGridItem xs={12}>
                            <h2 className="text-center">ALL ORDERS</h2>
                        </LAGridItem>

                        {redirectedMessage !== "" && <LAGridItem xs={12}>
                            <LAErrorBox text={redirectedMessage} />
                        </LAGridItem>}

                        <LAGridItem xs={12}>
                            <LAPaperWithPadding>
                                <SearchBox
                                    className="p-3"
                                    fullWidth={true}
                                    text={searchText}
                                    placeHolder="Search"
                                    onSubmit={undefinedFunction}
                                    onChange={this.handleSearchTextChange}
                                    searchStatus={STATUS_ENUM.SUCCEEDED}
                                />

                                <OrderList
                                    data={data}
                                    dataStatus={adminOrders.kind}
                                    onClick={this.onViewItems}
                                />
                            </LAPaperWithPadding>

                            {(open && (openOrder !== undefined)) &&
                                <OrderItemPopUpComponent open={open} data={data[openOrder]} onClose={this.handleClose} onSubItemChange={this.onSubItemChange}
                                    onSave={this.handleSave} customerView={false} disable={disable} onMasterObjChange={this.onMasterObjChange} message={message}
                                    updatePayment={updatePayment} onPaymentUpdate={this.onPaymentUpdate} errors={errors} status={data[openOrder].status}
                                    downloadInvoice={this.downloadInvoice} usdRate={usdRate ?? 0} onCancelOrder={this.onCancelOrder} reActivatePartsOrder={this.reActivatePartsOrder}
                                />}
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAPagination
                                rowsPerPage={20}
                                className="mt-5"
                                currentPage={currentPage}
                                numberOfItems={totalRecords}
                                onPageChange={this.handlePageChange}
                            />
                        </LAGridItem>
                    </>}
                </LAGrid>

                <RequestStatus requestStatus={updatePartsOrderAdmin.kind} />
                <RequestStatus requestStatus={updatePartsOrderPayment.kind} />
                
                <CancelConfirmationPopup 
                    open={cancelPopup}
                    onClose={this.onCancelOrder}
                    onSave={this.onCancelConfirmOrder}
                />

                <ReactivateConfirmationPopup 
                                    open={reactivatePopup}
                                    onClose={this.reActivatePartsOrder}
                                    onSave={this.reActivateConfirmPartsOrder}
                />

            </AdminOrdersStyles>
            </PageSpacing>
        );
    }

    private reActivatePartsOrder = (): void => {
        this.setState({ reactivatePopup: !this.state.reactivatePopup });
    };

    private reActivateConfirmPartsOrder = (): void => {
        
        if((this.state.openOrder !== undefined) && hasPayload(this.props.loginStatus)){
        const data = this.state.data[this.state.openOrder];
        const token = this.props.loginStatus.payload.token;

            fetch(END_POINTS.REACTIVATE_PARTS_ORDER, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: token,
                    request: {
                        OrderId: data.id
                    }
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    this.reActivatePartsOrder();
                    this.handleClose();
                    
                    const query: any = queryString.parse(this.props.location.search);
                    this.props.adminOrdersRequest({
                        PageSize: 20,
                        token: token,
                        keywords: query.keyword ? query.keyword : "",
                        itemId: query.itemId ? Number(query.itemId) : 0,
                        PageNumber: query.pageNumber ? Number(query.pageNumber) : 1
                    });
                })
                .catch((res) => console.log(res));
            }
    };

    private downloadInvoice = (id: number): void => {
        if (hasPayload(this.props.loginStatus))
            fetch(END_POINTS.DOWNLOAD_INVOICE, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: this.props.loginStatus.payload.token,
                    request: {
                        OrderId: id,
                        Type: "parts"
                    }
                })
            })
                .then(res => {
                    return res.blob();
                })
                .then(async blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.target = "_blank";
                    a.download = `OrderInvoice-${id}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch((res) => console.log(res));
    };

    private onCancelOrder = (): void => {
        this.setState({ cancelPopup: !this.state.cancelPopup });
    };

    private onCancelConfirmOrder = (): void => {
        if ((this.state.openOrder !== undefined) && hasPayload(this.props.loginStatus)) {
            const Items: IUpdatePartsOrderAdminItem[] = [];
            const { usdRate } = this.state;
            const data = this.state.data[this.state.openOrder];
            const countryCheck = CheckCountryCanada(data.shipping_Country);

            data.items.forEach(x => {
                Items.push({
                    ID: x.id,
                    Part_no: x.part_No.toString(),
                    Quantity: x.quantity,
                    Notes: x.notes,
                    Price: countryCheck ? x.price : x.price * (usdRate ?? 0),
                    Total_Cost: countryCheck ? (x.quantity * x.price) : ((x.quantity * x.price) * (usdRate ?? 0))
                });
            });

            const itemsTotal = Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0));
            const total = itemsTotal + Number(data.shipping_Cost);

            this.props.updatePartsOrderAdminRequest({
                token: this.props.loginStatus.payload.token,
                request: {
                    ID: data.id,
                    Shipping_Postal_Code: data.shipping_Postal_Code,
                    Customer_ID: data.customer_ID,
                    Shipping_Address: data.shipping_Address,
                    Modified: new Date().toISOString(),
                    Modified_By: this.props.loginStatus.payload.customer.email,
                    Status: "Cancelled",
                    Notes: data.notes,
                    Total_Items_Cost: countryCheck ? itemsTotal : itemsTotal * (usdRate ?? 0),
                    Shipping_Cost: Number(data.shipping_Cost),
                    Tax: countryCheck ? Number(((5 / 100) * Number(total)).toFixed(2)) : 0,
                    Total_Cost: countryCheck ? ((5 / 100) * total) : total * (usdRate ?? 0),
                    USD_Rate: countryCheck ? 0 : (Number(data.shipping_Cost) + (itemsTotal) * (usdRate ?? 0)),
                    Shipping_City: data.shipping_City,
                    Shipping_Country: data.shipping_Country,
                    Shipping_Province: data.shipping_Province,
                    Items,
                }
            });

            this.onCancelOrder();
            this.handleClose();
        };
    };

    private handleSearchTextChange = async (searchTextString: string): Promise<void> => {
        this.setState({ searchText: searchTextString, redirectedMessage: "" });
        this.keyWordSearch();
    };

    private keyWordSearch = async (): Promise<void> => {
        await callRouteWithQueryString({
            route: this.props, pathName: ROUTE.ADMIN_ORDER(),
            search: { keyword: this.state.searchText, pageNumber: this.state.currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private debounce = (fn: any, delay: any): any => {
        let timer: any = null;
        return (...args: any): any => {
            const context = this;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    };

    private onMasterObjChange = (name: string, value: string): void => {
        const { openOrder, errors } = this.state;
        if (openOrder !== undefined) {
            const iS = [...this.state.data];
            let found = { ...iS[openOrder] };
            iS[openOrder] = { ...found, [name]: value };

            if ((name === "shipping_Cost")) {
                if (value.length === ZEROTH) {
                    errors["shipping_Cost"] = { key: "shipping_Cost", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                } else {
                    delete errors["shipping_Cost"];
                }
            };

            if ((name === "payment_Details")) {
                if (value.length === ZEROTH) {
                    errors["payment_Details"] = { key: "payment_Details", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                } else {
                    delete errors["payment_Details"];
                }
            };

            this.setState({ data: iS, errors });
        };
    };

    private onSubItemChange = (name: string, value: string, index: number): void => {
        const { openOrder } = this.state;
        if (openOrder !== undefined) {
            const iS = [...this.state.data];
            let found = { ...iS[openOrder] };
            
            if(name === "quantity"){
                found.items[index] = { ...found.items[index], [name]: Number(value) };
            } else {
                found.items[index] = { ...found.items[index], [name]: value };
            };

            iS[openOrder] = found;
            this.setState({ data: iS });
        };
    };

    private handlePageChange = async (currentPage?: number, rowsPerPage?: number): Promise<void> => {
        this.setState({ currentPage: currentPage ?? 1, redirectedMessage: "" });
        await callRouteWithQueryString({
            route: this.props, pathName: ROUTE.ADMIN_ORDER(),
            search: { pageNumber: currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private setDataToState = async (): Promise<void> => {
        if (hasPayload(this.props.adminOrders)) {
            const query: any = queryString.parse(this.props.location.search);
            const val = this.props.adminOrders.payload;

            await this.setState({
                data: [...val.response],
                totalRecords: val.totalRecords ?? 0,
                currentPage: query.pageNumber ? Number(query.pageNumber) : 1
            });

            if (this.state.redirectFromEmail && (this.state.data.length > ZEROTH))
                this.onViewItems(0);
        };

        if (isNotLoaded(this.props.adminOrders))
            this.handleServerCall();
    };

    private handleServerCall = (): void => {
        if (hasPayload(this.props.loginStatus) && (this.props.loginStatus.payload.customer.email === AdminCheck)) {
            const query: any = queryString.parse(this.props.location.search);
            this.props.adminOrdersRequest({
                PageSize: 20,
                token: this.props.loginStatus.payload.token,
                keywords: query.keyword ? query.keyword : "",
                itemId: query.itemId ? Number(query.itemId) : 0,
                PageNumber: query.pageNumber ? Number(query.pageNumber) : 1
            });

            if (query.itemId)
                this.setState({ redirectFromEmail: true });
        };
    };

    private handleClose = async (): Promise<void> => {
        if (this.state.redirectFromEmail) {
            await this.setState({ open: false, openOrder: undefined, message: "", updatePayment: undefined, disable: undefined, redirectedMessage: "", redirectFromEmail: false });

            callRouteWithQueryString({
                route: this.props, pathName: ROUTE.ADMIN_ORDER(),
                search: { pageNumber: 1, pageSize: 20 }
            });

            this.handleServerCall();
        } else {
            this.setState({ open: false, openOrder: undefined, message: "", updatePayment: undefined, disable: undefined, redirectedMessage: "", redirectFromEmail: false });
        };
    };

    private onViewItems = async (index: number): Promise<void> => {
        let errors: ById<IFieldErrorKeyValue> = {};
        const { status, shipping_Cost, payment_Details } = this.state.data[index];

        if (shipping_Cost === null || (status === "Cancelled")) {
            errors["shipping_Cost"] = { key: "shipping_Cost", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        };

        if(((status === "Quote Sent") || (status === "Accepted") || (status === "Completed")) && (payment_Details === null)){
            errors["payment_Details"] = { key: "payment_Details", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        };

        if (hasPayload(this.props.adminOrders) && hasPayload(this.props.loginStatus)){
            let rate = this.state.usdRate;
            
            if(this.props.adminOrders.payload.response[index].status === "Order Received")
                rate = await getUSDRate(this.props.loginStatus.payload.token);

            this.setState({
                errors,
                open: true,
                usdRate: rate,
                openOrder: index,
                redirectedMessage: "",
                updatePayment: ((status === "Quote Sent") || (status === "Accepted") || (status === "Completed")) ? undefined : true,
                disable: ((status === "Completed") || (status === "Cancelled")) ? true : undefined
            });
        }
    };

    private handleSave = (): void => {

        if ((this.state.openOrder !== undefined) && hasPayload(this.props.loginStatus)) {
            const Items: IUpdatePartsOrderAdminItem[] = [];
            const { usdRate } = this.state;
            const data = this.state.data[this.state.openOrder];
            const countryCheck = CheckCountryCanada(data.shipping_Country);

            data.items.forEach(x => {
                Items.push({
                    ID: x.id,
                    Part_no: x.part_No.toString(),
                    Quantity: x.quantity,
                    Notes: x.notes,
                    Price: x.price,
                    Total_Cost: countryCheck ? (x.quantity * x.price) : ((x.quantity * x.price) * (usdRate ?? 0))
                });
            });

            const itemsTotal = Number(data.items.reduce((a, b) => +a + +b.price * b.quantity, 0));
            const total = itemsTotal + Number(data.shipping_Cost);

            this.props.updatePartsOrderAdminRequest({
                token: this.props.loginStatus.payload.token,
                request: {
                    ID: data.id,
                    Customer_ID: data.customer_ID,
                    Shipping_Address: data.shipping_Address,
                    Modified: new Date().toISOString(),
                    Modified_By: this.props.loginStatus.payload.customer.email,
                    Status: "Quote Sent",
                    Notes: data.notes,
                    Shipping_Postal_Code: data.shipping_Postal_Code,
                    Total_Items_Cost: countryCheck ? itemsTotal : itemsTotal * (usdRate ?? 0),
                    Shipping_Cost: Number(data.shipping_Cost),
                    Tax: countryCheck ? Number(((5 / 100) * Number(total)).toFixed(2)) : 0,
                    Total_Cost: countryCheck ? ((5 / 100) * total) : total * (usdRate ?? 0),
                    USD_Rate: countryCheck ? 0 : (usdRate ?? 0),
                    Shipping_City: data.shipping_City,
                    Shipping_Country: data.shipping_Country,
                    Shipping_Province: data.shipping_Province,
                    Items,
                }
            });
        };

    };

    private onPaymentUpdate = (): void => {
        const { openOrder, data } = this.state;

        if ((openOrder !== undefined) && hasPayload(this.props.loginStatus)) {
            this.props.updatePartsOrderPaymentRequest({
                token: this.props.loginStatus.payload.token,
                request: {
                    ID: data[openOrder].id,
                    Status: data[openOrder].status,
                    Quote_Response: data[openOrder].status,
                    payment_Details: data[openOrder].payment_Details,
                    shipment_Tracking_No: data[openOrder].shipment_Tracking_No,
                    Modified_By: this.props.loginStatus.payload.customer.email,
                }
            });
        }
    };
}

const mapStateToProps = (state: IStore): IAdminOrdersComponentStoreProps => ({
    loginStatus: login(state),
    adminOrders: getAllPartsOrder(state),
    updatePartsOrderAdmin: updatePartsOrderAdminStatus(state),
    updatePartsOrderPayment: updatePartsOrderPaymentStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAdminOrdersComponentDispatchProps => ({
    adminOrdersRequest: (data: IGetAllPartsOrderRequest): unknown => dispatch(getAllPartsOrderLoadAction(data)),
    updatePartsOrderAdminRequest: (data: IUpdatePartsOrderAdminRequest): unknown => dispatch(updatePartsOrderAdminLoadAction(data)),
    updatePartsOrderPaymentRequest: (data: IUpdatePartsOrderPaymentRequest): unknown => dispatch(updatePartsOrderPaymentLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(AdminOrdersComponent);