import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IUPDATE_PARTS_ORDER_PAYMENT_REQUEST } from "./updatePartsOrderPaymentConstants";
import { IUpdatePartsOrderPaymentLoadAction, IUpdatePartsOrderPaymentLoadFailedAction, IUpdatePartsOrderPaymentSuccessAction } from "./updatePartsOrderPaymentActions";


type Actions =
    IUpdatePartsOrderPaymentLoadAction
    | IUpdatePartsOrderPaymentLoadFailedAction
    | IUpdatePartsOrderPaymentSuccessAction
    | IFlushDataSuccessAction;

export const UpdatePartsOrderPaymentReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};