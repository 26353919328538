import { HasClass, ITextAreaWrapperProps } from "./publicInterfaces";
import { STATUS_ENUM } from "../../redux/server";
import React, { PureComponent, ReactNode } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { SearchIcon } from "./icons";
import { DARK_GREY_COLOR, LIGHT_GREY_COLOR, RED_COLOR } from "./theme";
import LALoading from "./loading";
import { SearchBoxEditableTextArea } from "./textArea";


const style: React.CSSProperties = { backgroundColor: LIGHT_GREY_COLOR };

const TextAreaWrapper: React.FC<ITextAreaWrapperProps> = (props: ITextAreaWrapperProps) =>
    <div className="text" onClick={props.onClick}>{props.value}</div>
    ;

interface ISearchBoxProps extends HasClass {
    searchStatus: STATUS_ENUM;
    placeHolder?: string;
    fullWidth?: true;
    focused?: true;
    text: string;
    onSubmit: () => void;
    onChange: (value: string) => void;
}


interface ISearchBoxState {
    searchText: string;
}

class SearchBox extends PureComponent<ISearchBoxProps, ISearchBoxState> {
    private textField: React.RefObject<HTMLInputElement>;

    public constructor(props: ISearchBoxProps) {
        super(props);
        this.state = {
            searchText: ""
        };
        this.textField = React.createRef();
    }

    public componentDidMount(): void {
        if (this.props.focused && this.textField.current) {
            this.textField.current.focus();
        }

        if (this.props.text) {
            this.setState({ searchText: this.props.text });
        }
    }

    public componentDidUpdate(prevProps: ISearchBoxProps): void {
        if ((prevProps.text !== this.props.text))
            this.setState({ searchText: this.props.text });
    }

    public render(): ReactNode {
        return (
            <SearchBoxEditableTextArea
                isEditMode={true}
                canEdit={true}
                unSavedValue={this.props.text || ""}
                Wrapper={TextAreaWrapper}
                label={undefined}
                style={style}
                name="text"
                rowsMax={2}
                autoFocus={true}
                value={this.state.searchText}
                placeholder={this.props.placeHolder ?? "Search (min 3 characters)"}
                fullWidth={true}
                onPressEnter={this.props.onSubmit}
                onChange={this.handleSearchTextChange}
                className={this.props.className}
                InputProps={{
                    endAdornment: <SearchAdornmentButton
                        disabled={false}
                        onClick={this.props.onSubmit}
                        status={this.props.searchStatus}
                    />
                }}
            />
        );
    }

    private handleSearchTextChange = (name: string, value: string): void => {
        if (this.props.onChange) {
            this.props.onChange(value);
            this.setState({ searchText: value });
        }
    };
}

export default SearchBox;



interface ISearchInputAdornmentProps {
    disabled: boolean;
    status: STATUS_ENUM;
    onClick: () => unknown;
}

const getAdornmentByStatus = (status: STATUS_ENUM, disabled: boolean): ReactNode => {
    switch (status) {
        case STATUS_ENUM.SUCCEEDED:
        case STATUS_ENUM.NOT_LOADED:
            return <SearchIcon color={disabled ? DARK_GREY_COLOR : RED_COLOR} />;

        case STATUS_ENUM.LOADING:
        case STATUS_ENUM.COMMUNICATING:
            return <LALoading />;

        case STATUS_ENUM.FAILED:
        default:
            return <SearchIcon color={disabled ? DARK_GREY_COLOR : RED_COLOR} />;
    }
};

export const SearchAdornmentButton: React.FC<ISearchInputAdornmentProps> = React.memo((props: ISearchInputAdornmentProps) =>
    <InputAdornment position="end">
        <IconButton
            edge="end"
            aria-label="Send"
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {getAdornmentByStatus(props.status, props.disabled)}
        </IconButton>
    </InputAdornment>
);