import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../flushData.tsx/flushDataActions";
import { Server, loading, failed, succeeded, notLoaded } from "../../server";
import { IPart } from "../getParts/getPartsConstants";
import { IGetPartsByIdsLoadAction, IGetPartsByIdsLoadFailedAction, IGetPartsByIdsSuccessAction } from "./getPartsByIdsActions";
import { IGET_PARTS_BY_IDS_REQUEST } from "./getPartsByIdsConstants";


type Actions =
IGetPartsByIdsLoadAction
    | IGetPartsByIdsLoadFailedAction
    | IGetPartsByIdsSuccessAction
    | IFlushDataSuccessAction;

export const GetPartsByIdsReducer = (state: Server<PartsResponse<IPart[]>> = notLoaded, action: Actions): Server<PartsResponse<IPart[]>> => {
    switch (action.type) {
        case IGET_PARTS_BY_IDS_REQUEST.REQUEST:
            return loading;

        case IGET_PARTS_BY_IDS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PARTS_BY_IDS_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};