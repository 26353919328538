import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { getAllPartsCashOrdersLoadFailedAction, getAllPartsCashOrdersLoadSuccessAction, IGetAllPartsCashOrdersLoadAction, IGetAllPartsCashOrdersLoadFailedAction, IGetAllPartsCashOrdersSuccessAction } from "./getAllPartsCashOrdersActions";
import { IGetAllPartsCashOrders, IGET_ALL_PARTS_CASH_ORDERS_REQUEST } from "./getAllPartsCashOrdersConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";

export const getAllPartsCashOrdersEpic: Epic = (
    action$: ActionsObservable<IGetAllPartsCashOrdersLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetAllPartsCashOrdersSuccessAction | IGetAllPartsCashOrdersLoadFailedAction> =>
    action$.ofType(IGET_ALL_PARTS_CASH_ORDERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IGetAllPartsCashOrders[]>>(
                    END_POINTS.GET_ALL_PARTS_CASH_ORDERS,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<IGetAllPartsCashOrders[]>): IGetAllPartsCashOrdersSuccessAction => getAllPartsCashOrdersLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(getAllPartsCashOrdersLoadFailedAction("Unable to get cash orders")))
                    )
            )
        );