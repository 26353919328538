import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useState } from "react";
import LAGrid from "./grid";
import LAGridItem from "./gridList";
import { TermsIcon } from "./icons";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "./paper";
import { LAPopover } from "./popOver";
import { HasClass } from "./publicInterfaces";

interface ITermsAndConditionTabsProps extends HasClass {
    value: number;
    onChange: (event: unknown, value: number) => void;
};

const TermsAndConditionTabs: React.FC<ITermsAndConditionTabsProps> = React.memo((props: ITermsAndConditionTabsProps) =>
    <div>
        <div className="container">
            <AppBar position="static" color="default" elevation={0} className="app-bar">
                <Tabs
                    value={props.value}
                    onChange={props.onChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="on"
                >
                    <Tab label="Sales terms" icon={<TermsIcon />} />
                </Tabs>
            </AppBar>
        </div>

    </div>
);

const SalesTermTab: React.FC<unknown> = React.memo((props: unknown) =>
    <>
        <LAGridItem xs={12}>
            <LAPaperWithLessPadding>
                <h3>Satisfaction guaranteed</h3>
                <span>
                    Because our parts are superior quality, they are certified satisfaction guaranteed.
                    {/* The quality of our products matters to us, so if you are not happy with it, you can ask for a refund. */}
                </span>
            </LAPaperWithLessPadding>
        </LAGridItem>

        {/* <LAGridItem xs={12}>
            <LAPaperWithLessPadding>
                <h3>Exchange</h3>
                <span>
                    If you bought a part with an EXCHANGE agreement, you have 1 month to return the core.
                    Our only core requirement in order to receive the full credit value is that the core must be complete and assembled upon arrival at our installation.
                    We pay the core freight and will provide the paperwork to ship it back via e-mail. You have nothing to do, we will send a truck to pick it up.
                </span>
            </LAPaperWithLessPadding>
        </LAGridItem> */}
    </>
);

interface ITermsAndConditionPopupComponentProps extends HasClass {
    tab?: number;
    open: boolean;
    onClose: () => void;
};

export const TermsAndConditionPopupComponent: React.FC<ITermsAndConditionPopupComponentProps> = React.memo((props: ITermsAndConditionPopupComponentProps) => {
    const [tab, setTab] = useState(props.tab ?? 0);
    const onTabClick = (event: unknown, value: number): void => setTab(value);

    return <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}
            anchorOrigin={{ horizontal: "center", vertical: "center" }}
            transformOrigin={{ horizontal: "center", vertical: "center" }}>

            <LAPaperWithPadding>
                <TermsAndConditionTabs value={tab} onChange={onTabClick} />

                <LAGrid>
                    {tab === 0 && <SalesTermTab />}
                </LAGrid>
            </LAPaperWithPadding>

        </LAPopover>;
});