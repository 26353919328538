import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { IGetAllPartsCashOrders } from "../getAllPartsCashOrders/getAllPartsCashOrdersConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IAddPartsCashOrderLoadAction, IAddPartsCashOrderLoadFailedAction, IAddPartsCashOrderSuccessAction } from "./addPartsCashOrderActions";
import { IADD_PARTS_CASH_ORDER_REQUEST } from "./addPartsCashOrderConstants";


type Actions =
    IAddPartsCashOrderLoadAction
    | IAddPartsCashOrderLoadFailedAction
    | IAddPartsCashOrderSuccessAction
    | IFlushDataSuccessAction;

export const AddPartsCashOrderReducer = (state: Server<PartsResponse<IGetAllPartsCashOrders>> = notLoaded, action: Actions): Server<PartsResponse<IGetAllPartsCashOrders>> => {
    switch (action.type) {
        case IADD_PARTS_CASH_ORDER_REQUEST.REQUEST:
            return loading;

        case IADD_PARTS_CASH_ORDER_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IADD_PARTS_CASH_ORDER_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};