import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IAddPartsOrderRequest, IAddPartsOrderResponse, IADD_PARTS_ORDER_REQUEST } from "./addPartsOrderConstants";

export interface IAddPartsOrderLoadAction {
    type: IADD_PARTS_ORDER_REQUEST.REQUEST;
    data: IAddPartsOrderRequest
}
export const addPartsOrderLoadAction = (data: IAddPartsOrderRequest): IAddPartsOrderLoadAction => ({
    type: IADD_PARTS_ORDER_REQUEST.REQUEST,
    data
});

export interface IAddPartsOrderSuccessAction {
    type: IADD_PARTS_ORDER_REQUEST.SUCCESS;
    response: PartsResponse<IAddPartsOrderResponse>;
}
export const addPartsOrderLoadSuccessAction = (response: PartsResponse<IAddPartsOrderResponse>): IAddPartsOrderSuccessAction => ({
    type: IADD_PARTS_ORDER_REQUEST.SUCCESS,
    response
});
export interface IAddPartsOrderLoadFailedAction {
    type: IADD_PARTS_ORDER_REQUEST.FAILED;
    message: string;
}
export const addPartsOrderLoadFailedAction = (message: string): IAddPartsOrderLoadFailedAction => ({
    type: IADD_PARTS_ORDER_REQUEST.FAILED,
    message
});
