import { ReactNode, PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { contactUsLoadAction } from "../../redux/contactUs/contactUsActions";
import { IContactUs, IContactUsRequest } from "../../redux/contactUs/contactUsConstants";
import { IDispatch, IStore } from "../../redux/reducers";
import { LAPaperWithPadding } from "../shared/paper";
import LAGridItem from "../shared/gridList";
import LAGrid from "../shared/grid";
import { LAPopover } from "../shared/popOver";
import LATextArea from "../shared/textArea";
import { LASaveAndCancelButton } from "../shared/buttons";
import { FieldValidator, IFieldErrorKeyValue } from "../shared/fieldValidation";
import { ById, PartsResponse } from "../shared/publicInterfaces";
import { hasPayload, Server } from "../../redux/server";
import { contactUsStatus } from "../../redux/contactUs/contactUsAccessor";
import LAErrorBox from "../shared/errorBox";
import LATextField from "../shared/textField";

interface IContactUsPopupStoreProps {
    submitStatus: Server<PartsResponse<string>>;
};

interface IContactUsPopupDispatchProps {
    submitMessage: (data: IContactUsRequest) => unknown;
};

interface IContactUsPopupOwnProps {
    open: boolean;
    onClose: () => void;
};

interface IContactUsPopupState {
    data: IContactUs;
    showMessage: boolean;
    errors: ById<IFieldErrorKeyValue>;
};



const ContactUsStyles = styled(LAPaperWithPadding)`

`;

type IContactUsPopupProps =
    IContactUsPopupOwnProps
    & IContactUsPopupStoreProps
    & IContactUsPopupDispatchProps;

class ContactUsPopup extends PureComponent<IContactUsPopupProps, IContactUsPopupState> {

    public constructor(props: IContactUsPopupProps) {
        super(props);
        this.state = {
            data: {
                Name: "",
                Phone_No: "",
                Email: "",
                Message: ""
            },
            showMessage: false,
            errors: {
                "Name": { key: "Name", message: "Name is Required" },
                "Phone_No": { key: "Phone_No", message: "Phone is Required" },
                "Email": { key: "Email", message: "Email is Required" },
                "Message": { key: "Message", message: "Message is Required" },
            }
        };
    }

    public componentDidMount(): void {
    };

    public componentDidUpdate(prevProps: IContactUsPopupProps): void {
        if (this.props.submitStatus !== prevProps.submitStatus)
            this.setState({ showMessage: true });
    };

    public render(): ReactNode {

        const { open, submitStatus } = this.props;
        const { data, errors, showMessage } = this.state;

        return (
            <LAPopover open={open} onClose={this.handleClose} anchorRef={null} anchorOrigin={{ horizontal: "center", vertical: "center" }} transformOrigin={{ horizontal: "center", vertical: "center" }}>
                <ContactUsStyles>
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12}>
                            <h3>CONTACT US</h3>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            <LATextArea
                                rowsMin={1}
                                rowsMax={1}
                                fullWidth={true}
                                name="Name"
                                label="Name"
                                onChange={this.onChange}
                                value={data.Name}
                                disabled={showMessage ? true : undefined}
                                errorText={errors["Name"] ? errors["Name"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={4}>
                        <LATextArea
                                rowsMin={1}
                                rowsMax={1}
                                fullWidth={true}
                                name="Phone_No"
                                label="Phone #"
                                onChange={this.onChange}
                                value={data.Phone_No}
                                disabled={showMessage ? true : undefined}
                                errorText={errors["Phone_No"] ? errors["Phone_No"].message : undefined}
                            />                            
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            <LATextArea
                                rowsMin={1}
                                rowsMax={1}
                                fullWidth={true}
                                name="Email"
                                label="Email"
                                onChange={this.onChange}
                                value={data.Email}
                                disabled={showMessage ? true : undefined}
                                errorText={errors["Email"] ? errors["Email"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LATextArea
                                rowsMin={4}
                                rowsMax={6}
                                fullWidth={true}
                                name="Message"
                                label="Message"
                                onChange={this.onChange}
                                value={data.Message}
                                disabled={showMessage ? true : undefined}
                                errorText={errors["Message"] ? errors["Message"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LASaveAndCancelButton
                                onSave={this.onSave}
                                saveButtonText="Submit"
                                onCancel={this.handleClose}
                                disableSave={(showMessage ? true : (Object.values(errors).length > 0 ? true : undefined))}
                            />
                        </LAGridItem>

                        {showMessage && hasPayload(submitStatus) && <LAGridItem xs={12}>
                            <LAErrorBox text={submitStatus.payload.message} />
                        </LAGridItem>}

                    </LAGrid>
                </ContactUsStyles>
            </LAPopover>
        );
    }

    private onChange = (name: string, value: string): void => {
        let initialErrors = this.state.errors;
        if (name === "Email") {
            initialErrors = this.errorChecker(name, value, initialErrors, true, true);
        } else {
            initialErrors = this.errorChecker(name, value, initialErrors, true, undefined);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors: initialErrors
        });
    };

    private handleClose = (): void => {
        this.setState({ data: { Name: "", Phone_No: "", Email: "", Message: "" }, showMessage: false });
        this.props.onClose();
    };

    private onSave = (): void => {
        this.props.submitMessage({ request: this.state.data });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean, email: true | undefined, min?: number, max?: number): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: min, maxLength: max, email, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

}

const mapStateToProps = (state: IStore): IContactUsPopupStoreProps => ({
    submitStatus: contactUsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IContactUsPopupDispatchProps => ({
    submitMessage: (data: IContactUsRequest): unknown => dispatch(contactUsLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPopup);