import React from "react";
import styled from "styled-components";
import { CheckCountryCanada, getDate, ONE, undefinedFunction, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { DARK_GREY_COLOR } from "../../shared/theme";
import { LAPaperWithPadding } from "../../shared/paper";
import { ById, HasClass } from "../../shared/publicInterfaces";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LAAutoComplete from "../../shared/autoComplete";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IGetPartsOrderByCustomer, IGetPartsOrderByCustomerItem } from "../../../redux/getPartsOrderByCustomer/getPartsOrderByCustomerConstants";
import { QuantityList } from "../../../redux/cart/cartConstants";
import LALinkButton from "../../shared/linkButton";
import { LAPopover } from "../../shared/popOver";
import LATextField from "../../shared/textField";
import { LAButton } from "../../shared/buttons";
import LAErrorBox from "../../shared/errorBox";
import LATextArea from "../../shared/textArea";
import { IFieldErrorKeyValue } from "../../shared/fieldValidation";
import { LACheckBox } from "../../shared/checkBox";
import Not_Available_Image from "../../../No-Image.jpg";

const OrderReceived = "Order Received";

interface IOrderItemPopUpComponentProps extends HasClass {
    open: boolean;
    status: string;
    message: string;
    usdRate: number;
    onSave: () => void;
    onClose: () => void;
    onReject?: () => void;
    customerView: boolean;
    onCancelOrder: () => void;
    disable: true | undefined;
    onPaymentUpdate?: () => void;
    data: IGetPartsOrderByCustomer;
    updatePayment: true | undefined;
    reActivatePartsOrder: () => void;
    errors: ById<IFieldErrorKeyValue>;
    downloadInvoice: (id: number) => void;
    onMasterObjChange: (name: string, value: string) => void;
    onSubItemChange: (name: string, value: string, index: number) => void;
};

const OrderItemPopUpComponentStyles = styled(LAPaperWithPadding)`
    .deskView {
        display: block;
    };

    .mobView {
        display: none;
    };

    .itemPaper .qtyWrapper {
        font-size: 20px;
    };

    .itemPaper .itemImageWithThumb {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 170px;
        height: 120px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };

    .itemPaper .itemImage {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 170px;
        height: 120px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };

    .title {
        font-weight: bold;
        background-color: ${DARK_GREY_COLOR};
    };

    @media only screen and (max-width: ${1700}px) {
        .deskView {
            display: none;
        };

        .mobView {
            display: block;
        };

        .itemPaper .itemImageWithThumb {
            padding: 5px;
            border: 1px solid ${DARK_GREY_COLOR};
            max-width: 300px;
            height: 290px;
            overflow: hidden;
            text-align: center;
            img {
                width: 100%;
                height: auto;
            };
        };

        .itemPaper .itemImage {
            padding: 5px;
            border: 1px solid ${DARK_GREY_COLOR};
            max-width: 300px;
            height: 240px;
            overflow: hidden;
            text-align: center;
            img {
                width: 100%;
                height: auto;
            };
        }; 
    };
`;

export const OrderItemPopUpComponent: React.FC<IOrderItemPopUpComponentProps> = React.memo((props: IOrderItemPopUpComponentProps) => {
    const total = Number(props.data.items.reduce((a, b) => +a + +b.price * b.quantity, 0)) + Number(props.data.shipping_Cost);
    const onInvoiceDownload = (): void => props.downloadInvoice(props.data.id);

    const countryCanada = CheckCountryCanada(props.data.shipping_Country);

    return <LAPopover anchorRef={null} onClose={props.onClose} open={props.open} anchorOrigin={{ horizontal: "center", vertical: "center" }} transformOrigin={{ horizontal: "center", vertical: "center" }}>
        <OrderItemPopUpComponentStyles>
            <LAGrid spacing={2}>

                <LAGridItem xs={4} sm={4} md={2}>
                    <strong>Customer: </strong>
                    <span>{props.data.customer_Name}</span>
                </LAGridItem>

                <LAGridItem xs={4} sm={4} md={2}>
                    <strong>Phone #: </strong>
                    <span>{props.data.phone_No}</span>
                </LAGridItem>

                <LAGridItem xs={4} sm={4} md={2}>
                    <strong>Email: </strong>
                    <span>{props.data.email}</span>
                </LAGridItem>

                <LAGridItem xs={4} sm={4} md={2}>
                    <strong>Status: </strong>
                    <span>{props.data.status}</span>
                </LAGridItem>

                <LAGridItem xs={4} sm={4} md={2}>
                    <strong>Date: </strong>
                    <span>{getDate(false, props.data.date)}</span>
                </LAGridItem>

                <LAGridItem xs={12} md={9}>
                    <LAPaperWithPadding>
                        <OrderItemPopUpComponentDesktop {...props} />
                        <OrderItemPopUpComponentMobile {...props} />
                    </LAPaperWithPadding>
                </LAGridItem>

                <LAGridItem xs={12} md={3}>
                    <LAPaperWithPadding>

                       {!props.customerView && <LACheckBox 
                            label="Cancel Order"
                            value={props.status === "Cancelled"} 
                            onChange={props.status !== "Cancelled" ? props.onCancelOrder : props.reActivatePartsOrder}
                        />}

                        <h3>Order Summary</h3>

                        <LAGrid spacing={2}>

                            {(!countryCanada && props.customerView) && <LAGridItem xs={12}>
                                <LAErrorBox text="All Price in USD" />
                            </LAGridItem>}

                            <LAGridItem xs={12}>
                                <LATextField
                                    disabled={true}
                                    fullWidth={true}
                                    type="number"
                                    variant="outlined"
                                    name="totalQuantity"
                                    onChange={undefinedFunction}
                                    label={`Items sub-total ${countryCanada ? "" : "(USD) "} (${props.data.items.length})`}
                                    value={((props.customerView) || (props.status !== OrderReceived)) ? props.data.total_Items_Cost 
                                        : Number(countryCanada ? props.data.items.reduce((a, b) => +a + +b.price * b.quantity, 0) 
                                        : props.data.items.reduce((a, b) => +a + +b.price * b.quantity, 0) * props.usdRate).toFixed(2)}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LATextField
                                    type="number"
                                    variant="outlined"
                                    fullWidth={true}
                                    name="shipping_Cost"
                                    onChange={props.onMasterObjChange}
                                    disabled={!props.customerView ? props.disable : true}
                                    label={countryCanada ? "Shipping & Handling" : "Shipping & Handling (USD)"}
                                    errorText={props.errors["shipping_Cost"] ? props.errors["shipping_Cost"].message : undefined}
                                    value={((props.customerView) || (props.status !== OrderReceived)) ? Number(props.data.shipping_Cost).toFixed(2) : (props.disable ? Number(props.data.shipping_Cost).toFixed(2) : props.data.shipping_Cost)}
                                    />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LATextField
                                    type="number"
                                    variant="outlined"
                                    fullWidth={true}
                                    name="tax"
                                    label="$ GST"
                                    disabled={true}
                                    onChange={undefinedFunction}
                                    value={countryCanada ? ((5 / 100) * Number(total)).toFixed(2) : 0}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LATextField
                                    type="number"
                                    fullWidth={true}
                                    disabled={true}
                                    name="total_Cost"
                                    variant="outlined"
                                    onChange={props.onMasterObjChange}
                                    label={`$ Total Cost ${countryCanada ? "(CAD)" : "(USD)"}`}
                                    value={((props.customerView) || (props.status !== OrderReceived)) ? props.data.total_Cost 
                                        : countryCanada ? (((5 / 100) * Number(total)) + Number(props.data.shipping_Cost) + (props.data.items.reduce((a, b) => +a + +b.price * b.quantity, 0))).toFixed(2)
                                        : (Number(props.data.shipping_Cost) + (props.data.items.reduce((a, b) => +a + +b.price * b.quantity, 0)) * props.usdRate).toFixed(2)}
                                />
                            </LAGridItem>

                            {/* {!countryCanada && <LAGridItem xs={12}>
                                <LATextField
                                    type="number"
                                    name="usd_Rate"
                                    disabled={true}
                                    fullWidth={true}
                                    variant="outlined"
                                    label="$ USD Total"
                                    onChange={undefinedFunction}
                                    value={Number(props.data.shipping_Cost) + (props.data.items.reduce((a, b) => +a + +b.price * b.quantity, 0)) * props.usdRate}
                                />
                            </LAGridItem>} */}

                            <LAGridItem xs={12}>
                                <LATextField
                                    variant="outlined"
                                    fullWidth={true}
                                    name="notes"
                                    label="Notes"
                                    disabled={props.customerView ? true : props.disable}
                                    value={props.data.notes}
                                    onChange={props.onMasterObjChange} 
                                    />
                            </LAGridItem>

                            {props.customerView && (props.status === "Completed") &&
                                <LAGridItem xs={12}>
                                    <LATextField
                                        variant="outlined"
                                        fullWidth={true}
                                        name="shipment_Tracking_No"
                                        label="Shipping Tracking Number"
                                        disabled={true}
                                        onChange={props.onMasterObjChange}
                                        value={props.data.shipment_Tracking_No} 
                                        />
                                </LAGridItem>}
                        
                                <LAGridItem xs={6}>
                                    <LATextArea
                                        rowsMin={1}
                                        rowsMax={4}
                                        fullWidth={true}
                                        name="shipping_Address"
                                        label="Shipping Address"
                                        onChange={props.onMasterObjChange}
                                        value={props.data.shipping_Address}
                                        disabled={true}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextArea
                                        rowsMin={1}
                                        rowsMax={4}
                                        fullWidth={true}
                                        name="shipping_City"
                                        label="Shipping City"
                                        disabled={true}
                                        onChange={props.onMasterObjChange}
                                        value={props.data.shipping_City}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextArea
                                        rowsMin={1}
                                        rowsMax={4}
                                        fullWidth={true}
                                        name="shipping_Province"
                                        label="Shipping Province"
                                        disabled={true}
                                        onChange={props.onMasterObjChange}
                                        value={props.data.shipping_Province}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextArea
                                        rowsMin={1}
                                        rowsMax={4}
                                        fullWidth={true}
                                        name="shipping_Country"
                                        label="Shipping Country"
                                        onChange={props.onMasterObjChange}
                                        value={props.data.shipping_Country}
                                        disabled={true}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextArea
                                        rowsMin={1}
                                        rowsMax={4}
                                        fullWidth={true}
                                        name="shipping_Postal_Code"
                                        label="Shipping Postal/Zip Code"
                                        onChange={props.onMasterObjChange}
                                        value={props.data.shipping_Postal_Code}
                                        disabled={true}
                                    />
                                </LAGridItem>

                                {!props.customerView && <>
                                {(props.updatePayment !== true) &&
                                    <LAGridItem xs={12}>
                                        <LATextArea
                                            rowsMin={4}
                                            rowsMax={4}
                                            variant="outlined"
                                            fullWidth={true}
                                            name="shipment_Tracking_No"
                                            label="Shipment Tracking No"
                                            onChange={props.onMasterObjChange}
                                            value={props.data.shipment_Tracking_No} 
                                            disabled={props.disable ? props.updatePayment : props.disable}
                                            />
                                    </LAGridItem>}


                                <LAGridItem xs={12}>
                                    <LATextArea
                                        rowsMax={2}
                                        variant="outlined"
                                        fullWidth={true}
                                        name="payment_Details"
                                        label="Payment Details"
                                        onChange={props.onMasterObjChange}
                                        value={props.data.payment_Details}
                                        disabled={props.disable ? props.updatePayment : props.disable}
                                        errorText={props.errors["payment_Details"] ? props.errors["payment_Details"].message : undefined}
                                    />
                                </LAGridItem>

                                {props.onPaymentUpdate && (props.updatePayment !== true) &&
                                    <LAGridItem xs={12} sm={12} className="text-center">
                                        <LAButton label="Update Payment/Shipment Number" onClick={props.onPaymentUpdate}
                                            disabled={((Object.values(props.errors).length > ZEROTH) ? true : undefined)}
                                        />
                                    </LAGridItem>}
                            </>}

                            {(props.message !== "") && <LAGridItem xs={12}>
                                <LAErrorBox text={props.message} />
                            </LAGridItem>}


                            {props.status === "Completed" && <LAGridItem xs={12} className="text-center">
                                <LAButton label="Download Invoice" onClick={onInvoiceDownload} />
                            </LAGridItem>}


                            <LAGridItem xs={12} sm={12} md={12}>
                                <LAGrid spacing={0} className="text-center">

                                    <LAGridItem xs={props.customerView ? 12 : 6}>
                                        <LAButton label="Close" onClick={props.onClose} />
                                    </LAGridItem>

                                    {props.customerView && props.onReject && <LAGridItem xs={6}>
                                        <LAButton disabled={props.disable} label="Reject Quote" onClick={props.onReject} />
                                    </LAGridItem>}

                                    <LAGridItem xs={6}>
                                        <LAButton 
                                            label={props.customerView ? "Accept Quote" : "Send Quote"} onClick={props.onSave}
                                            disabled={(props.disable) || ((Object.values(props.errors).length > ZEROTH) ? true : undefined)} 
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAGridItem>

                            {props.customerView && props.onReject && props.status === "Quote Received" && <LAGridItem xs={12} className="text-center">
                                <LAErrorBox text="After accepting the quote, you will be contacted by sales representatuve regarding payments." />
                            </LAGridItem>}

                        </LAGrid>
                    </LAPaperWithPadding>
                </LAGridItem>
            </LAGrid>

        </OrderItemPopUpComponentStyles>
    </LAPopover>
});

const OrderItemPopUpComponentDesktop: React.FC<IOrderItemPopUpComponentProps> = React.memo((props: IOrderItemPopUpComponentProps) =>
    <TableContainer className="deskView" component="div">
        <Table aria-label="Checkout Grid">
            <TableHead>
                <TableRow>
                    <TableCell className="title" align="center"></TableCell>
                    <TableCell className="title" align="center">Description</TableCell>
                    <TableCell className="title" align="center">Notes</TableCell>
                    <TableCell className="title" align="center">Quantity</TableCell>
                    <TableCell className="title" align="center">$ Price {CheckCountryCanada(props.data.shipping_Country) ? "(CAD)" : "(CAD)"}</TableCell>
                    {(!CheckCountryCanada(props.data.shipping_Country) && !props.customerView) ? <TableCell className="title" align="center">Exchange Rate $</TableCell> : undefined}
                    {!props.customerView ? <TableCell className="title" align="center">$ Item Cost {CheckCountryCanada(props.data.shipping_Country) ? "(CAD)" : "(USD)"}</TableCell> : undefined}
                    <TableCell className="title" align="center"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.items.map((part, index) => {
                    const images = getImage(part);
                    const countryCheck = CheckCountryCanada(props.data.shipping_Country);
                    const onChange = (name: string, value: string) => props.onSubItemChange(name, value, index);
                    const qtyChange = (event: unknown, value: { id: number, value: string }) => props.onSubItemChange("quantity", value === null ? "1" : value.id.toString(), index);
                    return (
                        <TableRow key={part.id} className="itemPaper">
                            <TableCell align="center" component="th" scope="row">
                                <div className={`${images.length > ONE ? "itemImageWithThumb" : "itemImage"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {

                                }}>
                                    <ImageGallery
                                        items={images}
                                        showNav={false}
                                        autoPlay={false}
                                        showPlayButton={false}
                                        showFullscreenButton={true}
                                        showThumbnails={(images.length > ONE) ? true : false}
                                    />
                                </div>
                            </TableCell>

                            <TableCell align="center">
                                {`${part.part_No}, ${part.part_Name}, ${part.type}, ${part.model}, ${part.condition}`}
                            </TableCell>

                            <TableCell align="center">
                                <LATextField
                                    variant="outlined"
                                    label="Notes"
                                    fullWidth={true}
                                    onChange={onChange}
                                    name="notes"
                                    value={part.notes}
                                    disabled={!props.customerView ? props.disable : true}
                                />
                            </TableCell>

                            <TableCell align="center">
                                <LAAutoComplete
                                    name="quantity"
                                    multiple={false}
                                    onChange={qtyChange}
                                    autoHighlight={true}
                                    option={QuantityList}
                                    getOptionLabel="value"
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Quantity"
                                    selectionRemove={undefinedFunction}
                                    disabled={!props.customerView ? props.disable : true}
                                    value={QuantityList.find(q => q.id === part.quantity)}
                                    defaultValue={QuantityList.find(q => q.id === part.quantity)}
                                />
                            </TableCell>

                            {!props.customerView && <TableCell align="center">
                                <LATextField
                                    type="number"
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={onChange}
                                    name="price"
                                    label="$ Price"
                                    value={part.price}
                                    disabled={!props.customerView ? props.disable : true}
                                />
                            </TableCell>}

                            {(!countryCheck && !props.customerView) ? <TableCell align="center">
                                <LATextField
                                    type="number"
                                    variant="outlined"
                                    label="$ USD Rate"
                                    fullWidth={true}
                                    name="price"
                                    disabled={true}
                                    onChange={undefinedFunction}
                                    value={props.status === OrderReceived ? props.usdRate : props.data.usD_Rate}
                                />
                            </TableCell> : undefined}

                            <TableCell align="center">
                                <LATextField
                                    type="number"
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={onChange}
                                    name="total_Cost"
                                    disabled={true}
                                    label={`$ Item Cost ${countryCheck ? "(CAD)" : "(USD)"}`}
                                    value={((props.customerView) || (props.status !== OrderReceived)) ? part.total_Cost
                                        : Number(countryCheck ? part.quantity * part.price : (part.quantity * part.price) * props.usdRate).toFixed(2)}
                                />
                            </TableCell>

                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </TableContainer>
);


const OrderItemPopUpComponentMobile: React.FC<IOrderItemPopUpComponentProps> = React.memo((props: IOrderItemPopUpComponentProps) =>
    <LAGrid className="mobView">
        {props.data.items.map((x, index) => {
            const images = getImage(x);
            const countryCheck = CheckCountryCanada(props.data.shipping_Country);
            const onChange = (name: string, value: string) => props.onSubItemChange(name, value, index);
            const qtyChange = (event: unknown, value: { id: number, value: string }) => props.onSubItemChange("quantity", value === null ? "1" : value.id.toString(), index);
            return (
                <LAGridItem xs={12} key={index}>

                    <LAPaperWithPadding className="itemPaper">
                        <LAGrid>
                            <LAGridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className={`${images.length > ONE ? "itemImageWithThumb" : "itemImage"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {

                                }}>
                                    <ImageGallery
                                        items={images}
                                        showNav={true}
                                        autoPlay={false}
                                        showPlayButton={false}
                                        showFullscreenButton={true}
                                        showThumbnails={(images.length > ONE) ? true : false}
                                    />
                                </div>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                                <LAGrid spacing={1}>

                                    <LAGridItem xs={12} md={12}>
                                        <LAGrid spacing={3} justify="space-between" alignItems="center">
                                            <LAGridItem xs={12} sm={6} md={6} lg={7} xl={8}>
                                                <div className="cursor" onClick={(e: React.MouseEvent<unknown>): void => {
                                                }}>
                                                    <LALinkButton label={`${x.part_Name}`} onClick={undefinedFunction} fontSize={20} />
                                                </div>
                                            </LAGridItem>

                                            <LAGridItem xs={12} sm={6} md={6} lg={5} xl={4}>
                                                <LAGrid spacing={0} justify="flex-end" alignItems="flex-end">
                                                    <LAGridItem sm={1} md={5}>

                                                    </LAGridItem>

                                                    <LAGridItem sm={11} md={7} className="qtyWrapper">
                                                    </LAGridItem>
                                                </LAGrid>
                                            </LAGridItem>

                                        </LAGrid>
                                        <hr />
                                    </LAGridItem>

                                    <LAGridItem xs={12} md={12}>
                                        <LAGrid>

                                            <LAGridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <LAGrid alignItems="center" justify="space-between">

                                                    <LAGridItem xs={12} className="qtyWrapper">
                                                        <strong className="">Part Number: </strong>
                                                        <span className=""> {x.part_No}</span>
                                                    </LAGridItem>

                                                    <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                        <strong>Make: </strong>
                                                        <span className=""> {x.type}</span>
                                                    </LAGridItem>

                                                    <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                        <strong>Model: </strong>
                                                        <span className=""> {x.model}</span>
                                                    </LAGridItem>

                                                    <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                        <strong>Condition: </strong>
                                                        <span className=""> {x.condition}</span>
                                                    </LAGridItem>

                                                </LAGrid>
                                            </LAGridItem>

                                            <LAGridItem xs={12} sm={12} md={6}>
                                                <LAAutoComplete
                                                    name="quantity"
                                                    multiple={false}
                                                    getOptionLabel="value"
                                                    onChange={qtyChange}
                                                    autoHighlight={true}
                                                    option={QuantityList}
                                                    filterSelectedOptions={true}
                                                    dropDownPlaceHolder="Quantity"
                                                    selectionRemove={undefinedFunction}
                                                    disabled={!props.customerView ? props.disable : true}
                                                    value={QuantityList.find(q => q.id === x.quantity)}
                                                    defaultValue={QuantityList.find(q => q.id === x.quantity)}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12} sm={12} md={6}>
                                                <LATextField
                                                    variant="outlined"
                                                    label="Notes"
                                                    onChange={onChange}
                                                    fullWidth={true}
                                                    name="notes"
                                                    value={x.notes}
                                                    disabled={!props.customerView ? props.disable : true}
                                                />
                                            </LAGridItem>

                                            {!props.customerView && <LAGridItem xs={6} sm={6} md={6}>
                                                <LATextField
                                                    type="number"
                                                    variant="outlined"
                                                    fullWidth={true}
                                                    onChange={onChange}
                                                    name="price"
                                                    value={x.price}
                                                    disabled={!props.customerView ? props.disable : true}
                                                    label="Price (CAD)"
                                                />
                                            </LAGridItem>}

                                            {(!countryCheck && !props.customerView) && <LAGridItem xs={6} sm={6} md={6}>
                                                <LATextField
                                                    type="number"
                                                    variant="outlined"
                                                    label="$ USD Rate"
                                                    fullWidth={true}
                                                    name="price"
                                                    disabled={true}
                                                    onChange={undefinedFunction}
                                                    value={props.status === OrderReceived ? props.usdRate : props.data.usD_Rate}
                                                />
                                            </LAGridItem>}

                                            <LAGridItem xs={6} sm={6} md={6}>
                                                <LATextField
                                                    type="number"
                                                    variant="outlined"
                                                    fullWidth={true}
                                                    label="$ Item Cost"
                                                    onChange={onChange}
                                                    name="total_Cost"
                                                    disabled={true}
                                                    value={((props.customerView) || (props.status === OrderReceived)) ? x.total_Cost
                                                    : Number(countryCheck ? x.quantity * x.price : (x.quantity * x.price) * props.usdRate).toFixed(2)}
                                                />
                                            </LAGridItem>

                                        </LAGrid>
                                        <hr />
                                    </LAGridItem>

                                </LAGrid>
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>

                </LAGridItem>
            );
        })}
    </LAGrid>
);


interface IPartImageRender {
    original: string;
    thumbnail: string;
};

const getImage = (part: IGetPartsOrderByCustomerItem): IPartImageRender[] => {
    const images: IPartImageRender[] = [];

    if (part.photos_1 != null) {
        images.push({ original: part.photos_1, thumbnail: part.photos_1 });
    }
    if (part.photos_2 != null)
        images.push({ original: part.photos_2, thumbnail: part.photos_2 });

    if (part.photos_3 != null)
        images.push({ original: part.photos_3, thumbnail: part.photos_3 });

    if (images.length === ZEROTH)
        images.push({ original: Not_Available_Image, thumbnail: Not_Available_Image });

    return images;
};