import { IREGISTRATION_REQUEST, IRegistrationRequest } from "./registrationConstants";

export interface IRegistrationLoadAction {
    type: IREGISTRATION_REQUEST.REQUEST;
    data: IRegistrationRequest;
    internalAdd: boolean;
}
export const registrationLoadAction = (data: IRegistrationRequest, internalAdd: boolean): IRegistrationLoadAction => ({
    type: IREGISTRATION_REQUEST.REQUEST,
    data,
    internalAdd
});

export interface IRegistrationSuccessAction {
    type: IREGISTRATION_REQUEST.SUCCESS;
    data: IRegistrationRequest;
    internalAdd: boolean;
}
export const registrationLoadSuccessAction = (data: IRegistrationRequest, internalAdd: boolean): IRegistrationSuccessAction => ({
    type: IREGISTRATION_REQUEST.SUCCESS,
    data,
    internalAdd
});
export interface IRegistrationLoadFailedAction {
    type: IREGISTRATION_REQUEST.FAILED;
    message: string;
}
export const registrationLoadFailedAction = (message: string): IRegistrationLoadFailedAction => ({
    type: IREGISTRATION_REQUEST.FAILED,
    message
});
