import React from "react";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { DARK_GREY_COLOR } from "../../shared/theme";
import { LAGreyPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import LALinkButton from "../../shared/linkButton";
import { ONE } from "../../shared/constExports";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { getImage } from "./dismantledList";
import { LAPopover } from "../../shared/popOver";
import styled from "styled-components";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IPartDismantledEquipment } from "../../../redux/getPartsDismantledEquipments/getPartsDismantledEquipmentConstants";

interface IDismantledPartsListOwnProps {
    open: boolean;
    onClose: () => void;
    data: IPartDismantledEquipment;
    cart: { id: number, name: string }[];
};

export const PartDetailPopUp = styled(LAGreyPaper)`
    .itemPaper .qtyWrapper {
        font-size: 20px;
    };

    .itemPaper .itemImageWithThumb {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 300px;
        height: 290px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };

    .title {
        font-weight: bold;
        background-color: ${DARK_GREY_COLOR};
    };

    .itemPaper .itemImage {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 300px;
        height: auto;
        max-height: 290px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };
`;

export const DismantledPartDetailPopup: React.FC<IDismantledPartsListOwnProps> = React.memo((props: IDismantledPartsListOwnProps) => {
    const images = getImage(props.data);

    return (
        <LAPopover anchorRef={null} open={props.open} onClose={props.onClose}>
            <PartDetailPopUp>
                <LAGrid>
                    <LAGridItem xs={12}>

                        <LAPaperWithPadding className="itemPaper">
                            <LAGrid>
                                <LAGridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className={`${images.length > ONE ? "itemImageWithThumb" : "itemImage"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {

                                    }}>
                                        <ImageGallery
                                            items={images}
                                            showNav={true}
                                            autoPlay={false}
                                            showPlayButton={false}
                                            showFullscreenButton={true}
                                            showThumbnails={(images.length > ONE) ? true : false}
                                        />
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <LAGrid spacing={1}>

                                        <LAGridItem xs={12} md={12}>
                                            <LAGrid spacing={3} justify="space-between" alignItems="center">
                                                <LAGridItem xs={12} sm={6} md={6} lg={7} xl={8}>
                                                    <div className="cursor" onClick={(e: React.MouseEvent<unknown>): void => {

                                                    }}>
                                                        <LALinkButton label={`${props.data.make}`} onClick={(): void => undefined} fontSize={20} />
                                                    </div>
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={6} md={6} lg={5} xl={4}>
                                                    <LAGrid spacing={0} justify="flex-end" alignItems="flex-end">
                                                        <LAGridItem sm={1} md={5}>

                                                        </LAGridItem>

                                                        <LAGridItem sm={11} md={7}>

                                                        </LAGridItem>
                                                    </LAGrid>
                                                </LAGridItem>

                                            </LAGrid>
                                            <hr />
                                        </LAGridItem>

                                        <LAGridItem xs={12} md={12}>
                                            <LAGrid>

                                                <LAGridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <LAGrid alignItems="center" justify="space-between">

                                                    <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Serial #: </strong>
                                                            <span> {props.data.serial_No}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Unit #: </strong>
                                                            <span> {props.data.unit_No}</span>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Model: </strong>
                                                            <span> {props.data.model}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Equipment: </strong>
                                                            <span> {props.data.equipment_Type}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Location: </strong>
                                                            <span> {props.data.location}</span>
                                                        </LAGridItem>

                                                    </LAGrid>
                                                </LAGridItem>

                                            </LAGrid>
                                            <hr />
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LAGrid alignItems="center" justify="space-between">

                                                <LAGridItem xs={12}>
                                                    <h3>Removed Components (Not Available)</h3>
                                                </LAGridItem>

                                                {props.data.available_Components && props.data.available_Components.length > 0 
                                                && <LAGridItem xs={12}>
                                                    <TableContainer component={LAPaperWithLessPadding}>
                                                        <Table aria-label="Parts Grid">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className="title">Name</TableCell>
                                                                    {/* <TableCell className="title">Notes</TableCell> */}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {props.data.available_Components.map((q, index) => (
                                                                        <TableRow key={index} className="itemPaper">
                                                                            <TableCell>{q.name}</TableCell>
                                                                            <TableCell>{q.notes}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </LAGridItem>}
                                            </LAGrid>
                                            <hr />
                                        </LAGridItem>

                                        {/* <LAGridItem xs={12}>
                                            <LAGrid>
                                                <LAGridItem xs={12}>
                                                    <strong>Notes: </strong>
                                                    <span>{props.data.notes}</span>
                                                </LAGridItem>
                                            </LAGrid>
                                        </LAGridItem> */}

                                    </LAGrid>
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                    </LAGridItem>
                </LAGrid>
            </PartDetailPopUp>
        </LAPopover>
    )
});