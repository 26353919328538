import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IGetPartsCashOrderLookup, IGetPartsCashOrderLookupRequest, IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST } from "./getPartsCashOrderLookupConstants";

export interface IGetPartsCashOrderLookupLoadAction {
    type: IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST.REQUEST;
    data: IGetPartsCashOrderLookupRequest
}
export const getPartsCashOrderLookupLoadAction = (data: IGetPartsCashOrderLookupRequest): IGetPartsCashOrderLookupLoadAction => ({
    type: IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST.REQUEST,
    data
});

export interface IGetPartsCashOrderLookupSuccessAction {
    type: IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST.SUCCESS;
    response: PartsResponse<IGetPartsCashOrderLookup>;
}
export const getPartsCashOrderLookupLoadSuccessAction = (response: PartsResponse<IGetPartsCashOrderLookup>): IGetPartsCashOrderLookupSuccessAction => ({
    type: IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST.SUCCESS,
    response
});
export interface IGetPartsCashOrderLookupLoadFailedAction {
    type: IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST.FAILED;
    message: string;
}
export const getPartsCashOrderLookupLoadFailedAction = (message: string): IGetPartsCashOrderLookupLoadFailedAction => ({
    type: IGET_PARTS_CASH_ORDER_LOOKUP_REQUEST.FAILED,
    message
});
