import { Reducer, combineReducers, Dispatch } from "redux";
import { ById, PartsResponse } from "../react/shared/publicInterfaces";
import { ICart } from "./cart/cartConstants";
import { AddCartReducer, CartReducer, RemoveCartReducer } from "./cart/cartReducer";
import { IGetAllPartsOrderResponse } from "./getAllPartsOrder/getPartsOrderConstants";
import { GetAllPartsOrderReducer } from "./getAllPartsOrder/getPartsOrderReducer";
import { IAddPartsOrderResponse } from "./addPartsOrder/addPartsOrderConstants";
import { AddPartsOrderReducer } from "./addPartsOrder/addPartsOrderReducer";
import { IGetPartsOrderByCustomer } from "./getPartsOrderByCustomer/getPartsOrderByCustomerConstants";
import { GetPartsOrderByCustomerReducer } from "./getPartsOrderByCustomer/getPartsOrderByCustomerReducer";
import { ILoginResponse } from "./login/loginConstants";
import { LoginReducer } from "./login/loginReducer";
import { IPartsFacetResponse } from "./parts/facets/partsFacetConstants";
import { PartsFacetReducer } from "./parts/facets/partsFacetReducer";
import { IPart } from "./parts/getParts/getPartsConstants";
import { GetPartsReducer } from "./parts/getParts/getPartsReducer";
import { GetPartsByIdsReducer } from "./parts/getPartsByIds/getPartsByIdsReducer";
import { RegistrationReducer } from "./registration/registrationReducer";
import { Server } from "./server";
import { UpdateProfileReducer } from "./userProfile/updateProfile/updateProfileReducer";
import { UpdatePartsOrderAdminReducer } from "./updatePartsOrderAdmin/updatePartsOrderAdminReducer";
import { PartsOrderCustomerQuoteResponseReducer } from "./partsOrderCustomerQuoteResponse/partsOrderCustomerQuoteResponseReducer";
import { UpdatePartsOrderPaymentReducer } from "./updatePartsOrderPayment/updatePartsOrderPaymentReducer";
import { ContactUsReducer } from "./contactUs/contactUsReducer";
import { ICustomer } from "./getCustomers/getCustomersConstants";
import { GetCustomersReducer } from "./getCustomers/getCustomersReducer";
import { GetPartsDismantledEquipmentsReducer } from "./getPartsDismantledEquipments/getPartsDismantledEquipmentReducer";
import { IPartDismantledEquipment } from "./getPartsDismantledEquipments/getPartsDismantledEquipmentConstants";
import { GetPartsOrderReportsReducer } from "./getPartsOrderReport/getPartsOrderReportReducer";
import { IPartsOrderReport } from "./getPartsOrderReport/getPartsOrderReportConstants";
import { GetAllPartsCashOrdersReducer } from "./getAllPartsCashOrders/getAllPartsCashOrdersReducer";
import { IGetAllPartsCashOrders } from "./getAllPartsCashOrders/getAllPartsCashOrdersConstants";
import { GetPartsCashOrderLookupReducer } from "./getPartsCashOrderLookup/getPartsCashOrderLookupReducer";
import { IGetPartsCashOrderLookup } from "./getPartsCashOrderLookup/getPartsCashOrderLookupConstants";
import { AddPartsCashOrderReducer } from "./addPartsCashOrder/addPartsCashOrderReducer";
import { UpdatePartsCashOrderReducer } from "./updatePartsCashOrder/updatePartsCashOrderReducer";
import { IDismantledLookups } from "./parts/getDismantledLookups/getDismantledLookupsConstants";
import { GetDismantledLookupsReducer } from "./parts/getDismantledLookups/getDismantledLookupsReducer";
import { GetSEOKeywordsReducer } from "./getSEOKeywords/getSEOKeywordsReducer";

export interface IAction {
  type: any;
  [key: string]: any;
}

export type ReducerMap<T> = { [K in keyof T]: Reducer<T[K], IAction>; };

export type IDispatch = Dispatch<IAction>;

export interface IStore {
  cart: Server<ICart[]>;
  addCart: Server<string>;
  removeCart: Server<string>;
  registration: Server<string>;
  login: Server<ILoginResponse>;
  updateProfile: Server<string>;
  updatePartsOrderAdmin: Server<string>;
  updatePartsOrderPayment: Server<string>;
  getParts: Server<PartsResponse<IPart[]>>;
  getPartsByIds: Server<PartsResponse<IPart[]>>;
  contactUsStatus: Server<PartsResponse<string>>;
  partsOrderCustomerQuoteResponse: Server<string>;
  getCustomersStatus: Server<PartsResponse<ICustomer[]>>;
  getPartsFacet: Server<PartsResponse<IPartsFacetResponse>>;
  addPartsOrder: Server<PartsResponse<IAddPartsOrderResponse>>;
  getPartsOrderReports: Server<PartsResponse<ById<IPartsOrderReport>>>;
  getAllPartsOrder: Server<PartsResponse<IGetAllPartsOrderResponse[]>>;
  getPartsOrderByCustomer: Server<PartsResponse<IGetPartsOrderByCustomer[]>>;
  getPartsDismantledEquipments: Server<PartsResponse<IPartDismantledEquipment[]>>;
  getAllPartsCashOrders: Server<PartsResponse<IGetAllPartsCashOrders[]>>;
  getPartsCashOrderLookup: Server<PartsResponse<IGetPartsCashOrderLookup>>;
  addPartsCashOrder: Server<PartsResponse<IGetAllPartsCashOrders>>;
  updatePartsCashOrder: Server<PartsResponse<IGetAllPartsCashOrders>>;
  GetDismantledEquipmentLookups: Server<PartsResponse<IDismantledLookups>>;
  GetSEOKeywords: Server<PartsResponse<string>>;
};

const reducerMap: ReducerMap<IStore> = {
  cart: CartReducer,
  login: LoginReducer,
  addCart: AddCartReducer,
  getParts: GetPartsReducer,
  removeCart: RemoveCartReducer,
  getPartsFacet: PartsFacetReducer,
  contactUsStatus: ContactUsReducer,
  registration: RegistrationReducer,
  getPartsByIds: GetPartsByIdsReducer,
  updateProfile: UpdateProfileReducer,
  addPartsOrder: AddPartsOrderReducer,
  getCustomersStatus: GetCustomersReducer,
  getAllPartsOrder: GetAllPartsOrderReducer,
  getPartsOrderReports: GetPartsOrderReportsReducer,
  updatePartsOrderAdmin: UpdatePartsOrderAdminReducer,
  getPartsOrderByCustomer: GetPartsOrderByCustomerReducer,
  updatePartsOrderPayment: UpdatePartsOrderPaymentReducer,
  getPartsDismantledEquipments: GetPartsDismantledEquipmentsReducer,
  partsOrderCustomerQuoteResponse: PartsOrderCustomerQuoteResponseReducer,
  getAllPartsCashOrders: GetAllPartsCashOrdersReducer,
  getPartsCashOrderLookup: GetPartsCashOrderLookupReducer,
  addPartsCashOrder: AddPartsCashOrderReducer,
  updatePartsCashOrder: UpdatePartsCashOrderReducer,
  GetDismantledEquipmentLookups: GetDismantledLookupsReducer,
  GetSEOKeywords: GetSEOKeywordsReducer
};

export default combineReducers<IStore>(reducerMap);