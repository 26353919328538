import React from "react";
import { Switch, Route } from "react-router";
import Error404Page from "./error404Page";
import { ROUTE } from "./routes";
import SecuredRoutes from "./securedRoutes";

const Main: React.FC = () => {
    return <Switch>
        <Route path={ROUTE.INDEX()} component={SecuredRoutes} />
        <Route component={Error404Page} />
    </Switch>;
};

export default Main;
