import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../redux/server";
import { PartsResponse } from "../../shared/publicInterfaces";
import queryString from "query-string";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import { login } from "../../../redux/login/loginAccessor";
import { OrderdeskCheck, undefinedFunction } from "../../shared/constExports";
import LoginAndRegisterComponent from "../loginAndRegister";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { IGetAllPartsCashOrders, IGetAllPartsCashOrdersRequest } from "../../../redux/getAllPartsCashOrders/getAllPartsCashOrdersConstants";
import { getAllPartsCashOrdersLoadAction } from "../../../redux/getAllPartsCashOrders/getAllPartsCashOrdersActions";
import { getAllPartsCashOrdersStatus } from "../../../redux/getAllPartsCashOrders/getAllPartsCashOrdersAccessor";
import AddUpdateCashOrderComponent from "./cashOrder";
import { LAIconButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import PageSpacing from "../../shared/pageSpacing";


interface ICashOrdersComponentStoreProps {
    loginStatus: Server<ILoginResponse>;
    orders: Server<PartsResponse<IGetAllPartsCashOrders[]>>;
};

interface ICashOrdersComponentDispatchProps {
    ordersRequest: (data: IGetAllPartsCashOrdersRequest) => unknown;
};

interface ICashOrdersComponentState {
    openOrder: boolean;
    order: IGetAllPartsCashOrders | undefined;
};

const CashOrdersStyles = styled(LAPaperWithPadding)`
    .pull-left {
        position: absolute;
        left: 1px;
        top: 1px;
    };
`;

type ICashOrdersComponentProps =
    RouteComponentProps
    & ICashOrdersComponentStoreProps
    & ICashOrdersComponentDispatchProps;

class CashOrdersComponent extends PureComponent<ICashOrdersComponentProps, ICashOrdersComponentState> {

    public constructor(props: ICashOrdersComponentProps) {
        super(props);
        this.state = {
            openOrder: false,
            order: undefined
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.handleServerCall();
        this.setDataToState();
    };

    public async componentDidUpdate(prevProps: ICashOrdersComponentProps): Promise<void> {
        if ((this.props.orders !== prevProps.orders)) {
            this.setDataToState();
        };
    }

    public render(): ReactNode {

        const { order, openOrder } = this.state;
        const { loginStatus, orders } = this.props;

        const data = hasPayload(orders) ? orders.payload.response : [];

        return (
            <PageSpacing title="Cash Order" description="Cash Order">
            <CashOrdersStyles>
                                            <LAPaperWithPadding>
                {!openOrder && <LAGrid spacing={1}>

                    {(isNotLoaded(loginStatus)) && <LAGridItem xs={12}>
                        <LoginAndRegisterComponent {...this.props} />
                    </LAGridItem>}

                    {(hasPayload(loginStatus) && (loginStatus.payload.customer.email === OrderdeskCheck)) && <>
                        <LAGridItem xs={12}>
                        <LAIconButton
                                        className="pull-left"
                                        label="Add New"
                                        icon={<AddIcon />}
                                        onClick={this.onAddClick}
                                    />

                            <h2 className="text-center">CASH ORDERS</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                                <LADevExtremeGrid
                                    export={true}
                                    searchPanel={true}
                                    filterHeader={true}
                                    exportFileName="CashOrders"
                                    onClick={undefinedFunction}
                                    onEdit={this.onOrderClick}
                                    data={data}
                                    columns={[
                                        { name: "invoice_No", caption: "Invoice Number", type: "string" },
                                        { name: "date", caption: "Date", type: "date" },
                                        { name: "customer", caption: "Customer", type: "string" },
                                        { name: "status", caption: "Status", type: "string" },
                                        { name: "total_Items_Cost", caption: "Total Items Cost", type: "number" },
                                        { name: "tax", caption: "Tax", type: "number" },
                                        { name: "total_Cost", caption: "Total Cost", type: "number" },
                                        { name: "created_By", caption: "Created By", type: "string" },
                                        { name: "created", caption: "Created", type: "date" },
                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                        { name: "modified", caption: "Modified", type: "date" },
                                    ]}
                                />
                        </LAGridItem>

                    </>}
                </LAGrid>}

                {openOrder && <AddUpdateCashOrderComponent 
                    {...this.props}
                    order={order}
                    token={loginStatus}
                    onAddUpdateCashOrderCancel={this.onAddClick}
                />}

                </LAPaperWithPadding>
            </CashOrdersStyles>
            </PageSpacing>
        );
    }

    private onOrderClick = (e: any): void => {
        if(hasPayload(this.props.orders))
            this.setState({ openOrder: !this.state.openOrder, 
                order: this.props.orders.payload.response.find((x) => x.id === e.row.data.id) 
            });
    };

    private onAddClick = (): void => {
       this.setState({ openOrder: !this.state.openOrder, order: undefined });
    };
    
    private setDataToState = async (): Promise<void> => {
        if (isNotLoaded(this.props.orders))
            this.handleServerCall();
    };

    private handleServerCall = (): void => {
        if (hasPayload(this.props.loginStatus) && (this.props.loginStatus.payload.customer.email === OrderdeskCheck)) {
            const query: any = queryString.parse(this.props.location.search);
            this.props.ordersRequest({
                PageSize: 20,
                token: this.props.loginStatus.payload.token,
                keywords: query.keyword ? query.keyword : "",
                PageNumber: query.pageNumber ? Number(query.pageNumber) : 1
            });
        };
    };

}

const mapStateToProps = (state: IStore): ICashOrdersComponentStoreProps => ({
    loginStatus: login(state),
    orders: getAllPartsCashOrdersStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ICashOrdersComponentDispatchProps => ({
    ordersRequest: (data: IGetAllPartsCashOrdersRequest): unknown => dispatch(getAllPartsCashOrdersLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(CashOrdersComponent);