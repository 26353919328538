import { ZEROTH } from "../react/shared/constExports";
import { ICart } from "../redux/cart/cartConstants";
import { ILoginResponse } from "../redux/login/loginConstants";
// import { deleteAllIndexDB } from "../redux/reduxUtils/dexieDB";

const LOGIN_INFO = "iLogin";
const CART_INFO = "iCart";
const INDEX_DB_INFO = "indexDB";

export enum INDEX_DB_AVAILABILITY {
    UNAVAILABLE = "0",
    AVAILABLE = "1"
}

// intentionally static class defined
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class LocalStorage {
    private static localStorage: Storage = window.localStorage;

    public static setILogin = (login: ILoginResponse): void => {
        LocalStorage.localStorage.setItem(LOGIN_INFO, JSON.stringify(login));
    };

    public static setRefreshToken = (session: ILoginResponse): void => {
        const login = LocalStorage.getILogin();
        if (login) {
            login.token = session.token;
            LocalStorage.setILogin(login);
        } else { // this should not happen
            LocalStorage.localStorage.setItem(LOGIN_INFO, JSON.stringify(session));
        }
    };

    public static getILogin = (): ILoginResponse | undefined => {
        const login = LocalStorage.localStorage.getItem(LOGIN_INFO);
        if (login) {
            return JSON.parse(login);
        }
        return undefined;
    };

    public static setICart = (cart: ICart[]): void => {
        LocalStorage.localStorage.setItem(CART_INFO, JSON.stringify(cart));
    };

    public static getCart = (): ICart[] => {
        const cart = LocalStorage.localStorage.getItem(CART_INFO);
        if (cart) {
            return JSON.parse(cart);
        }
        return [];
    };

    public static addToCart = (newItem: ICart): void => {
        const cart: ICart[] = LocalStorage.getCart();
        if (cart) {
            const findInd = cart.findIndex(x => x.id === newItem.id);
            if(findInd >= ZEROTH){
                cart[findInd].quantity = newItem.quantity;
            } else {
                cart.push(newItem);
            };
            LocalStorage.setICart(cart);
        } else {
            LocalStorage.localStorage.setItem(CART_INFO, JSON.stringify([newItem]));
        };
    };

    public static removeFromCart = (id: number): void => {
        const cart: ICart[] = LocalStorage.getCart();
        if (cart) {
            const ind = cart.findIndex(x => x.id === id);
            if(ind >= ZEROTH){
                cart.splice(ind, 1);
                LocalStorage.setICart(cart);
            };
        }
    };

    public static removeAllFromCart = (): void => {
        const cart: ICart[] = [];
        LocalStorage.setICart(cart);
    };

    public static logout = (): void => {
        LocalStorage.removeAllFromCart();
        LocalStorage.removeItem(LOGIN_INFO);
        LocalStorage.setIndexDBInfo(INDEX_DB_AVAILABILITY.UNAVAILABLE);
        LocalStorage.clearIndexDBTables();
    };

    public static clearEntireLocalStorageOnLogout = (): void => {
        LocalStorage.clearLocalStorage();
    }

    public static flushData = (): void => {
        LocalStorage.setIndexDBInfo(INDEX_DB_AVAILABILITY.UNAVAILABLE); 
    };
    
    public static setIndexDBInfo = (available: INDEX_DB_AVAILABILITY): void => {
        LocalStorage.localStorage.setItem(INDEX_DB_INFO, available);
    };
    
    public static isIndexDBAvailable = (): boolean => {
        const available = LocalStorage.localStorage.getItem(INDEX_DB_INFO);
        return available === INDEX_DB_AVAILABILITY.AVAILABLE;
    };

    public static removeILogin(): void {
        this.localStorage.removeItem(LOGIN_INFO);
    }

    private static clearLocalStorage = (): void => {
        LocalStorage.localStorage.clear();
    }

    private static removeItem = (name: string): void => {
        LocalStorage.localStorage.removeItem(name);
    };

    private static clearIndexDBTables = (): void => {
        // deleteAllIndexDB();
    };
}

export default LocalStorage;