import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../flushData.tsx/flushDataActions";
import { IRegistrationSuccessAction } from "../../registration/registrationActions";
import { IREGISTRATION_REQUEST } from "../../registration/registrationConstants";
import { Server, loading, failed, succeeded, notLoaded } from "../../server";
import { IPartsFacetLoadAction, IPartsFacetLoadFailedAction, IPartsFacetSuccessAction } from "./partsFacetActions";
import { IPartsFacetResponse, IPARTS_FACET_REQUEST } from "./partsFacetConstants";


type Actions =
    IPartsFacetLoadAction
    | IPartsFacetLoadFailedAction
    | IPartsFacetSuccessAction
    | IRegistrationSuccessAction
    | IFlushDataSuccessAction;

export const PartsFacetReducer = (state: Server<PartsResponse<IPartsFacetResponse>> = notLoaded, action: Actions): Server<PartsResponse<IPartsFacetResponse>> => {
    switch (action.type) {
        case IPARTS_FACET_REQUEST.REQUEST:
            return loading;

        case IPARTS_FACET_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IREGISTRATION_REQUEST.SUCCESS:
            return notLoaded;

        case IPARTS_FACET_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};