import { ReactNode, PureComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import HeaderCart from "./headerCart";
import { BLACK_COLOR, MAIN_COLOR, WHITE_COLOR } from "../shared/theme";
import { LASecondaryButton } from "../shared/buttons";
import { ROUTE } from "../routes";
import HomeDropDown from "./homeDropDown";
import { IDispatch, IStore } from "../../redux/reducers";
import { hasPayload, Server, STATUS_ENUM } from "../../redux/server";
import { ILoginResponse } from "../../redux/login/loginConstants";
import { login } from "../../redux/login/loginAccessor";
import LAHeaderAvatar from "./headerAvatar";
import { AdminCheck, OrderdeskCheck, SalesEmail, SalesPhone, undefinedFunction } from "../shared/constExports";
import SalesTermDropDown from "./salesTermDropDown";
import SurewayConstructionDropdown from "./surewayConstruction";
import ContactUsDropDown from "./contactUsDropDown";
import ContactUsPopup from "./contactUsPopup";
import { EmailIcon, ListIcon, PhoneIcon } from "../shared/icons";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { LAMenuItem } from "../shared/dropDownMenu";
import { TermsAndConditionPopupComponent } from "../shared/termsAndConditionPopup";
import { LACrossButtonWithInWindow } from "../shared/crossButton";
import EquipmentDropDown from "./equipmentDropDown";


interface IHeaderStoreProps {
    loginStatus: Server<ILoginResponse>;
};

interface IHeaderDispatchProps {

};

interface IHeaderState {
    open: boolean;
    drawer: boolean;
    termsAndCondition: boolean;
};

type IHeaderProps =
    RouteComponentProps
    & IHeaderStoreProps
    & IHeaderDispatchProps;

class MobileHeader extends PureComponent<IHeaderProps, IHeaderState> {

    public constructor(props: IHeaderProps) {
        super(props);
        this.state = {
            open: false,
            drawer: false,
            termsAndCondition: false
        };
    }

    public componentDidMount(): void {

    };

    public componentDidUpdate(): void {

    };

    public render(): ReactNode {
        const onLogInClick = () => this.onLogInClick();
        return (
            <>
                <MobileHeaderLayout {...this.props} onDrawer={this.onDrawer} onHome={this.onHome} onLogin={onLogInClick} />

                <Drawer
                    onClose={this.onDrawer}
                    open={this.state.drawer}
                    PaperProps={{ style: { width: "80%", backgroundColor: BLACK_COLOR, color: WHITE_COLOR } }}
                >
                    <LAGrid>

                        <LAGridItem xs={12}>
                            <HomeDropDown {...this.props} onClick={this.onDrawer} />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <EquipmentDropDown onClick={this.handleDismantled} />  
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <ContactUsDropDown onClick={this.onContactUsClick} />
                        </LAGridItem>

                        {/* <LAGridItem xs={12}>
                            <SalesTermDropDown onClick={this.onTermsAndCondition} />
                        </LAGridItem> */}

                        <LAGridItem xs={12}>
                            <LAMenuItem onClick={undefinedFunction}>
                                <PhoneIcon color={WHITE_COLOR} />

                                <a href={`tel:${SalesPhone}`} style={{ textDecoration: "none", color: WHITE_COLOR }}>
                                    <span className="ml-1">
                                        {SalesPhone}
                                    </span>
                                </a>
                            </LAMenuItem>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAMenuItem onClick={undefinedFunction}>
                                <EmailIcon color={WHITE_COLOR} />

                                    <span className="ml-1">
                                    <a href={`mailto:${SalesEmail}`} style={{ textDecoration: "none", color: WHITE_COLOR }}>
                                        {SalesEmail}
                                        </a>
                                    </span>
                            </LAMenuItem>
                        </LAGridItem>                       

                        <LAGridItem xs={12}>
                            <SurewayConstructionDropdown />
                        </LAGridItem>
                    </LAGrid>



                    <LACrossButtonWithInWindow onClick={this.onDrawer} />

                </Drawer>
                {this.state.open && <ContactUsPopup open={this.state.open} onClose={this.onContactUsClick} />}
                {this.state.termsAndCondition && <TermsAndConditionPopupComponent open={this.state.termsAndCondition} tab={0} onClose={this.onTermsAndCondition} />}
            </>
        );
    }

    private onTermsAndCondition = (): void => {
        this.setState({ termsAndCondition: !this.state.termsAndCondition, drawer: false });
    };

    private onDrawer = (): void => {
        this.setState({ drawer: !this.state.drawer });
    };

    private onHome = (): void => {
        this.props.history.push(ROUTE.INDEX());
        this.onDrawer();
    };

    private handleDismantled = (): void => {
        this.props.history.push(ROUTE.DISMANTLED());
        this.onDrawer();
    };

    private onContactUsClick = (): void => {
        this.setState({ open: !this.state.open, drawer: false });
    };

    private onLogInClick = (): void => {
        this.props.history.push(ROUTE.LOGIN());
      // this.onDrawer();
    };

}

const mapStateToProps = (state: IStore): IHeaderStoreProps => ({
    loginStatus: login(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IHeaderDispatchProps => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);


const theme = createTheme({
    palette: {
        primary: {
            main: BLACK_COLOR,
        }
    },
});

interface IMobileHeaderLayout extends RouteComponentProps {
    onHome: () => void;
    onLogin: () => void;
    onDrawer: () => void;
    loginStatus: Server<ILoginResponse>;
};

const MobileHeaderLayout: React.FC<IMobileHeaderLayout> = React.memo((props: IMobileHeaderLayout) =>
    <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="primary"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={props.onDrawer}
                    >
                        <ListIcon color={MAIN_COLOR} />
                    </IconButton>
                    <Typography onClick={props.onHome} variant="h6" component="div" sx={{ flexGrow: 1 }}>

                    </Typography>
                    {(props.loginStatus.kind === STATUS_ENUM.SUCCEEDED) ?
                        <>
                            {((hasPayload(props.loginStatus) && ((props.loginStatus.payload.customer.email !== AdminCheck)
                                && (props.loginStatus.payload.customer.email !== OrderdeskCheck)))) &&
                                <LAGridItem xs={12}>
                                    <HeaderCart {...props} />
                                </LAGridItem>}

                            <LAHeaderAvatar {...props} data={props.loginStatus.payload} />
                        </>
                        : <LASecondaryButton label="LogIn" onClick={props.onLogin} />}
                </Toolbar>
            </AppBar>
        </Box>
    </ThemeProvider>
);