import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { contactUsLoadFailedAction, contactUsLoadSuccessAction, IContactUsLoadAction, IContactUsLoadFailedAction, IContactUsSuccessAction } from "./contactUsActions";
import { ICONTACT_US_REQUEST } from "./contactUsConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";

export const contactUsEpic: Epic = (
    action$: ActionsObservable<IContactUsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IContactUsSuccessAction | IContactUsLoadFailedAction> =>
    action$.ofType(ICONTACT_US_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.CONTACT_US_MESSAGE,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<string>): IContactUsSuccessAction => contactUsLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(contactUsLoadFailedAction("Unable to save your message")))
                    )
            )
        );