import React, { useState } from "react";
import styled from "styled-components";
import LAGrid from "../../shared/grid";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import LAGridItem from "../../shared/gridList";
import { STATUS_ENUM } from "../../../redux/server";
import { LAIconButton, LASecondaryButton } from "../../shared/buttons";
import LALinkButton from "../../shared/linkButton";
import { CartIcon, RemoveCartIcon } from "../../shared/icons";
import Not_Available_Image from "../../../No-Image.jpg";
import { ONE, ZEROTH } from "../../shared/constExports";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { IPart } from "../../../redux/parts/getParts/getPartsConstants";
import Gallery from "devextreme-react/gallery";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { DARK_GREY_COLOR, GREEN_COLOR, MAIN_COLOR, RED_COLOR } from "../../shared/theme";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IGetPartsOrderByCustomerItem } from "../../../redux/getPartsOrderByCustomer/getPartsOrderByCustomerConstants";


interface IPartsListOwnProps {
    data: IPart[];
    isAdmin: boolean;
    dataStatus: STATUS_ENUM;
    loginStatus: STATUS_ENUM;
    onCartClick: (part: IPart) => void;
    onPartClick: (part: IPart) => void;
    cart: { id: number, name: string }[];
};

const PartListStyles = styled.div`
    .deskView {
        display: block;
    };

    .mobView {
        display: none;
    };

    .itemPaper .qtyWrapper {
        font-size: 20px;
    };

    .disableArrows {
        .lb-button.prev {
            display: none;
        };

        .lb-button.next {
            display: none;
        };
    };

    #gallery{
        width: 100% !important;
    }

    .itemPaper .itemImageWithThumb {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 170px;
        height: 120px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        }
    };

    .itemPaper .itemImage {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 170px;
        height: 120px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };

    @media only screen and (max-width: ${1700}px) {
        .deskView {
            display: none;
        };

        .mobView {
            display: block;
        };

        .itemPaper .itemImageWithThumb {
            padding: 5px;
            border: 1px solid ${DARK_GREY_COLOR};
            max-width: 300px;
            height: 290px;
            overflow: hidden;
            text-align: center;
            img {
                width: 100%;
                height: auto;
            }
        };
    
        .itemPaper .itemImage {
            padding: 5px;
            border: 1px solid ${DARK_GREY_COLOR};
            max-width: 300px;
            height: 240px;
            overflow: hidden;
            text-align: center;
            
            img {
                width: 100%;
                height: auto;
            };
        }; 
    };
`;

export const PartList: React.FC<IPartsListOwnProps> = React.memo((props: IPartsListOwnProps) => {
    switch (props.dataStatus) {
        case STATUS_ENUM.LOADING:
            return <LAGrid alignItems="center" spacing={3} justify="space-around">
                <LAGridItem xs={12}><LALoading className="text-center" message="Loading Parts. Please Wait..." /></LAGridItem>
            </LAGrid>;

        case STATUS_ENUM.FAILED:
            return <LAGrid alignItems="center" spacing={3} justify="space-around">
                <LAGridItem xs={12}><LAErrorBox color={MAIN_COLOR} text="Sorry Failed to Load Parts. Try to refresh the window..." /></LAGridItem>
            </LAGrid>;

        case STATUS_ENUM.SUCCEEDED:
            return <PartListStyles>
                <LAGrid alignItems="center" spacing={1} justify="space-around">
                    {Object.values(props.data).length === ZEROTH && <LAGridItem xs={12}>
                        <LAErrorBox text="No records found!" />
                    </LAGridItem>}

                    <LAGridItem xs={12} className="mobView">
                        <PartsPaper {...props} />
                    </LAGridItem>

                    <LAGridItem xs={12} className="deskView">
                        <PartsGridPaper {...props} />
                    </LAGridItem>
                </LAGrid>
            </PartListStyles>

        default:
            return <></>;
    }
});


const PartsPaper: React.FC<IPartsListOwnProps> = React.memo((props: IPartsListOwnProps) => {
    const [openImageId, setImageId] = useState<number | undefined>(undefined);

    return (
        <LAGrid>
            {props.data.map((x, index) => {
                const onCart = () => props.onCartClick(x);
                const cartCheck = props.cart.findIndex(q => q.id === x.id);
                const images = getImage(x);
                const openImages = () => setImageId(x.id);
                const onCloseImage = () => setImageId(undefined);

                return (
                    <LAGridItem xs={12} key={index}>

                        <LAPaperWithPadding className="itemPaper">
                            <LAGrid>
                                <LAGridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className={`${images.length > ONE ? "itemImageWithThumb" : "itemImage"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {
                                    }}>
                                        <Gallery
                                            id="gallery"
                                            loop={true}
                                            slideshowDelay={0}
                                            dataSource={images}
                                            showNavButtons={images.length > ONE ? true : false}
                                            showIndicator={false}
                                            onItemClick={openImages}
                                        />
                                    </div>

                                    {((openImageId !== undefined) && (openImageId === x.id)) &&
                                        <div className={(images.length > ONE ? "" : "disableArrows")}>
                                        <Lightbox
                                            images={images}
                                            onClose={onCloseImage}
                                        />
                                        </div>
                                    }
                                </LAGridItem>

                                <LAGridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <LAGrid spacing={1}>

                                        <LAGridItem xs={12} md={12}>
                                            <LAGrid spacing={3} justify="space-between" alignItems="center">
                                                <LAGridItem xs={12} sm={6} md={6} lg={7} xl={8}>
                                                    <div className="cursor" onClick={(e: React.MouseEvent<unknown>): void => {
                                                    }}>
                                                        <LALinkButton label={`${x.name}`} onClick={(): void => undefined} fontSize={20} />
                                                    </div>
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={6} md={6} lg={5} xl={4}>
                                                    <LAGrid spacing={0} justify="flex-end" alignItems="flex-end">
                                                        <LAGridItem sm={1} md={5}>

                                                        </LAGridItem>

                                                        <LAGridItem sm={11} md={7}>
                                                            {(props.loginStatus === STATUS_ENUM.SUCCEEDED && !props.isAdmin) && <LASecondaryButton
                                                                onClick={onCart}
                                                                label={cartCheck >= 0 ? "Remove" : "Add To"}
                                                                endIcon={cartCheck >= 0 ? <RemoveCartIcon color={RED_COLOR} /> : <CartIcon color={GREEN_COLOR} />}
                                                            />}
                                                        </LAGridItem>
                                                    </LAGrid>
                                                </LAGridItem>

                                            </LAGrid>
                                            <hr />
                                        </LAGridItem>

                                        <LAGridItem xs={12} md={12}>
                                            <LAGrid>

                                                <LAGridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <LAGrid alignItems="center" justify="space-between">

                                                        <LAGridItem xs={12} className="qtyWrapper">
                                                            <strong className="">Part Number: </strong>
                                                            <span className=""> {x.part_No}</span>
                                                        </LAGridItem>



                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong className="">Quantity: </strong>
                                                            <span className=""> {x.available_Quantity}</span>
                                                        </LAGridItem>

                                                        {/* <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Year: </strong>
                                                            <span className=""> {x.year}</span>
                                                        </LAGridItem> */}

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Make: </strong>
                                                            <span className=""> {x.make}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Model: </strong>
                                                            <span className=""> {x.model}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Condition: </strong>
                                                            <span className=""> {x.condition}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Type: </strong>
                                                            <span className=""> {x.type}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Weight: </strong>
                                                            <span className=""> {x.weight}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Location: </strong>
                                                            <span className=""> {x.location}</span>
                                                        </LAGridItem>

                                                    </LAGrid>
                                                </LAGridItem>


                                                <LAGridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <LAGrid alignItems="center" justify="space-between">

                                                    </LAGrid>
                                                </LAGridItem>


                                            </LAGrid>
                                            <hr />
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LAGrid>
                                                <LAGridItem xs={12}>
                                                    {x.description}
                                                </LAGridItem>

                                                {/* <LAGridItem xs={12}>
                                                    <strong>Notes: </strong>
                                                    <span>{x.notes}</span>
                                                </LAGridItem> */}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                    </LAGridItem>
                );
            })}
        </LAGrid>
    )
});

const PartsGridPaperStyles = styled("div")`
    .title {
        font-weight: bold;
        background-color: ${DARK_GREY_COLOR};
    };
`;

const PartsGridPaper: React.FC<IPartsListOwnProps> = React.memo((props: IPartsListOwnProps) => {
    const [openImageId, setImageId] = useState<number | undefined>(undefined);

    return <PartsGridPaperStyles>
        <TableContainer component={LAPaperWithLessPadding}>
            <Table aria-label="Parts Grid">
                <TableHead>
                    <TableRow>
                        <TableCell className="title" align="center"></TableCell>
                        <TableCell className="title" align="center">Make</TableCell>
                        <TableCell className="title" align="center">Model</TableCell>
                        <TableCell className="title" align="center">Name</TableCell>
                        <TableCell className="title" align="center">Part No</TableCell>
                        <TableCell className="title" align="center">Condition</TableCell>
                        <TableCell className="title" align="center">Weight</TableCell>
                        <TableCell className="title" align="center">Details</TableCell>
                        {(props.loginStatus === STATUS_ENUM.SUCCEEDED && !props.isAdmin) &&
                            <TableCell className="title" align="center">Cart</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((part) => {
                        const images = getImage(part);
                        const onCartClick = () => props.onCartClick(part);
                        const onPartClick = () => props.onPartClick(part);
                        const cartCheck = props.cart.findIndex(q => q.id === part.id);

                        const openImages = () => setImageId(part.id);
                        const onCloseImage = () => setImageId(undefined);

                        return (
                            <TableRow key={part.id} className="itemPaper">
                                <TableCell align="center" component="th" scope="row">
                                    <div className={`${images.length > ONE ? "itemImageWithThumb" : "itemImage"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {
                                    }}>
                                        <Gallery
                                            id="gallery"
                                            loop={true}
                                            height={150}
                                            slideshowDelay={0}
                                            dataSource={images}
                                            showIndicator={false}
                                            onItemClick={openImages}
                                            showNavButtons={images.length > ONE ? true : false}
                                        />
                                    </div>

                                    {((openImageId !== undefined) && (openImageId === part.id)) &&
                                    <div className={(images.length > ONE ? "" : "disableArrows")}>
                                        <Lightbox
                                            images={images}
                                            onClose={onCloseImage}
                                        />
                                        </div>}
                                </TableCell>

                                <TableCell align="center">{part.make}</TableCell>
                                <TableCell align="center">{part.model}</TableCell>
                                <TableCell align="center">{part.name}</TableCell>
                                <TableCell align="center">{part.part_No}</TableCell>
                                <TableCell align="center">{part.condition}</TableCell>
                                <TableCell align="center">{part.weight}</TableCell>
                                <TableCell align="center">
                                    <LASecondaryButton
                                        onClick={onPartClick}
                                        label="More Info" />
                                </TableCell>
                                {(props.loginStatus === STATUS_ENUM.SUCCEEDED && !props.isAdmin) && <TableCell align="center">
                                    <LAIconButton
                                        onClick={onCartClick}
                                        label={cartCheck >= 0 ? "Remove" : "Add To"}
                                        icon={cartCheck >= 0 ? <RemoveCartIcon color={RED_COLOR} /> : <CartIcon color={GREEN_COLOR} />} />
                                </TableCell>}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </PartsGridPaperStyles>
}
);

export const getImage = (part: IPart | IGetPartsOrderByCustomerItem): any[] => {
    const images: any[] = [];

    if (part.photos_1 != null) {
        images.push(part.photos_1);
    }
    if (part.photos_2 != null)
        images.push(part.photos_2);

    if (part.photos_3 != null)
        images.push(part.photos_3);

    if (images.length === ZEROTH)
        images.push(Not_Available_Image);

    return images;
};
