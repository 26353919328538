import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { ById, PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";
import { getPartsOrderReportsLoadFailedAction, getPartsOrderReportsLoadSuccessAction, IGetPartsOrderReportsLoadAction, IGetPartsOrderReportsLoadFailedAction, IGetPartsOrderReportsSuccessAction } from "./getPartsOrderReportActions";
import { IGET_PARTS_ORDER_REPORTS_REQUEST, IPartsOrderReport } from "./getPartsOrderReportConstants";

export const getPartsOrderReportsEpic: Epic = (
    action$: ActionsObservable<IGetPartsOrderReportsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPartsOrderReportsSuccessAction | IGetPartsOrderReportsLoadFailedAction> =>
    action$.ofType(IGET_PARTS_ORDER_REPORTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<ById<IPartsOrderReport>>>(
                    END_POINTS.GET_PARTS_ORDERS_REPORT,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<ById<IPartsOrderReport>>): IGetPartsOrderReportsSuccessAction => getPartsOrderReportsLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(getPartsOrderReportsLoadFailedAction("Unable to get customers")))
                    )
            )
        );