import { PureComponent, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popover, { PopoverOrigin } from "@material-ui/core/Popover";
import styled from "styled-components";
import { LAPaper } from "../shared/paper";
import { RED_COLOR, WHITE_COLOR, YELLOW_COLOR } from "../shared/theme";
import { CartIcon, RemoveCartIcon } from "../shared/icons";
import { IDispatch, IStore } from "../../redux/reducers";
import LALinkButton from "../shared/linkButton";
import { LAIconButton, LASecondaryButton } from "../shared/buttons";
import { undefinedFunction, ZEROTH } from "../shared/constExports";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { ROUTE } from "../routes";
import { ICart } from "../../redux/cart/cartConstants";
import { hasPayload, isNotLoaded, Server } from "../../redux/server";
import { cartLoadAction, removeCartLoadAction } from "../../redux/cart/cartActions";
import { getCart } from "../../redux/cart/cartAccessor";
import Badge from "@material-ui/core/Badge";


interface IHeaderCartState {
    open: boolean;
    openSwitch: boolean;
};

interface ILoginStoreProps {
    cart: Server<ICart[]>;
};

interface IUserDataDispatchProps {
    cartRequest: () => unknown;
    removeCartRequest: (id: number) => unknown;
};

type IHeaderCartProps =
    RouteComponentProps
    & ILoginStoreProps
    & IUserDataDispatchProps;


const HeaderPopOverButtonStyles = styled.div`
    .heading-container {
        background-color: ${WHITE_COLOR};
        color: ${YELLOW_COLOR};
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        padding: 0.5rem;
    };

    .container {
        text-align: center;
    };
`;

const anchorPosition = { top: 60, left: 1700 };
const anchorOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "right",
};

const HeaderCartStyles = styled.div`
`;

class HeaderCart extends PureComponent<IHeaderCartProps, IHeaderCartState> {

    public constructor(props: IHeaderCartProps) {
        super(props);
        this.state = {
            open: false,
            openSwitch: false,
        };
    };

    public componentDidMount(): void {
        this.props.cartRequest();
    };

    public componentDidUpdate(prevProps: IHeaderCartProps): void {
        if(isNotLoaded(this.props.cart)){
            this.props.cartRequest();
        };
    };

    public render(): ReactNode {
        return (
            <HeaderCartStyles>
                <Badge badgeContent={hasPayload(this.props.cart) ? this.props.cart.payload.length : ZEROTH} color="error">
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <div className="cursor" onClick={this.handleClick}>
                        <CartIcon color={RED_COLOR} />
                    </div>
                </ClickAwayListener>

                <Popover open={this.state.open}
                    anchorReference="anchorPosition"
                    anchorPosition={anchorPosition}
                    anchorOrigin={anchorOrigin}>
                    <LAPaper>

                        <HeaderPopOverButtonStyles>
                            <div className="heading-container">
                                PARTS
                            </div>

                            <div className="container">
                                {this.GetParts()}
                            </div>
                        </HeaderPopOverButtonStyles>

                        <LASecondaryButton fullWidth={true} label="View Cart / CheckOut" onClick={this.handleViewCart} />

                    </LAPaper>
                </Popover>
                </Badge>
            </HeaderCartStyles>
        );
    };

    private handleViewCart = (): void => {
        this.props.history.push(ROUTE.CHECKOUT());
    };

    private handleClickAway = (): void => {
        this.setState({ open: false, openSwitch: false });
    };

    private handleClick = (): void => {
        this.setState((state) => ({ open: !state.open }));
    };

    private GetParts = (): JSX.Element => {
        const arry: ICart[] = hasPayload(this.props.cart) ? this.props.cart.payload : [];
            return <LAGrid className="text-center">
                {arry.map((x) => {
                    // const onClick = () => callRouteWithQueryString({ route: this.props, pathName: ROUTE.PATIENT.INDEX(), search: { id: x.id } });
                    return (
                        <LAGridItem xs={12} key={x.id}>
                            <LALinkButton key={x.id} label={x.name} onClick={undefinedFunction} />
                            <LAIconButton label="Remove" icon={<RemoveCartIcon color={RED_COLOR} />} onClick={() => this.removePart(x.id)} />
                            <br />
                        </LAGridItem>
                    );
                })}
            </LAGrid>
    };


    private removePart = (id: number): void => {
       this.props.removeCartRequest(id);
       
    };

}

const mapStateToProps = (state: IStore): ILoginStoreProps => ({
    cart: getCart(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IUserDataDispatchProps => ({
    cartRequest: (): unknown => dispatch(cartLoadAction()),
    removeCartRequest: (id: number): unknown => dispatch(removeCartLoadAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCart);
