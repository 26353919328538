import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAGreyPaperWithLessPadding, LAPaperWithLessPadding } from "./shared/paper";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../redux/server";
import { HasClass, PartsResponse } from "./shared/publicInterfaces";
import { ILoginResponse } from "../redux/login/loginConstants";
import LAGrid from "./shared/grid";
import LAGridItem from "./shared/gridList";
import LAAutoComplete from "./shared/autoComplete";
import SearchBox from "./shared/searchBox";
import { ROUTE } from "./routes";
import { LAButton } from "./shared/buttons";
import { END_POINTS } from "../redux/endpoints";
import PageSpacing from "./shared/pageSpacing";
import { MEDIA_QUERY_PHONE } from "./shared/theme";
import { login } from "../redux/login/loginAccessor";
import { IDispatch, IStore } from "../redux/reducers";
import LALinkButton from "./shared/linkButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getPartsFacet } from "../redux/parts/facets/partsFacetAccessor";
import { partsFacetLoadAction } from "../redux/parts/facets/partsFacetActions";
import { getSEOKeywordsStatus } from "../redux/getSEOKeywords/getSEOKeywordsAccessor";
import { IGetSEOKeywordsRequest } from "../redux/getSEOKeywords/getSEOKeywordsConstants";
import { getSEOKeywordsLoadAction } from "../redux/getSEOKeywords/getSEOKeywordsActions";
import { callRouteWithQueryString, undefinedFunction, ZEROTH } from "./shared/constExports";
import { IGetPartsFacetRequest, IManufactureFacet, IModelFacet, IPartsFacetResponse } from "../redux/parts/facets/partsFacetConstants";
import cat from "../img/cat.jpg";
import volvo from "../img/volvo.jpg";
import komatsu from "../img/komatsu.jpg";
import hitachi from "../img/hitachi.jpg";
import dynapac from "../img/dynapac.jpg";
import john_deere from "../img/john_deere.jpg";
import PhotoAlbum from "react-photo-album";


interface IDesktopHomeComponentStoreProps {
    loginStatus: Server<ILoginResponse>;
    seoKeywords: Server<PartsResponse<string>>;
    partsFacet: Server<PartsResponse<IPartsFacetResponse>>;
};

interface IDesktopHomeComponentDispatchProps {
    partsFacetRequest: (data: IGetPartsFacetRequest) => unknown;
    getSEOKeywordsRequest: (data: IGetSEOKeywordsRequest) => unknown;
};

interface IDesktopHomeComponentOwnProps extends HasClass {

};

interface IPhoto {
    src: string;
    alt?: string;
    width: number;
    height: number;
};

interface IDesktopHomeComponentState {
    photos: IPhoto[];
    searchText: string;
    filterType: "parts" | "dismantled";
    selectedFacet: IPartsFacetResponse;
};

const DesktopHomeStyles = styled.div`

    .filter-img {
        padding: 4px;
        width: auto;
        max-width: 100%;
        max-height: 100px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    };

    .makeTilesText{
        text-align: left;
        padding: 5px;
    };

    .makeTiles {
        min-height: 90%;
        max-height: 90%;
    };

    .search-box {
        margin: 20px !important;
        left: 50%;
        font-size: 15px;
        position: absolute;
        transform: translate(-50%, 10%);
        width: 60%;
    };

    .filters {
        left: 50%;
        margin-top: 270px !important;
        transform: translate(-50%, 15%);
    };

    .filter-clickable {
        cursor: pointer;
    };

    .text-color {
        color: #8b0000;
        text-align: center;
        font-weight: bold;
    };

    .desktop-header {
        display: flex;
        align-items: center;
        .right-side {
            margin-left: auto;
            margin-right: 1rem;
        };
    };

    .react-photo-album{
        margin-top: 200px !important;
    };

    .desktop-header-left {
        display: flex;
        align-items: left;
        float: left;        
    };

    .desktop-header-right {       
        float: right;
    };

    .logo-img {
        padding-left: 70px;
        width: 190px;
        height: 60px;
        padding-top: 10px;
    };

    .pull-left {
        position: absolute;
        left: 1px;
        top: 1px;
        padding: 15px;
    };
   
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .media {
            display: none;            
        };

        .search-box {
            font-size: 10px;
            transform: translate(-50%, -5%);
        };

        .filters {
            transform: translate(-50%, 15%);
        };

        .logo-img {
            padding-left: 35px;
            width: 190px;
            height: 60px;
        };

        .desktop-header-right {       
            font-size: 8px;
            float: none;
          
        };

        .desktop-header-right::before{
            content: " ";
            white-space: pre;
        }
    };
`;

type IDesktopHomeComponentProps =
    RouteComponentProps
    & IDesktopHomeComponentOwnProps
    & IDesktopHomeComponentStoreProps
    & IDesktopHomeComponentDispatchProps;

class DesktopHomeComponent extends PureComponent<IDesktopHomeComponentProps, IDesktopHomeComponentState> {

    public constructor(props: IDesktopHomeComponentProps) {
        super(props);
        this.state = {
            photos: [],
            searchText: "",
            filterType: "parts",
            selectedFacet: { manufacturers: [], models: [] }
        };
    }

    public async componentDidMount(): Promise<void> {
        this.props.getSEOKeywordsRequest({ request: { page: "home" } });
        this.setDataToState();
        this.props.history.push(ROUTE.INDEX());
        this.props.history.push(ROUTE.INDEX());
        this.props.history.push(ROUTE.INDEX());
        this.props.history.push(ROUTE.INDEX());
    };

    public render(): ReactNode {
        const { partsFacet, seoKeywords } = this.props;
        const { searchText, selectedFacet, filterType } = this.state;

        const onSearch = () => this.keyWordSearch(filterType === "dismantled" ? true : undefined);
const tileMakes = ['CAT', 'Volvo', 'John Deere', 'Komatsu'];
        return (
            <PageSpacing title="Home" description="Home" keywords={hasPayload(seoKeywords) ? seoKeywords.payload.response : undefined}>
                <DesktopHomeStyles>

                    <LAGrid alignItems="center">

                        <LAGridItem xs={12} sm={12} md={4} lg={4}>
                            <LAGreyPaperWithLessPadding className="search-box">
                                <LAGrid spacing={0} justify="space-evenly" alignItems="center">

                                    <LAGridItem xs={12}>
                                        <h1 className="text-color">Heavy Equipment Parts</h1>
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={12} md={6}>
                                        <LAAutoComplete
                                            name="Make"
                                            multiple={false}
                                            autoHighlight={true}
                                            getOptionLabel="manufacturer"
                                            dropDownPlaceHolder="Make"
                                            onChange={this.handleManufacturerFilter}
                                            filterSelectedOptions={true}
                                            selectionRemove={undefinedFunction}
                                            option={hasPayload(partsFacet) ? partsFacet.payload.response.manufacturers : []}
                                            value={(hasPayload(partsFacet) && selectedFacet.manufacturers[0]) ?
                                                partsFacet.payload.response.manufacturers.find(x => selectedFacet.manufacturers[0].manufacturerID === x.manufacturerID)
                                                : {}}
                                            defaultValue={(hasPayload(partsFacet) && selectedFacet.manufacturers[0]) ?
                                                partsFacet.payload.response.manufacturers.find(x => selectedFacet.manufacturers[0].manufacturerID === x.manufacturerID)
                                                : {}}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={12} md={6}>
                                        <LAAutoComplete
                                            name="model"
                                            multiple={false}
                                            autoHighlight={true}
                                            getOptionLabel="model"
                                            dropDownPlaceHolder="Model"
                                            onChange={this.handleModelFilter}
                                            filterSelectedOptions={true}
                                            selectionRemove={undefinedFunction}
                                            disabled={selectedFacet.manufacturers.length === 0 ? true : undefined}
                                            option={hasPayload(partsFacet) ? partsFacet.payload.response.models : []}
                                            value={(hasPayload(partsFacet) && selectedFacet.models[0]) ?
                                                partsFacet.payload.response.models.find(x => selectedFacet.models[0].model === x.model)
                                                : {}}
                                            defaultValue={(hasPayload(partsFacet) && selectedFacet.models[0]) ?
                                                partsFacet.payload.response.models.find(x => selectedFacet.models[0].model === x.model)
                                                : {}}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={12} md={12} >
                                        <SearchBox
                                            fullWidth={true}
                                            text={searchText}
                                            placeHolder="Part # or Part Name"
                                            onSubmit={this.keyWordSearch}
                                            searchStatus={STATUS_ENUM.SUCCEEDED}
                                            onChange={this.handleSearchTextChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={12} md={6}>
                                        <LAButton label="Search / View Inventory" fullWidth={true} onClick={onSearch} />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={12} md={6}>
                                        <LAButton label="Reset" fullWidth={true} onClick={this.resetClick} />
                                    </LAGridItem>
                                </LAGrid>
                            </LAGreyPaperWithLessPadding>
                        </LAGridItem>

                        {hasPayload(partsFacet) && <LAGridItem xs={12}>
                            <LAPaperWithLessPadding className="filters">

                                <LAGrid spacing={2}>

                                    <LAGridItem xs={12}>

                                        <FormControl className="pull-left">
                                            <FormLabel id="filter-type">Search for</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="filter-type"
                                                name="row-radio-buttons-group"
                                                value={filterType}
                                                onChange={this.handleType}
                                            >
                                                <FormControlLabel value="parts" control={<Radio />} label="All Parts" />
                                                <FormControlLabel value="dismantled" control={<Radio />} label="Dismantled Equipment" />
                                            </RadioGroup>
                                        </FormControl>

                                        <h4 className="text-color makeTilesText">Filter By Make</h4>
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LAGrid spacing={2}>
                                            {partsFacet.payload.response.manufacturers
                                            .filter(name => tileMakes.includes(name.manufacturer))
                                                .map((q) => {
                                                    const onFilter = (): Promise<void> => this.handleFilterImageClick(null, q);
                                                    return (                                                        
                                                        <LAGridItem xs={4} sm={3} md={2} lg={1} key={q.manufacturerID} className="filter-clickable" onClick={onFilter}>
                                                            <LAPaperWithLessPadding className="text-center makeTiles">
                                                                {this.getImage(q.manufacturer)}
                                                                <LALinkButton
                                                                    onClick={onFilter}
                                                                    label={q.manufacturer}
                                                                />
                                                            </LAPaperWithLessPadding>
                                                        </LAGridItem>
                                                    );
                                                })}
                                        </LAGrid>

                                    </LAGridItem>
                                </LAGrid>

                            </LAPaperWithLessPadding>
                        </LAGridItem>}
                        <PhotoAlbum layout="rows" photos={this.state.photos} />
                    </LAGrid>
                </DesktopHomeStyles>
            </PageSpacing>
        );
    }

    private handleType = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const val: any = event.target.value;
        this.setState({ filterType: val });
    };

    private getImage = (name: string): JSX.Element => {
        switch (name) {
            case "Volvo":
                return <img className="filter-img" alt="Volvo" src={volvo} />

            case "CAT":
                return <img className="filter-img" alt="Cat" src={cat} />

            case "Komatsu":
                return <img className="filter-img" alt="Komatsu" src={komatsu} />

            case "Hitachi":
                return <img className="filter-img" alt="Hitachi" src={hitachi} />

            case "Dynapac":
                return <img className="filter-img" alt="Dynapac" src={dynapac} />

            case "John Deere":
                return <img className="filter-img" alt="John_Deere" src={john_deere} />

            default:
                return <h3>{name}</h3>
        }
    }

    private resetClick = (): void => {
        this.setState({ selectedFacet: { manufacturers: [], models: [] }, searchText: "" });
    };

    private handleFilterImageClick = async (event: unknown, value: IManufactureFacet): Promise<void> => {
        if (value === null) {
            await this.setState({ selectedFacet: { manufacturers: [], models: this.state.selectedFacet.models } });
        } else {
            await this.setState({ selectedFacet: { manufacturers: [value], models: this.state.selectedFacet.models } });
        };

        this.keyWordSearch(this.state.filterType === "dismantled" ? true : undefined);
    };

    private handleManufacturerFilter = (event: unknown, value: IManufactureFacet): void => {
        if (value === null) {
            this.setState({ selectedFacet: { manufacturers: [], models: this.state.selectedFacet.models } });
        } else {
            this.setState({ selectedFacet: { manufacturers: [value], models: this.state.selectedFacet.models } });
            this.props.partsFacetRequest({ token: hasPayload(this.props.loginStatus) ? this.props.loginStatus.payload.token : "", request: { Makes: [value.manufacturerID] } });
        };
    };

    private handleModelFilter = (event: unknown, value: IModelFacet): void => {
        if (value === null) {
            this.setState({ selectedFacet: { manufacturers: this.state.selectedFacet.manufacturers, models: [] } });
        } else {
            this.setState({ selectedFacet: { manufacturers: this.state.selectedFacet.manufacturers, models: [value] } });
        };
    };

    private keyWordSearch = async (dismantled?: true): Promise<void> => {
        const selMake = this.state.selectedFacet.manufacturers[0] != null ? this.state.selectedFacet.manufacturers[0].manufacturer : ""
        await callRouteWithQueryString({
            route: this.props, pathName: dismantled ? ROUTE.DISMANTLED() : ROUTE.PARTS(),
            search: { keyword: this.state.searchText, filter: dismantled ? { Make: selMake, Equipment: "" }
                : this.state.selectedFacet, pageNumber: 1, pageSize: 20 }
        });
    };

    private handleSearchTextChange = async (searchTextString: string): Promise<void> => {
        this.setState({ searchText: searchTextString });
    };

    private setDataToState = (): void => {
        this.getPhotos();

        if (isNotLoaded(this.props.partsFacet))
            this.props.partsFacetRequest({ token: hasPayload(this.props.loginStatus) ? this.props.loginStatus.payload.token : "", request: { Makes: [] } });
    };

    private getPhotos = (): void => {
        if (this.state.photos.length === ZEROTH) {
            fetch(END_POINTS.GET_HOME_PAGE_PHOTOS, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(async (res: any) => {
                    let photos: IPhoto[] = [];
                    await res.response.forEach((x: string) => {
                        const src = x.split(";")[0];
                        const alt = x.split(";")[1];
                        photos.push({
                            src: src,
                            alt: alt && alt.length > 0 ? alt : undefined,
                            width: 600,
                            height: 400
                        })
                    });
                    if (photos.length > 0) {
                        photos.pop();
                    }
                    this.setState({ photos });
                })
                .catch((res) => console.log(res));
        };
    };

}

const mapStateToProps = (state: IStore): IDesktopHomeComponentStoreProps => ({
    loginStatus: login(state),
    partsFacet: getPartsFacet(state),
    seoKeywords: getSEOKeywordsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IDesktopHomeComponentDispatchProps => ({
    partsFacetRequest: (data: IGetPartsFacetRequest): unknown => dispatch(partsFacetLoadAction(data)),
    getSEOKeywordsRequest: (data: IGetSEOKeywordsRequest): unknown => dispatch(getSEOKeywordsLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(DesktopHomeComponent);