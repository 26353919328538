import React from "react";
import Link from "@material-ui/core/Link";
import styled from "styled-components";
import { HasClass } from "./publicInterfaces";
import { EXTRA_DARK_GREY_COLOR, RED_COLOR } from "./theme";

interface ILALinkButtonProps extends HasClass {
    label: string;
    color?: colorOptions;
    onClick: () => void;
    badgeCount?: number;
    fontSize?: number;
    icon?: React.ReactNode;
};

type colorOptions = "primary" | "inherit";

const DEFAULT_COLOR = "secondary";

const LinkButtonStyles = styled.div`

    .linkButtonLabel:hover {
        color: ${RED_COLOR}
    };

    span {
        color: ${EXTRA_DARK_GREY_COLOR}
    };

    word-break: break-all;
`;


const LALinkButton: React.FC<ILALinkButtonProps> = (props: ILALinkButtonProps): JSX.Element =>       
    <Link
        className={props.className}
        component="button"
        variant="body2"
        color={props.color || DEFAULT_COLOR}
        onClick={props.onClick}
    >
        <LinkButtonStyles>
            <div className="linkButtonLabel" style={props.fontSize ? {fontSize: `${props.fontSize}px`, fontWeight: "bold", textAlign: `left`} : {textAlign: `left`}}>
                {props.label}{props.icon}
                {props.badgeCount !== undefined ? 
                    <span className="ml-1">
                        {`(${ props.badgeCount.toString()})`}
                    </span>
                    : ""}
            </div>
        </LinkButtonStyles>
    </Link>
;

export default LALinkButton;
