import React from "react";
import { LASaveAndCancelButton } from "../../shared/buttons";
import { IFieldErrorKeyValue } from "../../shared/fieldValidation";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAPaperWithPadding } from "../../shared/paper";
import { LAPopover } from "../../shared/popOver";
import { ById } from "../../shared/publicInterfaces";
import LATextField from "../../shared/textField";

interface IRejectQuoteReasonPopup {
    open: boolean;
    value: string;
    onSave: () => void;
    onClose: () => void;
    errors: ById<IFieldErrorKeyValue>;
    onChange: (name: string, value: string) => void;
}

export const RejectQuoteReasonPopup: React.FC<IRejectQuoteReasonPopup> = React.memo((props: IRejectQuoteReasonPopup) =>
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null} anchorOrigin={{ horizontal: "center", vertical: "center" }} transformOrigin={{ horizontal: "center", vertical: "center" }}>
        <LAPaperWithPadding>
            <LAGrid>

                <LAGridItem xs={12}>
                    <LATextField
                        name="notes"
                        label="Reason"
                        fullWidth={true}
                        variant="outlined"
                        value={props.value}
                        onChange={props.onChange} 
                        errorText={props.errors["notes"] ? props.errors["notes"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LASaveAndCancelButton
                        onSave={props.onSave}
                        onCancel={props.onClose}
                    />
                </LAGridItem>

            </LAGrid>
        </LAPaperWithPadding>
    </LAPopover>
);