import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IPARTS_FACET_REQUEST, IGetPartsFacetRequest, IPartsFacetResponse } from "./partsFacetConstants";

export interface IPartsFacetLoadAction {
    type: IPARTS_FACET_REQUEST.REQUEST;
    data: IGetPartsFacetRequest
}
export const partsFacetLoadAction = (data: IGetPartsFacetRequest): IPartsFacetLoadAction => ({
    type: IPARTS_FACET_REQUEST.REQUEST,
    data
});

export interface IPartsFacetSuccessAction {
    type: IPARTS_FACET_REQUEST.SUCCESS;
    list: PartsResponse<IPartsFacetResponse>;
}
export const partsFacetLoadSuccessAction = (list: PartsResponse<IPartsFacetResponse>): IPartsFacetSuccessAction => ({
    type: IPARTS_FACET_REQUEST.SUCCESS,
    list
});
export interface IPartsFacetLoadFailedAction {
    type: IPARTS_FACET_REQUEST.FAILED;
    message: string;
}
export const partsFacetLoadFailedAction = (message: string): IPartsFacetLoadFailedAction => ({
    type: IPARTS_FACET_REQUEST.FAILED,
    message
});
