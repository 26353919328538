import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IGetAllPartsCashOrders, IGetAllPartsCashOrdersRequest, IGET_ALL_PARTS_CASH_ORDERS_REQUEST } from "./getAllPartsCashOrdersConstants";

export interface IGetAllPartsCashOrdersLoadAction {
    type: IGET_ALL_PARTS_CASH_ORDERS_REQUEST.REQUEST;
    data: IGetAllPartsCashOrdersRequest
}
export const getAllPartsCashOrdersLoadAction = (data: IGetAllPartsCashOrdersRequest): IGetAllPartsCashOrdersLoadAction => ({
    type: IGET_ALL_PARTS_CASH_ORDERS_REQUEST.REQUEST,
    data
});

export interface IGetAllPartsCashOrdersSuccessAction {
    type: IGET_ALL_PARTS_CASH_ORDERS_REQUEST.SUCCESS;
    response: PartsResponse<IGetAllPartsCashOrders[]>;
}
export const getAllPartsCashOrdersLoadSuccessAction = (response: PartsResponse<IGetAllPartsCashOrders[]>): IGetAllPartsCashOrdersSuccessAction => ({
    type: IGET_ALL_PARTS_CASH_ORDERS_REQUEST.SUCCESS,
    response
});
export interface IGetAllPartsCashOrdersLoadFailedAction {
    type: IGET_ALL_PARTS_CASH_ORDERS_REQUEST.FAILED;
    message: string;
}
export const getAllPartsCashOrdersLoadFailedAction = (message: string): IGetAllPartsCashOrdersLoadFailedAction => ({
    type: IGET_ALL_PARTS_CASH_ORDERS_REQUEST.FAILED,
    message
});
