import React from "react";
import LAGrid from "../../shared/grid";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import LAGridItem from "../../shared/gridList";
import { STATUS_ENUM } from "../../../redux/server";
import { getDate, ZEROTH } from "../../shared/constExports";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { DARK_GREY_COLOR, MAIN_COLOR } from "../../shared/theme";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { LAButton } from "../../shared/buttons";
import { IGetAllPartsOrderResponse } from "../../../redux/getAllPartsOrder/getPartsOrderConstants";
import styled from "styled-components";

interface IAdminOrdersListOwnProps {
    dataStatus: STATUS_ENUM;
    data: IGetAllPartsOrderResponse[];
    onClick: (index: number, id: number) => void;
};

export const OrderList: React.FC<IAdminOrdersListOwnProps> = React.memo((props: IAdminOrdersListOwnProps) => {
    switch (props.dataStatus) {
        case STATUS_ENUM.LOADING:
            return <LAGrid alignItems="center" spacing={3} justify="space-around">
                <LAGridItem xs={12}><LALoading className="text-center" message="Loading your Admin Orders. Please Wait..." /></LAGridItem>
            </LAGrid>;

        case STATUS_ENUM.FAILED:
            return <LAGrid alignItems="center" spacing={3} justify="space-around">
                <LAGridItem xs={12}><LAErrorBox color={MAIN_COLOR} text="Sorry Failed to Load Admin Orders. Try to refresh the window..." /></LAGridItem>
            </LAGrid>;

        case STATUS_ENUM.SUCCEEDED:
            return <LAGrid alignItems="center" spacing={1} justify="space-around">
                    {Object.values(props.data).length === ZEROTH && <LAGridItem xs={12}>
                        <LAErrorBox text="No records found!" />
                    </LAGridItem>}

                    <LAGridItem xs={12}>
                        <AdminOrdersGridPaper {...props} />
                    </LAGridItem>
                </LAGrid>

        default:
            return <></>;
    }
});

const AdminOrdersGridPaperStyles = styled("div")`
    .title {
        font-weight: bold;
        background-color: ${DARK_GREY_COLOR};
    };
`;

const AdminOrdersGridPaper: React.FC<IAdminOrdersListOwnProps> = React.memo((props: IAdminOrdersListOwnProps) =>
<AdminOrdersGridPaperStyles>
    <TableContainer component={LAPaperWithLessPadding}>
        <Table aria-label="AdminOrders Grid">
            <TableHead>
                <TableRow>
                    <TableCell className="title" align="center">Order ID</TableCell>
                    <TableCell className="title" align="center">Customer Name</TableCell>
                    <TableCell className="title" align="center">Date</TableCell>
                    <TableCell className="title" align="center">Status</TableCell>
                    <TableCell className="title" align="center">Number of Items</TableCell>
                    <TableCell className="title" align="center"></TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {props.data.map((order, index) => {
                         const onClick = (): void => props.onClick(index, order.id);
                    return (
                        <TableRow key={order.id}>
                            <TableCell align="center">{order.id}</TableCell>
                            <TableCell align="center">{order.customer_Name}</TableCell>
                            <TableCell align="center">{getDate(false, order.date)}</TableCell>
                            <TableCell align="center">{order.status}</TableCell>
                            <TableCell align="center">{order.items.length}</TableCell>
                            <TableCell align="center"><LAButton label="View Details" onClick={onClick} /></TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </TableContainer>
    </AdminOrdersGridPaperStyles>
);
