import { IUPDATE_PROFILE_REQUEST, IUpdateProfileRequest } from "./updateProfileConstants";

export interface IUpdateProfileLoadAction {
    type: IUPDATE_PROFILE_REQUEST.REQUEST;
    data: IUpdateProfileRequest;
    internalUpdate: boolean;
}
export const updateProfileLoadAction = (data: IUpdateProfileRequest, internalUpdate: boolean): IUpdateProfileLoadAction => ({
    type: IUPDATE_PROFILE_REQUEST.REQUEST,
    data,
    internalUpdate
});

export interface IUpdateProfileSuccessAction {
    type: IUPDATE_PROFILE_REQUEST.SUCCESS;
    message: string;
    data: IUpdateProfileRequest;
    internalUpdate: boolean;
}
export const updateProfileLoadSuccessAction = (message: string, data: IUpdateProfileRequest, internalUpdate: boolean): IUpdateProfileSuccessAction => ({
    type: IUPDATE_PROFILE_REQUEST.SUCCESS,
    message,
    data,
    internalUpdate
});
export interface IUpdateProfileLoadFailedAction {
    type: IUPDATE_PROFILE_REQUEST.FAILED;
    message: string;
}
export const updateProfileLoadFailedAction = (message: string): IUpdateProfileLoadFailedAction => ({
    type: IUPDATE_PROFILE_REQUEST.FAILED,
    message
});
