import React from "react";
import { LASaveAndCancelButton } from "../../shared/buttons";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAPaperWithPadding } from "../../shared/paper";
import { LAPopover } from "../../shared/popOver";

interface ICancelConfirmationPopup {
    open: boolean;
    onSave: () => void;
    onClose: () => void;
};

export const CancelConfirmationPopup: React.FC<ICancelConfirmationPopup> = React.memo((props: ICancelConfirmationPopup) =>
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null} anchorOrigin={{ horizontal: "center", vertical: "center" }} transformOrigin={{ horizontal: "center", vertical: "center" }}>
        <LAPaperWithPadding>
            <LAGrid className="text-center">

                <LAGridItem xs={12}>
                    <strong>Are you sure you want to cancel this order ?</strong>
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LASaveAndCancelButton
                        onSave={props.onSave}
                        onCancel={props.onClose}
                    />
                </LAGridItem>

            </LAGrid>
        </LAPaperWithPadding>
    </LAPopover>
);

export const ReactivateConfirmationPopup: React.FC<ICancelConfirmationPopup> = React.memo((props: ICancelConfirmationPopup) =>
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null} anchorOrigin={{ horizontal: "center", vertical: "center" }} transformOrigin={{ horizontal: "center", vertical: "center" }}>
        <LAPaperWithPadding>
            <LAGrid className="text-center">

                <LAGridItem xs={12}>
                    <strong>Are you sure you want to Reactivate this order ?</strong>
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LASaveAndCancelButton
                        onSave={props.onSave}
                        onCancel={props.onClose}
                    />
                </LAGridItem>

            </LAGrid>
        </LAPaperWithPadding>
    </LAPopover>
);