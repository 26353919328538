import { RouteComponentProps } from "react-router";
import { END_POINTS } from "../../redux/endpoints";


export const calculateAge = (dateString: string): number => {
  var birthday = +new Date(dateString);
  return ~~((Date.now() - birthday) / (31557600000));
};


export const ZEROTH = 0;
export const ONE = 1;
export const AdminCheck = "Admin";
export const OrderdeskCheck = "orderdesk@sureway.ca";
export const SalesEmail = "sales.parts@sureway.ca";
export const SalesPhone = "780-619-3227";

export const CheckCountryCanada = (country: string): boolean => {
  if (country && (country === "Canada")) {
    return true;
  } else {
    return false;
  }
};

export const undefinedFunction = (): void => undefined;

export const getIP = async (): Promise<string> => {
  return fetch("https://geolocation-db.com/json/")
  .then((response) => {
    return response.json();
  })
  .then((response) => {
   return response.IPv4;
  })
  .catch((error) => {
    console.log(error);
    return "";
  });
};


// Javascript Month Index starts from 0, so it return wrong month number so that's why we need to declare these below array values
export const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
  "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
];

export const monthInNumber = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const getDate = (showTime: boolean, value?: string): string => {
  if (value !== "" && value) {
    const coeff = 1000 * 60 * 5;
    const date = value ? new Date(value) : new Date();
    const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);

    const format = `${monthInNumber[date.getMonth()]}/${date.getDate()}/${date.getFullYear()}`;
    const mins = rounded.getMinutes() > 9 ? rounded.getMinutes() : "0" + rounded.getMinutes();
    const time = showTime ? ` • ${date.getHours()}:${mins} ${date.getHours() < 12 ? "AM" : "PM"}` : "";
    return format + time;
  }
  return "";
};

export interface IQueryString {
  id?: string;
  keyword?: string;
  pageNumber?: number;
  pageSize?: number;
  filter?: any;
  isActive?: boolean;
  message?: string;
};

interface ICallRouteWithQueryStringProps {
  route: RouteComponentProps;
  pathName: string;
  search: IQueryString;
};

export const callRouteWithQueryString = (props: ICallRouteWithQueryStringProps): void => {
  let search = {};
  if (props.search.id)
    search = { ...search, id: props.search.id };
  if (props.search.keyword)
    search = { ...search, keyword: props.search.keyword };
  if (props.search.pageNumber)
    search = { ...search, pageNumber: props.search.pageNumber.toString() };
  if (props.search.pageSize)
    search = { ...search, pageSize: props.search.pageSize.toString() };
  if (props.search.isActive)
    search = { ...search, isActive: props.search.isActive.toString() };
  if (props.search.message)
    search = { ...search, message: props.search.message };
  if (props.search.filter) {
    search = { ...search, filter: JSON.stringify(props.search.filter) };
  }

  props.route.history.push({ pathname: props.pathName, search: "?" + new URLSearchParams(search).toString() });
};

export const SILENT_LOGIN_FAILED_MESSAGE = "Logged Out";


export const getUSDRate = async (token: string): Promise<any> => {
  let response = 0;
  
  await fetch(END_POINTS.GET_USD_RATE_FOR_CAD, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      token
    })
  })
    .then(res => {
      return res.json();
    })
    .then((res) => {
      response = res.response;
    })
    .catch((res) => {
      return 0;
    });

    return response;
};