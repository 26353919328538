import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IContactUsRequest, ICONTACT_US_REQUEST } from "./contactUsConstants";

export interface IContactUsLoadAction {
    type: ICONTACT_US_REQUEST.REQUEST;
    data: IContactUsRequest
}
export const contactUsLoadAction = (data: IContactUsRequest): IContactUsLoadAction => ({
    type: ICONTACT_US_REQUEST.REQUEST,
    data
});

export interface IContactUsSuccessAction {
    type: ICONTACT_US_REQUEST.SUCCESS;
    response: PartsResponse<string>;
}
export const contactUsLoadSuccessAction = (response: PartsResponse<string>): IContactUsSuccessAction => ({
    type: ICONTACT_US_REQUEST.SUCCESS,
    response
});
export interface IContactUsLoadFailedAction {
    type: ICONTACT_US_REQUEST.FAILED;
    message: string;
}
export const contactUsLoadFailedAction = (message: string): IContactUsLoadFailedAction => ({
    type: ICONTACT_US_REQUEST.FAILED,
    message
});
