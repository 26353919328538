import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { IPartsOrderCustomerQuoteResponseSuccessAction } from "../partsOrderCustomerQuoteResponse/partsOrderCustomerQuoteResponseActions";
import { IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST } from "../partsOrderCustomerQuoteResponse/partsOrderCustomerQuoteResponseConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IGetPartsOrderByCustomerLoadAction, IGetPartsOrderByCustomerLoadFailedAction, IGetPartsOrderByCustomerSuccessAction } from "./getPartsOrderByCustomerActions";
import { IGetPartsOrderByCustomer, IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST } from "./getPartsOrderByCustomerConstants";


type Actions =
    IGetPartsOrderByCustomerLoadAction
    | IGetPartsOrderByCustomerLoadFailedAction
    | IGetPartsOrderByCustomerSuccessAction
    | IPartsOrderCustomerQuoteResponseSuccessAction
    | IFlushDataSuccessAction;

export const GetPartsOrderByCustomerReducer = (state: Server<PartsResponse<IGetPartsOrderByCustomer[]>> = notLoaded, action: Actions): Server<PartsResponse<IGetPartsOrderByCustomer[]>> => {
    switch (action.type) {
        case IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.REQUEST:
            return loading;

        case IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.FAILED:
            return failed(action.message);

            case IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};