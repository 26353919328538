import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RED_COLOR } from "./theme";
import { HasClass } from "./publicInterfaces";
import styled from "styled-components";

interface ILoadingProps extends HasClass {
    message?: string;
    size?: number;
}

const DEFAULT_LOADING_SIZE = 24;
const LALoadingStyles = { color: RED_COLOR };

const LALoading = (props: ILoadingProps): JSX.Element =>
    <div className="text-center">
        <CircularProgress color="inherit" size={props.size || DEFAULT_LOADING_SIZE} className={props.className} />
        {props.message && <div className="mt-2" style={LALoadingStyles}>{props.message}</div>}
    </div>
    ;

export default LALoading;


const LACenteredLoadingStyles = styled.div`
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
`;

export const LACenteredLoading = (props: ILoadingProps): JSX.Element => 
    <LACenteredLoadingStyles>
        <div className="text-center">
            <LALoading {...props} />
        </div>
    </LACenteredLoadingStyles>
;
