import { webConfig } from "../utils/webConfig";

export const END_POINTS = {
  LOGIN: `${webConfig.apiBaseUrl}/api/PartsLoginValidation`,
  GET_PARTS: `${webConfig.apiBaseUrl}/api/GetPartsByUserInput`,
  ADD_PARTS_ORDER: `${webConfig.apiBaseUrl}/api/AddPartsOrder`,
  GET_PARTS_FACETS: `${webConfig.apiBaseUrl}/api/GetPartsFacetsByMake`,
  GET_PARTS_BY_IDS: `${webConfig.apiBaseUrl}/api/GetPartsByIDSs`,
  REGISTRATION: `${webConfig.apiBaseUrl}/api/RegisterPartsCustomer`,
  UPDATE_PROFILE: `${webConfig.apiBaseUrl}/api/UpdateCustomerProfile`,
  GET_ALL_PARTS_ORDER: `${webConfig.apiBaseUrl}/api/GetAllPartsOrders`,
  GET_PARTS_ORDER_BY_CUSTOMER: `${webConfig.apiBaseUrl}/api/GetPartsOrderByCustomer`,
  DOWNLOAD_INVOICE: `${webConfig.apiBaseUrl}/api/GetPartsOrderInvoice`,
  GET_USD_RATE_FOR_CAD: `${webConfig.apiBaseUrl}/api/Shop/GetUSDRateForCAD`,
  GET_PARTS_ORDERS_REPORT: `${webConfig.apiBaseUrl}/api/GetPartsOrderReport`,
  GET_ALL_PARTS_CASH_ORDERS: `${webConfig.apiBaseUrl}/api/GetAllPartsCashOrders`,
  GET_PARTS_CASH_ORDER_LOOKUP: `${webConfig.apiBaseUrl}/api/GetPartsCashOrderLookup`,
  ADD_PARTS_CASH_ORDER: `${webConfig.apiBaseUrl}/api/AddPartsCashOrder`,
  UPDATE_PARTS_CASH_ORDER: `${webConfig.apiBaseUrl}/api/UpdatePartsCashOrder`,
  REACTIVATE_PARTS_ORDER: `${webConfig.apiBaseUrl}/api/ReactivatePartsOrder`,
  GET_HOME_PAGE_PHOTOS: `${webConfig.apiBaseUrl}/api/GetPartsPublicImages`,
  GET_DISMANTLED_LOOKUP: `${webConfig.apiBaseUrl}/api/GetDismantledEquipmentLookups`,
  DISMANTLED: `${webConfig.apiBaseUrl}/api/GetPartsDismantledEquipmentsPublic`,
  UPDATE_PARTS_ORDER_ADMIN: `${webConfig.apiBaseUrl}/api/UpdatePartsOrderAdmin`,
  PARTS_ORDER_CUSTOMER_QUOTE_RESPONSE: `${webConfig.apiBaseUrl}/api/PartsOrderCustomerQuoteResponse`,
  UPDATE_PARTS_ORDER_PAYMENT: `${webConfig.apiBaseUrl}/api/UpdatePartsOrderPayment`,
  GET_ALL_CUSTOMERS: `${webConfig.apiBaseUrl}/api/GetAllPartsCustomers`,
  CONTACT_US_MESSAGE: `${webConfig.apiBaseUrl}/api/SavePartsCustomerMessages`,
  SEO_KEYWORDS: `${webConfig.apiBaseUrl}/api/GetPartsSEOKeywords`,
};
