import React, { ReactNode } from "react";
import Chip from "@material-ui/core/Chip/Chip";
import { HasClass } from "./publicInterfaces";


interface ILAChipProps extends HasClass {
    icon?: ReactNode;
    label: string;
    onClick?: () => void;
    onDelete?: () => void;
    color?: "primary" | "secondary" | "default";
    size?: "medium" | "small";
    variant?: "outlined" | "default";
}


const LAChip: React.FC<ILAChipProps> = (props: ILAChipProps) => {
    return (
        <Chip
            style={{marginBottom: "3px"}}
            size={props.size ?? "small"}
            label={props.label}
            onClick={props.onClick}
            onDelete={props.onDelete}
            color={props.color}
            variant={props.variant ?? "outlined"}
        />
    );
};


export default LAChip;