import React from "react";
import styled from "styled-components";
import { IPart } from "../../../redux/parts/getParts/getPartsConstants";
import { LASecondaryButton } from "../../shared/buttons";
import { ONE, undefinedFunction, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { RemoveCartIcon } from "../../shared/icons";
import LALinkButton from "../../shared/linkButton";
import { DARK_GREY_COLOR, RED_COLOR } from "../../shared/theme";
import { LAPaperWithPadding } from "../../shared/paper";
import { ICart, QuantityList } from "../../../redux/cart/cartConstants";
import { HasClass } from "../../shared/publicInterfaces";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LAAutoComplete from "../../shared/autoComplete";
import LATextArea from "../../shared/textArea";
import Not_Available_Image from "../../../No-Image.jpg";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

interface ICheckOutItemProps extends HasClass {
    cart: ICart[];
    parts: IPart[];
    disablePrice: true | undefined;
    onCart: (part: IPart, index: number) => void;
    onNote: (name: string, index: number) => void;
    onQtyChange: (value: number, index: number) => void;
};

const CheckOutItemStyles = styled(LAPaperWithPadding)`
    .deskView {
        display: block;
    };

    .mobView {
        display: none;
    };

    .itemPaper .qtyWrapper {
        font-size: 20px;
    };

    .itemPaper .itemImageWithThumb {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 170px;
        height: 120px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };

    .itemPaper .itemImage {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 170px;
        height: 120px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };

    .title {
        font-weight: bold;
        background-color: ${DARK_GREY_COLOR};
    };

    @media only screen and (max-width: ${1700}px) {
        .deskView {
            display: none;
        };

        .mobView {
            display: block;
        };

        .itemPaper .itemImageWithThumb {
            padding: 5px;
            border: 1px solid ${DARK_GREY_COLOR};
            max-width: 300px;
            height: 290px;
            overflow: hidden;
            text-align: center;
            img {
                width: 100%;
                height: auto;
            };
        };

        .itemPaper .itemImage {
            padding: 5px;
            border: 1px solid ${DARK_GREY_COLOR};
            max-width: 300px;
            height: 240px;
            overflow: hidden;
            text-align: center;
            img {
                width: 100%;
                height: auto;
            };
        }; 
    };
`;

export const CheckOutItem: React.FC<ICheckOutItemProps> = React.memo((props: ICheckOutItemProps) =>
    <CheckOutItemStyles>
        <CheckOutItemDesktop {...props} />
        <CheckOutItemMobile {...props} />
    </CheckOutItemStyles>
);

const CheckOutItemDesktop: React.FC<ICheckOutItemProps> = React.memo((props: ICheckOutItemProps) =>
    <TableContainer className="deskView" component="div">
        <Table aria-label="Checkout Grid">
            <TableHead>
                <TableRow>
                    <TableCell className="title" align="center"></TableCell>
                    <TableCell className="title" align="center">Description</TableCell>
                    <TableCell className="title" align="center">Notes</TableCell>
                    <TableCell className="title" align="center">Quantity</TableCell>
                    {/* <TableCell className="title" align="center">$ Price</TableCell>
                    <TableCell className="title" align="center">$ Item Cost</TableCell> */}
                    <TableCell className="title" align="center"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.parts.map((part, index) => {
                    const images = getImage(part);
                    const onRemove = () => props.onCart(part, index);
                    // const onPrice = () => props.onPrice(value, index);
                    const onNote = (name: string, value: string) => props.onNote(value, index);
                    const qtyChange = (event: unknown, value: { id: number, value: string }) => props.onQtyChange(value === null ? 1 : value.id, index);
                    return (
                        <TableRow key={part.id} className="itemPaper">
                            <TableCell align="center" component="th" scope="row">
                                <div className={`${images.length > ONE ? "itemImageWithThumb" : "itemImage"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {

                                }}>
                                    <ImageGallery
                                        items={images}
                                        showNav={false}
                                        autoPlay={false}
                                        showPlayButton={false}
                                        showFullscreenButton={true}
                                        showThumbnails={(images.length > ONE) ? true : false} 
                                        />
                                </div>
                            </TableCell>

                            <TableCell align="center">
                                {`${part.part_No}, ${part.name}, ${part.make}, ${part.model}, ${part.condition}`}
                            </TableCell>

                            <TableCell align="center">
                                <LATextArea
                                    rowsMax={3}
                                    label="Notes"
                                    fullWidth={true}
                                    onChange={onNote}
                                    name={index.toString() + "note"}
                                    value={props.cart[index].note}
                                />
                            </TableCell>

                            <TableCell align="center">
                                <LAAutoComplete
                                    name="quantity"
                                    multiple={false}
                                    onChange={qtyChange}
                                    autoHighlight={true}
                                    option={QuantityList}
                                    getOptionLabel="value"
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Quantity"
                                    selectionRemove={undefinedFunction}
                                    value={QuantityList.find(q => q.id === props.cart[index].quantity)}
                                    defaultValue={QuantityList.find(q => q.id === props.cart[index].quantity)}
                                />
                            </TableCell>

                            {/* <TableCell align="center">
                                <LATextArea
                                    rowsMax={1}
                                    label="$ Price"
                                    fullWidth={true}
                                    onChange={undefinedFunction}
                                    disabled={props.disablePrice}
                                    name={index.toString() + "price"}
                                    value={props.cart[index].price}
                                />
                            </TableCell>

                            <TableCell align="center">
                                <LATextArea
                                    rowsMax={1}
                                    disabled={true}
                                    fullWidth={true}
                                    label="$ Item Cost"
                                    onChange={undefinedFunction}
                                    name={index.toString() + "totalCost"}
                                    value={(Number(props.cart[index].price) * Number(props.cart[index].quantity))}
                                />
                            </TableCell> */}

                            <TableCell align="center">
                                <LASecondaryButton
                                    onClick={onRemove}
                                    label="Remove"
                                    endIcon={<RemoveCartIcon color={RED_COLOR} />}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </TableContainer>
);


const CheckOutItemMobile: React.FC<ICheckOutItemProps> = React.memo((props: ICheckOutItemProps) =>
    <LAGrid className="mobView">
        {props.parts.map((x, index) => {
            const images = getImage(x);
            const onRemove = () => props.onCart(x, index);
            const onNote = (name: string, value: string) => props.onNote(value, index);
            const qtyChange = (event: unknown, value: { id: number, value: string }) => props.onQtyChange(value.id, index);
            return (
                <LAGridItem xs={12} key={index}>

                    <LAPaperWithPadding className="itemPaper">
                        <LAGrid>
                            <LAGridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className={`${images.length > ONE ? "itemImageWithThumb" : "itemImage"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {

                                }}>
                                    <ImageGallery
                                        items={images}
                                        showNav={true}
                                        autoPlay={false}
                                        showPlayButton={false}
                                        showFullscreenButton={true}
                                        showThumbnails={(images.length > ONE) ? true : false}
                                    />
                                </div>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                                <LAGrid spacing={1}>

                                    <LAGridItem xs={12} md={12}>
                                        <LAGrid spacing={3} justify="space-between" alignItems="center">
                                            <LAGridItem xs={12} sm={6} md={6} lg={7} xl={8}>
                                                <div className="cursor" onClick={(e: React.MouseEvent<unknown>): void => {
                                                }}>
                                                    <LALinkButton label={`${x.name}`} onClick={undefinedFunction} fontSize={20} />
                                                </div>
                                            </LAGridItem>

                                            <LAGridItem xs={12} sm={6} md={6} lg={5} xl={4}>
                                                <LAGrid spacing={0} justify="flex-end" alignItems="flex-end">
                                                    <LAGridItem sm={1} md={5}>

                                                    </LAGridItem>

                                                    <LAGridItem sm={11} md={7} className="qtyWrapper">
                                                    </LAGridItem>
                                                </LAGrid>
                                            </LAGridItem>

                                        </LAGrid>
                                        <hr />
                                    </LAGridItem>

                                    <LAGridItem xs={12} md={12}>
                                        <LAGrid>

                                            <LAGridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <LAGrid alignItems="center" justify="space-between">

                                                    <LAGridItem xs={12} className="qtyWrapper">
                                                        <strong className="">Part Number: </strong>
                                                        <span className=""> {x.part_No}</span>
                                                    </LAGridItem>

                                                    <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                        <strong>Make: </strong>
                                                        <span className=""> {x.make}</span>
                                                    </LAGridItem>

                                                    <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                        <strong>Model: </strong>
                                                        <span className=""> {x.model}</span>
                                                    </LAGridItem>

                                                    <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                        <strong>Condition: </strong>
                                                        <span className=""> {x.condition}</span>
                                                    </LAGridItem>

                                                </LAGrid>
                                            </LAGridItem>

                                            <LAGridItem xs={12} sm={12} md={6}>
                                                <LAAutoComplete
                                                    name="quantity"
                                                    multiple={false}
                                                    getOptionLabel="value"
                                                    onChange={qtyChange}
                                                    autoHighlight={true}
                                                    option={QuantityList}
                                                    filterSelectedOptions={true}
                                                    dropDownPlaceHolder="Quantity"
                                                    selectionRemove={undefinedFunction}
                                                    value={QuantityList.find(q => q.id === props.cart[index].quantity)}
                                                    defaultValue={QuantityList.find(q => q.id === props.cart[index].quantity)}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12} sm={12} md={6}>
                                                <LATextArea
                                                    rowsMax={3}
                                                    label="Notes"
                                                    onChange={onNote}
                                                    fullWidth={true}
                                                    name={index.toString()}
                                                    value={props.cart[index].note}
                                                />
                                            </LAGridItem>

                                            {/* <LAGridItem xs={6} sm={6} md={6}>
                                                <LATextArea
                                                    rowsMax={1}
                                                    label="$ Price"
                                                    fullWidth={true}
                                                    onChange={undefinedFunction}
                                                    disabled={props.disablePrice}
                                                    name={index.toString() + "price"}
                                                    value={props.cart[index].price}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={6} sm={6} md={6}>
                                                <LATextArea
                                                    rowsMax={1}
                                                    disabled={true}
                                                    fullWidth={true}
                                                    label="$ Item Cost"
                                                    onChange={undefinedFunction}
                                                    name={index.toString() + "totalCost"}
                                                    value={(Number(props.cart[index].price) * Number(props.cart[index].quantity))}
                                                />
                                            </LAGridItem> */}

                                            <LAGridItem xs={12} sm={12} md={6}>
                                                <LASecondaryButton
                                                    label="Remove"
                                                    onClick={onRemove}
                                                    endIcon={<RemoveCartIcon color={RED_COLOR} />}
                                                />
                                            </LAGridItem>

                                        </LAGrid>
                                        <hr />
                                    </LAGridItem>

                                </LAGrid>
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>

                </LAGridItem>
            );
        })}
    </LAGrid>
);

interface IPartImageRender {
    original: string;
    thumbnail: string;
};

const getImage = (part: IPart): IPartImageRender[] => {
    const images: IPartImageRender[] = [];

    if (part.photos_1 != null) {
        images.push({ original: part.photos_1, thumbnail: part.photos_1 });
    }
    if (part.photos_2 != null)
        images.push({ original: part.photos_2, thumbnail: part.photos_2 });

    if (part.photos_3 != null)
        images.push({ original: part.photos_3, thumbnail: part.photos_3 });

    if (images.length === ZEROTH)
        images.push({ original: Not_Available_Image, thumbnail: Not_Available_Image });

    return images;
};
