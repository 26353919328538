
export enum IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST {
    REQUEST = "getPartsOrderByCustomer/GET_PARTS_ORDER_BY_CUSTOMER_REQUEST",
    SUCCESS = "getPartsOrderByCustomer/GET_PARTS_ORDER_BY_CUSTOMER_SUCCESS",
    FAILED = "getPartsOrderByCustomer/GET_PARTS_ORDER_BY_CUSTOMER_FAILED"
};

export interface IGetPartsOrderByCustomerRequest {
    token: string;
    itemId: number;
    request: {
        CustomerID: number;
    };
    keywords: string;
    PageSize: number;
    PageNumber: number;
};

export interface IGetPartsOrderByCustomer {
    created: string;
    created_By: string;
    customer_ID: number;
    customer_Name: string;
    phone_No: string;
    email: string;
    date: string;
    id: number;
    invoice_No: string;
    modified: string;
    modified_By: string;
    notes: string;
    payment_Details: string;
    shipment_Tracking_No: string;
    shipping_Address: string;
    shipping_City: string;
    shipping_Country: string;
    shipping_Province: string;
    shipping_Postal_Code: string;
    shipping_Cost: number;
    status: string;
    usD_Rate: number;
    tax: number;
    total_Cost: number;
    total_Items_Cost: number;
    items: IGetPartsOrderByCustomerItem[];
};

export interface IGetPartsOrderByCustomerItem {
    condition: string;
    discount: number;
    id: number;
    model: string;
    notes: string;
    part_Name: string;
    part_No: number;
    photos_1: string;
    photos_2: string;
    photos_3: string;
    price: number;
    quantity: number;
    total_Cost: number;
    type: string;
    weight: number;
    year: string;
};
