import { IAddPartsOrderItem } from "../addPartsOrder/addPartsOrderConstants";

export enum IUPDATE_PARTS_ORDER_ADMIN_REQUEST {
    REQUEST = "updatePartsOrderAdmin/UPDATE_PARTS_ORDER_ADMIN_REQUEST",
    SUCCESS = "updatePartsOrderAdmin/UPDATE_PARTS_ORDER_ADMIN_SUCCESS",
    FAILED = "updatePartsOrderAdmin/UPDATE_PARTS_ORDER_ADMIN_FAILED"
};

export interface IUpdatePartsOrderAdminRequest {
    token?: string;
    request: IUpdatePartsOrderAdmin;
};

export interface IUpdatePartsOrderAdmin {
    ID: number;
    Customer_ID: number;
    Shipping_Address: string;
    Shipping_Province: string;
    Shipping_City: string;
    Shipping_Country: string;
    Shipping_Postal_Code: string;
    Modified: string;
    Modified_By: string;
    Notes: string;
    Status: string;
    Total_Items_Cost: number;
    Shipping_Cost: number;
    Tax: number;
    Total_Cost: number;
    USD_Rate: number;
    Items: IUpdatePartsOrderAdminItem[];
};

export interface IUpdatePartsOrderAdminItem extends IAddPartsOrderItem {
    ID: number;
}


export interface IUpdatePartsOrderAdminResponse {

};
