import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR } from "../../shared/theme";
import LAPagination from "../../shared/pagination";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import LAFacet from "../../shared/facet/facet";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { PartsResponse } from "../../shared/publicInterfaces";
import { partsFacetLoadAction } from "../../../redux/parts/facets/partsFacetActions";
import { getPartsFacet } from "../../../redux/parts/facets/partsFacetAccessor";
import { IGetPartsRequest, IPart } from "../../../redux/parts/getParts/getPartsConstants";
import { getParts } from "../../../redux/parts/getParts/getPartsAccessor";
import { getPartsLoadAction } from "../../../redux/parts/getParts/getPartsActions";
import { PartList } from "./partList";
import queryString from "query-string";
import { ROUTE } from "../../routes";
import SearchBox from "../../shared/searchBox";
import { PartDetailPopup } from "./partDetailPopUp";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import { login } from "../../../redux/login/loginAccessor";
import LAAutoComplete from "../../shared/autoComplete";
import { ICart } from "../../../redux/cart/cartConstants";
import { getCart } from "../../../redux/cart/cartAccessor";
import { AdminCheck, callRouteWithQueryString, getIP, ONE, OrderdeskCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import { addCartLoadAction, cartLoadAction, removeCartLoadAction } from "../../../redux/cart/cartActions";
import { IGetPartsFacetRequest, IManufactureFacet, IModelFacet, IPartsFacetResponse } from "../../../redux/parts/facets/partsFacetConstants";
import PageSpacing from "../../shared/pageSpacing";
import { IGetSEOKeywordsRequest } from "../../../redux/getSEOKeywords/getSEOKeywordsConstants";
import { getSEOKeywordsLoadAction } from "../../../redux/getSEOKeywords/getSEOKeywordsActions";
import { getSEOKeywordsStatus } from "../../../redux/getSEOKeywords/getSEOKeywordsAccessor";
import { LASecondaryButton } from "../../shared/buttons";
import { EquipmentIcon } from "../../shared/icons";


interface IPartsComponentStoreProps {
    cart: Server<ICart[]>;
    loginStatus: Server<ILoginResponse>;
    parts: Server<PartsResponse<IPart[]>>;
    seoKeywords: Server<PartsResponse<string>>;
    partsFacet: Server<PartsResponse<IPartsFacetResponse>>;
};

interface IPartsComponentDispatchProps {
    cartRequest: () => unknown;
    addCartRequest: (cart: ICart) => unknown;
    removeCartRequest: (id: number) => unknown;
    partsRequest: (data: IGetPartsRequest) => unknown;
    partsFacetRequest: (data: IGetPartsFacetRequest) => unknown;
    getSEOKeywordsRequest: (data: IGetSEOKeywordsRequest) => unknown;
};

interface IPartsComponentState {
    cart: ICart[];
    data: IPart[];
    open: boolean;
    searchText: string;
    partPopup: {
        part: IPart | undefined;
        open: boolean;
    };
    currentPage: number;
    totalRecords: number;
    selectedFacet: IPartsFacetResponse;
};

const PartsStyles = styled(LAPaperWithPadding)`

    .deskFacet {
        display: block;
    };

    .mobFacet {
        display: none;
    };

    .manualBtn{
background-color: #7F172A;
color: white;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .deskFacet {
            display: none;
        };

        .mobFacet {
            display: block;
        };
    };
`;

type IPartsComponentProps =
    RouteComponentProps
    & IPartsComponentStoreProps
    & IPartsComponentDispatchProps;

class PartsComponent extends PureComponent<IPartsComponentProps, IPartsComponentState> {

    public constructor(props: IPartsComponentProps) {
        super(props);
        this.state = {
            cart: [],
            data: [],
            open: false,
            searchText: "",
            currentPage: ONE,
            partPopup: {
                open: false,
                part: undefined
            },
            totalRecords: ZEROTH,
            selectedFacet: { manufacturers: [], models: [] }
        };
        this.keyWordSearch = this.debounce(this.keyWordSearch, 500);
    }

    public async componentDidMount(): Promise<void> {
        this.props.getSEOKeywordsRequest({ request: { page: "parts" } });
        await this.handleServerCall();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IPartsComponentProps): void {
        if ((this.props.parts !== prevProps.parts) || (this.props.cart !== prevProps.cart)) {
            this.setDataToState();
        };
    }

    public render(): ReactNode {

        const { partsFacet, parts, loginStatus, seoKeywords } = this.props;
        const { totalRecords, currentPage, searchText, selectedFacet, data, cart, partPopup } = this.state;

        return (
            <PageSpacing title="Parts" description="Parts" keywords={hasPayload(seoKeywords) ? seoKeywords.payload.response : undefined}>
                <PartsStyles>
                    <LAGrid spacing={3}>

                        <LAGridItem xs={12} sm={12} md={12} lg={2}>

                            <LASecondaryButton
                                fullWidth={true}
                                label="View Dismantled"
                                className="text-center mb-3"
                                onClick={this.handleDismantled}
                                startIcon={<EquipmentIcon color={RED_COLOR} />}
                            />

                            <LASecondaryButton
                                fullWidth={true}
                                label="View Available Manuals"
                                className="text-center mb-3"
                                onClick={this.handleManualClick}                                
                            />

                            <LAPaperWithLessPadding>
                                <LAGrid spacing={1}>

                                    <LAGridItem xs={12}>
                                        <h2 className="text-center">SUREWAY PARTS</h2>
                                    </LAGridItem>

                                    <LAGridItem xs={12} className="deskFacet">
                                        <LAFacet
                                            {...this.state}
                                            preSelected={selectedFacet}
                                            numberOfItems={totalRecords}
                                            onFacetUpdateClick={this.onFacetUpdateClick}
                                            facets={hasPayload(partsFacet) ? partsFacet.payload.response : { manufacturers: [], models: [] }}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAPaperWithLessPadding>
                        </LAGridItem>

                        <LAGridItem sm={12} md={12} lg={10}>
                            <LAGrid spacing={2}>

                                <LAGridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                                    <LAAutoComplete
                                        name="Make"
                                        multiple={false}
                                        autoHighlight={true}
                                        getOptionLabel="manufacturer"
                                        dropDownPlaceHolder="Make"
                                        onChange={this.handleManufacturerFilter}
                                        filterSelectedOptions={true}
                                        selectionRemove={undefinedFunction}
                                        option={hasPayload(partsFacet) ? partsFacet.payload.response.manufacturers : []}
                                        value={(hasPayload(partsFacet) && selectedFacet.manufacturers[0]) ?
                                            partsFacet.payload.response.manufacturers.find(x => selectedFacet.manufacturers[0].manufacturerID === x.manufacturerID)
                                            : {}}
                                        defaultValue={(hasPayload(partsFacet) && selectedFacet.manufacturers[0]) ?
                                            partsFacet.payload.response.manufacturers.find(x => selectedFacet.manufacturers[0].manufacturerID === x.manufacturerID)
                                            : {}}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                                    <LAAutoComplete
                                        name="model"
                                        multiple={false}
                                        autoHighlight={true}
                                        getOptionLabel="model"
                                        dropDownPlaceHolder="Model"
                                        onChange={this.handleModelFilter}
                                        filterSelectedOptions={true}
                                        selectionRemove={undefinedFunction}
                                        disabled={selectedFacet.manufacturers.length === 0 ? true : undefined}
                                        option={hasPayload(partsFacet) ? partsFacet.payload.response.models : []}
                                        value={(hasPayload(partsFacet) && selectedFacet.models[0]) ?
                                            partsFacet.payload.response.models.find(x => selectedFacet.models[0].model === x.model)
                                            : {}}
                                        defaultValue={(hasPayload(partsFacet) && selectedFacet.models[0]) ?
                                            partsFacet.payload.response.models.find(x => selectedFacet.models[0].model === x.model)
                                            : {}}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={4} md={4} lg={4} xl={4}>
                                    <SearchBox
                                        fullWidth={true}
                                        text={searchText}
                                        placeHolder="Part # or Part Name"
                                        onSubmit={undefinedFunction}
                                        onChange={this.handleSearchTextChange}
                                        searchStatus={STATUS_ENUM.SUCCEEDED}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAPagination
                                        rowsPerPage={20}
                                        className="mt-5"
                                        currentPage={currentPage}
                                        numberOfItems={totalRecords}
                                        onPageChange={this.handlePageChange}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAPaperWithPadding>
                                        <PartList
                                            cart={cart}
                                            data={data}
                                            dataStatus={parts.kind}
                                            loginStatus={loginStatus.kind}
                                            onCartClick={this.onCartClick}
                                            onPartClick={this.onPartClick}
                                            isAdmin={(hasPayload(loginStatus) && ((loginStatus.payload.customer.email === AdminCheck)
                                                || (loginStatus.payload.customer.email === OrderdeskCheck))) ? true : false}
                                        />
                                    </LAPaperWithPadding>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAPagination
                                        rowsPerPage={20}
                                        className="mt-5"
                                        currentPage={currentPage}
                                        numberOfItems={totalRecords}
                                        onPageChange={this.handlePageChange}
                                    />
                                </LAGridItem>
                            </LAGrid>
                        </LAGridItem>

                    </LAGrid>

                    {(partPopup.open && (partPopup.part !== undefined)) &&
                        <PartDetailPopup
                            cart={cart}
                            open={partPopup.open}
                            data={partPopup.part}
                            onClose={this.partPopUp}
                            onCartClick={this.onCartClick}
                        />}
                </PartsStyles>
            </PageSpacing>
        );
    }

    private handleDismantled = (): void => {
        this.props.history.push(ROUTE.DISMANTLED());
    };

    

    private handleManualClick = (): void => {
        window.open('https://portal.propsense.com/parts/manuals/manual.pdf', '_blank', 'fullscreen=yes');
    };

    private handleManufacturerFilter = (event: unknown, value: IManufactureFacet): void => {
        if (value === null) {
            this.setState({ selectedFacet: { manufacturers: [], models: [] } });
            this.onFacetUpdateClick({ manufacturers: [], models: [] });
        } else {
            this.onFacetUpdateClick({ manufacturers: [value], models: this.state.selectedFacet.models });
        };
    };

    private handleModelFilter = (event: unknown, value: IModelFacet): void => {
        if (value === null) {
            this.setState({ selectedFacet: { manufacturers: this.state.selectedFacet.manufacturers, models: [] } });
            this.onFacetUpdateClick({ manufacturers: this.state.selectedFacet.manufacturers, models: [] });
        } else {
            this.onFacetUpdateClick({ manufacturers: this.state.selectedFacet.manufacturers, models: [value] });
        };
    };

    private onCartClick = (part: IPart): void => {
        let findPart = this.state.cart.findIndex(x => x.id === part.id);

        if (findPart >= ZEROTH) {
            this.props.removeCartRequest(part.id);
        } else {
            this.props.addCartRequest({ id: part.id, name: part.name, quantity: ONE, note: "", price: (part.price !== null) ? part.price : "1" });
        };
    };

    private onPartClick = (part: IPart): void => {
        this.setState({
            partPopup: {
                part,
                open: true
            }
        });
    };

    private partPopUp = (): void => {
        this.setState({
            partPopup: {
                open: false,
                part: undefined
            }
        });
    };

    private checkCart = (): void => {
        if (hasPayload(this.props.cart))
            this.setState({ cart: this.props.cart.payload });
    };

    private debounce = (fn: any, delay: any): any => {
        let timer: any = null;
        return (...args: any): any => {
            const context = this;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    };

    private onFacetUpdateClick = async (values: IPartsFacetResponse): Promise<void> => {

        if (values.models.length === 0) {
            this.props.partsFacetRequest({ token: hasPayload(this.props.loginStatus) ? this.props.loginStatus.payload.token : "", request: { Makes: values.manufacturers.map(({ manufacturerID }) => manufacturerID) } });
        };

        await callRouteWithQueryString({
            route: this.props, pathName: ROUTE.PARTS(),
            search: { keyword: this.state.searchText, filter: values, pageNumber: this.state.currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private keyWordSearch = async (): Promise<void> => {
        await callRouteWithQueryString({
            route: this.props, pathName: ROUTE.PARTS(),
            search: { keyword: this.state.searchText, filter: this.state.selectedFacet, pageNumber: this.state.currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private handleSearchTextChange = async (searchTextString: string): Promise<void> => {
        this.setState({ searchText: searchTextString });
        this.keyWordSearch();
    };

    private handlePageChange = async (currentPage?: number, rowsPerPage?: number): Promise<void> => {
        this.setState({ currentPage: currentPage ?? 1 });
        await callRouteWithQueryString({
            route: this.props, pathName: ROUTE.PARTS(),
            search: { keyword: this.state.searchText, filter: this.state.selectedFacet, pageNumber: currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private setDataToState = (): void => {
        this.checkCart();

        if (hasPayload(this.props.parts)) {
            const query: any = queryString.parse(this.props.location.search);
            const val = this.props.parts.payload;
            
            this.setState({
                data: val.response,
                totalRecords: val.totalRecords ?? 0,
                searchText: query.keyword ? query.keyword : "",
                currentPage: query.pageNumber ? Number(query.pageNumber) : 1,
                selectedFacet: query.filter ? { manufacturers: JSON.parse(query.filter).manufacturers, models: JSON.parse(query.filter).models } : { manufacturers: [], models: [] }
            });
        };

        if (isNotLoaded(this.props.partsFacet))
            this.props.partsFacetRequest({ token: hasPayload(this.props.loginStatus) ? this.props.loginStatus.payload.token : "", request: { Makes: [] } });

        if (isNotLoaded(this.props.parts))
            this.handleServerCall();
    };

    private handleServerCall = async (): Promise<void> => {
        const query: any = queryString.parse(this.props.location.search);

        const facets = query.filter ? JSON.parse(query.filter) : undefined;

        this.setState({
            searchText: query.keyword ? query.keyword : "",
            currentPage: query.pageNumber ? Number(query.pageNumber) : 1,
            selectedFacet: facets ? { manufacturers: facets.manufacturers, models: facets.models } : { manufacturers: [], models: [] }
        });

        if (facets && (facets.models.length === 0) && (facets.manufacturers.length > 0)){
            const manuf: IManufactureFacet[] = facets.manufacturers;
            this.props.partsFacetRequest({ token: hasPayload(this.props.loginStatus) ? this.props.loginStatus.payload.token : "", request: { Makes: manuf.map(({ manufacturerID }) => manufacturerID) } });
        };

        if (isNotLoaded(this.props.partsFacet))
            this.props.partsFacetRequest({ token: hasPayload(this.props.loginStatus) ? this.props.loginStatus.payload.token : "", request: { Makes: [] } });

        const ip = await getIP();

        this.props.partsRequest({
            request: {
                ip,
                keyword: query.keyword ? query.keyword : "",
                models: facets ? facets.models.map((x: IModelFacet) => x.model) : [],
                manufacturers: facets ? facets.manufacturers.map((x: IManufactureFacet) => x.manufacturerID) : []
            },
            PageSize: 20,
            PageNumber: query.pageNumber ? Number(query.pageNumber) : 1,
            token: hasPayload(this.props.loginStatus) ? this.props.loginStatus.payload.token : ""
        });
    };
}

const mapStateToProps = (state: IStore): IPartsComponentStoreProps => ({
    cart: getCart(state),
    parts: getParts(state),
    loginStatus: login(state),
    partsFacet: getPartsFacet(state),
    seoKeywords: getSEOKeywordsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IPartsComponentDispatchProps => ({
    cartRequest: (): unknown => dispatch(cartLoadAction()),
    addCartRequest: (cart: ICart): unknown => dispatch(addCartLoadAction(cart)),
    removeCartRequest: (id: number): unknown => dispatch(removeCartLoadAction(id)),
    partsRequest: (data: IGetPartsRequest): unknown => dispatch(getPartsLoadAction(data)),
    partsFacetRequest: (data: IGetPartsFacetRequest): unknown => dispatch(partsFacetLoadAction(data)),
    getSEOKeywordsRequest: (data: IGetSEOKeywordsRequest): unknown => dispatch(getSEOKeywordsLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(PartsComponent);