import React from "react";
import { HasClass } from "./publicInterfaces";

const DEFAULT_SIZE = 24;


export interface IIconProps extends HasClass {
    width?: number;
    height?: number;
    color?: string;
};


export const ListIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M6 29.75V26.75H42V29.75ZM6 38.05V35.05H42V38.05ZM6 21.4V18.4H42V21.4ZM6 13.05V10.05H42V13.05Z" />
    </svg>
);

export const TermsIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <rect fill="none" height="24" width="24" x="0" /><g><g><rect height="20" transform="matrix(0.7075 -0.7067 0.7067 0.7075 -5.6854 13.7194)" width="4" x="11.73" y="3.73" /><rect height="8" transform="matrix(0.707 -0.7072 0.7072 0.707 0.3157 11.246)" width="4" x="11.73" y="1.24" /><rect height="8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.1722 7.7256)" width="4" x="3.24" y="9.73" />
            <rect height="2" width="12" x="1" y="21" /></g></g>
    </svg>
);

export const GlobeIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
    </svg>
);

export const HourIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z" />
    </svg>

);

export const CrossIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const PersonIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />


        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
    </svg>
);


export const MultiPersonIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
    </svg>
);

export const PurchaseEquipmentIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M17,2H7C5.9,2,5,2.9,5,4v2c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V4C19,2.9,18.1,2,17,2z M17,6H7V4h10V6z M20,22H4 c-1.1,0-2-0.9-2-2v-1h20v1C22,21.1,21.1,22,20,22z M18.53,10.19C18.21,9.47,17.49,9,16.7,9H7.3c-0.79,0-1.51,0.47-1.83,1.19L2,18 h20L18.53,10.19z M9.5,16h-1C8.22,16,8,15.78,8,15.5C8,15.22,8.22,15,8.5,15h1c0.28,0,0.5,0.22,0.5,0.5C10,15.78,9.78,16,9.5,16z M9.5,14h-1C8.22,14,8,13.78,8,13.5C8,13.22,8.22,13,8.5,13h1c0.28,0,0.5,0.22,0.5,0.5C10,13.78,9.78,14,9.5,14z M9.5,12h-1 C8.22,12,8,11.78,8,11.5C8,11.22,8.22,11,8.5,11h1c0.28,0,0.5,0.22,0.5,0.5C10,11.78,9.78,12,9.5,12z M12.5,16h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,15.78,12.78,16,12.5,16z M12.5,14h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,13.78,12.78,14,12.5,14z M12.5,12h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C13,11.78,12.78,12,12.5,12z M15.5,16h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,15.78,15.78,16,15.5,16z M15.5,14h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,13.78,15.78,14,15.5,14z M15.5,12h-1 c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h1c0.28,0,0.5,0.22,0.5,0.5C16,11.78,15.78,12,15.5,12z" />
    </svg>
);

export const Visibility = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </svg>
);

export const VisibilityOff = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path
            fill="none"
            d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
        />
        <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46A11.804 11.804 0 001 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
    </svg>
);

export const CartIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
);

export const RemoveCartIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M22.73 22.73L2.77 2.77 2 2l-.73-.73L0 2.54l4.39 4.39 2.21 4.66-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h7.46l1.38 1.38c-.5.36-.83.95-.83 1.62 0 1.1.89 2 1.99 2 .67 0 1.26-.33 1.62-.84L21.46 24l1.27-1.27zM7.42 15c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h2.36l2 2H7.42zm8.13-2c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H6.54l9.01 9zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2z" />
    </svg>
);

export const DateIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const WarningIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
    </svg>
);

export const EditIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
    </svg>
);

export const SearchIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const PhoneIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
    </svg>
);

export const EmailIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
    </svg>
);

export const DeleteIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const SaveIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
    </svg>
);

export const CancelIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M3,3v18h18V3H3z M17,15.59L15.59,17L12,13.41L8.41,17L7,15.59L10.59,12L7,8.41L8.41,7L12,10.59L15.59,7L17,8.41L13.41,12 L17,15.59z" />
    </svg>
);

export const AddIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
    </svg>
);

export const ArrowFastForwardIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M4 18l8.5-6L4 6v12zm9-12v12l8.5-6L13 6z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const ArrowFastRewindIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M11 18V6l-8.5 6 8.5 6zm.5-6l8.5 6V6l-8.5 6z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const ArrowLeftIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
        <path fill="none" d="M0 0h24v24H0z" />
    </svg>
);

export const ArrowRightIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
        <path fill="none" d="M0 0h24v24H0z" />
    </svg>
);

export const ExpandIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
);

export const CollapseIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>
);

export const DollarIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
);

export const OrderIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M17.21 9l-4.38-6.56c-.19-.28-.51-.42-.83-.42-.32 0-.64.14-.83.43L6.79 9H2c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27L23 10c0-.55-.45-1-1-1h-4.79zM9 9l3-4.4L15 9H9zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </svg>
);

export const ProjectIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <polygon points="18,16 16,16 16,15 8,15 8,16 6,16 6,15 2,15 2,20 22,20 22,15 18,15" />
        <path d="M20,8h-3V6c0-1.1-0.9-2-2-2H9C7.9,4,7,4.9,7,6v2H4c-1.1,0-2,0.9-2,2v4h4v-2h2v2h8v-2h2v2h4v-4C22,8.9,21.1,8,20,8z M15,8 H9V6h6V8z" />
    </svg>
);

export const UnitIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5C13 2.12 11.88 1 10.5 1S8 2.12 8 3.5V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5c1.38 0 2.5-1.12 2.5-2.5S21.88 11 20.5 11z" />
    </svg>
);

export const LogOutIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" />
    </svg>
);

export const DownloadIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
    </svg>
);

export const HomeIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
        className={props.className}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </svg>
);

export const AddPhotoIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M19 7v2.99s-1.99.01-2 0V7h-3s.01-1.99 0-2h3V2h2v3h3v2h-3zm-3 4V8h-3V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8h-3zM5 19l3-4 2 3 3-4 4 5H5z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export const ReportIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0,0h24v24H0V0z" fill="none" />
        <path d="M15,3H5C3.9,3,3.01,3.9,3.01,5L3,19c0,1.1,0.89,2,1.99,2H19c1.1,0,2-0.9,2-2V9L15,3z M8,17c-0.55,0-1-0.45-1-1s0.45-1,1-1 s1,0.45,1,1S8.55,17,8,17z M8,13c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S8.55,13,8,13z M8,9C7.45,9,7,8.55,7,8s0.45-1,1-1 s1,0.45,1,1S8.55,9,8,9z M14,10V4.5l5.5,5.5H14z" />
    </svg>
);

export const WarrantyIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <rect fill="none" height="24" width="24" />
        <g><g /><g><path d="M21,5l-9-4L3,5v6c0,5.55,3.84,10.74,9,12c2.3-0.56,4.33-1.9,5.88-3.71l-3.12-3.12c-1.94,1.29-4.58,1.07-6.29-0.64 c-1.95-1.95-1.95-5.12,0-7.07c1.95-1.95,5.12-1.95,7.07,0c1.71,1.71,1.92,4.35,0.64,6.29l2.9,2.9C20.29,15.69,21,13.38,21,11V5z" />
            <circle cx="12" cy="12" r="3" /></g></g></svg>
);

export const ContactUsIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M21 8V7l-3 2-3-2v1l3 2 3-2zm1-5H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm8-6h-8V6h8v6z" />
    </svg>
);


export const EquipmentIcon = React.memo((props: IIconProps): JSX.Element =>
    <svg xmlns="http://www.w3.org/2000/svg"
        width={props.width || DEFAULT_SIZE}
        height={props.height || DEFAULT_SIZE}
        viewBox="0 0 24 24"
        fill={props.color}
    >
       <path d="M19.5 13c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0 5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM4 9h5c0-1.1-.9-2-2-2H4c-.55 0-1 .45-1 1s.45 1 1 1zm5.83 4.82-.18-.47.93-.35c-.46-1.06-1.28-1.91-2.31-2.43l-.4.89-.46-.21.4-.9C7.26 10.13 6.64 10 6 10c-.53 0-1.04.11-1.52.26l.34.91-.47.18-.35-.93c-1.06.46-1.91 1.28-2.43 2.31l.89.4-.21.46-.9-.4C1.13 13.74 1 14.36 1 15c0 .53.11 1.04.26 1.52l.91-.34.18.47-.93.35c.46 1.06 1.28 1.91 2.31 2.43l.4-.89.46.21-.4.9c.55.22 1.17.35 1.81.35.53 0 1.04-.11 1.52-.26l-.34-.91.47-.18.35.93c1.06-.46 1.91-1.28 2.43-2.31l-.89-.4.21-.46.9.4c.22-.55.35-1.17.35-1.81 0-.53-.11-1.04-.26-1.52l-.91.34zm-2.68 3.95c-1.53.63-3.29-.09-3.92-1.62-.63-1.53.09-3.29 1.62-3.92 1.53-.63 3.29.09 3.92 1.62.64 1.53-.09 3.29-1.62 3.92z"></path>
    </svg>
);
