import { IUpdatePartsOrderPaymentRequest, IUPDATE_PARTS_ORDER_PAYMENT_REQUEST } from "./updatePartsOrderPaymentConstants";

export interface IUpdatePartsOrderPaymentLoadAction {
    type: IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.REQUEST;
    data: IUpdatePartsOrderPaymentRequest
}
export const updatePartsOrderPaymentLoadAction = (data: IUpdatePartsOrderPaymentRequest): IUpdatePartsOrderPaymentLoadAction => ({
    type: IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.REQUEST,
    data
});

export interface IUpdatePartsOrderPaymentSuccessAction {
    type: IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.SUCCESS;
    response: string;
}
export const updatePartsOrderPaymentLoadSuccessAction = (response: string): IUpdatePartsOrderPaymentSuccessAction => ({
    type: IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.SUCCESS,
    response
});
export interface IUpdatePartsOrderPaymentLoadFailedAction {
    type: IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.FAILED;
    message: string;
}
export const updatePartsOrderPaymentLoadFailedAction = (message: string): IUpdatePartsOrderPaymentLoadFailedAction => ({
    type: IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.FAILED,
    message
});
