import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { updatePartsOrderAdminLoadFailedAction, updatePartsOrderAdminLoadSuccessAction, IUpdatePartsOrderAdminLoadAction, IUpdatePartsOrderAdminLoadFailedAction, IUpdatePartsOrderAdminSuccessAction } from "./updatePartsOrderAdminActions";
import { IUPDATE_PARTS_ORDER_ADMIN_REQUEST } from "./updatePartsOrderAdminConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";

export const updatePartsOrderAdminEpic: Epic = (
    action$: ActionsObservable<IUpdatePartsOrderAdminLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdatePartsOrderAdminSuccessAction | IUpdatePartsOrderAdminLoadFailedAction> =>
    action$.ofType(IUPDATE_PARTS_ORDER_ADMIN_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.UPDATE_PARTS_ORDER_ADMIN,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<string>): IUpdatePartsOrderAdminSuccessAction => {
                            return updatePartsOrderAdminLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updatePartsOrderAdminLoadFailedAction("Unable to update parts order")))
                    )
            )
        );