import { combineEpics, Epic } from "redux-observable";
import { addCartEpic, cartEpic, removeCartEpic } from "./cart/cartEpic";
import { flushDataEpic } from "./flushData.tsx/flushDataEpic";
import { getAllPartsOrderEpic } from "./getAllPartsOrder/getPartsOrderEpic";
import { addPartsOrderEpic } from "./addPartsOrder/addPartsOrderEpic";
import { getPartsOrderByCustomerEpic } from "./getPartsOrderByCustomer/getPartsOrderByCustomerEpic";
import { loginEpic } from "./login/loginEpic";
import { partsFacetEpic } from "./parts/facets/partsFacetEpic";
import { getPartsEpic } from "./parts/getParts/getPartsEpic";
import { getPartsByIdsEpic } from "./parts/getPartsByIds/getPartsByIdsEpic";
import { IAction, IStore } from "./reducers";
import { registrationEpic } from "./registration/registrationEpic";
import { EpicDependencies } from "./store";
import { updateProfileEpic } from "./userProfile/updateProfile/updateProfileEpic";
import { updatePartsOrderAdminEpic } from "./updatePartsOrderAdmin/updatePartsOrderAdminEpic";
import { partsOrderCustomerQuoteResponseEpic } from "./partsOrderCustomerQuoteResponse/partsOrderCustomerQuoteResponseEpic";
import { updatePartsOrderPaymentEpic } from "./updatePartsOrderPayment/updatePartsOrderPaymentEpic";
import { contactUsEpic } from "./contactUs/contactUsEpic";
import { getCustomersEpic } from "./getCustomers/getCustomersEpic";
import { getPartsDismantledEquipmentsEpic } from "./getPartsDismantledEquipments/getPartsDismantledEquipmentEpic";
import { getPartsOrderReportsEpic } from "./getPartsOrderReport/getPartsOrderReportEpic";
import { getAllPartsCashOrdersEpic } from "./getAllPartsCashOrders/getAllPartsCashOrdersEpic";
import { getPartsCashOrderLookupEpic } from "./getPartsCashOrderLookup/getPartsCashOrderLookupEpic";
import { addPartsCashOrderEpic } from "./addPartsCashOrder/addPartsCashOrderEpic";
import { updatePartsCashOrderEpic } from "./updatePartsCashOrder/updatePartsCashOrderEpic";
import { getDismantledLookupsEpic } from "./parts/getDismantledLookups/getDismantledLookupsEpic";
import { getSEOKeywordsEpic } from "./getSEOKeywords/getSEOKeywordsEpic";

export type IEpic = Epic<IAction, IAction, IStore, EpicDependencies>;

export default combineEpics(
    partsFacetEpic,
    flushDataEpic,
    getPartsEpic,
    registrationEpic,
    getPartsByIdsEpic,
    loginEpic,
    updateProfileEpic,
    getCustomersEpic,
    cartEpic,
    addCartEpic,
    contactUsEpic,
    removeCartEpic,
    addPartsOrderEpic,
    getAllPartsOrderEpic,
    getPartsOrderByCustomerEpic,
    updatePartsOrderAdminEpic,
    updatePartsOrderPaymentEpic,
    partsOrderCustomerQuoteResponseEpic,
    getPartsDismantledEquipmentsEpic,
    getPartsOrderReportsEpic,
    getAllPartsCashOrdersEpic,
    getPartsCashOrderLookupEpic,
    addPartsCashOrderEpic,
    updatePartsCashOrderEpic,    
    getDismantledLookupsEpic,
    getSEOKeywordsEpic
);
