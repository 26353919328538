import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popover, { PopoverOrigin } from "@material-ui/core/Popover";
import { PureComponent, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { logoutAction } from "../../redux/login/loginActions";
import { ILoginResponse } from "../../redux/login/loginConstants";
import { IDispatch, IStore } from "../../redux/reducers";
import { ROUTE } from "../routes";
import LAAvatar from "../shared/avatar";
import { LAButton } from "../shared/buttons";
import { AdminCheck, OrderdeskCheck } from "../shared/constExports";
import { PersonIcon, MultiPersonIcon, PurchaseEquipmentIcon, ReportIcon } from "../shared/icons";
import { LAPaper } from "../shared/paper";
import { RED_COLOR, WHITE_COLOR } from "../shared/theme";



interface ILAHeaderAvatarOwnProps {
    data: ILoginResponse;
};
interface ILAHeaderAvatarState {
    open: boolean;
    openSwitch: boolean;
};
interface ILoginStoreProps {

};

interface IUserDataDispatchProps {
    logOutRequest: () => unknown;
};

type IHeaderAvatarProps =
    RouteComponentProps
    & ILAHeaderAvatarOwnProps
    & ILoginStoreProps
    & IUserDataDispatchProps;


const HeaderPopOverButtonStyles = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 210px;

    .heading-avatar {
        background-color: ${WHITE_COLOR};
        color: ${RED_COLOR};
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        padding: 0.5rem;
    };

    .welcomeText {
        font-size: 20px;
    };

    .company-name {
        margin-left: 5px;
    };
    
`;

const anchorPosition = { top: 60, left: 1900 };
const anchorOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "right",
};

class LAHeaderAvatar extends PureComponent<IHeaderAvatarProps, ILAHeaderAvatarState> {

    public constructor(props: IHeaderAvatarProps) {
        super(props);
        this.state = {
            open: false,
            openSwitch: false,
        };
    };


    public render(): ReactNode {
        const { firstname, lastname, email } = this.props.data.customer;

        return (
            <>
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <div className="cursor" onClick={this.handleClick}>
                        <LAAvatar style={{ width: 45, height: 45, margin: 0 }} initials={firstname.charAt(0) + lastname.charAt(0)} />
                    </div>
                </ClickAwayListener>

                <Popover open={this.state.open}
                    anchorReference="anchorPosition"
                    anchorPosition={anchorPosition}
                    anchorOrigin={anchorOrigin}>
                    <LAPaper className="paper-border">
                        <HeaderPopOverButtonStyles>
                            <div className="heading-avatar">
                                <p className="welcomeText">
                                    Welcome
                                </p>

                                <p>
                                    {firstname + " " + lastname}
                                </p>
                            </div>

                            <div className="p-1">
                                {((email !== AdminCheck) && (email !== OrderdeskCheck)) && <LAButton
                                    className="mb-2"
                                    fullWidth={true}
                                    label="PROFILE"
                                    onClick={this.onProfileClick}
                                    startIcon={<PersonIcon color={WHITE_COLOR} />}
                                />}

                                {((email === AdminCheck) || (email === OrderdeskCheck)) && <LAButton
                                    className="mb-2"
                                    fullWidth={true}
                                    label="CUSTOMERS"
                                    onClick={this.onCustomerClick}
                                    startIcon={<MultiPersonIcon color={WHITE_COLOR} />}
                                />}

                                {(email === AdminCheck) && <LAButton
                                    label="REPORTS"
                                    className="mb-2"
                                    fullWidth={true}
                                    onClick={this.onReportClick}
                                    startIcon={<ReportIcon color={WHITE_COLOR} />}
                                />}

                                <LAButton
                                    className="mb-2"
                                    fullWidth={true}
                                    onClick={this.onOrderClick}
                                    startIcon={<PurchaseEquipmentIcon color={WHITE_COLOR} />}
                                    label={(email === AdminCheck) ? "ALL ORDERS" : (email === OrderdeskCheck) ? "CASH ORDERS" : "ORDERS"}
                                />

                                <LAButton label="LOGOUT" fullWidth={true} onClick={this.logOut} />
                            </div>
                        </HeaderPopOverButtonStyles>
                    </LAPaper>
                </Popover>
            </>
        );
    };

    private onReportClick = (): void => {
        this.props.history.push(ROUTE.REPORT());
    };

    private logOut = (): void => {
        this.props.logOutRequest();
        this.props.history.push(ROUTE.LOGIN());
    };

    private onCustomerClick = (): void => {
        this.props.history.push(ROUTE.CUSTOMERS());
    };

    private onOrderClick = (): void => {
        if (this.props.data.customer.email === AdminCheck) {
            this.props.history.push(ROUTE.ADMIN_ORDER());
        } else if(this.props.data.customer.email === OrderdeskCheck){
            this.props.history.push(ROUTE.CASH_ORDER());
        } else {
            this.props.history.push(ROUTE.CUSTOMER_ORDER());
        };
    };

    private handleClickAway = (): void => {
        this.setState({ open: false, openSwitch: false });
    };

    private onProfileClick = (): void => {
        this.props.history.push(ROUTE.LOGIN());
    };

    private handleClick = (): void => {
        this.setState((state) => ({ open: !state.open }));
    };

}

const mapStateToProps = (state: IStore): ILoginStoreProps => ({

});

const mapDispatchToProps = (dispatch: IDispatch): IUserDataDispatchProps => ({
    logOutRequest: (): unknown => dispatch(logoutAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(LAHeaderAvatar);
