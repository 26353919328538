import Helmet from "react-helmet";

interface IPageInfoProps {
    title: string;
    keywords?: string;
    description: string;
};

const PageInfo = (props: IPageInfoProps): JSX.Element => {
    //const title = `${props.title} - ${APP_NAME}`;
    const title = "Heavy Equipment Parts";
    const desc = "Heavy Equipment Parts and Dismantled Equipment - Volvo, CAT, Hitachi, Hyundai, Komatsu, Dodge, ISuzu, Esco ";
    return <Helmet>
        <title>{title}</title>
        <meta name="keywords" content={props.keywords ?? "Canada Heavy Equipment Parts, Edmonton Equipment Parts, Alberta Equipment Parts, Heavy Equipment Parts, Sureway Construction Parts, Dismantled Equipment, Parts site, parts buy online"} />
        <meta name="twitter:title" content={title} />
        <meta name="og:title" content={title} />
        <meta name="description" content={desc} />
    </Helmet>;
};

export default PageInfo;
