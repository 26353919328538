import { ReactNode, PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import LATextField from "../../shared/textField";
import PasswordTextField from "../../shared/passwordField";
import { LAButton } from "../../shared/buttons";
import { Server, STATUS_ENUM } from "../../../redux/server";
import { ILoginRequest, ILoginResponse } from "../../../redux/login/loginConstants";
import { login } from "../../../redux/login/loginAccessor";
import { loginLoadAction, logoutAction } from "../../../redux/login/loginActions";
import { ZEROTH } from "../../shared/constExports";
import LAErrorBox from "../../shared/errorBox";
import { RouteComponentProps } from "react-router";
import { ROUTE } from "../../routes";
import LALinkButton from "../../shared/linkButton";


interface ILoginComponentStoreProps {
    loginStatus: Server<ILoginResponse>;
};

interface ILoginComponentDispatchProps {
    logOutRequest: () => unknown;
    loginRequest: (data: ILoginRequest) => unknown;
};

interface ILoginComponentOwnProps {
    onRegister: () => void;
};

interface ILoginComponentState {
    email: string;
    password: string;
};

const LoginStyles = styled(LAPaperWithPadding)`
    margin: 20px 20px;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 15px 10px;
    };
`;

type ILoginComponentProps =
    RouteComponentProps
    & ILoginComponentStoreProps
    & ILoginComponentOwnProps
    & ILoginComponentDispatchProps;

class LoginComponent extends PureComponent<ILoginComponentProps, ILoginComponentState> {

    public constructor(props: ILoginComponentProps) {
        super(props);
        this.state = {
            email: "",
            password: ""
        };
    }

    public componentDidMount(): void {
    };

    public componentDidUpdate(prevProps: ILoginComponentProps): void {
        if (prevProps.loginStatus !== this.props.loginStatus) {
            if (this.props.loginStatus.kind === STATUS_ENUM.SUCCEEDED)
                this.props.history.push(ROUTE.PARTS());
        };
    };

    public render(): ReactNode {

        const { loginStatus, onRegister } = this.props;
        const { email, password } = this.state;

        return (
            <LoginStyles>
                <LAGrid spacing={3}>

                    {(loginStatus.kind === STATUS_ENUM.SUCCEEDED) &&
                        <>
                            <LAGridItem xs={12} className="text-center">
                                <h3>Welcome {loginStatus.payload.customer.firstname + " " + loginStatus.payload.customer.lastname}</h3>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAButton label="LOGOUT" fullWidth={true} onClick={this.logOut} />
                            </LAGridItem>
                        </>
                    }

                    {((loginStatus.kind === STATUS_ENUM.NOT_LOADED) || (loginStatus.kind === STATUS_ENUM.FAILED)) && <>
                        <LAGridItem xs={12}>
                            <h2 className="text-center">LOGIN</h2>
                        </LAGridItem>

                        {(loginStatus.kind === STATUS_ENUM.FAILED) &&
                            <LAGridItem xs={12}>
                                <LAErrorBox text={loginStatus.message.toString()} />
                            </LAGridItem>}

                        <LAGridItem xs={12} sm={12}>
                            <LATextField
                                name="email"
                                label="Email"
                                value={email}
                                fullWidth={true}
                                onChange={this.handleInfo}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12}>
                            <PasswordTextField
                                id="password"
                                name="password"
                                label="Password"
                                value={password}
                                required={true}
                                onChange={this.handleInfo}
                                fullWidth={true}
                                onPressEnter={this.login}
                                className="mb-2"
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAButton label="LOGIN" fullWidth={true} onClick={this.login} />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LALinkButton label="Register New Account" onClick={onRegister} />
                        </LAGridItem>
                    </>}

                </LAGrid>
            </LoginStyles>
        );
    }

    private handleInfo = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            [name]: value
        });
    };

    private logOut = (): void => {
        this.props.logOutRequest();
    };

    private login = (): void => {
        if ((this.state.email.length > ZEROTH) && (this.state.password.length > ZEROTH))
            this.props.loginRequest({ request: { Email: this.state.email, Password: this.state.password } });
    };

}

const mapStateToProps = (state: IStore): ILoginComponentStoreProps => ({
    loginStatus: login(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ILoginComponentDispatchProps => ({
    logOutRequest: (): unknown => dispatch(logoutAction),
    loginRequest: (data: ILoginRequest): unknown => dispatch(loginLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);