import React from "react";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { DARK_GREY_COLOR, GREEN_COLOR, RED_COLOR } from "../../shared/theme";
import { IPart } from "../../../redux/parts/getParts/getPartsConstants";
import { LAGreyPaper, LAPaperWithPadding } from "../../shared/paper";
import { LASecondaryButton } from "../../shared/buttons";
import LALinkButton from "../../shared/linkButton";
import { CartIcon, RemoveCartIcon } from "../../shared/icons";
import { ONE, ZEROTH } from "../../shared/constExports";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { LAPopover } from "../../shared/popOver";
import styled from "styled-components";
import Not_Available_Image from "../../../No-Image.jpg";


interface IPartsListOwnProps {
    data: IPart;
    open: boolean;
    onClose: () => void;
    onCartClick: (part: IPart) => void;
    cart: { id: number, name: string }[];
};

export const PartDetailPopUp = styled(LAGreyPaper)`
    .itemPaper .qtyWrapper {
        font-size: 20px;
    };

    .itemPaper .itemImageWithThumb {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 300px;
        height: 290px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };

    .itemPaper .itemImage {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 300px;
        height: 240px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };
`;

export const PartDetailPopup: React.FC<IPartsListOwnProps> = React.memo((props: IPartsListOwnProps) => {
    const images = getImage(props.data);
    const onCart = () => props.onCartClick(props.data);
    const cartCheck = props.cart.findIndex(q => q.id === props.data.id);
    return (
        <LAPopover anchorRef={null} open={props.open} onClose={props.onClose}>
            <PartDetailPopUp>
                <LAGrid>
                    <LAGridItem xs={12}>

                        <LAPaperWithPadding className="itemPaper">
                            <LAGrid>
                                <LAGridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <div className={`${images.length > ONE ? "itemImageWithThumb" : "itemImage"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {

                                    }}>
                                        <ImageGallery
                                            items={images}
                                            showNav={true}
                                            autoPlay={false}
                                            showPlayButton={false}
                                            showFullscreenButton={true}
                                            showThumbnails={(images.length > ONE) ? true : false}
                                        />
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <LAGrid spacing={1}>

                                        <LAGridItem xs={12} md={12}>
                                            <LAGrid spacing={3} justify="space-between" alignItems="center">
                                                <LAGridItem xs={12} sm={6} md={6} lg={7} xl={8}>
                                                    <div className="cursor" onClick={(e: React.MouseEvent<unknown>): void => {

                                                    }}>
                                                        <LALinkButton label={`${props.data.name}`} onClick={(): void => undefined} fontSize={20} />
                                                    </div>
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={6} md={6} lg={5} xl={4}>
                                                    <LAGrid spacing={0} justify="flex-end" alignItems="flex-end">
                                                        <LAGridItem sm={1} md={5}>

                                                        </LAGridItem>

                                                        <LAGridItem sm={11} md={7}>
                                                            <LASecondaryButton
                                                                onClick={onCart}
                                                                label={cartCheck >= 0 ? "Remove" : "Add To"}
                                                                endIcon={cartCheck >= 0 ? <RemoveCartIcon color={RED_COLOR} /> : <CartIcon color={GREEN_COLOR} />}
                                                            />
                                                        </LAGridItem>
                                                    </LAGrid>
                                                </LAGridItem>

                                            </LAGrid>
                                            <hr />
                                        </LAGridItem>

                                        <LAGridItem xs={12} md={12}>
                                            <LAGrid>

                                                <LAGridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <LAGrid alignItems="center" justify="space-between">

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Part #: </strong>
                                                            <span> {props.data.part_No}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Alternate Part #: </strong>
                                                            <span> {props.data.alternate_Part_No}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Quantity: </strong>
                                                            <span> {props.data.available_Quantity}</span>
                                                        </LAGridItem>

                                                        {/* <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Year: </strong>
                                                            <span> {props.data.year}</span>
                                                        </LAGridItem> */}

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Make: </strong>
                                                            <span> {props.data.make}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Model: </strong>
                                                            <span> {props.data.model}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Condition: </strong>
                                                            <span> {props.data.condition}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Type: </strong>
                                                            <span> {props.data.type}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Weight: </strong>
                                                            <span> {props.data.weight}</span>
                                                        </LAGridItem>

                                                        {/* <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Location: </strong>
                                                            <span> {props.data.location}</span>
                                                        </LAGridItem> */}

                                                    </LAGrid>
                                                </LAGridItem>


                                                <LAGridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <LAGrid alignItems="center" justify="space-between">

                                                    </LAGrid>
                                                </LAGridItem>


                                            </LAGrid>
                                            <hr />
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LAGrid>
                                                <LAGridItem xs={12}>
                                                    {props.data.description}
                                                </LAGridItem>

                                                {/* <LAGridItem xs={12}>
                                                    <strong>Notes: </strong>
                                                    <span>{props.data.notes}</span>
                                                </LAGridItem> */}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                    </LAGridItem>
                </LAGrid>
            </PartDetailPopUp>
        </LAPopover>
    )
});


interface IPartImageRender {
    original: string;
    thumbnail: string;
};

const getImage = (part: IPart): IPartImageRender[] => {
    const images: IPartImageRender[] = [];

    if (part.photos_1 != null) {
        images.push({ original: part.photos_1, thumbnail: part.photos_1 });
    }
    if (part.photos_2 != null)
        images.push({ original: part.photos_2, thumbnail: part.photos_2 });

    if (part.photos_3 != null)
        images.push({ original: part.photos_3, thumbnail: part.photos_3 });

    if (images.length === ZEROTH)
        images.push({ original: Not_Available_Image, thumbnail: Not_Available_Image });

    return images;
};
