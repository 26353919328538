import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../flushData.tsx/flushDataActions";
import { IRegistrationSuccessAction } from "../../registration/registrationActions";
import { IREGISTRATION_REQUEST } from "../../registration/registrationConstants";
import { Server, loading, failed, succeeded, notLoaded } from "../../server";
import { IGetPartsLoadAction, IGetPartsLoadFailedAction, IGetPartsSuccessAction } from "./getPartsActions";
import { IPart, IGET_PARTS_REQUEST } from "./getPartsConstants";


type Actions =
    IGetPartsLoadAction
    | IGetPartsLoadFailedAction
    | IGetPartsSuccessAction
    | IRegistrationSuccessAction
    | IFlushDataSuccessAction;

export const GetPartsReducer = (state: Server<PartsResponse<IPart[]>> = notLoaded, action: Actions): Server<PartsResponse<IPart[]>> => {
    switch (action.type) {
        case IGET_PARTS_REQUEST.REQUEST:
            return loading;

        case IGET_PARTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IREGISTRATION_REQUEST.SUCCESS:
            return notLoaded;

        case IGET_PARTS_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};