import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { updatePartsOrderPaymentLoadFailedAction, updatePartsOrderPaymentLoadSuccessAction, IUpdatePartsOrderPaymentLoadAction, IUpdatePartsOrderPaymentLoadFailedAction, IUpdatePartsOrderPaymentSuccessAction } from "./updatePartsOrderPaymentActions";
import { IUPDATE_PARTS_ORDER_PAYMENT_REQUEST } from "./updatePartsOrderPaymentConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";

export const updatePartsOrderPaymentEpic: Epic = (
    action$: ActionsObservable<IUpdatePartsOrderPaymentLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdatePartsOrderPaymentSuccessAction | IUpdatePartsOrderPaymentLoadFailedAction> =>
    action$.ofType(IUPDATE_PARTS_ORDER_PAYMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.UPDATE_PARTS_ORDER_PAYMENT,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<string>): IUpdatePartsOrderPaymentSuccessAction => {
                            return updatePartsOrderPaymentLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updatePartsOrderPaymentLoadFailedAction("Unable to save your selection")))
                    )
            )
        );