import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IContactUsLoadAction, IContactUsLoadFailedAction, IContactUsSuccessAction } from "./contactUsActions";
import { ICONTACT_US_REQUEST } from "./contactUsConstants";


type Actions =
    IContactUsLoadAction
    | IContactUsLoadFailedAction
    | IContactUsSuccessAction
    | IFlushDataSuccessAction;

export const ContactUsReducer = (state: Server<PartsResponse<string>> = notLoaded, action: Actions): Server<PartsResponse<string>> => {
    switch (action.type) {
        case ICONTACT_US_REQUEST.REQUEST:
            return loading;

        case ICONTACT_US_REQUEST.SUCCESS:
            return succeeded(action.response);

        case ICONTACT_US_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};