
export enum ICART_REQUEST {
    REQUEST = "cart/CART_REQUEST",
    SUCCESS = "cart/CART_SUCCESS",
    FAILED = "cart/CART_FAILED"
};

export enum IADD_CART_REQUEST {
    REQUEST = "addCart/ADD_CART_REQUEST",
    SUCCESS = "addCart/ADD_CART_SUCCESS",
    FAILED = "addCart/ADD_CART_FAILED"
};

export enum IREMOVE_CART_REQUEST {
    REQUEST = "removeCart/REMOVE_CART_REQUEST",
    SUCCESS = "removeCart/REMOVE_CART_SUCCESS",
    FAILED = "removeCart/REMOVE_CART_FAILED"
};

export interface ICart {
    id: number;
    name: string;
    note: string;
    price: string;
    quantity: number;
};

export const QuantityList = [
    {id: 0, value: "0"},
    {id: 1, value: "1"},{id: 2, value: "2"},{id: 3, value: "3"},{id: 4, value: "4"},{id: 5, value: "5"},
    {id: 6, value: "6"},{id: 7, value: "7"},{id: 8, value: "8"},{id: 9, value: "9"},{id: 10, value: "10+"}
];