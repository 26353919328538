export enum IUPDATE_PARTS_ORDER_PAYMENT_REQUEST {
    REQUEST = "updatePartsOrderPayment/UPDATE_PARTS_ORDER_PAYMENT_REQUEST",
    SUCCESS = "updatePartsOrderPayment/UPDATE_PARTS_ORDER_PAYMENT_SUCCESS",
    FAILED = "updatePartsOrderPayment/UPDATE_PARTS_ORDER_PAYMENT_FAILED"
};

export interface IUpdatePartsOrderPaymentRequest {
    token?: string;
    request: IUpdatePartsOrderPayment;
};

export interface IUpdatePartsOrderPayment {
    ID: number;
    Status: string;
    Modified_By: string;
    Quote_Response: string;
    payment_Details: string;
    shipment_Tracking_No: string;
};
