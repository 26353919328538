import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { updatePartsCashOrderLoadFailedAction, updatePartsCashOrderLoadSuccessAction, IUpdatePartsCashOrderLoadAction, IUpdatePartsCashOrderLoadFailedAction, IUpdatePartsCashOrderSuccessAction } from "./updatePartsCashOrderActions";
import { IUPDATE_PARTS_CASH_ORDER_REQUEST } from "./updatePartsCashOrderConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";
import { IGetAllPartsCashOrders } from "../getAllPartsCashOrders/getAllPartsCashOrdersConstants";

export const updatePartsCashOrderEpic: Epic = (
    action$: ActionsObservable<IUpdatePartsCashOrderLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdatePartsCashOrderSuccessAction | IUpdatePartsCashOrderLoadFailedAction> =>
    action$.ofType(IUPDATE_PARTS_CASH_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IGetAllPartsCashOrders>>(
                    END_POINTS.UPDATE_PARTS_CASH_ORDER,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<IGetAllPartsCashOrders>): IUpdatePartsCashOrderSuccessAction => {
                            return updatePartsCashOrderLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(updatePartsCashOrderLoadFailedAction("Unable to update parts order")))
                    )
            )
        );