import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import LAPagination from "../../shared/pagination";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, PartsResponse } from "../../shared/publicInterfaces";
import queryString from "query-string";
import { ROUTE } from "../../routes";
import { ILoginResponse } from "../../../redux/login/loginConstants";
import { login } from "../../../redux/login/loginAccessor";
import { AdminCheck, callRouteWithQueryString, CheckCountryCanada, getUSDRate, ONE, OrderdeskCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import { IGetPartsOrderByCustomer, IGetPartsOrderByCustomerRequest } from "../../../redux/getPartsOrderByCustomer/getPartsOrderByCustomerConstants";
import { getPartsOrderByCustomer } from "../../../redux/getPartsOrderByCustomer/getPartsOrderByCustomerAccessor";
import { getPartsOrderByCustomerLoadAction } from "../../../redux/getPartsOrderByCustomer/getPartsOrderByCustomerActions";
import { OrderList } from "./orderList";
import LAErrorBox from "../../shared/errorBox";
import { OrderItemPopUpComponent } from "../adminOrder/orderItemPopUp";
import { partsOrderCustomerQuoteResponseStatus } from "../../../redux/partsOrderCustomerQuoteResponse/partsOrderCustomerQuoteResponseAccessor";
import { partsOrderCustomerQuoteResponseLoadAction } from "../../../redux/partsOrderCustomerQuoteResponse/partsOrderCustomerQuoteResponseActions";
import { IPartsOrderCustomerQuoteResponseRequest } from "../../../redux/partsOrderCustomerQuoteResponse/partsOrderCustomerQuoteResponseConstants";
import RequestStatus from "../../shared/requestStatusSnackbar";
import SearchBox from "../../shared/searchBox";
import LoginAndRegisterComponent from "../loginAndRegister";
import { addPartsOrder } from "../../../redux/addPartsOrder/addPartsOrderAccessor";
import { IAddPartsOrderResponse } from "../../../redux/addPartsOrder/addPartsOrderConstants";
import { END_POINTS } from "../../../redux/endpoints";
import { RejectQuoteReasonPopup } from "./rejectQuoteReasonPopup";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidation";
import PageSpacing from "../../shared/pageSpacing";


interface IOrdersComponentStoreProps {
    loginStatus: Server<ILoginResponse>;
    partsOrderCustomerQuoteResponse: Server<string>;
    orders: Server<PartsResponse<IGetPartsOrderByCustomer[]>>;
    addPartsOrder: Server<PartsResponse<IAddPartsOrderResponse>>;
};

interface IOrdersComponentDispatchProps {
    ordersRequest: (data: IGetPartsOrderByCustomerRequest) => unknown;
    partsOrderCustomerQuoteResponseRequest: (data: IPartsOrderCustomerQuoteResponseRequest) => unknown;
};

interface IOrdersComponentState {
    open: boolean;
    message: string;
    searchText: string;
    currentPage: number;
    totalRecords: number;
    rejectQuote: boolean;
    disable: true | undefined;
    redirectedMessage: string;
    redirectFromEmail: boolean;
    usdRate: number | undefined;
    openOrder: number | undefined;
    data: IGetPartsOrderByCustomer[];
    errors: ById<IFieldErrorKeyValue>;
};

const OrdersStyles = styled(LAPaperWithPadding)`

`;

type IOrdersComponentProps =
    RouteComponentProps
    & IOrdersComponentStoreProps
    & IOrdersComponentDispatchProps;

class OrdersComponent extends PureComponent<IOrdersComponentProps, IOrdersComponentState> {

    public constructor(props: IOrdersComponentProps) {
        super(props);
        this.state = {
            data: [],
            errors: {},
            message: "",
            open: false,
            searchText: "",
            currentPage: ONE,
            disable: undefined,
            rejectQuote: false,
            usdRate: undefined,
            openOrder: undefined,
            totalRecords: ZEROTH,
            redirectedMessage: "",
            redirectFromEmail: false,
        };
        this.keyWordSearch = this.debounce(this.keyWordSearch, 500);
    }

    public async componentDidMount(): Promise<void> {
        await this.handleServerCall();
        this.setDataToState();

        // const query: any = queryString.parse(this.props.location.search);
        // if (query.message && (query.message === "success") && hasPayload(this.props.addPartsOrder) && this.props.addPartsOrder.payload.message.toLocaleLowerCase().includes("success")) {
        //     this.setState({ redirectedMessage: this.props.addPartsOrder.payload.message });
        // };
    };

    public async componentDidUpdate(prevProps: IOrdersComponentProps): Promise<void> {

        if (this.props.loginStatus !== prevProps.loginStatus) {
            await this.handleServerCall();
            this.setDataToState();
        };

        if ((hasPayload(this.props.loginStatus) && this.props.loginStatus.payload.customer.email === AdminCheck))
            this.props.history.push(ROUTE.LOGIN());

        if ((this.props.orders !== prevProps.orders))
            this.setDataToState();

        if (this.props.partsOrderCustomerQuoteResponse !== prevProps.partsOrderCustomerQuoteResponse) {
            if (hasPayload(this.props.partsOrderCustomerQuoteResponse)) {
                if (this.props.partsOrderCustomerQuoteResponse.payload.toLocaleLowerCase().includes("recorded and sent to parts")) {
                    if (this.state.redirectFromEmail) {
                        await this.setState({ disable: true, redirectedMessage: this.props.partsOrderCustomerQuoteResponse.payload, open: false, openOrder: undefined, redirectFromEmail: false });
                        callRouteWithQueryString({
                            route: this.props, pathName: ROUTE.CUSTOMER_ORDER(),
                            search: { pageNumber: 1, pageSize: 20 }
                        });

                        this.handleServerCall();
                    } else {
                        this.setState({ disable: true, redirectedMessage: this.props.partsOrderCustomerQuoteResponse.payload, open: false, openOrder: undefined });
                    }
                } else {
                    this.setState({ message: this.props.partsOrderCustomerQuoteResponse.payload });
                }
            };
        };
    }

    public render(): ReactNode {

        const { orders, loginStatus, partsOrderCustomerQuoteResponse } = this.props;
        const { totalRecords, currentPage, data, open, openOrder, disable, message, searchText, redirectedMessage, rejectQuote, errors, usdRate } = this.state;
        

        return (
            <PageSpacing title="Customer Orders" description="Customer Orders">
            <OrdersStyles>
                <LAGrid spacing={1}>

                    {(isNotLoaded(loginStatus)) && <LAGridItem xs={12}>
                        <LoginAndRegisterComponent {...this.props} />
                    </LAGridItem>}

                    {(hasPayload(loginStatus) && (loginStatus.payload.customer.email !== AdminCheck) && (loginStatus.payload.customer.email !== OrderdeskCheck)) && <>
                        <LAGridItem xs={12}>
                            <h2 className="text-center">ORDER HISTORY</h2>
                        </LAGridItem>

                        {redirectedMessage !== "" && <LAGridItem xs={12}>
                            <LAErrorBox text={redirectedMessage} />
                        </LAGridItem>}

                        <LAGridItem xs={12}>
                            <LAPaperWithPadding>

                                <SearchBox
                                    className="p-3"
                                    fullWidth={true}
                                    text={searchText}
                                    placeHolder="Search"
                                    onSubmit={undefinedFunction}
                                    onChange={this.handleSearchTextChange}
                                    searchStatus={STATUS_ENUM.SUCCEEDED}
                                />

                                <OrderList
                                    data={data}
                                    dataStatus={orders.kind}
                                    onClick={this.onViewItems}
                                />
                            </LAPaperWithPadding>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAPagination
                                rowsPerPage={20}
                                className="mt-5"
                                currentPage={currentPage}
                                numberOfItems={totalRecords}
                                onPageChange={this.handlePageChange}
                            />
                        </LAGridItem>
                    </>}

                </LAGrid>

                {(open && (openOrder !== undefined)) &&
                    <OrderItemPopUpComponent open={open} data={data[openOrder]} onClose={this.handleClose} onSubItemChange={undefinedFunction} onSave={this.handleAccept}
                        customerView={true} disable={disable} onMasterObjChange={undefinedFunction} onReject={this.onRejectQuote} message={message} updatePayment={true}
                        errors={{}} status={data[openOrder].status} downloadInvoice={this.downloadInvoice} usdRate={usdRate ?? 0} onCancelOrder={undefinedFunction}
                        reActivatePartsOrder={undefinedFunction}
                    />
                }

                {rejectQuote && (openOrder !== undefined) && <RejectQuoteReasonPopup value={data[openOrder].notes} open={rejectQuote}
                    onChange={this.onMasterObjChange} onSave={this.handleReject} onClose={this.onRejectQuote} errors={errors} />}

                <RequestStatus requestStatus={partsOrderCustomerQuoteResponse.kind} />
            </OrdersStyles>
            </PageSpacing>
        );
    }

    private onRejectQuote = (): void => {
        const { errors } = this.state;
        errors["notes"] = { key: "notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        this.setState({ rejectQuote: !this.state.rejectQuote, errors });
    };

    private onMasterObjChange = (name: string, value: string): void => {
        const { openOrder, errors } = this.state;
        if (openOrder !== undefined) {
            const iS = [...this.state.data];
            let found = { ...iS[openOrder] };
            iS[openOrder] = { ...found, [name]: value };

            if ((name === "notes")) {
                if (value.length === ZEROTH) {
                    errors["notes"] = { key: "notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                } else {
                    delete errors["notes"];
                }
            };

            this.setState({ data: iS, errors });
        };
    };

    private downloadInvoice = (id: number): void => {
        if (hasPayload(this.props.loginStatus))
            fetch(END_POINTS.DOWNLOAD_INVOICE, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: this.props.loginStatus.payload.token,
                    request: {
                        OrderId: id,
                        Type: "parts"
                    }
                })
            })
                .then(res => {
                    return res.blob();
                })
                .then(async blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.target = "_blank";
                    a.download = `OrderInvoice-${id}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch((res) => console.log(res));
    };

    private handleSearchTextChange = async (searchTextString: string): Promise<void> => {
        this.setState({ searchText: searchTextString, redirectedMessage: "" });
        this.keyWordSearch();
    };

    private keyWordSearch = async (): Promise<void> => {
        await callRouteWithQueryString({
            route: this.props, pathName: ROUTE.CUSTOMER_ORDER(),
            search: { keyword: this.state.searchText, pageNumber: this.state.currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private debounce = (fn: any, delay: any): any => {
        let timer: any = null;
        return (...args: any): any => {
            const context = this;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    };

    private handleAccept = (): void => {
        if (hasPayload(this.props.loginStatus) && (this.state.openOrder !== undefined)) {
            this.props.partsOrderCustomerQuoteResponseRequest({
                token: this.props.loginStatus.payload.token,
                request: {
                    Comments: "",
                    Quote_Response: "Accepted",
                    ID: this.state.data[this.state.openOrder].id,
                    Modified_By: this.props.loginStatus.payload.customer.email
                }
            });
        }
    };

    private handleReject = (): void => {
        if (hasPayload(this.props.loginStatus) && (this.state.openOrder !== undefined)) {
            this.props.partsOrderCustomerQuoteResponseRequest({
                token: this.props.loginStatus.payload.token,
                request: {
                    Quote_Response: "Rejected",
                    ID: this.state.data[this.state.openOrder].id,
                    Comments: this.state.data[this.state.openOrder].notes,
                    Modified_By: this.props.loginStatus.payload.customer.email
                }
            });
        }
    };

    private handlePageChange = async (currentPage?: number, rowsPerPage?: number): Promise<void> => {
        this.setState({ currentPage: currentPage ?? 1, redirectedMessage: "" });
        await callRouteWithQueryString({
            route: this.props, pathName: ROUTE.CUSTOMER_ORDER(),
            search: { pageNumber: currentPage, pageSize: 20 }
        });

        this.handleServerCall();
    };

    private setDataToState = async (): Promise<void> => {
        if (hasPayload(this.props.orders)) {
            const query: any = queryString.parse(this.props.location.search);

            const val = this.props.orders.payload;
            await this.setState({
                data: [...val.response],
                totalRecords: val.totalRecords ?? 0,
                currentPage: query.pageNumber ? Number(query.pageNumber) : 1
            });

            if (this.state.redirectFromEmail && (this.state.data.length > ZEROTH))
                this.onViewItems(0);
        };

        if (isNotLoaded(this.props.orders))
            this.handleServerCall();
    };

    private handleServerCall = (): void => {
        if (hasPayload(this.props.loginStatus)) {
            const query: any = queryString.parse(this.props.location.search);

            this.props.ordersRequest({
                token: this.props.loginStatus.payload.token,
                itemId: query.itemId ? Number(query.itemId) : 0,
                request: {
                    CustomerID: this.props.loginStatus.payload.customer.id
                },
                PageSize: 20,
                keywords: query.keyword ? query.keyword : "",
                PageNumber: query.pageNumber ? Number(query.pageNumber) : 1
            });

            if (query.itemId)
                this.setState({ redirectFromEmail: true });

        };
    };

    private handleClose = async (): Promise<void> => {
        if (this.state.redirectFromEmail) {
            await this.setState({ open: false, openOrder: undefined, disable: undefined, message: "", redirectedMessage: "", redirectFromEmail: false });

            callRouteWithQueryString({
                route: this.props, pathName: ROUTE.CUSTOMER_ORDER(),
                search: { pageNumber: 1, pageSize: 20 }
            });

            this.handleServerCall();
        } else {
            this.setState({ open: false, openOrder: undefined, disable: undefined, message: "", redirectedMessage: "", redirectFromEmail: false });
        };
    };

    private onViewItems = async (index: number): Promise<void> => {
        const status = this.state.data[index].status;
        let usdRate = this.state.usdRate;

        if((usdRate === undefined) && (!CheckCountryCanada(this.state.data[index].shipping_Country))
        && (status === "Order Received") && hasPayload(this.props.loginStatus)){
            usdRate = await getUSDRate(this.props.loginStatus.payload.token);
        };
        
        this.setState({
            usdRate,
            open: true, openOrder: index,
            redirectedMessage: "",
            disable: ((status === "Order Sent") || (status === "Accepted") 
            || (status === "Rejected") || (status === "Completed")
            || (status === "Order Received") || (status === "Cancelled")) ? true : undefined,
        });
    };
}

const mapStateToProps = (state: IStore): IOrdersComponentStoreProps => ({
    loginStatus: login(state),
    addPartsOrder: addPartsOrder(state),
    orders: getPartsOrderByCustomer(state),
    partsOrderCustomerQuoteResponse: partsOrderCustomerQuoteResponseStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IOrdersComponentDispatchProps => ({
    ordersRequest: (data: IGetPartsOrderByCustomerRequest): unknown => dispatch(getPartsOrderByCustomerLoadAction(data)),
    partsOrderCustomerQuoteResponseRequest: (data: IPartsOrderCustomerQuoteResponseRequest): unknown => dispatch(partsOrderCustomerQuoteResponseLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(OrdersComponent);