import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { HasClass } from "./publicInterfaces";

interface ILACheckBoxProps extends HasClass {
    label: string;
    value: boolean;
    disabled?: true;
    onChange: (checked: boolean) => void;
}

export const LACheckBox = (props: ILACheckBoxProps): JSX.Element =>
    <FormControlLabel
        className={props.className}
        control={
            <Checkbox
                color="secondary"
                name={props.label}
                checked={props.value}
                onChange={(e, checked) => props.onChange(checked)}
            />
        }
        label={props.label}
    />
    ;
