
export enum ILOGIN_REQUEST {
    REQUEST = "login/LOGIN_REQUEST",
    SUCCESS = "login/LOGIN_SUCCESS",
    FAILED = "login/LOGIN_FAILED",
    LOGOUT =  "login/LOGOUT_ACTION"
};

export interface ILoginRequest {
    request: ILogin;
};

export interface ILogin {
    Email: string;
    Password: string;
};

export interface ILoginResponse {
    token: string;
    customer: {
        id: number;
        firstname: string;
        lastname: string;
        email: string;
        address: string;
        city: string;
        company: string;
        country: string;
        province: string;
        phone: string;
        billing_city: string;
        billing_province: string;
        billing_country: string;        
        billing_address: string;
        postal_code: string;
        billing_postal_code: string;
    };
};
