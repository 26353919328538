import { ILOGIN_REQUEST, ILoginRequest, ILoginResponse } from "./loginConstants";

export interface ILoginLoadAction {
    type: ILOGIN_REQUEST.REQUEST;
    data: ILoginRequest
}
export const loginLoadAction = (data: ILoginRequest): ILoginLoadAction => ({
    type: ILOGIN_REQUEST.REQUEST,
    data
});

export interface ILoginSuccessAction {
    type: ILOGIN_REQUEST.SUCCESS;
    data: ILoginResponse;
}
export const loginLoadSuccessAction = (data: ILoginResponse): ILoginSuccessAction => ({
    type: ILOGIN_REQUEST.SUCCESS,
    data
});
export interface ILoginLoadFailedAction {
    type: ILOGIN_REQUEST.FAILED;
    message: string;
}
export const loginLoadFailedAction = (message: string): ILoginLoadFailedAction => ({
    type: ILOGIN_REQUEST.FAILED,
    message
});

export interface ILogoutAction {
    type: ILOGIN_REQUEST.LOGOUT;
}

export const logoutAction: ILogoutAction = {
    type: ILOGIN_REQUEST.LOGOUT
};
