
export enum IADD_PARTS_CASH_ORDER_REQUEST {
    REQUEST = "addPartsCashOrder/ADD_PARTS_CASH_ORDER_REQUEST",
    SUCCESS = "addPartsCashOrder/ADD_PARTS_CASH_ORDER_SUCCESS",
    FAILED = "addPartsCashOrder/ADD_PARTS_CASH_ORDER_FAILED"
};

export interface IAddPartsCashOrderRequest {
    token?: string;
    request: IAddPartsCashOrder;
};

export interface IAddPartsCashOrder {
    Customer_ID: number; 
    Date: string;
    Total_Items_Cost: number;
    Tax: number;
    Total_Cost: number;
    Payment_Details: string;
    Notes: string;
    Created: string;
    Created_By: string;
    Items: IAddPartsCashOrderItem[];
};

export interface IAddPartsCashOrderItem {
    Part_no: string;
    Quantity: number;
    Price: number;
    Total_Cost: number;
    Notes: string;
};

export interface IAddPartsCashOrderResponse {

};
