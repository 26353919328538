import {IStore} from "./reducers";

export const ajaxHeadersFromState = (state: IStore): object => {
    return {};
};

export const ajaxHeadersFromStore = (theStore: IStore): object => {
    if (!theStore) {
        throw new Error("The store is not initialized");
    }
    return ajaxHeadersFromState(theStore);
};

export const withJsonContentType = (obj: object): object => ({
    ...obj,
    "Content-Type": "application/json;charset=utf-8"
});