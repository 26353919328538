import { IPartsOrderCustomerQuoteResponseRequest, IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST } from "./partsOrderCustomerQuoteResponseConstants";

export interface IPartsOrderCustomerQuoteResponseLoadAction {
    type: IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.REQUEST;
    data: IPartsOrderCustomerQuoteResponseRequest
}
export const partsOrderCustomerQuoteResponseLoadAction = (data: IPartsOrderCustomerQuoteResponseRequest): IPartsOrderCustomerQuoteResponseLoadAction => ({
    type: IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.REQUEST,
    data
});

export interface IPartsOrderCustomerQuoteResponseSuccessAction {
    type: IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.SUCCESS;
    response: string;
}
export const partsOrderCustomerQuoteResponseLoadSuccessAction = (response: string): IPartsOrderCustomerQuoteResponseSuccessAction => ({
    type: IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.SUCCESS,
    response
});
export interface IPartsOrderCustomerQuoteResponseLoadFailedAction {
    type: IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.FAILED;
    message: string;
}
export const partsOrderCustomerQuoteResponseLoadFailedAction = (message: string): IPartsOrderCustomerQuoteResponseLoadFailedAction => ({
    type: IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.FAILED,
    message
});
