import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";
import { ILoginLoadAction, ILoginLoadFailedAction, ILoginSuccessAction, loginLoadFailedAction, loginLoadSuccessAction } from "./loginActions";
import { ILoginResponse, ILOGIN_REQUEST } from "./loginConstants";
import { IStore } from "../reducers";
import { EpicDependencies } from "../store";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { END_POINTS } from "../endpoints";

export const loginEpic: Epic = (
    action$: ActionsObservable<ILoginLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ILoginSuccessAction | ILoginLoadFailedAction> =>
    action$.ofType(ILOGIN_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<ILoginResponse>>(
                    END_POINTS.LOGIN,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<ILoginResponse>): ILoginSuccessAction | ILoginLoadFailedAction => {
                            if(response.message === "Success"){
                                return loginLoadSuccessAction(response.response);
                            };
                            return loginLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(loginLoadFailedAction("Unable to login")))
                    )
            )
        );