
export enum ICONTACT_US_REQUEST {
    REQUEST = "contactUs/CONTACT_US_REQUEST",
    SUCCESS = "contactUs/CONTACT_US_SUCCESS",
    FAILED = "contactUs/CONTACT_US_FAILED"
};

export interface IContactUsRequest {
    request: IContactUs;
};

export interface IContactUs {
    Name: string;
    Phone_No: string;
    Email: string;
    Message: string;
};
