import { hasPayload } from './../server';
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IAddPartsCashOrderSuccessAction } from "../addPartsCashOrder/addPartsCashOrderActions";
import { IADD_PARTS_CASH_ORDER_REQUEST } from "../addPartsCashOrder/addPartsCashOrderConstants";
import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IUpdatePartsCashOrderSuccessAction } from "../updatePartsCashOrder/updatePartsCashOrderActions";
import { IUPDATE_PARTS_CASH_ORDER_REQUEST } from "../updatePartsCashOrder/updatePartsCashOrderConstants";
import { IGetAllPartsCashOrdersLoadAction, IGetAllPartsCashOrdersLoadFailedAction, IGetAllPartsCashOrdersSuccessAction } from "./getAllPartsCashOrdersActions";
import { IGetAllPartsCashOrders, IGET_ALL_PARTS_CASH_ORDERS_REQUEST } from "./getAllPartsCashOrdersConstants";


type Actions =
    IGetAllPartsCashOrdersLoadAction
    | IGetAllPartsCashOrdersLoadFailedAction
    | IGetAllPartsCashOrdersSuccessAction
    | IAddPartsCashOrderSuccessAction
    | IUpdatePartsCashOrderSuccessAction
    | IFlushDataSuccessAction;

export const GetAllPartsCashOrdersReducer = (state: Server<PartsResponse<IGetAllPartsCashOrders[]>> = notLoaded, action: Actions): Server<PartsResponse<IGetAllPartsCashOrders[]>> => {
    switch (action.type) {
        case IGET_ALL_PARTS_CASH_ORDERS_REQUEST.REQUEST:
            return loading;

        case IGET_ALL_PARTS_CASH_ORDERS_REQUEST.SUCCESS:
            return succeeded(action.response);

        case IGET_ALL_PARTS_CASH_ORDERS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_PARTS_CASH_ORDER_REQUEST.SUCCESS:
            if(hasPayload(state)){
                const newData = action.response.response;

                const index = state.payload.response.findIndex(x => x.id === newData.id);
                state.payload.response[index] = newData;
                return state;

            } else {
                return notLoaded;
            };

        case IADD_PARTS_CASH_ORDER_REQUEST.SUCCESS:
            if(hasPayload(state)){
                const newData = action.response.response;
                state.payload.response.push(newData);
                return state;

            } else {
                return notLoaded;
            };

        default:
            return state;
    }
};