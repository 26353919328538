
export enum IUPDATE_PROFILE_REQUEST {
    REQUEST = "updateProfile/UPDATE_PROFILE_REQUEST",
    SUCCESS = "updateProfile/UPDATE_PROFILE_SUCCESS",
    FAILED = "updateProfile/UPDATE_PROFILE_FAILED"
};

export interface IUpdateProfileRequest {
    request: IUpdateProfileData;
    token: string;
};

export interface IUpdateProfileData {
    id: number;
    First_Name: string;
    Last_Name: string;
    Email: string;
    Password: string;
    Address: string;
    City: string;
    Company: string;
    Country: string;
    Province: string;
    Postal_Code: string;
    Phone: string;
    Billing_City: string;
    Billing_Province: string;
    Billing_Country: string;
    Billing_Address: string;
    Billing_Postal_Code: string;
};