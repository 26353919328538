import { PartsResponse } from "../../react/shared/publicInterfaces";
import { IGetSEOKeywordsRequest, IGET_SEO_KEYWORDS_REQUEST } from "./getSEOKeywordsConstants";

export interface IGetSEOKeywordsLoadAction {
    type: IGET_SEO_KEYWORDS_REQUEST.REQUEST;
    data: IGetSEOKeywordsRequest
}
export const getSEOKeywordsLoadAction = (data: IGetSEOKeywordsRequest): IGetSEOKeywordsLoadAction => ({
    type: IGET_SEO_KEYWORDS_REQUEST.REQUEST,
    data
});

export interface IGetSEOKeywordsSuccessAction {
    type: IGET_SEO_KEYWORDS_REQUEST.SUCCESS;
    response: PartsResponse<string>;
}
export const getSEOKeywordsLoadSuccessAction = (response: PartsResponse<string>): IGetSEOKeywordsSuccessAction => ({
    type: IGET_SEO_KEYWORDS_REQUEST.SUCCESS,
    response
});
export interface IGetSEOKeywordsLoadFailedAction {
    type: IGET_SEO_KEYWORDS_REQUEST.FAILED;
    message: string;
}
export const getSEOKeywordsLoadFailedAction = (message: string): IGetSEOKeywordsLoadFailedAction => ({
    type: IGET_SEO_KEYWORDS_REQUEST.FAILED,
    message
});
