
export enum IPARTS_FACET_REQUEST {
    REQUEST = "partsFacet/PARTS_FACET_REQUEST",
    SUCCESS = "partsFacet/PARTS_FACET_SUCCESS",
    FAILED = "partsFacet/PARTS_FACET_FAILED"
};

export interface IGetPartsFacetRequest {
    token?: string;
    request: {
        Makes: number[];
    }
};

export interface IPartsFacetResponse {
  manufacturers: IManufactureFacet[];
  models: IModelFacet[];
};

export interface IDismantledFilter {
    Make: string;
    Equipment: string;
  };

export interface IManufactureFacet {
    count: number;
    manufacturer: string;
    manufacturerID: number;
};

export interface IModelFacet {
    count: number;
    model: string;
};