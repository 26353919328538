
export enum IGET_PARTS_ORDER_REPORTS_REQUEST {
    REQUEST = "getPartsOrderReports/GET_PARTS_ORDERS_REPORTS_REQUEST",
    SUCCESS = "getPartsOrderReports/GET_PARTS_ORDERS_REPORTS_SUCCESS",
    FAILED = "getPartsOrderReports/GET_PARTS_ORDERS_REPORTS_FAILED"
};

export interface IGetPartsOrderReportsRequest {
    token: string;
};

export interface IPartsOrderReport {
    id: number;
    date: string;
    customer_ID: number;
    customer_Name: string;
    phone_No: string;
    email: string;
    invoice_No: string;
    status: string;
    notes: string;
    shipping_Address: string;
    shipping_Postal_Code: string;
    total_Items_Cost: number;
    shipping_Cost: number;
    total_Cost: number;
    tax: number;
    shipment_Tracking_No: string;
    payment_Details: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    usD_Rate: number;
    customer_Last_Email_Reminder_Sent: string;
    shipping_City: string;
    shipping_Province: string;
    shipping_Country: string;
    itemID: number;
    part_No: string;
    quantity: number;
    price: number;
    item_Notes: string;
    discount: number;
    item_Total_Cost: number;
};