import React from "react";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import { DEFAULT_BOX_SHADOW, DARK_GREY_COLOR, LIGHT_GREY_COLOR } from "./theme";
import { HasClassAndChildren } from "./publicInterfaces";

const LAPaperWithPaddingStyles = styled(Paper)`
    padding: 15px 25px 25px !important;
    position: relative;
    border: 1px solid ${DARK_GREY_COLOR};
    ${DEFAULT_BOX_SHADOW}
`;

interface ILAPaperWithPaddingProps extends HasClassAndChildren {
    changeColorOnHover?: boolean;
}


export const LAPaperWithPadding: React.FC<HasClassAndChildren> = (props: ILAPaperWithPaddingProps) =>
    <LAPaperWithPaddingStyles className={`${props.className}`} elevation={0}>{props.children}</LAPaperWithPaddingStyles>
    ;

const LAPaperWithLessPaddingStyles = styled(Paper)`
    padding: 5px 5px 5px !important;
    position: relative;
    border: 1px solid ${DARK_GREY_COLOR};
    ${DEFAULT_BOX_SHADOW}
`;

export const LAPaperWithLessPadding: React.FC<HasClassAndChildren> = (props: ILAPaperWithPaddingProps) =>
    <LAPaperWithLessPaddingStyles className={`${props.className}`} elevation={0}>{props.children}</LAPaperWithLessPaddingStyles>
    ;


const LAGreyPaperStyles = styled(Paper)`
    padding: 15px 25px 25px !important;
    position: relative;
    border: 1px solid ${DARK_GREY_COLOR};
    ${DEFAULT_BOX_SHADOW}
    background-color: ${LIGHT_GREY_COLOR} !important;
    border: 1px solid ${DARK_GREY_COLOR};
`;

export const LAGreyPaper: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LAGreyPaperStyles
        className={props.className}
        elevation={0}
    >
        {props.children}
    </LAGreyPaperStyles>
    ;

    const LAGreyPaperWithLessPaddingStyles = styled(Paper)`
    padding: 5px 5px 5px !important;
    position: relative;
    border: 1px solid ${DARK_GREY_COLOR};
    ${DEFAULT_BOX_SHADOW}
    background-color: ${LIGHT_GREY_COLOR} !important;
    border: 1px solid ${DARK_GREY_COLOR};
`;

export const LAGreyPaperWithLessPadding: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LAGreyPaperWithLessPaddingStyles
        className={props.className}
        elevation={0}
    >
        {props.children}
    </LAGreyPaperWithLessPaddingStyles>
    ;

const LAPaperStyles = styled(Paper)`

    position: relative;
    border: 1px solid ${DARK_GREY_COLOR};
    ${DEFAULT_BOX_SHADOW}
`;
export const LAPaper: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LAPaperStyles className={props.className} elevation={0}>{props.children}</LAPaperStyles>
    ;
