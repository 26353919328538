import React, { useState } from "react";
import styled from "styled-components";
import LAGrid from "../../shared/grid";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import LAGridItem from "../../shared/gridList";
import { STATUS_ENUM } from "../../../redux/server";
import { LASecondaryButton } from "../../shared/buttons";
import LALinkButton from "../../shared/linkButton";
import Not_Available_Image from "../../../No-Image.jpg";
import { ONE, ZEROTH } from "../../shared/constExports";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Gallery from "devextreme-react/gallery";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { DARK_GREY_COLOR, MAIN_COLOR } from "../../shared/theme";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IGetPartsOrderByCustomerItem } from "../../../redux/getPartsOrderByCustomer/getPartsOrderByCustomerConstants";
import { IPartDismantledEquipment } from "../../../redux/getPartsDismantledEquipments/getPartsDismantledEquipmentConstants";

interface IDismantledPartsListOwnProps {
    isAdmin: boolean;
    dataStatus: STATUS_ENUM;
    data: IPartDismantledEquipment[];
    cart: { id: number, name: string }[];
    onPartClick: (part: IPartDismantledEquipment) => void;
};

const PartListStyles = styled.div`
    .deskView {
        display: block;
    };

    .mobView {
        display: none;
    };

    .itemPaper .qtyWrapper {
        font-size: 20px;
    };

    #gallery{
        width: 100% !important;
    };

    .disableArrows {
        .lb-button.prev {
            display: none;
        };

        .lb-button.next {
            display: none;
        };
    };
    
    .itemPaper .itemImageWithThumb {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 170px;
        height: 100px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        }
    };

    .itemPaper .itemImage {
        padding: 5px;
        border: 1px solid ${DARK_GREY_COLOR};
        max-width: 170px;
        height: 100px;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            height: auto;
        };
    };

    @media only screen and (max-width: ${1700}px) {
        .deskView {
            display: none;
        };

        .mobView {
            display: block;
        };

        .itemPaper .itemImageWithThumb {
            padding: 5px;
            border: 1px solid ${DARK_GREY_COLOR};
            max-width: 300px;
            height: 260px;
            overflow: hidden;
            text-align: center;
            img {
                width: 100%;
                height: auto;
            }
        };
    
        .itemPaper .itemImage {
            padding: 5px;
            border: 1px solid ${DARK_GREY_COLOR};
            max-width: 300px;
            height: 220px;
            overflow: hidden;
            text-align: center;
            img {
                width: 100%;
                height: auto;
            };
        };
    };
`;

export const PartList: React.FC<IDismantledPartsListOwnProps> = React.memo((props: IDismantledPartsListOwnProps) => {
    switch (props.dataStatus) {
        case STATUS_ENUM.LOADING:
            return <LAGrid alignItems="center" spacing={3} justify="space-around">
                <LAGridItem xs={12}><LALoading className="text-center" message="Loading Dismantled Parts. Please Wait..." /></LAGridItem>
            </LAGrid>;

        case STATUS_ENUM.FAILED:
            return <LAGrid alignItems="center" spacing={3} justify="space-around">
                <LAGridItem xs={12}><LAErrorBox color={MAIN_COLOR} text="Sorry Failed to Load Dismantled Parts. Try to refresh the window..." /></LAGridItem>
            </LAGrid>;

        case STATUS_ENUM.SUCCEEDED:
            return <PartListStyles>
                <LAGrid alignItems="center" spacing={1} justify="space-around">
                    {Object.values(props.data).length === ZEROTH && <LAGridItem xs={12}>
                        <LAErrorBox text="No records found!" />
                    </LAGridItem>}

                    <LAGridItem xs={12} className="mobView">
                        <DismantledPartsPaper {...props} />
                    </LAGridItem>

                    <LAGridItem xs={12} className="deskView">
                        <DismantledPartsGridPaper {...props} />
                    </LAGridItem>
                </LAGrid>
            </PartListStyles>

        default:
            return <></>;
    }
});


const DismantledPartsPaper: React.FC<IDismantledPartsListOwnProps> = React.memo((props: IDismantledPartsListOwnProps) => {
    const [openImageId, setImageId] = useState<number | undefined>(undefined);
    
    return (
        <LAGrid>
            {props.data.map((x, index) => {
                const images = renderImages(x);
                const openImages = () => setImageId(x.id);
                const onCloseImage = () => setImageId(undefined);

                return (
                    <LAGridItem xs={12} key={index}>

                        <LAPaperWithPadding className="itemPaper">
                            <LAGrid>
                                <LAGridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className={`${images.length > 1 ? "" : "disable-arrows"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {
                                    }}>
                                         <Gallery
                                            id="gallery"
                                            height={350}
                                            width={450}
                                            loop={true}
                                            slideshowDelay={0}
                                            dataSource={images}
                                            showNavButtons={images.length > 1 ? true : false}
                                            showIndicator={false}
                                            onItemClick={openImages}
                                            />
                                    </div>

                                    {((openImageId !== undefined) && (openImageId === x.id)) &&
                                     <div className={(images.length > ONE ? "" : "disableArrows")}>
                                                <Lightbox 
                                                    images={images} 
                                                    onClose={onCloseImage}
                                                />
                                                </div>
                                            }
                                </LAGridItem>

                                <LAGridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <LAGrid spacing={1}>

                                        <LAGridItem xs={12} md={12}>
                                            <LAGrid spacing={3} justify="space-between" alignItems="center">
                                                <LAGridItem xs={12} sm={6} md={6} lg={7} xl={8}>
                                                    <div className="cursor" onClick={(e: React.MouseEvent<unknown>): void => {
                                                    }}>
                                                        <LALinkButton label={`${x.make}`} onClick={(): void => undefined} fontSize={20} />
                                                    </div>
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={6} md={6} lg={5} xl={4}>
                                                    <LAGrid spacing={0} justify="flex-end" alignItems="flex-end">
                                                        <LAGridItem sm={1} md={5}>

                                                        </LAGridItem>

                                                        <LAGridItem sm={11} md={7}>
                                                         
                                                        </LAGridItem>
                                                    </LAGrid>
                                                </LAGridItem>

                                            </LAGrid>
                                            <hr />
                                        </LAGridItem>

                                        <LAGridItem xs={12} md={12}>
                                            <LAGrid>

                                                <LAGridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <LAGrid alignItems="center" justify="space-between">

                                                    <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong className="">Serial #: </strong>
                                                            <span className=""> {x.serial_No}</span>
                                                        </LAGridItem>
 
                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong className="">Unit #: </strong>
                                                            <span className=""> {x.unit_No}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong className="">Model: </strong>
                                                            <span className=""> {x.model}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Equipment Type: </strong>
                                                            <span className=""> {x.equipment_Type}</span>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} className="qtyWrapper">
                                                            <strong>Location: </strong>
                                                            <span className=""> {x.location}</span>
                                                        </LAGridItem>

                                                    </LAGrid>
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <LAGrid alignItems="center" justify="space-between">

                                                    </LAGrid>
                                                </LAGridItem>


                                            </LAGrid>
                                            <hr />
                                        </LAGridItem>

                                        {/* <LAGridItem xs={12}>
                                            <LAGrid>
                                                <LAGridItem xs={12}>
                                                    <strong>Notes: </strong>
                                                    <span>{x.notes}</span>
                                                </LAGridItem>
                                            </LAGrid>
                                        </LAGridItem> */}

                                    </LAGrid>
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                    </LAGridItem>
                );
            })}
        </LAGrid>
    )
});

const DismantledPartsGridPaperStyles = styled("div")`
    .title {
        font-weight: bold;
        background-color: ${DARK_GREY_COLOR};
    };
`;

const DismantledPartsGridPaper: React.FC<IDismantledPartsListOwnProps> = React.memo((props: IDismantledPartsListOwnProps) =>
    {
        const [openImageId, setImageId] = useState<number | undefined>(undefined);

        return <DismantledPartsGridPaperStyles>
            <TableContainer component={LAPaperWithLessPadding}>
                <Table aria-label="Parts Grid">
                    <TableHead>
                        <TableRow>
                            <TableCell className="title" align="center"></TableCell>
                            <TableCell className="title" align="center">Serial #</TableCell>
                            <TableCell className="title" align="center">Unit #</TableCell>
                            <TableCell className="title" align="center">Make</TableCell>
                            <TableCell className="title" align="center">Model</TableCell>
                            <TableCell className="title" align="center">Equipment</TableCell>
                            {/* <TableCell className="title" align="center">Hours</TableCell> */}
                            <TableCell className="title" align="center">Location</TableCell>
                            {/* <TableCell className="title" align="center">Notes</TableCell> */}
                            <TableCell className="title" align="center"></TableCell>
                            <TableCell className="title" align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((part) => {
                            const images = renderImages(part);
                            const openImages = () => setImageId(part.id);
                            const onCloseImage = () => setImageId(undefined);
                            const onPartClick = () => props.onPartClick(part);

                            return (
                                <TableRow key={part.id} className="itemPaper">
                                    <TableCell align="center" component="th" scope="row">
                                        <div className={`${images.length > ONE ? "itemImageWithThumb" : "itemImage"} cursor`} onClick={(e: React.MouseEvent<unknown>): void => {
                                        } }>
                                            <Gallery
                                                id="gallery"
                                                height={150}
                                                loop={true}
                                                slideshowDelay={0}
                                                dataSource={images}
                                                showNavButtons={images.length > 1 ? true : false}
                                                showIndicator={false}
                                                onItemClick={openImages} />
                                        </div>

                                        {((openImageId !== undefined) && (openImageId === part.id)) &&
                                        <div className={(images.length > ONE ? "" : "disableArrows")}>
                                                <Lightbox
                                                    images={images}
                                                    onClose={onCloseImage}
                                            />
                                            </div>}
                                    </TableCell>

                                    <TableCell align="center">{part.serial_No}</TableCell>
                                    <TableCell align="center">{part.unit_No}</TableCell>
                                    <TableCell align="center">{part.make}</TableCell>
                                    <TableCell align="center">{part.model}</TableCell>
                                    <TableCell align="center">{part.equipment_Type}</TableCell>
                                    <TableCell align="center">{part.location}</TableCell>
                                    {/* <TableCell align="center">{part.notes}</TableCell> */}
                                    <TableCell align="center">
                                        <LASecondaryButton
                                            onClick={onPartClick}
                                            label="More Info" />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </DismantledPartsGridPaperStyles>;
    }
);


interface IPartImageRender {
    original: string;
    thumbnail: string;
};

export const getImage = (part: IPartDismantledEquipment | IGetPartsOrderByCustomerItem): IPartImageRender[] => {
    const images: IPartImageRender[] = [];

    if (part.photos_1 != null) {
        images.push({ original: part.photos_1, thumbnail: part.photos_1 });
    }
    if (part.photos_2 != null)
        images.push({ original: part.photos_2, thumbnail: part.photos_2 });

    if (part.photos_3 != null)
        images.push({ original: part.photos_3, thumbnail: part.photos_3 });

    if (images.length === ZEROTH)
        images.push({ original: Not_Available_Image, thumbnail: Not_Available_Image });

    return images;
};

export const renderImages = (part: IPartDismantledEquipment): any[] => {
    const images: any[] = [];

    if (part.photos_1 != null) {
        images.push(part.photos_1);
    }
    if (part.photos_2 != null)
        images.push(part.photos_2);

    if (part.photos_3 != null)
        images.push(part.photos_3);

    if (images.length === ZEROTH)
        images.push(Not_Available_Image);

    return images;
};
