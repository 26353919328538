import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";
import { getPartsOrderByCustomerLoadFailedAction, getPartsOrderByCustomerLoadSuccessAction, IGetPartsOrderByCustomerLoadAction, IGetPartsOrderByCustomerLoadFailedAction, IGetPartsOrderByCustomerSuccessAction } from "./getPartsOrderByCustomerActions";
import { IGetPartsOrderByCustomer, IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST } from "./getPartsOrderByCustomerConstants";

export const getPartsOrderByCustomerEpic: Epic = (
    action$: ActionsObservable<IGetPartsOrderByCustomerLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPartsOrderByCustomerSuccessAction | IGetPartsOrderByCustomerLoadFailedAction> =>
    action$.ofType(IGET_PARTS_ORDER_BY_CUSTOMER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IGetPartsOrderByCustomer[]>>(
                    END_POINTS.GET_PARTS_ORDER_BY_CUSTOMER,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<IGetPartsOrderByCustomer[]>): IGetPartsOrderByCustomerSuccessAction => getPartsOrderByCustomerLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(getPartsOrderByCustomerLoadFailedAction("Unable to load orders")))
                    )
            )
        );