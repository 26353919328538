
export enum IADD_PARTS_ORDER_REQUEST {
    REQUEST = "addPartsOrder/ADD_PARTS_ORDER_REQUEST",
    SUCCESS = "addPartsOrder/ADD_PARTS_ORDER_SUCCESS",
    FAILED = "addPartsOrder/ADD_PARTS_ORDER_FAILED"
};

export interface IAddPartsOrderRequest {
    token?: string;
    request: IAddPartsOrder;
};

export interface IAddPartsOrder {
    Created: string;
    Created_By: string;
    Customer_ID: number;
    Shipping_Address: string;
    Shipping_Province: string;
    Shipping_City: string;
    Shipping_Country: string;
    Shipping_Postal_Code: string;
    Items: IAddPartsOrderItem[];
};

export interface IAddPartsOrderItem {
    Part_no: string;
    Quantity: number;
    Notes: string;
    Price: number;
    Total_Cost: number;
};

export interface IAddPartsOrderResponse {

};
