import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ajaxHeadersFromStore, withJsonContentType } from "../../epicUtils";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../endpoints";
import { IPart } from "../getParts/getPartsConstants";
import { IGET_PARTS_BY_IDS_REQUEST } from "./getPartsByIdsConstants";
import { getPartsByIdsLoadFailedAction, getPartsByIdsLoadSuccessAction, IGetPartsByIdsLoadAction, IGetPartsByIdsLoadFailedAction, IGetPartsByIdsSuccessAction } from "./getPartsByIdsActions";

export const getPartsByIdsEpic: Epic = (
    action$: ActionsObservable<IGetPartsByIdsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPartsByIdsSuccessAction | IGetPartsByIdsLoadFailedAction> =>
    action$.ofType(IGET_PARTS_BY_IDS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<IPart[]>>(
                    END_POINTS.GET_PARTS_BY_IDS,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<IPart[]>): IGetPartsByIdsSuccessAction => getPartsByIdsLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(getPartsByIdsLoadFailedAction("Unable to load parts")))
                    )
            )
        );