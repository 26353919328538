import { IFlushDataSuccessAction } from "../flushData.tsx/flushDataActions";
import { Server, loading, failed, succeeded, notLoaded } from "../server";
import { IRegistrationLoadAction, IRegistrationLoadFailedAction, IRegistrationSuccessAction } from "./registrationActions";
import { IREGISTRATION_REQUEST } from "./registrationConstants";


type Actions =
    IRegistrationLoadAction
    | IRegistrationLoadFailedAction
    | IRegistrationSuccessAction
    | IFlushDataSuccessAction;

export const RegistrationReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IREGISTRATION_REQUEST.REQUEST:
            return loading;

        case IREGISTRATION_REQUEST.SUCCESS:
            return succeeded("Success");

        case IREGISTRATION_REQUEST.FAILED:
            return failed(action.message);

        default:
            return state;
    }
};