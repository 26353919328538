
export enum IREGISTRATION_REQUEST {
    REQUEST = "registration/REGISTRATION_REQUEST",
    SUCCESS = "registration/REGISTRATION_SUCCESS",
    FAILED = "registration/REGISTRATION_FAILED"
};

export interface IRegistrationRequest {
    request: IRegistrationData;
    token?: string;
};

export interface IRegistrationResponse {
    customer_Id: number;
    token: string;
};

export interface IRegistrationData {
    Id?: number;
    First_Name: string;
    Last_Name: string;
    Email: string;
    Password: string;
    City: string;
    Province: string;
    Country: string;
    Address: string;
    Postal_Code: string;
    Phone: string;
    Billing_City: string;
    Billing_Province: string;
    Billing_Country: string;
    Billing_Address: string;
    Billing_Postal_Code: string;
    Company: string;
};