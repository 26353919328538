import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../reducers";
import { partsOrderCustomerQuoteResponseLoadFailedAction, partsOrderCustomerQuoteResponseLoadSuccessAction, IPartsOrderCustomerQuoteResponseLoadAction, IPartsOrderCustomerQuoteResponseLoadFailedAction, IPartsOrderCustomerQuoteResponseSuccessAction } from "./partsOrderCustomerQuoteResponseActions";
import { IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST } from "./partsOrderCustomerQuoteResponseConstants";
import { PartsResponse } from "../../react/shared/publicInterfaces";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../epicUtils";

export const partsOrderCustomerQuoteResponseEpic: Epic = (
    action$: ActionsObservable<IPartsOrderCustomerQuoteResponseLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IPartsOrderCustomerQuoteResponseSuccessAction | IPartsOrderCustomerQuoteResponseLoadFailedAction> =>
    action$.ofType(IPARTS_ORDER_CUSTOMER_QUOTE_RESPONSE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<string>>(
                    END_POINTS.PARTS_ORDER_CUSTOMER_QUOTE_RESPONSE,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: PartsResponse<string>): IPartsOrderCustomerQuoteResponseSuccessAction => {
                            return partsOrderCustomerQuoteResponseLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(partsOrderCustomerQuoteResponseLoadFailedAction("Unable to save your selection")))
                    )
            )
        );